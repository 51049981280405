import { Box, SxProps, Typography } from '@mui/material';
import { ImageLoader, loadImage } from '@prismamedia/one-components';
import { Options } from 'pages/RailroadPage/DisplayOptions';
import { FC, useEffect, useState } from 'react';
import { theme } from 'theme';
import { getRailroadImage } from '../utils';
import { RailroadStatusBar } from './RailroadStatusBar';
import { getColorFromDecimal, getWorkFlow, HalfPage } from './utils';

export const RailroadIssueHalfPage: FC<{
  page: HalfPage | null;
  previousPage: HalfPage | null;
  nextPage: HalfPage | null;
  displayOptions?: Options;
  sx?: SxProps;
  pageWidth: number;
  withInfos?: boolean;
  pageRatio?: number;
  setPageRatio?: (height: number) => void;
}> = ({
  page,
  previousPage,
  nextPage,
  sx,
  pageWidth,
  withInfos,
  setPageRatio,
  displayOptions,
  pageRatio,
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (
      !page?.filename ||
      (displayOptions && !displayOptions.displayThumbnails)
    ) {
      return;
    }

    getRailroadImage(page.filename)
      .then((res) => {
        const urlCreator = window.URL || window.webkitURL;
        const src = urlCreator.createObjectURL(res);
        setImageSrc(src);
        return loadImage(src);
      })
      .then((image) => setPageRatio?.(image.height / image.width))
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page?.filename, displayOptions?.displayThumbnails]);

  const pageIsEven = Number(page?.pageNumber || 0) % 2 === 0;

  const workflow = getWorkFlow(page);

  const displayedSrc =
    !displayOptions || displayOptions.displayThumbnails ? imageSrc : undefined;

  if (!page) return null;

  return (
    <Box
      sx={{
        breakInside: 'avoid',
        width: pageWidth,
        ...sx,
      }}
    >
      {withInfos && (
        <>
          <RailroadStatusBar
            value={page.sheet?.id}
            previousValue={previousPage?.sheet?.id}
            nextValue={nextPage?.sheet?.id}
            pageNumber={page.pageNumber}
            pageWidth={pageWidth}
            label={page.sheet?.name}
            sx={{ mb: 0.5 }}
            color={getColorFromDecimal(page.sheet?.color)}
          />
          <RailroadStatusBar
            value={page.indesignFilename}
            previousValue={previousPage?.indesignFilename}
            nextValue={nextPage?.indesignFilename}
            pageNumber={page.pageNumber}
            pageWidth={pageWidth}
            label={page.indesignFilename}
            sx={{ mb: 1 }}
            color={page.indesignFilename ? theme.palette.grey[400] : undefined}
          />
          <Typography
            sx={{
              width: '100%',
              textAlign: pageIsEven ? 'left' : 'right',
              fontSize: 10,
              height: 15,
              maxWidth: pageWidth,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '@media print': {
                fontSize: 4,
                height: 6,
              },
            }}
          >
            {workflow?.label_workflowStep}
          </Typography>
        </>
      )}
      <Box
        sx={{
          border:
            !displayOptions || displayOptions.displayBorders
              ? `${pageWidth * 0.02}px solid ${
                  getColorFromDecimal(Number(workflow?.workflowStepColor)) ||
                  theme.palette.common.black
                }`
              : undefined,
          display: 'flex',
        }}
      >
        <ImageLoader
          src={displayedSrc}
          loaderSize={pageWidth / 5}
          sx={{ minHeight: pageWidth * (pageRatio || 1.1) }}
        />
      </Box>
      <Typography
        sx={{
          width: '100%',
          textAlign: pageIsEven ? 'left' : 'right',
          fontSize: 10,
          maxWidth: pageWidth,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '@media print': {
            fontSize: 4,
            height: 6,
          },
        }}
      >
        {page.pageNumber}
      </Typography>
    </Box>
  );
};
