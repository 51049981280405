import { DialogContent, DialogTitle, List, ListItem } from '@mui/material';
import {
  IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_censhareCreationDate_CenshareRailroadByNameCreationDateResultType,
  IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_censhareUpdateDate_CenshareRailroadByNameUpdateDateResultType,
} from '__generated__/queries-topic';
import { format } from 'date-fns';
import { FC } from 'react';

export const RailroadHistoryDialog: FC<{
  censhareCreationDate:
    | IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_censhareCreationDate_CenshareRailroadByNameCreationDateResultType
    | null
    | undefined;
  censhareUpdateDate:
    | IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_censhareUpdateDate_CenshareRailroadByNameUpdateDateResultType
    | null
    | undefined;
}> = ({ censhareCreationDate, censhareUpdateDate }) => {
  return (
    <>
      <DialogTitle>Historique des modifications du chemin de fer</DialogTitle>
      <DialogContent>
        <List>
          {censhareCreationDate?.date && (
            <ListItem>
              Créé par : {censhareCreationDate.label_by} le{' '}
              {format(new Date(censhareCreationDate.date), 'Pp')}
            </ListItem>
          )}
          {censhareUpdateDate?.date && (
            <ListItem>
              Modifié par : {censhareUpdateDate.label_by} le{' '}
              {format(new Date(censhareUpdateDate.date), 'Pp')}
            </ListItem>
          )}
        </List>
      </DialogContent>
    </>
  );
};
