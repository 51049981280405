import { auth } from 'utils/auth';
import {
  useGetCenshareRailroadByName,
  useGetElisaPdf,
  useGetPrintIssue,
  usePrintIssue,
} from './issuePage.topic.graphql';
import { HalfPage, Sheet } from './Railroad/utils';

export const usePdfUrl = (printIssueId: string | undefined) => {
  const { data } = usePrintIssue(
    {
      where: { id: printIssueId },
    },
    !printIssueId,
  );

  const brandKey = data?.printIssue.printPublication.key || '';
  const issue = Number(data?.printIssue.title || '');

  return useGetElisaPdf({ brandKey, issue }, !brandKey || !issue);
};

export const useGetPdf = () => {
  const getPrintIssue = useGetPrintIssue();

  return async (printIssueId: string) => {
    const { printIssue } = await getPrintIssue({ where: { id: printIssueId } });

    const brandKey = printIssue.printPublication.key;
    const jwt = await auth.getJwt();

    const res = await fetch(
      `${config.API_ONE_TOPIC}/downloadPdf/${brandKey}/${printIssue.title}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/pdf',
        },
      },
    );

    if (!res.ok) throw new Error(`Error ${res.status} (${res.statusText})`);

    const blob = await res.blob();

    return {
      blob,
      title: `${brandKey}_${printIssue.title}.pdf`,
    };
  };
};

export const useGetRailRoadPages = (printIssueId: string | undefined) => {
  const { data: printIssueData } = usePrintIssue(
    {
      where: { id: printIssueId },
    },
    !printIssueId,
  );

  const brandKey = printIssueData?.printIssue.printPublication.key || '';
  const issue = printIssueData?.printIssue.title || '';

  const { data, loading, error } = useGetCenshareRailroadByName(
    { brandKey, issue },
    !brandKey || !issue,
  );

  let orderedSheets: Sheet[] = [];
  try {
    const sheets = JSON.parse(data?.getCenshareRailroadByName?.sheets || '[]');

    // eslint-disable-next-line fp/no-mutating-methods
    const firstHalfOfIssueSheets = [...sheets].sort(
      (sheetA, sheetB) =>
        sheetA.traits.sortable.sorting - sheetB.traits.sortable.sorting,
    );

    // eslint-disable-next-line fp/no-mutating-methods
    const lastHalfOfIssueSheets = [...sheets].sort(
      (sheetA, sheetB) =>
        sheetB.traits.sortable.sorting - sheetA.traits.sortable.sorting,
    );

    orderedSheets = [
      ...firstHalfOfIssueSheets,
      ...lastHalfOfIssueSheets,
    ].reduce<Sheet[]>(
      (prev, sheet) => [
        ...prev,
        ...new Array(sheet.traits.sheet_info.firstHalfPages).fill({
          id: sheet.self,
          name: sheet.traits.display.name,
          color: sheet.traits.display.color,
        }),
      ],
      [],
    );
  } catch (e) {}

  const pages = data?.getCenshareRailroadByName?.pages?.reduce<HalfPage[]>(
    (prev, page, index) => {
      let parsedFilenames: { page: number; url: string }[] = [];
      try {
        parsedFilenames = JSON.parse(page?.filename || '');
      } catch (e) {}

      const filenamePage =
        (prev.filter(
          ({ indesignFilename }) => indesignFilename === page?.indesignFilename,
        )?.length || 0) + 1;

      const filename = parsedFilenames.find(
        (parsedFilename) => parsedFilename.page === filenamePage,
      );

      return [
        ...prev,
        {
          ...page,
          sheet: orderedSheets[index],
          filename: filename?.url,
        } as HalfPage,
      ];
    },
    [],
  );

  return { ...data?.getCenshareRailroadByName, pages, loading, error };
};

export const getRailroadImage = async (filename: string) => {
  const jwt = await auth.getJwt();

  const encodedRessourceId = encodeURIComponent(filename);

  const res = await fetch(
    `${config.API_ONE_TOPIC}/censhare/download?url=${encodedRessourceId}`,
    { headers: { Authorization: `Bearer ${jwt}` } },
  );

  if (!res.ok) throw new Error(`Error ${res.status} (${res.statusText})`);

  return res.blob();
};
