import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { backgroundIsLight, MARGIN_BETWEEN_TWO_PAGES } from './utils';

const GAP_BETWEEN_DIFFERENT_VALUES = 0.2;

export const RailroadStatusBar: FC<{
  value: string | undefined | null;
  previousValue: string | undefined | null;
  nextValue: string | undefined | null;
  label: string | undefined | null;
  pageNumber: string;
  pageWidth: number;
  sx?: SxProps;
  color?: string;
}> = ({
  value,
  previousValue,
  nextValue,
  pageNumber,
  pageWidth,
  color,
  sx,
  label,
}) => {
  const pageIsEven = Number(pageNumber || 0) % 2 === 0;

  const extendsToPreviousPage = pageIsEven && value && value === previousValue;
  const extendsToNextPage = !pageIsEven && value && value === nextValue;

  const gapBeforePreviousPage =
    !pageIsEven && previousValue && value && previousValue !== value;
  const gapBeforeNextPage =
    pageIsEven && nextValue && value && nextValue !== value;

  return (
    <Tooltip title={label}>
      <Box
        sx={{
          width: `${
            pageWidth +
            (extendsToPreviousPage ? (MARGIN_BETWEEN_TWO_PAGES / 2) * 8 : 0) +
            // X*8 replace theme.spacing(X) which return a string
            (extendsToNextPage ? (MARGIN_BETWEEN_TWO_PAGES / 2) * 8 : 0) -
            (gapBeforePreviousPage || gapBeforeNextPage
              ? GAP_BETWEEN_DIFFERENT_VALUES * 8
              : 0)
          }px`,
          height: 15,
          bgcolor: color,
          ml: extendsToPreviousPage
            ? -1
            : gapBeforePreviousPage
            ? GAP_BETWEEN_DIFFERENT_VALUES
            : undefined,
          mr: extendsToNextPage
            ? -1
            : gapBeforeNextPage
            ? GAP_BETWEEN_DIFFERENT_VALUES
            : undefined,
          display: 'flex',
          alignItems: 'center',
          px: 1,
          overflow: 'hidden',
          '@media print': {
            height: 8,
          },
          ...sx,
        }}
      >
        {(value !== previousValue || value !== nextValue) && (
          <Typography
            sx={{
              width: '100%',
              fontSize: 10,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              color:
                color && backgroundIsLight(color)
                  ? theme.palette.common.black
                  : theme.palette.common.white,
              textAlign:
                value !== nextValue
                  ? value !== previousValue
                    ? 'center'
                    : 'right'
                  : undefined,
              '@media print': {
                fontSize: 4,
              },
            }}
          >
            {label}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};
