import { IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_pages_CenshareRailroadByNamePagesResultType } from '__generated__/queries-topic';
import tinycolor from 'tinycolor2';

export const MARGIN_BETWEEN_TWO_PAGES = 2;

export interface Sheet {
  id: string;
  color: number;
  name: string;
}

export interface HalfPage
  extends IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_pages_CenshareRailroadByNamePagesResultType {
  sheet: Sheet | undefined;
}

export const getWorkFlow = (page: HalfPage | null) => {
  try {
    return JSON.parse(page?.workflow || '') as Record<string, unknown>;
  } catch (e) {
    return undefined;
  }
};

export const getColorFromDecimal = (decimalColor: number | undefined) =>
  decimalColor ? `#${decimalColor.toString(16)}` : undefined;

export const backgroundIsLight = (hexColor: string) => {
  return tinycolor(hexColor).isLight();
};
