import { gql } from '@apollo/client';

export const PHOTO_FRAGMENT = gql`
  fragment Photo on Photo {
    id
    archive {
      id
      accesses
    }
    createdAt
    updatedAt
    height
    width
    filesize
    originalName
    contentType
    publicUrl
    downloadUrl
    previews {
      href
      size
    }
    photoPublications(first: 100) {
      printIssueTitle
      printPublicationKey
      page
    }
    metadataByName(
      names: [
        title
        subject
        Instructions
        creator
        Headline
        Credit
        Source
        rights
        description
        CustomField14
        ImageNotes
        UploadedBy
        IdPhotoPrisma
        Product
        Issue
        Undersubject
        DeliveryCompany
        OriginalName
        DeliveryKind
        DeliverySubject
        ResolutionKind
        ProcessHistory
        DeliveryPhotoshopCredit
        BackupName
        UserDefined239
        PriceLevel
        Urgency
        EditStatus
        UserDefined238
        ProcessStatus
        DateCreated
      ]
    )
  }
`;
