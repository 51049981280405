import { Unit } from '@prismamedia/one-brandkey';
import { Auth } from '@prismamedia/one-components';
import { RoleUnit } from '@prismamedia/one-user';

export const auth = new Auth(config.API_ONE_AUTH);

export const isUserAdmin = () =>
  auth.user?.isSuperAdmin() ||
  auth.user?.isAdmin() ||
  auth.user?.isAdmin(Unit.PECO as RoleUnit) ||
  auth.user?.isAdmin(Unit.PFEM as RoleUnit) ||
  auth.user?.isAdmin(Unit.PTVS as RoleUnit) ||
  auth.user?.isAdmin(Unit.GEO as RoleUnit);
