import { SxProps } from '@mui/material';
import { Box } from '@mui/system';
import { Options } from 'pages/RailroadPage/DisplayOptions';
import { FC } from 'react';
import { RailroadIssueHalfPage } from './RailroadIssueHalfPage';
import { HalfPage } from './utils';

export const RailroadIssueFullPage: FC<{
  pages: (HalfPage | null)[];
  doublePage: (HalfPage | null)[];
  pageWidth: number;
  displayOptions?: Options;
  withInfos?: boolean;
  sx?: SxProps;
  setPageRatio?: (ratio: number) => void;
  pageRatio?: number;
}> = ({
  pages,
  doublePage,
  sx,
  pageWidth,
  withInfos,
  setPageRatio,
  pageRatio,
  displayOptions,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        ...sx,
      }}
    >
      {doublePage.map((page, i) => (
        <RailroadIssueHalfPage
          withInfos={withInfos}
          key={i}
          page={page}
          previousPage={page && pages[Number(page.pageNumber) - 2]}
          nextPage={page && pages[Number(page.pageNumber)]}
          pageWidth={pageWidth}
          displayOptions={displayOptions}
          pageRatio={pageRatio}
          setPageRatio={setPageRatio}
        />
      ))}
    </Box>
  );
};
