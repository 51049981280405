import {
  Download,
  History,
  MenuBook,
  Print,
  Visibility,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  CircularProgress,
  createTheme,
  MenuItem,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {
  HomeAppBar,
  IconLabelButton,
  NotificationTypeEnum,
  PageWithDrawer,
  useAppBarHeight,
  useDialog,
  useMenu,
  useNotification,
  useOnMount,
} from '@prismamedia/one-components';
import { OneDrawerMenu } from 'components/OneDrawerMenu';
import { PrintPortal } from 'components/PrintPortal';
import { format } from 'date-fns';
import { RailroadIssueFullPage } from 'pages/IssuePage/Railroad/RailroadIssueFullPage';
import {
  HalfPage,
  MARGIN_BETWEEN_TWO_PAGES,
} from 'pages/IssuePage/Railroad/utils';
import {
  useGetPdf,
  useGetRailRoadPages,
  usePdfUrl,
} from 'pages/IssuePage/utils';
import { FC, useEffect, useRef, useState } from 'react';
import { theme } from 'theme';
import { StringParam, useQueryParam } from 'use-query-params';
import { auth } from 'utils/auth';
import { download } from 'utils/downloadBlob';
import { DisplayOptions, Options } from './DisplayOptions';
import { FILTER_BAR_HEIGHT, FilterBar } from './FilterBar';
import { RailroadHistoryDialog } from './RailroadHistoryDialog';

enum PrintFormat {
  A4 = 'A4',
  A3 = 'A3',
}

export const RailroadPage: FC = () => {
  const appBarHeight = useAppBarHeight();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedIssue, setSelectedIssue] = useState<string | undefined>(
    undefined,
  );
  const { openDialog } = useDialog();
  const [selectedIssueUrl, setSelectedIssueUrl] = useQueryParam(
    'issue',
    StringParam,
  );
  const [loadingUrlParams, setLoadingUrlParams] = useState(true);
  const [pageRatio, setPageRatio] = useState<number | undefined>(undefined);
  const [displayOptions, setDisplayOptions] = useState<Options>({
    displayBorders: true,
    displayThumbnails: true,
    size: 4,
  });
  const [pdfDownloadLoading, setPdfDownloadLoading] = useState(false);
  const getPdf = useGetPdf();
  const { data: pdfUrlData } = usePdfUrl(selectedIssue);
  const { pushNotification } = useNotification();
  const [printFormat, setPrintFormat] = useState<PrintFormat>(PrintFormat.A4);
  const { openMenu, closeMenu } = useMenu();

  const {
    pages,
    publicationDate,
    comment,
    censhareCreationDate,
    censhareUpdateDate,
    loading,
    error,
  } = useGetRailRoadPages(selectedIssue);

  useOnMount(() => {
    selectedIssueUrl && setSelectedIssue(selectedIssueUrl);
    setTimeout(() => setLoadingUrlParams(false));
  });

  useEffect(() => {
    setSelectedIssueUrl(selectedIssue, 'replaceIn');
  }, [selectedIssue, setSelectedIssueUrl]);

  const doublePages = pages?.reduce<(HalfPage | null)[][]>((prev, card) => {
    const lastBatch = prev[prev.length - 1];
    if (!lastBatch || lastBatch.length === 2 || prev.length === 1) {
      return [...prev, [card]];
    }
    // eslint-disable-next-line immutable/no-mutation
    prev[prev.length - 1] = [...lastBatch, card];
    return prev;
  }, []);

  const onDownloadPdf = async () => {
    if (!selectedIssue) return;

    setPdfDownloadLoading(true);
    try {
      const { blob, title } = await getPdf(selectedIssue);
      download(blob, title);
    } catch (e) {
      pushNotification({
        message: (e as Error).message,
        type: NotificationTypeEnum.error,
      });
    }
    setPdfDownloadLoading(false);
  };

  const renderPage = (print?: boolean) => {
    if (!pages) return null;

    let halfPageWidth =
      (((wrapperRef.current?.offsetWidth || window.innerWidth) - 2 * 8 - 1) /
        (9 - displayOptions.size) -
        2 * 8) /
      2;

    if (print) {
      switch (printFormat) {
        case PrintFormat.A4:
          halfPageWidth = Math.max((200 - pages.length) / 2, 30);
          break;

        case PrintFormat.A3:
          halfPageWidth = Math.max((250 - pages.length) / 2, 30);
          break;
      }
    }

    return (
      <Box
        ref={wrapperRef}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignSelf: 'center',
          pt: 2,
          pl: 2,
        }}
      >
        <Box
          sx={{
            width: halfPageWidth,
            color: theme.palette.grey[600],
            fontSize: 14,
            pr: 2,
            pb: 2,
            overflow: 'hidden',
          }}
        >
          {publicationDate && (
            <Box
              sx={{
                mb: 2,
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Box sx={{ fontWeight: 'bold' }}>Date de publication</Box>
              <Box>{format(new Date(publicationDate), 'P')}</Box>
            </Box>
          )}
          {comment && (
            <Box
              sx={{
                mb: 2,
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Box sx={{ fontWeight: 'bold' }}>Commentaire</Box>
              <Box sx={{ whiteSpace: 'pre-line' }}>{comment}</Box>
            </Box>
          )}
        </Box>
        {doublePages?.map((doublePage, i) => (
          <RailroadIssueFullPage
            withInfos
            key={i}
            pages={pages}
            doublePage={doublePage}
            pageWidth={halfPageWidth}
            displayOptions={displayOptions}
            sx={{
              mr: MARGIN_BETWEEN_TWO_PAGES,
              mb: 2,
              '@media print': {
                mb: 1,
              },
            }}
            setPageRatio={(ratio) => {
              setPageRatio((prev) => prev || ratio);
            }}
            pageRatio={pageRatio}
          />
        ))}
      </Box>
    );
  };

  return (
    <>
      <HomeAppBar
        appDrawerMenu={<OneDrawerMenu />}
        brandMenu={<Typography variant="h6">Chemin de fer</Typography>}
        currentUser={auth.user}
        disconnectUser={auth.logout}
        additionalElement={
          <ThemeProvider theme={createTheme({ palette: { mode: 'dark' } })}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {selectedIssue && (
                <>
                  {pdfDownloadLoading ? (
                    <CircularProgress
                      sx={{
                        mr: 2,
                        '.MuiCircularProgress-svg': { color: 'white' },
                      }}
                    />
                  ) : (
                    <IconLabelButton
                      label="Télécharger"
                      onClick={() => onDownloadPdf()}
                      sx={{ mr: 2 }}
                      disabled={!pdfUrlData}
                    >
                      <Download />
                    </IconLabelButton>
                  )}
                  <IconLabelButton
                    label="Visualiser"
                    onClick={() =>
                      pdfUrlData && window.open(pdfUrlData.getElisaPdf.link)
                    }
                    sx={{ mr: 2 }}
                    disabled={!pdfUrlData}
                  >
                    <Visibility />
                  </IconLabelButton>
                  <IconLabelButton
                    label="Imprimer"
                    onClick={(e) =>
                      openMenu(
                        e.currentTarget,
                        <div>
                          {Object.values(PrintFormat).map((pFormat) => (
                            <MenuItem
                              key={pFormat}
                              onClick={() => {
                                setPrintFormat(pFormat);
                                closeMenu();
                                setTimeout(() => {
                                  window.print();
                                });
                              }}
                            >
                              {pFormat}
                            </MenuItem>
                          ))}
                        </div>,
                      )
                    }
                    sx={{ mr: 2 }}
                  >
                    <Print />
                  </IconLabelButton>
                  <IconLabelButton
                    label="Historique"
                    onClick={() => {
                      openDialog(
                        <RailroadHistoryDialog
                          censhareCreationDate={censhareCreationDate}
                          censhareUpdateDate={censhareUpdateDate}
                        />,
                      );
                    }}
                    edge="end"
                    size="large"
                  >
                    <History />
                  </IconLabelButton>
                </>
              )}
            </Box>
          </ThemeProvider>
        }
      />
      <AppBar
        position="fixed"
        color="default"
        sx={{
          top: appBarHeight,
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <FilterBar
          selectedIssue={selectedIssue}
          setSelectedIssue={setSelectedIssue}
        />
        <DisplayOptions
          sx={{ ml: 'auto' }}
          displayOptions={displayOptions}
          setDisplayOptions={setDisplayOptions}
        />
      </AppBar>
      <PageWithDrawer
        fullWidth
        sx={{ minHeight: '100%' }}
        paddingTop={FILTER_BAR_HEIGHT}
        error={error}
        loading={loading}
      >
        {selectedIssue ? (
          !loadingUrlParams &&
          (pages?.length ? (
            renderPage()
          ) : (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: theme.palette.primary.main,
              }}
            >
              <Box sx={{ fontSize: 25 }}>
                Aucun chemin de fer pour cette parution
              </Box>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              color: theme.palette.primary.main,
            }}
          >
            <MenuBook sx={{ fontSize: 40, mb: 2 }} />
            <Box sx={{ fontSize: 25 }}>
              Sélectionnez un magazine et une parution
            </Box>
          </Box>
        )}
      </PageWithDrawer>
      <PrintPortal>{renderPage(true)}</PrintPortal>
    </>
  );
};
