import {
  Download,
  GridViewRounded,
  MenuBook,
  Visibility,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import {
  HomeAppBar,
  IconLabelButton,
  NotificationTypeEnum,
  PageWithDrawer,
  useAppBarHeight,
  useNotification,
  useOnMount,
} from '@prismamedia/one-components';
import { OneDrawerMenu } from 'components/OneDrawerMenu';
import { FC, useEffect, useState } from 'react';
import { paths } from 'routing';
import { theme } from 'theme';
import { JsonParam, StringParam, useQueryParam } from 'use-query-params';
import { auth } from 'utils/auth';
import { download } from 'utils/downloadBlob';
import { FILTER_BAR_HEIGHT, FilterBar } from './FilterBar';
import { Filters, filtersAreDefault, getDefaultFilters } from './filters';
import { Railroad } from './Railroad';
import { Table } from './Table';
import { useGetPdf, usePdfUrl } from './utils';

export const IssuePage: FC = () => {
  const appBarHeight = useAppBarHeight();
  const [filters, setFilters] = useState<Filters>(getDefaultFilters());
  const [selectedIssue, setSelectedIssue] = useState<string | undefined>(
    undefined,
  );
  const [filtersUrl, setFiltersUrl] = useQueryParam('filters', JsonParam);
  const [selectedIssueUrl, setSelectedIssueUrl] = useQueryParam(
    'issue',
    StringParam,
  );
  const [loadingUrlParams, setLoadingUrlParams] = useState(true);
  const [pdfDownloadLoading, setPdfDownloadLoading] = useState(false);
  const getPdf = useGetPdf();
  const { data: pdfUrlData } = usePdfUrl(selectedIssue);
  const { pushNotification } = useNotification();

  useOnMount(() => {
    filtersUrl && setFilters(filtersUrl as Filters);
    selectedIssueUrl && setSelectedIssue(selectedIssueUrl);
    setTimeout(() => setLoadingUrlParams(false));
  });

  useEffect(() => {
    setSelectedIssueUrl(selectedIssue, 'replaceIn');
  }, [selectedIssue, setSelectedIssueUrl]);

  useEffect(() => {
    setFiltersUrl(
      filtersAreDefault(filters) ? undefined : filters,
      'replaceIn',
    );
  }, [filters, setFiltersUrl]);

  const onDownloadPdf = async () => {
    if (!selectedIssue) return;

    setPdfDownloadLoading(true);
    try {
      const { blob, title } = await getPdf(selectedIssue);
      download(blob, title);
    } catch (e) {
      pushNotification({
        message: (e as Error).message,
        type: NotificationTypeEnum.error,
      });
    }
    setPdfDownloadLoading(false);
  };

  return (
    <>
      <HomeAppBar
        appDrawerMenu={<OneDrawerMenu />}
        additionalElement={
          <ThemeProvider theme={createTheme({ palette: { mode: 'dark' } })}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {selectedIssue && (
                <>
                  {pdfDownloadLoading ? (
                    <CircularProgress
                      sx={{
                        mr: 2,
                        '.MuiCircularProgress-svg': { color: 'white' },
                      }}
                    />
                  ) : (
                    <IconLabelButton
                      label="Télécharger"
                      onClick={() => onDownloadPdf()}
                      sx={{ mr: 2 }}
                      disabled={!pdfUrlData}
                    >
                      <Download />
                    </IconLabelButton>
                  )}
                  <IconLabelButton
                    label="Visualiser"
                    onClick={() =>
                      pdfUrlData && window.open(pdfUrlData.getElisaPdf.link)
                    }
                    sx={{ mr: 2 }}
                    disabled={!pdfUrlData}
                  >
                    <Visibility />
                  </IconLabelButton>
                </>
              )}
              <IconLabelButton
                label="Chemin de fer"
                onClick={() =>
                  window
                    .open(
                      `${paths.RAILROAD}${
                        selectedIssue ? `?issue=${selectedIssue}` : ''
                      }`,
                      '_blank',
                    )
                    ?.focus()
                }
              >
                <GridViewRounded />
              </IconLabelButton>
            </Box>
          </ThemeProvider>
        }
        currentUser={auth.user}
        disconnectUser={auth.logout}
      />
      <AppBar position="fixed" color="default" sx={{ top: appBarHeight }}>
        <FilterBar
          selectedIssue={selectedIssue}
          setSelectedIssue={setSelectedIssue}
          filters={filters}
          setFilters={setFilters}
        />
      </AppBar>
      <PageWithDrawer
        fullWidth
        sx={{ minHeight: '100%' }}
        paddingTop={FILTER_BAR_HEIGHT}
      >
        {selectedIssue ? (
          !loadingUrlParams && (
            <>
              <Railroad selectedIssue={selectedIssue} />
              <Table selectedIssue={selectedIssue} filters={filters} />
            </>
          )
        ) : (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              color: theme.palette.primary.main,
            }}
          >
            <MenuBook sx={{ fontSize: 40, mb: 2 }} />
            <Box sx={{ fontSize: 25 }}>
              Sélectionnez un magazine et une parution
            </Box>
          </Box>
        )}
      </PageWithDrawer>
    </>
  );
};
