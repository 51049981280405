import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  NonEmptyString: { input: string; output: string; }
  UnsignedInt: { input: number; output: number; }
};

/** Article template data */
export type ArticleTemplate = {
  __typename?: 'ArticleTemplate';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** Number of "assignments" */
  assignmentCount: Scalars['UnsignedInt']['output'];
  assignments: Array<Assignment>;
  /** The raw datas */
  block?: Maybe<Scalars['String']['output']>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  fields: Array<Maybe<PrintTemplateField>>;
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The article template label */
  label?: Maybe<Scalars['String']['output']>;
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  printTemplate: PrintTemplate;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<User>;
};


/** Article template data */
export type ArticleTemplateAssignmentCountArgs = {
  where?: InputMaybe<AssignmentFilterInput>;
};


/** Article template data */
export type ArticleTemplateAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<AssignmentFilterInput>;
};

export type ArticleTemplateCreationAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutArticleTemplateInput>>;
};

/** The "ArticleTemplate" node's creation */
export type ArticleTemplateCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<ArticleTemplateCreationAssignmentsInput>;
  /** The raw datas */
  block?: InputMaybe<Scalars['String']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The article template label */
  label?: InputMaybe<Scalars['String']['input']>;
  printTemplate: ArticleTemplateCreationPrintTemplateInput;
};

export type ArticleTemplateCreationPrintTemplateCreateIfNotExistsInput = {
  data: PrintTemplateCreationInput;
  where: PrintTemplateUniqueFilterInput;
};

export type ArticleTemplateCreationPrintTemplateInput = {
  /** Connect a "PrintTemplate" to a new "ArticleTemplate" through the "ArticleTemplate.printTemplate" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintTemplateUniqueFilterInput>;
  /** Create a "PrintTemplate" and connect it to a new "ArticleTemplate" through the "ArticleTemplate.printTemplate" edge. */
  create?: InputMaybe<PrintTemplateCreationInput>;
  /** Create a "PrintTemplate" if it does not exist, and connect it to a new "ArticleTemplate" through the "ArticleTemplate.printTemplate" edge. */
  createIfNotExists?: InputMaybe<ArticleTemplateCreationPrintTemplateCreateIfNotExistsInput>;
};

/** The "ArticleTemplate" node's creation */
export type ArticleTemplateCreationWithoutPrintTemplateInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<ArticleTemplateCreationAssignmentsInput>;
  /** The raw datas */
  block?: InputMaybe<Scalars['String']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The article template label */
  label?: InputMaybe<Scalars['String']['input']>;
};

/** The "ArticleTemplate" nodes' filter */
export type ArticleTemplateFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<ArticleTemplateFilterInput>>>;
  assignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments_every?: InputMaybe<AssignmentFilterInput>;
  assignments_none?: InputMaybe<AssignmentFilterInput>;
  assignments_some?: InputMaybe<AssignmentFilterInput>;
  block?: InputMaybe<Scalars['String']['input']>;
  block_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  block_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  block_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  block_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  block_not?: InputMaybe<Scalars['String']['input']>;
  block_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  block_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  block_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  block_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  block_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  label_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  label_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  label_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  NOT?: InputMaybe<ArticleTemplateFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<ArticleTemplateFilterInput>>>;
  printTemplate?: InputMaybe<PrintTemplateFilterInput>;
  printTemplate_not?: InputMaybe<PrintTemplateFilterInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "ArticleTemplate" nodes */
export enum ArticleTemplateOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest number of "assignments" to the highest */
  assignmentCount_ASC = 'assignmentCount_ASC',
  /** from the highest number of "assignments" to the lowest */
  assignmentCount_DESC = 'assignmentCount_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "ArticleTemplate" with one of the following combination of components' value:
 * - _id
 * - id
 */
export type ArticleTemplateUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ArticleTemplateUpdateAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutArticleTemplateInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<AssignmentFilterInput>;
  updateAll?: InputMaybe<AssignmentUpdateWithoutArticleTemplateInput>;
  updateMany?: InputMaybe<ArticleTemplateUpdateAssignmentsUpdateManyInput>;
};

export type ArticleTemplateUpdateAssignmentsUpdateManyInput = {
  data?: InputMaybe<AssignmentUpdateWithoutArticleTemplateInput>;
  where?: InputMaybe<AssignmentFilterInput>;
};

/** The "ArticleTemplate" node's update */
export type ArticleTemplateUpdateInput = {
  assignments?: InputMaybe<ArticleTemplateUpdateAssignmentsInput>;
  /** The raw datas */
  block?: InputMaybe<Scalars['String']['input']>;
  /** The article template label */
  label?: InputMaybe<Scalars['String']['input']>;
  printTemplate?: InputMaybe<ArticleTemplateUpdatePrintTemplateInput>;
};

export type ArticleTemplateUpdatePrintTemplateCreateIfNotExistsInput = {
  data: PrintTemplateCreationInput;
  where: PrintTemplateUniqueFilterInput;
};

export type ArticleTemplateUpdatePrintTemplateInput = {
  /** Connect a "PrintTemplate" to an existing "ArticleTemplate" through the "ArticleTemplate.printTemplate" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintTemplateUniqueFilterInput>;
  /** Create a "PrintTemplate" and connect it to an existing "ArticleTemplate" through the "ArticleTemplate.printTemplate" edge. */
  create?: InputMaybe<PrintTemplateCreationInput>;
  /** Create a "PrintTemplate" if it does not exist, and connect it to an existing "ArticleTemplate" through the "ArticleTemplate.printTemplate" edge. */
  createIfNotExists?: InputMaybe<ArticleTemplateUpdatePrintTemplateCreateIfNotExistsInput>;
  /** Update the connected "PrintTemplate", throw an error if the "ArticleTemplate.printTemplate" edge does not exist. */
  update?: InputMaybe<PrintTemplateUpdateInput>;
};

/** The "ArticleTemplate" node's update */
export type ArticleTemplateUpdateWithoutPrintTemplateInput = {
  assignments?: InputMaybe<ArticleTemplateUpdateAssignmentsInput>;
  /** The raw datas */
  block?: InputMaybe<Scalars['String']['input']>;
  /** The article template label */
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Assignment of a subject on a printPublication, a printIssue or a printHeading */
export type Assignment = {
  __typename?: 'Assignment';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  articleTemplate?: Maybe<ArticleTemplate>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  flatAssignment?: Maybe<FlatAssignment>;
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  mediaSearchStatus: MediaSearchStatus;
  pageNumber?: Maybe<Scalars['Int']['output']>;
  printHeading?: Maybe<PrintHeading>;
  printIssue?: Maybe<PrintIssue>;
  printPublication?: Maybe<PrintPublication>;
  printTemplate?: Maybe<PrintTemplate>;
  rawArticle?: Maybe<RawArticle>;
  subject: Subject;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  website?: Maybe<Website>;
};

export type AssignmentCreationArticleTemplateCreateIfNotExistsInput = {
  data: ArticleTemplateCreationInput;
  where: ArticleTemplateUniqueFilterInput;
};

export type AssignmentCreationArticleTemplateInput = {
  /** Connect an "ArticleTemplate" to a new "Assignment" through the "Assignment.articleTemplate" edge, throw an error if it does not exist. */
  connect?: InputMaybe<ArticleTemplateUniqueFilterInput>;
  /** Connect an "ArticleTemplate" to a new "Assignment" through the "Assignment.articleTemplate" edge, if it exists. */
  connectIfExists?: InputMaybe<ArticleTemplateUniqueFilterInput>;
  /** Create an "ArticleTemplate" and connect it to a new "Assignment" through the "Assignment.articleTemplate" edge. */
  create?: InputMaybe<ArticleTemplateCreationInput>;
  /** Create an "ArticleTemplate" if it does not exist, and connect it to a new "Assignment" through the "Assignment.articleTemplate" edge. */
  createIfNotExists?: InputMaybe<AssignmentCreationArticleTemplateCreateIfNotExistsInput>;
};

/** The "Assignment" node's creation */
export type AssignmentCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplate?: InputMaybe<AssignmentCreationArticleTemplateInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentCreationPrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentCreationPrintIssueInput>;
  printPublication?: InputMaybe<AssignmentCreationPrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentCreationPrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentCreationRawArticleInput>;
  subject: AssignmentCreationSubjectInput;
  website?: InputMaybe<AssignmentCreationWebsiteInput>;
};

export type AssignmentCreationPrintHeadingCreateIfNotExistsInput = {
  data: PrintHeadingCreationInput;
  where: PrintHeadingUniqueFilterInput;
};

export type AssignmentCreationPrintHeadingInput = {
  /** Connect a "PrintHeading" to a new "Assignment" through the "Assignment.printHeading" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintHeadingUniqueFilterInput>;
  /** Connect a "PrintHeading" to a new "Assignment" through the "Assignment.printHeading" edge, if it exists. */
  connectIfExists?: InputMaybe<PrintHeadingUniqueFilterInput>;
  /** Create a "PrintHeading" and connect it to a new "Assignment" through the "Assignment.printHeading" edge. */
  create?: InputMaybe<PrintHeadingCreationInput>;
  /** Create a "PrintHeading" if it does not exist, and connect it to a new "Assignment" through the "Assignment.printHeading" edge. */
  createIfNotExists?: InputMaybe<AssignmentCreationPrintHeadingCreateIfNotExistsInput>;
};

export type AssignmentCreationPrintIssueCreateIfNotExistsInput = {
  data: PrintIssueCreationInput;
  where: PrintIssueUniqueFilterInput;
};

export type AssignmentCreationPrintIssueInput = {
  /** Connect a "PrintIssue" to a new "Assignment" through the "Assignment.printIssue" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintIssueUniqueFilterInput>;
  /** Connect a "PrintIssue" to a new "Assignment" through the "Assignment.printIssue" edge, if it exists. */
  connectIfExists?: InputMaybe<PrintIssueUniqueFilterInput>;
  /** Create a "PrintIssue" and connect it to a new "Assignment" through the "Assignment.printIssue" edge. */
  create?: InputMaybe<PrintIssueCreationInput>;
  /** Create a "PrintIssue" if it does not exist, and connect it to a new "Assignment" through the "Assignment.printIssue" edge. */
  createIfNotExists?: InputMaybe<AssignmentCreationPrintIssueCreateIfNotExistsInput>;
};

export type AssignmentCreationPrintPublicationCreateIfNotExistsInput = {
  data: PrintPublicationCreationInput;
  where: PrintPublicationUniqueFilterInput;
};

export type AssignmentCreationPrintPublicationInput = {
  /** Connect a "PrintPublication" to a new "Assignment" through the "Assignment.printPublication" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintPublicationUniqueFilterInput>;
  /** Connect a "PrintPublication" to a new "Assignment" through the "Assignment.printPublication" edge, if it exists. */
  connectIfExists?: InputMaybe<PrintPublicationUniqueFilterInput>;
  /** Create a "PrintPublication" and connect it to a new "Assignment" through the "Assignment.printPublication" edge. */
  create?: InputMaybe<PrintPublicationCreationInput>;
  /** Create a "PrintPublication" if it does not exist, and connect it to a new "Assignment" through the "Assignment.printPublication" edge. */
  createIfNotExists?: InputMaybe<AssignmentCreationPrintPublicationCreateIfNotExistsInput>;
};

export type AssignmentCreationPrintTemplateCreateIfNotExistsInput = {
  data: PrintTemplateCreationInput;
  where: PrintTemplateUniqueFilterInput;
};

export type AssignmentCreationPrintTemplateInput = {
  /** Connect a "PrintTemplate" to a new "Assignment" through the "Assignment.printTemplate" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintTemplateUniqueFilterInput>;
  /** Connect a "PrintTemplate" to a new "Assignment" through the "Assignment.printTemplate" edge, if it exists. */
  connectIfExists?: InputMaybe<PrintTemplateUniqueFilterInput>;
  /** Create a "PrintTemplate" and connect it to a new "Assignment" through the "Assignment.printTemplate" edge. */
  create?: InputMaybe<PrintTemplateCreationInput>;
  /** Create a "PrintTemplate" if it does not exist, and connect it to a new "Assignment" through the "Assignment.printTemplate" edge. */
  createIfNotExists?: InputMaybe<AssignmentCreationPrintTemplateCreateIfNotExistsInput>;
};

export type AssignmentCreationRawArticleCreateIfNotExistsInput = {
  data: RawArticleCreationInput;
  where: RawArticleUniqueFilterInput;
};

export type AssignmentCreationRawArticleInput = {
  /** Connect a "RawArticle" to a new "Assignment" through the "Assignment.rawArticle" edge, throw an error if it does not exist. */
  connect?: InputMaybe<RawArticleUniqueFilterInput>;
  /** Connect a "RawArticle" to a new "Assignment" through the "Assignment.rawArticle" edge, if it exists. */
  connectIfExists?: InputMaybe<RawArticleUniqueFilterInput>;
  /** Create a "RawArticle" and connect it to a new "Assignment" through the "Assignment.rawArticle" edge. */
  create?: InputMaybe<RawArticleCreationInput>;
  /** Create a "RawArticle" if it does not exist, and connect it to a new "Assignment" through the "Assignment.rawArticle" edge. */
  createIfNotExists?: InputMaybe<AssignmentCreationRawArticleCreateIfNotExistsInput>;
};

export type AssignmentCreationSubjectCreateIfNotExistsInput = {
  data: SubjectCreationInput;
  where: SubjectUniqueFilterInput;
};

export type AssignmentCreationSubjectInput = {
  /** Connect a "Subject" to a new "Assignment" through the "Assignment.subject" edge, throw an error if it does not exist. */
  connect?: InputMaybe<SubjectUniqueFilterInput>;
  /** Create a "Subject" and connect it to a new "Assignment" through the "Assignment.subject" edge. */
  create?: InputMaybe<SubjectCreationInput>;
  /** Create a "Subject" if it does not exist, and connect it to a new "Assignment" through the "Assignment.subject" edge. */
  createIfNotExists?: InputMaybe<AssignmentCreationSubjectCreateIfNotExistsInput>;
};

export type AssignmentCreationWebsiteCreateIfNotExistsInput = {
  data: WebsiteCreationInput;
  where: WebsiteUniqueFilterInput;
};

export type AssignmentCreationWebsiteInput = {
  /** Connect a "Website" to a new "Assignment" through the "Assignment.website" edge, throw an error if it does not exist. */
  connect?: InputMaybe<WebsiteUniqueFilterInput>;
  /** Connect a "Website" to a new "Assignment" through the "Assignment.website" edge, if it exists. */
  connectIfExists?: InputMaybe<WebsiteUniqueFilterInput>;
  /** Create a "Website" and connect it to a new "Assignment" through the "Assignment.website" edge. */
  create?: InputMaybe<WebsiteCreationInput>;
  /** Create a "Website" if it does not exist, and connect it to a new "Assignment" through the "Assignment.website" edge. */
  createIfNotExists?: InputMaybe<AssignmentCreationWebsiteCreateIfNotExistsInput>;
};

/** The "Assignment" node's creation */
export type AssignmentCreationWithoutArticleTemplateInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentCreationPrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentCreationPrintIssueInput>;
  printPublication?: InputMaybe<AssignmentCreationPrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentCreationPrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentCreationRawArticleInput>;
  subject: AssignmentCreationSubjectInput;
  website?: InputMaybe<AssignmentCreationWebsiteInput>;
};

/** The "Assignment" node's creation */
export type AssignmentCreationWithoutPrintHeadingInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplate?: InputMaybe<AssignmentCreationArticleTemplateInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printIssue?: InputMaybe<AssignmentCreationPrintIssueInput>;
  printPublication?: InputMaybe<AssignmentCreationPrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentCreationPrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentCreationRawArticleInput>;
  subject: AssignmentCreationSubjectInput;
  website?: InputMaybe<AssignmentCreationWebsiteInput>;
};

/** The "Assignment" node's creation */
export type AssignmentCreationWithoutPrintIssueInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplate?: InputMaybe<AssignmentCreationArticleTemplateInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentCreationPrintHeadingInput>;
  printPublication?: InputMaybe<AssignmentCreationPrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentCreationPrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentCreationRawArticleInput>;
  subject: AssignmentCreationSubjectInput;
  website?: InputMaybe<AssignmentCreationWebsiteInput>;
};

/** The "Assignment" node's creation */
export type AssignmentCreationWithoutPrintPublicationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplate?: InputMaybe<AssignmentCreationArticleTemplateInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentCreationPrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentCreationPrintIssueInput>;
  printTemplate?: InputMaybe<AssignmentCreationPrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentCreationRawArticleInput>;
  subject: AssignmentCreationSubjectInput;
  website?: InputMaybe<AssignmentCreationWebsiteInput>;
};

/** The "Assignment" node's creation */
export type AssignmentCreationWithoutPrintTemplateInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplate?: InputMaybe<AssignmentCreationArticleTemplateInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentCreationPrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentCreationPrintIssueInput>;
  printPublication?: InputMaybe<AssignmentCreationPrintPublicationInput>;
  rawArticle?: InputMaybe<AssignmentCreationRawArticleInput>;
  subject: AssignmentCreationSubjectInput;
  website?: InputMaybe<AssignmentCreationWebsiteInput>;
};

/** The "Assignment" node's creation */
export type AssignmentCreationWithoutRawArticleInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplate?: InputMaybe<AssignmentCreationArticleTemplateInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentCreationPrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentCreationPrintIssueInput>;
  printPublication?: InputMaybe<AssignmentCreationPrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentCreationPrintTemplateInput>;
  subject: AssignmentCreationSubjectInput;
  website?: InputMaybe<AssignmentCreationWebsiteInput>;
};

/** The "Assignment" node's creation */
export type AssignmentCreationWithoutSubjectInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplate?: InputMaybe<AssignmentCreationArticleTemplateInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentCreationPrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentCreationPrintIssueInput>;
  printPublication?: InputMaybe<AssignmentCreationPrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentCreationPrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentCreationRawArticleInput>;
  website?: InputMaybe<AssignmentCreationWebsiteInput>;
};

/** The "Assignment" node's creation */
export type AssignmentCreationWithoutWebsiteInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplate?: InputMaybe<AssignmentCreationArticleTemplateInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentCreationPrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentCreationPrintIssueInput>;
  printPublication?: InputMaybe<AssignmentCreationPrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentCreationPrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentCreationRawArticleInput>;
  subject: AssignmentCreationSubjectInput;
};

/** The "Assignment" nodes' filter */
export type AssignmentFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<AssignmentFilterInput>>>;
  articleTemplate?: InputMaybe<ArticleTemplateFilterInput>;
  articleTemplate_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  articleTemplate_not?: InputMaybe<ArticleTemplateFilterInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  flatAssignment?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignment_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  flatAssignment_not?: InputMaybe<FlatAssignmentFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  legacyId_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId_lt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_lte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  mediaSearchStatus_in?: InputMaybe<Array<MediaSearchStatus>>;
  mediaSearchStatus_not?: InputMaybe<MediaSearchStatus>;
  mediaSearchStatus_not_in?: InputMaybe<Array<MediaSearchStatus>>;
  NOT?: InputMaybe<AssignmentFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<AssignmentFilterInput>>>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageNumber_gt?: InputMaybe<Scalars['Int']['input']>;
  pageNumber_gte?: InputMaybe<Scalars['Int']['input']>;
  pageNumber_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pageNumber_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  pageNumber_lt?: InputMaybe<Scalars['Int']['input']>;
  pageNumber_lte?: InputMaybe<Scalars['Int']['input']>;
  pageNumber_not?: InputMaybe<Scalars['Int']['input']>;
  pageNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  printHeading?: InputMaybe<PrintHeadingFilterInput>;
  printHeading_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  printHeading_not?: InputMaybe<PrintHeadingFilterInput>;
  printIssue?: InputMaybe<PrintIssueFilterInput>;
  printIssue_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  printIssue_not?: InputMaybe<PrintIssueFilterInput>;
  printPublication?: InputMaybe<PrintPublicationFilterInput>;
  printPublication_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  printPublication_not?: InputMaybe<PrintPublicationFilterInput>;
  printTemplate?: InputMaybe<PrintTemplateFilterInput>;
  printTemplate_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  printTemplate_not?: InputMaybe<PrintTemplateFilterInput>;
  rawArticle?: InputMaybe<RawArticleFilterInput>;
  rawArticle_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  rawArticle_not?: InputMaybe<RawArticleFilterInput>;
  subject?: InputMaybe<SubjectFilterInput>;
  subject_not?: InputMaybe<SubjectFilterInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  website?: InputMaybe<WebsiteFilterInput>;
  website_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  website_not?: InputMaybe<WebsiteFilterInput>;
};

/** Order the "Assignment" nodes */
export enum AssignmentOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "legacyId" to the highest */
  legacyId_ASC = 'legacyId_ASC',
  /** from the highest "legacyId" to the lowest */
  legacyId_DESC = 'legacyId_DESC',
  /** from the lowest "pageNumber" to the highest */
  pageNumber_ASC = 'pageNumber_ASC',
  /** from the highest "pageNumber" to the lowest */
  pageNumber_DESC = 'pageNumber_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "Assignment" with one of the following combination of components' value:
 * - _id
 * - id
 * - subject / legacyId
 * - rawArticle
 */
export type AssignmentUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  rawArticle?: InputMaybe<RawArticleUniqueFilterInput>;
  subject?: InputMaybe<SubjectUniqueFilterInput>;
};

/**
 * Given a "subject", identifies exactly one "Assignment" with one of the following combination of components' value:
 * - (subject) / legacyId
 */
export type AssignmentUniqueFilterWithoutSubjectInput = {
  legacyId?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignmentUpdateArticleTemplateCreateIfNotExistsInput = {
  data: ArticleTemplateCreationInput;
  where: ArticleTemplateUniqueFilterInput;
};

export type AssignmentUpdateArticleTemplateInput = {
  /** Connect an "ArticleTemplate" to an existing "Assignment" through the "Assignment.articleTemplate" edge, throw an error if it does not exist. */
  connect?: InputMaybe<ArticleTemplateUniqueFilterInput>;
  /** Connect an "ArticleTemplate" to an existing "Assignment" through the "Assignment.articleTemplate" edge, if it exists. */
  connectIfExists?: InputMaybe<ArticleTemplateUniqueFilterInput>;
  /** Create an "ArticleTemplate" and connect it to an existing "Assignment" through the "Assignment.articleTemplate" edge. */
  create?: InputMaybe<ArticleTemplateCreationInput>;
  /** Create an "ArticleTemplate" if it does not exist, and connect it to an existing "Assignment" through the "Assignment.articleTemplate" edge. */
  createIfNotExists?: InputMaybe<AssignmentUpdateArticleTemplateCreateIfNotExistsInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  disconnectIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the connected "ArticleTemplate", throw an error if the "Assignment.articleTemplate" edge does not exist. */
  update?: InputMaybe<ArticleTemplateUpdateInput>;
  /** Update the connected "ArticleTemplate", if the "Assignment.articleTemplate" edge exists. */
  updateIfExists?: InputMaybe<ArticleTemplateUpdateInput>;
};

/** The "Assignment" node's update */
export type AssignmentUpdateInput = {
  articleTemplate?: InputMaybe<AssignmentUpdateArticleTemplateInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentUpdatePrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentUpdatePrintIssueInput>;
  printPublication?: InputMaybe<AssignmentUpdatePrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentUpdatePrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentUpdateRawArticleInput>;
  subject?: InputMaybe<AssignmentUpdateSubjectInput>;
  website?: InputMaybe<AssignmentUpdateWebsiteInput>;
};

export type AssignmentUpdatePrintHeadingCreateIfNotExistsInput = {
  data: PrintHeadingCreationInput;
  where: PrintHeadingUniqueFilterInput;
};

export type AssignmentUpdatePrintHeadingInput = {
  /** Connect a "PrintHeading" to an existing "Assignment" through the "Assignment.printHeading" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintHeadingUniqueFilterInput>;
  /** Connect a "PrintHeading" to an existing "Assignment" through the "Assignment.printHeading" edge, if it exists. */
  connectIfExists?: InputMaybe<PrintHeadingUniqueFilterInput>;
  /** Create a "PrintHeading" and connect it to an existing "Assignment" through the "Assignment.printHeading" edge. */
  create?: InputMaybe<PrintHeadingCreationInput>;
  /** Create a "PrintHeading" if it does not exist, and connect it to an existing "Assignment" through the "Assignment.printHeading" edge. */
  createIfNotExists?: InputMaybe<AssignmentUpdatePrintHeadingCreateIfNotExistsInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  disconnectIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the connected "PrintHeading", throw an error if the "Assignment.printHeading" edge does not exist. */
  update?: InputMaybe<PrintHeadingUpdateInput>;
  /** Update the connected "PrintHeading", if the "Assignment.printHeading" edge exists. */
  updateIfExists?: InputMaybe<PrintHeadingUpdateInput>;
};

export type AssignmentUpdatePrintIssueCreateIfNotExistsInput = {
  data: PrintIssueCreationInput;
  where: PrintIssueUniqueFilterInput;
};

export type AssignmentUpdatePrintIssueInput = {
  /** Connect a "PrintIssue" to an existing "Assignment" through the "Assignment.printIssue" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintIssueUniqueFilterInput>;
  /** Connect a "PrintIssue" to an existing "Assignment" through the "Assignment.printIssue" edge, if it exists. */
  connectIfExists?: InputMaybe<PrintIssueUniqueFilterInput>;
  /** Create a "PrintIssue" and connect it to an existing "Assignment" through the "Assignment.printIssue" edge. */
  create?: InputMaybe<PrintIssueCreationInput>;
  /** Create a "PrintIssue" if it does not exist, and connect it to an existing "Assignment" through the "Assignment.printIssue" edge. */
  createIfNotExists?: InputMaybe<AssignmentUpdatePrintIssueCreateIfNotExistsInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  disconnectIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the connected "PrintIssue", throw an error if the "Assignment.printIssue" edge does not exist. */
  update?: InputMaybe<PrintIssueUpdateInput>;
  /** Update the connected "PrintIssue", if the "Assignment.printIssue" edge exists. */
  updateIfExists?: InputMaybe<PrintIssueUpdateInput>;
};

export type AssignmentUpdatePrintPublicationCreateIfNotExistsInput = {
  data: PrintPublicationCreationInput;
  where: PrintPublicationUniqueFilterInput;
};

export type AssignmentUpdatePrintPublicationInput = {
  /** Connect a "PrintPublication" to an existing "Assignment" through the "Assignment.printPublication" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintPublicationUniqueFilterInput>;
  /** Connect a "PrintPublication" to an existing "Assignment" through the "Assignment.printPublication" edge, if it exists. */
  connectIfExists?: InputMaybe<PrintPublicationUniqueFilterInput>;
  /** Create a "PrintPublication" and connect it to an existing "Assignment" through the "Assignment.printPublication" edge. */
  create?: InputMaybe<PrintPublicationCreationInput>;
  /** Create a "PrintPublication" if it does not exist, and connect it to an existing "Assignment" through the "Assignment.printPublication" edge. */
  createIfNotExists?: InputMaybe<AssignmentUpdatePrintPublicationCreateIfNotExistsInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  disconnectIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the connected "PrintPublication", throw an error if the "Assignment.printPublication" edge does not exist. */
  update?: InputMaybe<PrintPublicationUpdateInput>;
  /** Update the connected "PrintPublication", if the "Assignment.printPublication" edge exists. */
  updateIfExists?: InputMaybe<PrintPublicationUpdateInput>;
};

export type AssignmentUpdatePrintTemplateCreateIfNotExistsInput = {
  data: PrintTemplateCreationInput;
  where: PrintTemplateUniqueFilterInput;
};

export type AssignmentUpdatePrintTemplateInput = {
  /** Connect a "PrintTemplate" to an existing "Assignment" through the "Assignment.printTemplate" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintTemplateUniqueFilterInput>;
  /** Connect a "PrintTemplate" to an existing "Assignment" through the "Assignment.printTemplate" edge, if it exists. */
  connectIfExists?: InputMaybe<PrintTemplateUniqueFilterInput>;
  /** Create a "PrintTemplate" and connect it to an existing "Assignment" through the "Assignment.printTemplate" edge. */
  create?: InputMaybe<PrintTemplateCreationInput>;
  /** Create a "PrintTemplate" if it does not exist, and connect it to an existing "Assignment" through the "Assignment.printTemplate" edge. */
  createIfNotExists?: InputMaybe<AssignmentUpdatePrintTemplateCreateIfNotExistsInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  disconnectIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the connected "PrintTemplate", throw an error if the "Assignment.printTemplate" edge does not exist. */
  update?: InputMaybe<PrintTemplateUpdateInput>;
  /** Update the connected "PrintTemplate", if the "Assignment.printTemplate" edge exists. */
  updateIfExists?: InputMaybe<PrintTemplateUpdateInput>;
};

export type AssignmentUpdateRawArticleCreateIfNotExistsInput = {
  data: RawArticleCreationInput;
  where: RawArticleUniqueFilterInput;
};

export type AssignmentUpdateRawArticleInput = {
  /** Connect a "RawArticle" to an existing "Assignment" through the "Assignment.rawArticle" edge, throw an error if it does not exist. */
  connect?: InputMaybe<RawArticleUniqueFilterInput>;
  /** Connect a "RawArticle" to an existing "Assignment" through the "Assignment.rawArticle" edge, if it exists. */
  connectIfExists?: InputMaybe<RawArticleUniqueFilterInput>;
  /** Create a "RawArticle" and connect it to an existing "Assignment" through the "Assignment.rawArticle" edge. */
  create?: InputMaybe<RawArticleCreationInput>;
  /** Create a "RawArticle" if it does not exist, and connect it to an existing "Assignment" through the "Assignment.rawArticle" edge. */
  createIfNotExists?: InputMaybe<AssignmentUpdateRawArticleCreateIfNotExistsInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  disconnectIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the connected "RawArticle", throw an error if the "Assignment.rawArticle" edge does not exist. */
  update?: InputMaybe<RawArticleUpdateInput>;
  /** Update the connected "RawArticle", if the "Assignment.rawArticle" edge exists. */
  updateIfExists?: InputMaybe<RawArticleUpdateInput>;
};

export type AssignmentUpdateSubjectCreateIfNotExistsInput = {
  data: SubjectCreationInput;
  where: SubjectUniqueFilterInput;
};

export type AssignmentUpdateSubjectInput = {
  /** Connect a "Subject" to an existing "Assignment" through the "Assignment.subject" edge, throw an error if it does not exist. */
  connect?: InputMaybe<SubjectUniqueFilterInput>;
  /** Create a "Subject" and connect it to an existing "Assignment" through the "Assignment.subject" edge. */
  create?: InputMaybe<SubjectCreationInput>;
  /** Create a "Subject" if it does not exist, and connect it to an existing "Assignment" through the "Assignment.subject" edge. */
  createIfNotExists?: InputMaybe<AssignmentUpdateSubjectCreateIfNotExistsInput>;
  /** Update the connected "Subject", throw an error if the "Assignment.subject" edge does not exist. */
  update?: InputMaybe<SubjectUpdateInput>;
};

export type AssignmentUpdateWebsiteCreateIfNotExistsInput = {
  data: WebsiteCreationInput;
  where: WebsiteUniqueFilterInput;
};

export type AssignmentUpdateWebsiteInput = {
  /** Connect a "Website" to an existing "Assignment" through the "Assignment.website" edge, throw an error if it does not exist. */
  connect?: InputMaybe<WebsiteUniqueFilterInput>;
  /** Connect a "Website" to an existing "Assignment" through the "Assignment.website" edge, if it exists. */
  connectIfExists?: InputMaybe<WebsiteUniqueFilterInput>;
  /** Create a "Website" and connect it to an existing "Assignment" through the "Assignment.website" edge. */
  create?: InputMaybe<WebsiteCreationInput>;
  /** Create a "Website" if it does not exist, and connect it to an existing "Assignment" through the "Assignment.website" edge. */
  createIfNotExists?: InputMaybe<AssignmentUpdateWebsiteCreateIfNotExistsInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  disconnectIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the connected "Website", throw an error if the "Assignment.website" edge does not exist. */
  update?: InputMaybe<WebsiteUpdateInput>;
  /** Update the connected "Website", if the "Assignment.website" edge exists. */
  updateIfExists?: InputMaybe<WebsiteUpdateInput>;
};

/** The "Assignment" node's update */
export type AssignmentUpdateWithoutArticleTemplateInput = {
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentUpdatePrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentUpdatePrintIssueInput>;
  printPublication?: InputMaybe<AssignmentUpdatePrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentUpdatePrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentUpdateRawArticleInput>;
  subject?: InputMaybe<AssignmentUpdateSubjectInput>;
  website?: InputMaybe<AssignmentUpdateWebsiteInput>;
};

/** The "Assignment" node's update */
export type AssignmentUpdateWithoutPrintHeadingInput = {
  articleTemplate?: InputMaybe<AssignmentUpdateArticleTemplateInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printIssue?: InputMaybe<AssignmentUpdatePrintIssueInput>;
  printPublication?: InputMaybe<AssignmentUpdatePrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentUpdatePrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentUpdateRawArticleInput>;
  subject?: InputMaybe<AssignmentUpdateSubjectInput>;
  website?: InputMaybe<AssignmentUpdateWebsiteInput>;
};

/** The "Assignment" node's update */
export type AssignmentUpdateWithoutPrintIssueInput = {
  articleTemplate?: InputMaybe<AssignmentUpdateArticleTemplateInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentUpdatePrintHeadingInput>;
  printPublication?: InputMaybe<AssignmentUpdatePrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentUpdatePrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentUpdateRawArticleInput>;
  subject?: InputMaybe<AssignmentUpdateSubjectInput>;
  website?: InputMaybe<AssignmentUpdateWebsiteInput>;
};

/** The "Assignment" node's update */
export type AssignmentUpdateWithoutPrintPublicationInput = {
  articleTemplate?: InputMaybe<AssignmentUpdateArticleTemplateInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentUpdatePrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentUpdatePrintIssueInput>;
  printTemplate?: InputMaybe<AssignmentUpdatePrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentUpdateRawArticleInput>;
  subject?: InputMaybe<AssignmentUpdateSubjectInput>;
  website?: InputMaybe<AssignmentUpdateWebsiteInput>;
};

/** The "Assignment" node's update */
export type AssignmentUpdateWithoutPrintTemplateInput = {
  articleTemplate?: InputMaybe<AssignmentUpdateArticleTemplateInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentUpdatePrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentUpdatePrintIssueInput>;
  printPublication?: InputMaybe<AssignmentUpdatePrintPublicationInput>;
  rawArticle?: InputMaybe<AssignmentUpdateRawArticleInput>;
  subject?: InputMaybe<AssignmentUpdateSubjectInput>;
  website?: InputMaybe<AssignmentUpdateWebsiteInput>;
};

/** The "Assignment" node's update */
export type AssignmentUpdateWithoutRawArticleInput = {
  articleTemplate?: InputMaybe<AssignmentUpdateArticleTemplateInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentUpdatePrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentUpdatePrintIssueInput>;
  printPublication?: InputMaybe<AssignmentUpdatePrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentUpdatePrintTemplateInput>;
  subject?: InputMaybe<AssignmentUpdateSubjectInput>;
  website?: InputMaybe<AssignmentUpdateWebsiteInput>;
};

/** The "Assignment" node's update */
export type AssignmentUpdateWithoutSubjectInput = {
  articleTemplate?: InputMaybe<AssignmentUpdateArticleTemplateInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentUpdatePrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentUpdatePrintIssueInput>;
  printPublication?: InputMaybe<AssignmentUpdatePrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentUpdatePrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentUpdateRawArticleInput>;
  website?: InputMaybe<AssignmentUpdateWebsiteInput>;
};

/** The "Assignment" node's update */
export type AssignmentUpdateWithoutWebsiteInput = {
  articleTemplate?: InputMaybe<AssignmentUpdateArticleTemplateInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  printHeading?: InputMaybe<AssignmentUpdatePrintHeadingInput>;
  printIssue?: InputMaybe<AssignmentUpdatePrintIssueInput>;
  printPublication?: InputMaybe<AssignmentUpdatePrintPublicationInput>;
  printTemplate?: InputMaybe<AssignmentUpdatePrintTemplateInput>;
  rawArticle?: InputMaybe<AssignmentUpdateRawArticleInput>;
  subject?: InputMaybe<AssignmentUpdateSubjectInput>;
};

/** Stock attachment written by topic using api */
export type Attachment = {
  __typename?: 'Attachment';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** update from S3 */
  contentType?: Maybe<Scalars['String']['output']>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
  /** update from S3 */
  etag?: Maybe<Scalars['String']['output']>;
  /** sent from the front */
  filename: Scalars['String']['output'];
  /** update from S3 */
  filesize?: Maybe<Scalars['Int']['output']>;
  /** The public ID */
  id: Scalars['ID']['output'];
  isUploaded?: Maybe<Scalars['Boolean']['output']>;
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  subject: Subject;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  /** get presigned url from S3 */
  uploadUrl?: Maybe<Scalars['String']['output']>;
  /** update from S3 */
  versionId?: Maybe<Scalars['String']['output']>;
};

/** The "Attachment" node's creation */
export type AttachmentCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** update from S3 */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** update from S3 */
  etag?: InputMaybe<Scalars['String']['input']>;
  /** sent from the front */
  filename: Scalars['String']['input'];
  /** update from S3 */
  filesize?: InputMaybe<Scalars['Int']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  subject: AttachmentCreationSubjectInput;
  /** update from S3 */
  versionId?: InputMaybe<Scalars['String']['input']>;
};

export type AttachmentCreationSubjectCreateIfNotExistsInput = {
  data: SubjectCreationInput;
  where: SubjectUniqueFilterInput;
};

export type AttachmentCreationSubjectInput = {
  /** Connect a "Subject" to a new "Attachment" through the "Attachment.subject" edge, throw an error if it does not exist. */
  connect?: InputMaybe<SubjectUniqueFilterInput>;
  /** Create a "Subject" and connect it to a new "Attachment" through the "Attachment.subject" edge. */
  create?: InputMaybe<SubjectCreationInput>;
  /** Create a "Subject" if it does not exist, and connect it to a new "Attachment" through the "Attachment.subject" edge. */
  createIfNotExists?: InputMaybe<AttachmentCreationSubjectCreateIfNotExistsInput>;
};

/** The "Attachment" node's creation */
export type AttachmentCreationWithoutSubjectInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** update from S3 */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** update from S3 */
  etag?: InputMaybe<Scalars['String']['input']>;
  /** sent from the front */
  filename: Scalars['String']['input'];
  /** update from S3 */
  filesize?: InputMaybe<Scalars['Int']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** update from S3 */
  versionId?: InputMaybe<Scalars['String']['input']>;
};

/** The "Attachment" nodes' filter */
export type AttachmentFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<AttachmentFilterInput>>>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentType_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  contentType_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  contentType_not?: InputMaybe<Scalars['String']['input']>;
  contentType_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  contentType_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  contentType_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  etag?: InputMaybe<Scalars['String']['input']>;
  etag_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  etag_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  etag_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  etag_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  etag_not?: InputMaybe<Scalars['String']['input']>;
  etag_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  etag_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  etag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  etag_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  etag_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filename_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename_in?: InputMaybe<Array<Scalars['String']['input']>>;
  filename_not?: InputMaybe<Scalars['String']['input']>;
  filename_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  filename_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  filesize_gt?: InputMaybe<Scalars['Int']['input']>;
  filesize_gte?: InputMaybe<Scalars['Int']['input']>;
  filesize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  filesize_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  filesize_lt?: InputMaybe<Scalars['Int']['input']>;
  filesize_lte?: InputMaybe<Scalars['Int']['input']>;
  filesize_not?: InputMaybe<Scalars['Int']['input']>;
  filesize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  isUploaded_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded_not?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  NOT?: InputMaybe<AttachmentFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<AttachmentFilterInput>>>;
  subject?: InputMaybe<SubjectFilterInput>;
  subject_not?: InputMaybe<SubjectFilterInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  versionId?: InputMaybe<Scalars['String']['input']>;
  versionId_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  versionId_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  versionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  versionId_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  versionId_not?: InputMaybe<Scalars['String']['input']>;
  versionId_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  versionId_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  versionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  versionId_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  versionId_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
};

/** Order the "Attachment" nodes */
export enum AttachmentOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "filesize" to the highest */
  filesize_ASC = 'filesize_ASC',
  /** from the highest "filesize" to the lowest */
  filesize_DESC = 'filesize_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "Attachment" with one of the following combination of components' value:
 * - _id
 * - id
 */
export type AttachmentUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** The "Attachment" node's update */
export type AttachmentUpdateInput = {
  /** update from S3 */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** update from S3 */
  etag?: InputMaybe<Scalars['String']['input']>;
  /** sent from the front */
  filename?: InputMaybe<Scalars['String']['input']>;
  /** update from S3 */
  filesize?: InputMaybe<Scalars['Int']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<AttachmentUpdateSubjectInput>;
  /** update from S3 */
  versionId?: InputMaybe<Scalars['String']['input']>;
};

export type AttachmentUpdateSubjectCreateIfNotExistsInput = {
  data: SubjectCreationInput;
  where: SubjectUniqueFilterInput;
};

export type AttachmentUpdateSubjectInput = {
  /** Connect a "Subject" to an existing "Attachment" through the "Attachment.subject" edge, throw an error if it does not exist. */
  connect?: InputMaybe<SubjectUniqueFilterInput>;
  /** Create a "Subject" and connect it to an existing "Attachment" through the "Attachment.subject" edge. */
  create?: InputMaybe<SubjectCreationInput>;
  /** Create a "Subject" if it does not exist, and connect it to an existing "Attachment" through the "Attachment.subject" edge. */
  createIfNotExists?: InputMaybe<AttachmentUpdateSubjectCreateIfNotExistsInput>;
  /** Update the connected "Subject", throw an error if the "Attachment.subject" edge does not exist. */
  update?: InputMaybe<SubjectUpdateInput>;
};

/** The "Attachment" node's update */
export type AttachmentUpdateWithoutSubjectInput = {
  /** update from S3 */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** update from S3 */
  etag?: InputMaybe<Scalars['String']['input']>;
  /** sent from the front */
  filename?: InputMaybe<Scalars['String']['input']>;
  /** update from S3 */
  filesize?: InputMaybe<Scalars['Int']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** update from S3 */
  versionId?: InputMaybe<Scalars['String']['input']>;
};

/** A Brand: FAC, CAC, T2S, TEL */
export type Brand = {
  __typename?: 'Brand';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** Number of "flatAssignments" */
  flatAssignmentCount: Scalars['UnsignedInt']['output'];
  flatAssignments: Array<FlatAssignment>;
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  /** Number of "printPublications" */
  printPublicationCount: Scalars['UnsignedInt']['output'];
  printPublications: Array<PrintPublication>;
  title: Scalars['String']['output'];
  unit: Unit;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<User>;
  /** Number of "websites" */
  websiteCount: Scalars['UnsignedInt']['output'];
  websites: Array<Website>;
};


/** A Brand: FAC, CAC, T2S, TEL */
export type BrandFlatAssignmentCountArgs = {
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** A Brand: FAC, CAC, T2S, TEL */
export type BrandFlatAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<FlatAssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** A Brand: FAC, CAC, T2S, TEL */
export type BrandPrintPublicationCountArgs = {
  where?: InputMaybe<PrintPublicationFilterInput>;
};


/** A Brand: FAC, CAC, T2S, TEL */
export type BrandPrintPublicationsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintPublicationOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<PrintPublicationFilterInput>;
};


/** A Brand: FAC, CAC, T2S, TEL */
export type BrandWebsiteCountArgs = {
  where?: InputMaybe<WebsiteFilterInput>;
};


/** A Brand: FAC, CAC, T2S, TEL */
export type BrandWebsitesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<WebsiteOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<WebsiteFilterInput>;
};

/** The "Brand" node's creation */
export type BrandCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  printPublications?: InputMaybe<BrandCreationPrintPublicationsInput>;
  title: Scalars['String']['input'];
  unit: BrandCreationUnitInput;
  websites?: InputMaybe<BrandCreationWebsitesInput>;
};

export type BrandCreationPrintPublicationsInput = {
  create?: InputMaybe<Array<PrintPublicationCreationWithoutBrandInput>>;
};

export type BrandCreationUnitCreateIfNotExistsInput = {
  data: UnitCreationInput;
  where: UnitUniqueFilterInput;
};

export type BrandCreationUnitInput = {
  /** Connect a "Unit" to a new "Brand" through the "Brand.unit" edge, throw an error if it does not exist. */
  connect?: InputMaybe<UnitUniqueFilterInput>;
  /** Create a "Unit" and connect it to a new "Brand" through the "Brand.unit" edge. */
  create?: InputMaybe<UnitCreationInput>;
  /** Create a "Unit" if it does not exist, and connect it to a new "Brand" through the "Brand.unit" edge. */
  createIfNotExists?: InputMaybe<BrandCreationUnitCreateIfNotExistsInput>;
};

export type BrandCreationWebsitesInput = {
  create?: InputMaybe<Array<WebsiteCreationWithoutBrandInput>>;
};

/** The "Brand" node's creation */
export type BrandCreationWithoutUnitInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  printPublications?: InputMaybe<BrandCreationPrintPublicationsInput>;
  title: Scalars['String']['input'];
  websites?: InputMaybe<BrandCreationWebsitesInput>;
};

/** The "Brand" nodes' filter */
export type BrandFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<BrandFilterInput>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  flatAssignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignments_every?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_none?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_some?: InputMaybe<FlatAssignmentFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  NOT?: InputMaybe<BrandFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<BrandFilterInput>>>;
  printPublicationCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printPublicationCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printPublicationCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printPublicationCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printPublicationCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printPublicationCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printPublications_every?: InputMaybe<PrintPublicationFilterInput>;
  printPublications_none?: InputMaybe<PrintPublicationFilterInput>;
  printPublications_some?: InputMaybe<PrintPublicationFilterInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  unit?: InputMaybe<UnitFilterInput>;
  unit_not?: InputMaybe<UnitFilterInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  websiteCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  websiteCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  websiteCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  websiteCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  websiteCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  websiteCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  websites_every?: InputMaybe<WebsiteFilterInput>;
  websites_none?: InputMaybe<WebsiteFilterInput>;
  websites_some?: InputMaybe<WebsiteFilterInput>;
};

/** Order the "Brand" nodes */
export enum BrandOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest number of "flatAssignments" to the highest */
  flatAssignmentCount_ASC = 'flatAssignmentCount_ASC',
  /** from the highest number of "flatAssignments" to the lowest */
  flatAssignmentCount_DESC = 'flatAssignmentCount_DESC',
  /** from the lowest number of "printPublications" to the highest */
  printPublicationCount_ASC = 'printPublicationCount_ASC',
  /** from the highest number of "printPublications" to the lowest */
  printPublicationCount_DESC = 'printPublicationCount_DESC',
  /** from the lowest "title" to the highest */
  title_ASC = 'title_ASC',
  /** from the highest "title" to the lowest */
  title_DESC = 'title_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC',
  /** from the lowest number of "websites" to the highest */
  websiteCount_ASC = 'websiteCount_ASC',
  /** from the highest number of "websites" to the lowest */
  websiteCount_DESC = 'websiteCount_DESC'
}

/**
 * Identifies exactly one "Brand" with one of the following combination of components' value:
 * - _id
 * - id
 * - title
 */
export type BrandUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The "Brand" node's update */
export type BrandUpdateInput = {
  printPublications?: InputMaybe<BrandUpdatePrintPublicationsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<BrandUpdateUnitInput>;
  websites?: InputMaybe<BrandUpdateWebsitesInput>;
};

export type BrandUpdatePrintPublicationsCreateIfNotExistsInput = {
  data: PrintPublicationCreationWithoutBrandInput;
  where: PrintPublicationUniqueFilterWithoutBrandInput;
};

export type BrandUpdatePrintPublicationsInput = {
  create?: InputMaybe<Array<PrintPublicationCreationWithoutBrandInput>>;
  createIfNotExists?: InputMaybe<Array<BrandUpdatePrintPublicationsCreateIfNotExistsInput>>;
  delete?: InputMaybe<Array<PrintPublicationUniqueFilterWithoutBrandInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Array<PrintPublicationUniqueFilterWithoutBrandInput>>;
  deleteMany?: InputMaybe<PrintPublicationFilterInput>;
  update?: InputMaybe<Array<BrandUpdatePrintPublicationsUpdateInput>>;
  updateAll?: InputMaybe<PrintPublicationUpdateWithoutBrandInput>;
  updateIfExists?: InputMaybe<Array<BrandUpdatePrintPublicationsUpdateIfExistsInput>>;
  updateMany?: InputMaybe<BrandUpdatePrintPublicationsUpdateManyInput>;
  upsert?: InputMaybe<Array<BrandUpdatePrintPublicationsUpsertInput>>;
};

export type BrandUpdatePrintPublicationsUpdateIfExistsInput = {
  data?: InputMaybe<PrintPublicationUpdateWithoutBrandInput>;
  where: PrintPublicationUniqueFilterWithoutBrandInput;
};

export type BrandUpdatePrintPublicationsUpdateInput = {
  data?: InputMaybe<PrintPublicationUpdateWithoutBrandInput>;
  where: PrintPublicationUniqueFilterWithoutBrandInput;
};

export type BrandUpdatePrintPublicationsUpdateManyInput = {
  data?: InputMaybe<PrintPublicationUpdateWithoutBrandInput>;
  where?: InputMaybe<PrintPublicationFilterInput>;
};

export type BrandUpdatePrintPublicationsUpsertInput = {
  create: PrintPublicationCreationWithoutBrandInput;
  update?: InputMaybe<PrintPublicationUpdateWithoutBrandInput>;
  where: PrintPublicationUniqueFilterWithoutBrandInput;
};

export type BrandUpdateUnitCreateIfNotExistsInput = {
  data: UnitCreationInput;
  where: UnitUniqueFilterInput;
};

export type BrandUpdateUnitInput = {
  /** Connect a "Unit" to an existing "Brand" through the "Brand.unit" edge, throw an error if it does not exist. */
  connect?: InputMaybe<UnitUniqueFilterInput>;
  /** Create a "Unit" and connect it to an existing "Brand" through the "Brand.unit" edge. */
  create?: InputMaybe<UnitCreationInput>;
  /** Create a "Unit" if it does not exist, and connect it to an existing "Brand" through the "Brand.unit" edge. */
  createIfNotExists?: InputMaybe<BrandUpdateUnitCreateIfNotExistsInput>;
  /** Update the connected "Unit", throw an error if the "Brand.unit" edge does not exist. */
  update?: InputMaybe<UnitUpdateInput>;
};

export type BrandUpdateWebsitesCreateIfNotExistsInput = {
  data: WebsiteCreationWithoutBrandInput;
  where: WebsiteUniqueFilterWithoutBrandInput;
};

export type BrandUpdateWebsitesInput = {
  create?: InputMaybe<Array<WebsiteCreationWithoutBrandInput>>;
  createIfNotExists?: InputMaybe<Array<BrandUpdateWebsitesCreateIfNotExistsInput>>;
  delete?: InputMaybe<Array<WebsiteUniqueFilterWithoutBrandInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Array<WebsiteUniqueFilterWithoutBrandInput>>;
  deleteMany?: InputMaybe<WebsiteFilterInput>;
  update?: InputMaybe<Array<BrandUpdateWebsitesUpdateInput>>;
  updateAll?: InputMaybe<WebsiteUpdateWithoutBrandInput>;
  updateIfExists?: InputMaybe<Array<BrandUpdateWebsitesUpdateIfExistsInput>>;
  updateMany?: InputMaybe<BrandUpdateWebsitesUpdateManyInput>;
  upsert?: InputMaybe<Array<BrandUpdateWebsitesUpsertInput>>;
};

export type BrandUpdateWebsitesUpdateIfExistsInput = {
  data?: InputMaybe<WebsiteUpdateWithoutBrandInput>;
  where: WebsiteUniqueFilterWithoutBrandInput;
};

export type BrandUpdateWebsitesUpdateInput = {
  data?: InputMaybe<WebsiteUpdateWithoutBrandInput>;
  where: WebsiteUniqueFilterWithoutBrandInput;
};

export type BrandUpdateWebsitesUpdateManyInput = {
  data?: InputMaybe<WebsiteUpdateWithoutBrandInput>;
  where?: InputMaybe<WebsiteFilterInput>;
};

export type BrandUpdateWebsitesUpsertInput = {
  create: WebsiteCreationWithoutBrandInput;
  update?: InputMaybe<WebsiteUpdateWithoutBrandInput>;
  where: WebsiteUniqueFilterWithoutBrandInput;
};

/** The "Brand" node's update */
export type BrandUpdateWithoutUnitInput = {
  printPublications?: InputMaybe<BrandUpdatePrintPublicationsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  websites?: InputMaybe<BrandUpdateWebsitesInput>;
};

export type CenshareRailroadByNameCreationDateResultType = {
  __typename?: 'CenshareRailroadByNameCreationDateResultType';
  date?: Maybe<Scalars['String']['output']>;
  label_by?: Maybe<Scalars['String']['output']>;
};

export type CenshareRailroadByNamePagesResultType = {
  __typename?: 'CenshareRailroadByNamePagesResultType';
  filename?: Maybe<Scalars['String']['output']>;
  indesignFilename?: Maybe<Scalars['String']['output']>;
  pageNumber: Scalars['String']['output'];
  workflow?: Maybe<Scalars['String']['output']>;
};

export type CenshareRailroadByNameResultType = {
  __typename?: 'CenshareRailroadByNameResultType';
  censhareCreationDate?: Maybe<CenshareRailroadByNameCreationDateResultType>;
  censhareUpdateDate?: Maybe<CenshareRailroadByNameUpdateDateResultType>;
  comment: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pageCount: Scalars['Int']['output'];
  pages?: Maybe<Array<Maybe<CenshareRailroadByNamePagesResultType>>>;
  publicationDate: Scalars['String']['output'];
  sheets?: Maybe<Scalars['String']['output']>;
};

export type CenshareRailroadByNameUpdateDateResultType = {
  __typename?: 'CenshareRailroadByNameUpdateDateResultType';
  date?: Maybe<Scalars['String']['output']>;
  label_by?: Maybe<Scalars['String']['output']>;
};

export type CenshareRailroadResultType = {
  __typename?: 'CenshareRailroadResultType';
  asset: Scalars['String']['output'];
};

/** traces censhare api calls result */
export type CenshareTrace = {
  __typename?: 'CenshareTrace';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** The censhare used api */
  api?: Maybe<Scalars['String']['output']>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  error?: Maybe<Scalars['Boolean']['output']>;
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  /** http call query params */
  params?: Maybe<Scalars['String']['output']>;
  /** http call payload */
  payload?: Maybe<Scalars['String']['output']>;
  /** http call (xml) raw response */
  rawResponse?: Maybe<Scalars['String']['output']>;
  /** http call response code */
  responseCode?: Maybe<Scalars['Int']['output']>;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};

/** The "CenshareTrace" node's creation */
export type CenshareTraceCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The censhare used api */
  api?: InputMaybe<Scalars['String']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** http call query params */
  params?: InputMaybe<Scalars['String']['input']>;
  /** http call payload */
  payload?: InputMaybe<Scalars['String']['input']>;
  /** http call (xml) raw response */
  rawResponse?: InputMaybe<Scalars['String']['input']>;
  /** http call response code */
  responseCode?: InputMaybe<Scalars['Int']['input']>;
};

/** The "CenshareTrace" nodes' filter */
export type CenshareTraceFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<CenshareTraceFilterInput>>>;
  api?: InputMaybe<Scalars['String']['input']>;
  api_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  api_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  api_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  api_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  api_not?: InputMaybe<Scalars['String']['input']>;
  api_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  api_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  api_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  api_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  api_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  NOT?: InputMaybe<CenshareTraceFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<CenshareTraceFilterInput>>>;
  params?: InputMaybe<Scalars['String']['input']>;
  params_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  params_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  params_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  params_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  params_not?: InputMaybe<Scalars['String']['input']>;
  params_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  params_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  params_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  params_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  params_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  payload_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  payload_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  payload_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payload_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  payload_not?: InputMaybe<Scalars['String']['input']>;
  payload_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  payload_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  payload_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  payload_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  payload_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  rawResponse?: InputMaybe<Scalars['String']['input']>;
  rawResponse_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  rawResponse_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  rawResponse_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rawResponse_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  rawResponse_not?: InputMaybe<Scalars['String']['input']>;
  rawResponse_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  rawResponse_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  rawResponse_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rawResponse_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  rawResponse_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  responseCode?: InputMaybe<Scalars['Int']['input']>;
  responseCode_gt?: InputMaybe<Scalars['Int']['input']>;
  responseCode_gte?: InputMaybe<Scalars['Int']['input']>;
  responseCode_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  responseCode_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  responseCode_lt?: InputMaybe<Scalars['Int']['input']>;
  responseCode_lte?: InputMaybe<Scalars['Int']['input']>;
  responseCode_not?: InputMaybe<Scalars['Int']['input']>;
  responseCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "CenshareTrace" nodes */
export enum CenshareTraceOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "responseCode" to the highest */
  responseCode_ASC = 'responseCode_ASC',
  /** from the highest "responseCode" to the lowest */
  responseCode_DESC = 'responseCode_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "CenshareTrace" with one of the following combination of components' value:
 * - _id
 * - id
 */
export type CenshareTraceUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** The "CenshareTrace" node's update */
export type CenshareTraceUpdateInput = {
  /** The censhare used api */
  api?: InputMaybe<Scalars['String']['input']>;
  /** http call query params */
  params?: InputMaybe<Scalars['String']['input']>;
  /** http call payload */
  payload?: InputMaybe<Scalars['String']['input']>;
  /** http call (xml) raw response */
  rawResponse?: InputMaybe<Scalars['String']['input']>;
  /** http call response code */
  responseCode?: InputMaybe<Scalars['Int']['input']>;
};

export type EmailRawArticleResultType = {
  __typename?: 'EmailRawArticleResultType';
  success: Scalars['Boolean']['output'];
};

export type EmailSubjectResultType = {
  __typename?: 'EmailSubjectResultType';
  success: Scalars['Boolean']['output'];
};

export type ExportPrintIssueToCenshareResultType = {
  __typename?: 'ExportPrintIssueToCenshareResultType';
  message: Scalars['String']['output'];
};

export type ExportToCenshareResultType = {
  __typename?: 'ExportToCenshareResultType';
  message: Scalars['String']['output'];
};

/** Read-only view to shortcut assignment joins */
export type FlatAssignment = {
  __typename?: 'FlatAssignment';
  assignment: Assignment;
  assignmentMediaSearchStatus: MediaSearchStatus;
  assignmentPageNumber?: Maybe<Scalars['Int']['output']>;
  brand?: Maybe<Brand>;
  /** The assignation location */
  location: Scalars['String']['output'];
  printHeading?: Maybe<PrintHeading>;
  printIssue?: Maybe<PrintIssue>;
  printPublication?: Maybe<PrintPublication>;
  printTemplate?: Maybe<PrintTemplate>;
  rawArticle?: Maybe<RawArticle>;
  subject?: Maybe<Subject>;
  website?: Maybe<Website>;
};

/** The "FlatAssignment" nodes' filter */
export type FlatAssignmentFilterInput = {
  AND?: InputMaybe<Array<InputMaybe<FlatAssignmentFilterInput>>>;
  assignment?: InputMaybe<AssignmentFilterInput>;
  assignment_not?: InputMaybe<AssignmentFilterInput>;
  assignmentMediaSearchStatus?: InputMaybe<MediaSearchStatus>;
  assignmentMediaSearchStatus_in?: InputMaybe<Array<MediaSearchStatus>>;
  assignmentMediaSearchStatus_not?: InputMaybe<MediaSearchStatus>;
  assignmentMediaSearchStatus_not_in?: InputMaybe<Array<MediaSearchStatus>>;
  assignmentPageNumber?: InputMaybe<Scalars['Int']['input']>;
  assignmentPageNumber_gt?: InputMaybe<Scalars['Int']['input']>;
  assignmentPageNumber_gte?: InputMaybe<Scalars['Int']['input']>;
  assignmentPageNumber_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  assignmentPageNumber_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  assignmentPageNumber_lt?: InputMaybe<Scalars['Int']['input']>;
  assignmentPageNumber_lte?: InputMaybe<Scalars['Int']['input']>;
  assignmentPageNumber_not?: InputMaybe<Scalars['Int']['input']>;
  assignmentPageNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  brand?: InputMaybe<BrandFilterInput>;
  brand_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  brand_not?: InputMaybe<BrandFilterInput>;
  location?: InputMaybe<Scalars['String']['input']>;
  location_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  location_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  location_in?: InputMaybe<Array<Scalars['String']['input']>>;
  location_not?: InputMaybe<Scalars['String']['input']>;
  location_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  location_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  location_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  location_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  location_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  NOT?: InputMaybe<FlatAssignmentFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<FlatAssignmentFilterInput>>>;
  printHeading?: InputMaybe<PrintHeadingFilterInput>;
  printHeading_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  printHeading_not?: InputMaybe<PrintHeadingFilterInput>;
  printIssue?: InputMaybe<PrintIssueFilterInput>;
  printIssue_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  printIssue_not?: InputMaybe<PrintIssueFilterInput>;
  printPublication?: InputMaybe<PrintPublicationFilterInput>;
  printPublication_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  printPublication_not?: InputMaybe<PrintPublicationFilterInput>;
  printTemplate?: InputMaybe<PrintTemplateFilterInput>;
  printTemplate_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  printTemplate_not?: InputMaybe<PrintTemplateFilterInput>;
  rawArticle?: InputMaybe<RawArticleFilterInput>;
  rawArticle_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  rawArticle_not?: InputMaybe<RawArticleFilterInput>;
  subject?: InputMaybe<SubjectFilterInput>;
  subject_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  subject_not?: InputMaybe<SubjectFilterInput>;
  website?: InputMaybe<WebsiteFilterInput>;
  website_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  website_not?: InputMaybe<WebsiteFilterInput>;
};

/** Order the "FlatAssignment" nodes */
export enum FlatAssignmentOrderingInput {
  /** from the lowest "assignmentPageNumber" to the highest */
  assignmentPageNumber_ASC = 'assignmentPageNumber_ASC',
  /** from the highest "assignmentPageNumber" to the lowest */
  assignmentPageNumber_DESC = 'assignmentPageNumber_DESC'
}

/**
 * Identifies exactly one "FlatAssignment" with one of the following combination of components' value:
 * - assignment
 */
export type FlatAssignmentUniqueFilterInput = {
  assignment: AssignmentUniqueFilterInput;
};

export enum MediaSearchStatus {
  Ready = 'Ready',
  Sent = 'Sent',
  Started = 'Started',
  Waiting = 'Waiting'
}

export type Mutation = {
  __typename?: 'mutation';
  /** Creates one "ArticleTemplate", throws an error if it already exists */
  createArticleTemplate: ArticleTemplate;
  /** Creates one "ArticleTemplate" if it does not exist, returns the existing otherwise */
  createArticleTemplateIfNotExists: ArticleTemplate;
  /** Creates some "ArticleTemplate", throws an error if they already exist */
  createArticleTemplates: Array<ArticleTemplate>;
  /** Creates one "Assignment", throws an error if it already exists */
  createAssignment: Assignment;
  /** Creates one "Assignment" if it does not exist, returns the existing otherwise */
  createAssignmentIfNotExists: Assignment;
  /** Creates some "Assignment", throws an error if they already exist */
  createAssignments: Array<Assignment>;
  /** Creates one "Attachment", throws an error if it already exists */
  createAttachment: Attachment;
  /** Creates one "Attachment" if it does not exist, returns the existing otherwise */
  createAttachmentIfNotExists: Attachment;
  /** Creates some "Attachment", throws an error if they already exist */
  createAttachments: Array<Attachment>;
  /** Creates one "Brand", throws an error if it already exists */
  createBrand: Brand;
  /** Creates one "Brand" if it does not exist, returns the existing otherwise */
  createBrandIfNotExists: Brand;
  /** Creates some "Brand", throws an error if they already exist */
  createBrands: Array<Brand>;
  /** Creates one "CenshareTrace", throws an error if it already exists */
  createCenshareTrace: CenshareTrace;
  /** Creates one "CenshareTrace" if it does not exist, returns the existing otherwise */
  createCenshareTraceIfNotExists: CenshareTrace;
  /** Creates some "CenshareTrace", throws an error if they already exist */
  createCenshareTraces: Array<CenshareTrace>;
  /** Creates one "PrintHeading", throws an error if it already exists */
  createPrintHeading: PrintHeading;
  /** Creates one "PrintHeading" if it does not exist, returns the existing otherwise */
  createPrintHeadingIfNotExists: PrintHeading;
  /** Creates one "PrintHeadingPrintTemplate", throws an error if it already exists */
  createPrintHeadingPrintTemplate: PrintHeadingPrintTemplate;
  /** Creates one "PrintHeadingPrintTemplate" if it does not exist, returns the existing otherwise */
  createPrintHeadingPrintTemplateIfNotExists: PrintHeadingPrintTemplate;
  /** Creates some "PrintHeadingPrintTemplate", throws an error if they already exist */
  createPrintHeadingPrintTemplates: Array<PrintHeadingPrintTemplate>;
  /** Creates some "PrintHeading", throws an error if they already exist */
  createPrintHeadings: Array<PrintHeading>;
  /** Creates one "PrintIssue", throws an error if it already exists */
  createPrintIssue: PrintIssue;
  /** Creates one "PrintIssue" if it does not exist, returns the existing otherwise */
  createPrintIssueIfNotExists: PrintIssue;
  /** Creates one "PrintIssueRailroad", throws an error if it already exists */
  createPrintIssueRailroad: PrintIssueRailroad;
  /** Creates one "PrintIssueRailroad" if it does not exist, returns the existing otherwise */
  createPrintIssueRailroadIfNotExists: PrintIssueRailroad;
  /** Creates some "PrintIssueRailroad", throws an error if they already exist */
  createPrintIssueRailroads: Array<PrintIssueRailroad>;
  /** Creates some "PrintIssue", throws an error if they already exist */
  createPrintIssues: Array<PrintIssue>;
  /** Creates one "PrintPublication", throws an error if it already exists */
  createPrintPublication: PrintPublication;
  /** Creates one "PrintPublication" if it does not exist, returns the existing otherwise */
  createPrintPublicationIfNotExists: PrintPublication;
  /** Creates some "PrintPublication", throws an error if they already exist */
  createPrintPublications: Array<PrintPublication>;
  /** Creates one "PrintTemplate", throws an error if it already exists */
  createPrintTemplate: PrintTemplate;
  /** Creates one "PrintTemplate" if it does not exist, returns the existing otherwise */
  createPrintTemplateIfNotExists: PrintTemplate;
  /** Creates some "PrintTemplate", throws an error if they already exist */
  createPrintTemplates: Array<PrintTemplate>;
  /** Creates one "RailroadPage", throws an error if it already exists */
  createRailroadPage: RailroadPage;
  /** Creates one "RailroadPage" if it does not exist, returns the existing otherwise */
  createRailroadPageIfNotExists: RailroadPage;
  /** Creates some "RailroadPage", throws an error if they already exist */
  createRailroadPages: Array<RailroadPage>;
  /** Creates one "RawArticle", throws an error if it already exists */
  createRawArticle: RawArticle;
  /** Creates one "RawArticle" if it does not exist, returns the existing otherwise */
  createRawArticleIfNotExists: RawArticle;
  /** Creates some "RawArticle", throws an error if they already exist */
  createRawArticles: Array<RawArticle>;
  /** Creates one "RawArticleVersion", throws an error if it already exists */
  createRawArticleVersion: RawArticleVersion;
  /** Creates one "RawArticleVersion" if it does not exist, returns the existing otherwise */
  createRawArticleVersionIfNotExists: RawArticleVersion;
  /** Creates some "RawArticleVersion", throws an error if they already exist */
  createRawArticleVersions: Array<RawArticleVersion>;
  /** Creates one "Subject", throws an error if it already exists */
  createSubject: Subject;
  /** Creates some "SubjectCategory", throws an error if they already exist */
  createSubjectCategories: Array<SubjectCategory>;
  /** Creates one "SubjectCategory", throws an error if it already exists */
  createSubjectCategory: SubjectCategory;
  /** Creates one "SubjectCategory" if it does not exist, returns the existing otherwise */
  createSubjectCategoryIfNotExists: SubjectCategory;
  /** Creates one "Subject" if it does not exist, returns the existing otherwise */
  createSubjectIfNotExists: Subject;
  /** Creates some "Subject", throws an error if they already exist */
  createSubjects: Array<Subject>;
  /** Creates one "SubjectUser", throws an error if it already exists */
  createSubjectUser: SubjectUser;
  /** Creates one "SubjectUser" if it does not exist, returns the existing otherwise */
  createSubjectUserIfNotExists: SubjectUser;
  /** Creates some "SubjectUser", throws an error if they already exist */
  createSubjectUsers: Array<SubjectUser>;
  /** Creates one "SubSubject", throws an error if it already exists */
  createSubSubject: SubSubject;
  /** Creates one "SubSubject" if it does not exist, returns the existing otherwise */
  createSubSubjectIfNotExists: SubSubject;
  /** Creates some "SubSubject", throws an error if they already exist */
  createSubSubjects: Array<SubSubject>;
  /** Creates one "TopicFotowebPige", throws an error if it already exists */
  createTopicFotowebPige: TopicFotowebPige;
  /** Creates one "TopicFotowebPige" if it does not exist, returns the existing otherwise */
  createTopicFotowebPigeIfNotExists: TopicFotowebPige;
  /** Creates some "TopicFotowebPige", throws an error if they already exist */
  createTopicFotowebPiges: Array<TopicFotowebPige>;
  /** Creates one "Unit", throws an error if it already exists */
  createUnit: Unit;
  /** Creates one "Unit" if it does not exist, returns the existing otherwise */
  createUnitIfNotExists: Unit;
  /** Creates some "Unit", throws an error if they already exist */
  createUnits: Array<Unit>;
  /** Creates one "Website", throws an error if it already exists */
  createWebsite: Website;
  /** Creates one "Website" if it does not exist, returns the existing otherwise */
  createWebsiteIfNotExists: Website;
  /** Creates some "Website", throws an error if they already exist */
  createWebsites: Array<Website>;
  /** Deletes one "ArticleTemplate", throws an error if it does not exists */
  deleteArticleTemplate: ArticleTemplate;
  /** Deletes one "ArticleTemplate" if it exists, returns null otherwise */
  deleteArticleTemplateIfExists?: Maybe<ArticleTemplate>;
  /** Deletes many "ArticleTemplates" */
  deleteArticleTemplates: Array<ArticleTemplate>;
  /** Deletes one "Assignment", throws an error if it does not exists */
  deleteAssignment: Assignment;
  /** Deletes one "Assignment" if it exists, returns null otherwise */
  deleteAssignmentIfExists?: Maybe<Assignment>;
  /** Deletes many "Assignments" */
  deleteAssignments: Array<Assignment>;
  /** Deletes one "Attachment", throws an error if it does not exists */
  deleteAttachment: Attachment;
  /** Deletes one "Attachment" if it exists, returns null otherwise */
  deleteAttachmentIfExists?: Maybe<Attachment>;
  /** Deletes many "Attachments" */
  deleteAttachments: Array<Attachment>;
  /** Deletes one "Brand", throws an error if it does not exists */
  deleteBrand: Brand;
  /** Deletes one "Brand" if it exists, returns null otherwise */
  deleteBrandIfExists?: Maybe<Brand>;
  /** Deletes many "Brands" */
  deleteBrands: Array<Brand>;
  /** Deletes one "CenshareTrace", throws an error if it does not exists */
  deleteCenshareTrace: CenshareTrace;
  /** Deletes one "CenshareTrace" if it exists, returns null otherwise */
  deleteCenshareTraceIfExists?: Maybe<CenshareTrace>;
  /** Deletes many "CenshareTraces" */
  deleteCenshareTraces: Array<CenshareTrace>;
  /** Deletes one "PrintHeading", throws an error if it does not exists */
  deletePrintHeading: PrintHeading;
  /** Deletes one "PrintHeading" if it exists, returns null otherwise */
  deletePrintHeadingIfExists?: Maybe<PrintHeading>;
  /** Deletes one "PrintHeadingPrintTemplate", throws an error if it does not exists */
  deletePrintHeadingPrintTemplate: PrintHeadingPrintTemplate;
  /** Deletes one "PrintHeadingPrintTemplate" if it exists, returns null otherwise */
  deletePrintHeadingPrintTemplateIfExists?: Maybe<PrintHeadingPrintTemplate>;
  /** Deletes many "PrintHeadingPrintTemplates" */
  deletePrintHeadingPrintTemplates: Array<PrintHeadingPrintTemplate>;
  /** Deletes many "PrintHeadings" */
  deletePrintHeadings: Array<PrintHeading>;
  /** Deletes one "PrintIssue", throws an error if it does not exists */
  deletePrintIssue: PrintIssue;
  /** Deletes one "PrintIssue" if it exists, returns null otherwise */
  deletePrintIssueIfExists?: Maybe<PrintIssue>;
  /** Deletes one "PrintIssueRailroad", throws an error if it does not exists */
  deletePrintIssueRailroad: PrintIssueRailroad;
  /** Deletes one "PrintIssueRailroad" if it exists, returns null otherwise */
  deletePrintIssueRailroadIfExists?: Maybe<PrintIssueRailroad>;
  /** Deletes many "PrintIssueRailroads" */
  deletePrintIssueRailroads: Array<PrintIssueRailroad>;
  /** Deletes many "PrintIssues" */
  deletePrintIssues: Array<PrintIssue>;
  /** Deletes one "PrintPublication", throws an error if it does not exists */
  deletePrintPublication: PrintPublication;
  /** Deletes one "PrintPublication" if it exists, returns null otherwise */
  deletePrintPublicationIfExists?: Maybe<PrintPublication>;
  /** Deletes many "PrintPublications" */
  deletePrintPublications: Array<PrintPublication>;
  /** Deletes one "PrintTemplate", throws an error if it does not exists */
  deletePrintTemplate: PrintTemplate;
  /** Deletes one "PrintTemplate" if it exists, returns null otherwise */
  deletePrintTemplateIfExists?: Maybe<PrintTemplate>;
  /** Deletes many "PrintTemplates" */
  deletePrintTemplates: Array<PrintTemplate>;
  /** Deletes one "RailroadPage", throws an error if it does not exists */
  deleteRailroadPage: RailroadPage;
  /** Deletes one "RailroadPage" if it exists, returns null otherwise */
  deleteRailroadPageIfExists?: Maybe<RailroadPage>;
  /** Deletes many "RailroadPages" */
  deleteRailroadPages: Array<RailroadPage>;
  /** Deletes one "RawArticle", throws an error if it does not exists */
  deleteRawArticle: RawArticle;
  /** Deletes one "RawArticle" if it exists, returns null otherwise */
  deleteRawArticleIfExists?: Maybe<RawArticle>;
  /** Deletes many "RawArticles" */
  deleteRawArticles: Array<RawArticle>;
  /** Deletes one "RawArticleVersion", throws an error if it does not exists */
  deleteRawArticleVersion: RawArticleVersion;
  /** Deletes one "RawArticleVersion" if it exists, returns null otherwise */
  deleteRawArticleVersionIfExists?: Maybe<RawArticleVersion>;
  /** Deletes many "RawArticleVersions" */
  deleteRawArticleVersions: Array<RawArticleVersion>;
  /** Deletes one "Subject", throws an error if it does not exists */
  deleteSubject: Subject;
  /** Deletes many "SubjectCategories" */
  deleteSubjectCategories: Array<SubjectCategory>;
  /** Deletes one "SubjectCategory", throws an error if it does not exists */
  deleteSubjectCategory: SubjectCategory;
  /** Deletes one "SubjectCategory" if it exists, returns null otherwise */
  deleteSubjectCategoryIfExists?: Maybe<SubjectCategory>;
  /** Deletes one "Subject" if it exists, returns null otherwise */
  deleteSubjectIfExists?: Maybe<Subject>;
  /** Deletes many "Subjects" */
  deleteSubjects: Array<Subject>;
  /** Deletes one "SubjectUser", throws an error if it does not exists */
  deleteSubjectUser: SubjectUser;
  /** Deletes one "SubjectUser" if it exists, returns null otherwise */
  deleteSubjectUserIfExists?: Maybe<SubjectUser>;
  /** Deletes many "SubjectUsers" */
  deleteSubjectUsers: Array<SubjectUser>;
  /** Deletes one "SubSubject", throws an error if it does not exists */
  deleteSubSubject: SubSubject;
  /** Deletes one "SubSubject" if it exists, returns null otherwise */
  deleteSubSubjectIfExists?: Maybe<SubSubject>;
  /** Deletes many "SubSubjects" */
  deleteSubSubjects: Array<SubSubject>;
  /** Deletes one "TopicFotowebPige", throws an error if it does not exists */
  deleteTopicFotowebPige: TopicFotowebPige;
  /** Deletes one "TopicFotowebPige" if it exists, returns null otherwise */
  deleteTopicFotowebPigeIfExists?: Maybe<TopicFotowebPige>;
  /** Deletes many "TopicFotowebPiges" */
  deleteTopicFotowebPiges: Array<TopicFotowebPige>;
  /** Deletes one "Unit", throws an error if it does not exists */
  deleteUnit: Unit;
  /** Deletes one "Unit" if it exists, returns null otherwise */
  deleteUnitIfExists?: Maybe<Unit>;
  /** Deletes many "Units" */
  deleteUnits: Array<Unit>;
  /** Deletes one "Website", throws an error if it does not exists */
  deleteWebsite: Website;
  /** Deletes one "Website" if it exists, returns null otherwise */
  deleteWebsiteIfExists?: Maybe<Website>;
  /** Deletes many "Websites" */
  deleteWebsites: Array<Website>;
  emailRawArticle: EmailRawArticleResultType;
  emailSubject: EmailSubjectResultType;
  /** Photo print issue headings to censhare */
  exportPrintIssueToCenshare: ExportPrintIssueToCenshareResultType;
  /** Photo photos to censhare */
  exportToCentshare: ExportToCenshareResultType;
  shareSubject?: Maybe<Subject>;
  /** Updates one "ArticleTemplate", throws an error if it does not exists */
  updateArticleTemplate: ArticleTemplate;
  /** Updates one "ArticleTemplate" if it exists, returns null otherwise */
  updateArticleTemplateIfExists?: Maybe<ArticleTemplate>;
  /** Updates many "ArticleTemplates" */
  updateArticleTemplates: Array<ArticleTemplate>;
  /** Updates one "Assignment", throws an error if it does not exists */
  updateAssignment: Assignment;
  /** Updates one "Assignment" if it exists, returns null otherwise */
  updateAssignmentIfExists?: Maybe<Assignment>;
  /** Updates many "Assignments" */
  updateAssignments: Array<Assignment>;
  /** Updates one "Attachment", throws an error if it does not exists */
  updateAttachment: Attachment;
  /** Updates one "Attachment" if it exists, returns null otherwise */
  updateAttachmentIfExists?: Maybe<Attachment>;
  /** Updates many "Attachments" */
  updateAttachments: Array<Attachment>;
  /** Updates one "Brand", throws an error if it does not exists */
  updateBrand: Brand;
  /** Updates one "Brand" if it exists, returns null otherwise */
  updateBrandIfExists?: Maybe<Brand>;
  /** Updates many "Brands" */
  updateBrands: Array<Brand>;
  /** Updates one "CenshareTrace", throws an error if it does not exists */
  updateCenshareTrace: CenshareTrace;
  /** Updates one "CenshareTrace" if it exists, returns null otherwise */
  updateCenshareTraceIfExists?: Maybe<CenshareTrace>;
  /** Updates many "CenshareTraces" */
  updateCenshareTraces: Array<CenshareTrace>;
  /** Updates one "PrintHeading", throws an error if it does not exists */
  updatePrintHeading: PrintHeading;
  /** Updates one "PrintHeading" if it exists, returns null otherwise */
  updatePrintHeadingIfExists?: Maybe<PrintHeading>;
  /** Updates one "PrintHeadingPrintTemplate", throws an error if it does not exists */
  updatePrintHeadingPrintTemplate: PrintHeadingPrintTemplate;
  /** Updates one "PrintHeadingPrintTemplate" if it exists, returns null otherwise */
  updatePrintHeadingPrintTemplateIfExists?: Maybe<PrintHeadingPrintTemplate>;
  /** Updates many "PrintHeadingPrintTemplates" */
  updatePrintHeadingPrintTemplates: Array<PrintHeadingPrintTemplate>;
  /** Updates many "PrintHeadings" */
  updatePrintHeadings: Array<PrintHeading>;
  /** Updates one "PrintIssue", throws an error if it does not exists */
  updatePrintIssue: PrintIssue;
  /** Updates one "PrintIssue" if it exists, returns null otherwise */
  updatePrintIssueIfExists?: Maybe<PrintIssue>;
  /** Updates one "PrintIssueRailroad", throws an error if it does not exists */
  updatePrintIssueRailroad: PrintIssueRailroad;
  /** Updates one "PrintIssueRailroad" if it exists, returns null otherwise */
  updatePrintIssueRailroadIfExists?: Maybe<PrintIssueRailroad>;
  /** Updates many "PrintIssueRailroads" */
  updatePrintIssueRailroads: Array<PrintIssueRailroad>;
  /** Updates many "PrintIssues" */
  updatePrintIssues: Array<PrintIssue>;
  /** Updates one "PrintPublication", throws an error if it does not exists */
  updatePrintPublication: PrintPublication;
  /** Updates one "PrintPublication" if it exists, returns null otherwise */
  updatePrintPublicationIfExists?: Maybe<PrintPublication>;
  /** Updates many "PrintPublications" */
  updatePrintPublications: Array<PrintPublication>;
  /** Updates one "PrintTemplate", throws an error if it does not exists */
  updatePrintTemplate: PrintTemplate;
  /** Updates one "PrintTemplate" if it exists, returns null otherwise */
  updatePrintTemplateIfExists?: Maybe<PrintTemplate>;
  /** Updates many "PrintTemplates" */
  updatePrintTemplates: Array<PrintTemplate>;
  /** Updates one "RailroadPage", throws an error if it does not exists */
  updateRailroadPage: RailroadPage;
  /** Updates one "RailroadPage" if it exists, returns null otherwise */
  updateRailroadPageIfExists?: Maybe<RailroadPage>;
  /** Updates many "RailroadPages" */
  updateRailroadPages: Array<RailroadPage>;
  /** Updates one "RawArticle", throws an error if it does not exists */
  updateRawArticle: RawArticle;
  /** Updates one "RawArticle" if it exists, returns null otherwise */
  updateRawArticleIfExists?: Maybe<RawArticle>;
  /** Updates many "RawArticles" */
  updateRawArticles: Array<RawArticle>;
  /** Updates one "RawArticleVersion", throws an error if it does not exists */
  updateRawArticleVersion: RawArticleVersion;
  /** Updates one "RawArticleVersion" if it exists, returns null otherwise */
  updateRawArticleVersionIfExists?: Maybe<RawArticleVersion>;
  /** Updates many "RawArticleVersions" */
  updateRawArticleVersions: Array<RawArticleVersion>;
  /** Updates one "Subject", throws an error if it does not exists */
  updateSubject: Subject;
  /** Updates many "SubjectCategories" */
  updateSubjectCategories: Array<SubjectCategory>;
  /** Updates one "SubjectCategory", throws an error if it does not exists */
  updateSubjectCategory: SubjectCategory;
  /** Updates one "SubjectCategory" if it exists, returns null otherwise */
  updateSubjectCategoryIfExists?: Maybe<SubjectCategory>;
  /** Updates one "Subject" if it exists, returns null otherwise */
  updateSubjectIfExists?: Maybe<Subject>;
  /** Updates many "Subjects" */
  updateSubjects: Array<Subject>;
  /** Updates one "SubSubject", throws an error if it does not exists */
  updateSubSubject: SubSubject;
  /** Updates one "SubSubject" if it exists, returns null otherwise */
  updateSubSubjectIfExists?: Maybe<SubSubject>;
  /** Updates many "SubSubjects" */
  updateSubSubjects: Array<SubSubject>;
  /** Updates one "TopicFotowebPige", throws an error if it does not exists */
  updateTopicFotowebPige: TopicFotowebPige;
  /** Updates one "TopicFotowebPige" if it exists, returns null otherwise */
  updateTopicFotowebPigeIfExists?: Maybe<TopicFotowebPige>;
  /** Updates many "TopicFotowebPiges" */
  updateTopicFotowebPiges: Array<TopicFotowebPige>;
  /** Updates one "Unit", throws an error if it does not exists */
  updateUnit: Unit;
  /** Updates one "Unit" if it exists, returns null otherwise */
  updateUnitIfExists?: Maybe<Unit>;
  /** Updates many "Units" */
  updateUnits: Array<Unit>;
  /** Updates one "Website", throws an error if it does not exists */
  updateWebsite: Website;
  /** Updates one "Website" if it exists, returns null otherwise */
  updateWebsiteIfExists?: Maybe<Website>;
  /** Updates many "Websites" */
  updateWebsites: Array<Website>;
  /** Updates an existing "ArticleTemplate" or creates a new one */
  upsertArticleTemplate: ArticleTemplate;
  /** Updates an existing "Assignment" or creates a new one */
  upsertAssignment: Assignment;
  /** Updates an existing "Attachment" or creates a new one */
  upsertAttachment: Attachment;
  /** Updates an existing "Brand" or creates a new one */
  upsertBrand: Brand;
  /** Updates an existing "CenshareTrace" or creates a new one */
  upsertCenshareTrace: CenshareTrace;
  /** Updates an existing "PrintHeading" or creates a new one */
  upsertPrintHeading: PrintHeading;
  /** Updates an existing "PrintHeadingPrintTemplate" or creates a new one */
  upsertPrintHeadingPrintTemplate: PrintHeadingPrintTemplate;
  /** Updates an existing "PrintIssue" or creates a new one */
  upsertPrintIssue: PrintIssue;
  /** Updates an existing "PrintIssueRailroad" or creates a new one */
  upsertPrintIssueRailroad: PrintIssueRailroad;
  /** Updates an existing "PrintPublication" or creates a new one */
  upsertPrintPublication: PrintPublication;
  /** Updates an existing "PrintTemplate" or creates a new one */
  upsertPrintTemplate: PrintTemplate;
  /** Updates an existing "RailroadPage" or creates a new one */
  upsertRailroadPage: RailroadPage;
  /** Updates an existing "RawArticle" or creates a new one */
  upsertRawArticle: RawArticle;
  /** Updates an existing "RawArticleVersion" or creates a new one */
  upsertRawArticleVersion: RawArticleVersion;
  /** Updates an existing "Subject" or creates a new one */
  upsertSubject: Subject;
  /** Updates an existing "SubjectCategory" or creates a new one */
  upsertSubjectCategory: SubjectCategory;
  /** Updates an existing "SubSubject" or creates a new one */
  upsertSubSubject: SubSubject;
  /** Updates an existing "TopicFotowebPige" or creates a new one */
  upsertTopicFotowebPige: TopicFotowebPige;
  /** Updates an existing "Unit" or creates a new one */
  upsertUnit: Unit;
  /** Updates an existing "Website" or creates a new one */
  upsertWebsite: Website;
};


export type MutationCreateArticleTemplateArgs = {
  data: ArticleTemplateCreationInput;
};


export type MutationCreateArticleTemplateIfNotExistsArgs = {
  data: ArticleTemplateCreationInput;
  where: ArticleTemplateUniqueFilterInput;
};


export type MutationCreateArticleTemplatesArgs = {
  data: Array<ArticleTemplateCreationInput>;
};


export type MutationCreateAssignmentArgs = {
  data: AssignmentCreationInput;
};


export type MutationCreateAssignmentIfNotExistsArgs = {
  data: AssignmentCreationInput;
  where: AssignmentUniqueFilterInput;
};


export type MutationCreateAssignmentsArgs = {
  data: Array<AssignmentCreationInput>;
};


export type MutationCreateAttachmentArgs = {
  data: AttachmentCreationInput;
};


export type MutationCreateAttachmentIfNotExistsArgs = {
  data: AttachmentCreationInput;
  where: AttachmentUniqueFilterInput;
};


export type MutationCreateAttachmentsArgs = {
  data: Array<AttachmentCreationInput>;
};


export type MutationCreateBrandArgs = {
  data: BrandCreationInput;
};


export type MutationCreateBrandIfNotExistsArgs = {
  data: BrandCreationInput;
  where: BrandUniqueFilterInput;
};


export type MutationCreateBrandsArgs = {
  data: Array<BrandCreationInput>;
};


export type MutationCreateCenshareTraceArgs = {
  data: CenshareTraceCreationInput;
};


export type MutationCreateCenshareTraceIfNotExistsArgs = {
  data: CenshareTraceCreationInput;
  where: CenshareTraceUniqueFilterInput;
};


export type MutationCreateCenshareTracesArgs = {
  data: Array<CenshareTraceCreationInput>;
};


export type MutationCreatePrintHeadingArgs = {
  data: PrintHeadingCreationInput;
};


export type MutationCreatePrintHeadingIfNotExistsArgs = {
  data: PrintHeadingCreationInput;
  where: PrintHeadingUniqueFilterInput;
};


export type MutationCreatePrintHeadingPrintTemplateArgs = {
  data: PrintHeadingPrintTemplateCreationInput;
};


export type MutationCreatePrintHeadingPrintTemplateIfNotExistsArgs = {
  data: PrintHeadingPrintTemplateCreationInput;
  where: PrintHeadingPrintTemplateUniqueFilterInput;
};


export type MutationCreatePrintHeadingPrintTemplatesArgs = {
  data: Array<PrintHeadingPrintTemplateCreationInput>;
};


export type MutationCreatePrintHeadingsArgs = {
  data: Array<PrintHeadingCreationInput>;
};


export type MutationCreatePrintIssueArgs = {
  data: PrintIssueCreationInput;
};


export type MutationCreatePrintIssueIfNotExistsArgs = {
  data: PrintIssueCreationInput;
  where: PrintIssueUniqueFilterInput;
};


export type MutationCreatePrintIssueRailroadArgs = {
  data: PrintIssueRailroadCreationInput;
};


export type MutationCreatePrintIssueRailroadIfNotExistsArgs = {
  data: PrintIssueRailroadCreationInput;
  where: PrintIssueRailroadUniqueFilterInput;
};


export type MutationCreatePrintIssueRailroadsArgs = {
  data: Array<PrintIssueRailroadCreationInput>;
};


export type MutationCreatePrintIssuesArgs = {
  data: Array<PrintIssueCreationInput>;
};


export type MutationCreatePrintPublicationArgs = {
  data: PrintPublicationCreationInput;
};


export type MutationCreatePrintPublicationIfNotExistsArgs = {
  data: PrintPublicationCreationInput;
  where: PrintPublicationUniqueFilterInput;
};


export type MutationCreatePrintPublicationsArgs = {
  data: Array<PrintPublicationCreationInput>;
};


export type MutationCreatePrintTemplateArgs = {
  data: PrintTemplateCreationInput;
};


export type MutationCreatePrintTemplateIfNotExistsArgs = {
  data: PrintTemplateCreationInput;
  where: PrintTemplateUniqueFilterInput;
};


export type MutationCreatePrintTemplatesArgs = {
  data: Array<PrintTemplateCreationInput>;
};


export type MutationCreateRailroadPageArgs = {
  data: RailroadPageCreationInput;
};


export type MutationCreateRailroadPageIfNotExistsArgs = {
  data: RailroadPageCreationInput;
  where: RailroadPageUniqueFilterInput;
};


export type MutationCreateRailroadPagesArgs = {
  data: Array<RailroadPageCreationInput>;
};


export type MutationCreateRawArticleArgs = {
  data: RawArticleCreationInput;
};


export type MutationCreateRawArticleIfNotExistsArgs = {
  data: RawArticleCreationInput;
  where: RawArticleUniqueFilterInput;
};


export type MutationCreateRawArticlesArgs = {
  data: Array<RawArticleCreationInput>;
};


export type MutationCreateRawArticleVersionArgs = {
  data: RawArticleVersionCreationInput;
};


export type MutationCreateRawArticleVersionIfNotExistsArgs = {
  data: RawArticleVersionCreationInput;
  where: RawArticleVersionUniqueFilterInput;
};


export type MutationCreateRawArticleVersionsArgs = {
  data: Array<RawArticleVersionCreationInput>;
};


export type MutationCreateSubjectArgs = {
  data: SubjectCreationInput;
};


export type MutationCreateSubjectCategoriesArgs = {
  data: Array<SubjectCategoryCreationInput>;
};


export type MutationCreateSubjectCategoryArgs = {
  data: SubjectCategoryCreationInput;
};


export type MutationCreateSubjectCategoryIfNotExistsArgs = {
  data: SubjectCategoryCreationInput;
  where: SubjectCategoryUniqueFilterInput;
};


export type MutationCreateSubjectIfNotExistsArgs = {
  data: SubjectCreationInput;
  where: SubjectUniqueFilterInput;
};


export type MutationCreateSubjectsArgs = {
  data: Array<SubjectCreationInput>;
};


export type MutationCreateSubjectUserArgs = {
  data: SubjectUserCreationInput;
};


export type MutationCreateSubjectUserIfNotExistsArgs = {
  data: SubjectUserCreationInput;
  where: SubjectUserUniqueFilterInput;
};


export type MutationCreateSubjectUsersArgs = {
  data: Array<SubjectUserCreationInput>;
};


export type MutationCreateSubSubjectArgs = {
  data: SubSubjectCreationInput;
};


export type MutationCreateSubSubjectIfNotExistsArgs = {
  data: SubSubjectCreationInput;
  where: SubSubjectUniqueFilterInput;
};


export type MutationCreateSubSubjectsArgs = {
  data: Array<SubSubjectCreationInput>;
};


export type MutationCreateTopicFotowebPigeArgs = {
  data: TopicFotowebPigeCreationInput;
};


export type MutationCreateTopicFotowebPigeIfNotExistsArgs = {
  data: TopicFotowebPigeCreationInput;
  where: TopicFotowebPigeUniqueFilterInput;
};


export type MutationCreateTopicFotowebPigesArgs = {
  data: Array<TopicFotowebPigeCreationInput>;
};


export type MutationCreateUnitArgs = {
  data: UnitCreationInput;
};


export type MutationCreateUnitIfNotExistsArgs = {
  data: UnitCreationInput;
  where: UnitUniqueFilterInput;
};


export type MutationCreateUnitsArgs = {
  data: Array<UnitCreationInput>;
};


export type MutationCreateWebsiteArgs = {
  data: WebsiteCreationInput;
};


export type MutationCreateWebsiteIfNotExistsArgs = {
  data: WebsiteCreationInput;
  where: WebsiteUniqueFilterInput;
};


export type MutationCreateWebsitesArgs = {
  data: Array<WebsiteCreationInput>;
};


export type MutationDeleteArticleTemplateArgs = {
  where: ArticleTemplateUniqueFilterInput;
};


export type MutationDeleteArticleTemplateIfExistsArgs = {
  where: ArticleTemplateUniqueFilterInput;
};


export type MutationDeleteArticleTemplatesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<ArticleTemplateOrderingInput>>;
  where?: InputMaybe<ArticleTemplateFilterInput>;
};


export type MutationDeleteAssignmentArgs = {
  where: AssignmentUniqueFilterInput;
};


export type MutationDeleteAssignmentIfExistsArgs = {
  where: AssignmentUniqueFilterInput;
};


export type MutationDeleteAssignmentsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AssignmentOrderingInput>>;
  where?: InputMaybe<AssignmentFilterInput>;
};


export type MutationDeleteAttachmentArgs = {
  where: AttachmentUniqueFilterInput;
};


export type MutationDeleteAttachmentIfExistsArgs = {
  where: AttachmentUniqueFilterInput;
};


export type MutationDeleteAttachmentsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AttachmentOrderingInput>>;
  where?: InputMaybe<AttachmentFilterInput>;
};


export type MutationDeleteBrandArgs = {
  where: BrandUniqueFilterInput;
};


export type MutationDeleteBrandIfExistsArgs = {
  where: BrandUniqueFilterInput;
};


export type MutationDeleteBrandsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<BrandOrderingInput>>;
  where?: InputMaybe<BrandFilterInput>;
};


export type MutationDeleteCenshareTraceArgs = {
  where: CenshareTraceUniqueFilterInput;
};


export type MutationDeleteCenshareTraceIfExistsArgs = {
  where: CenshareTraceUniqueFilterInput;
};


export type MutationDeleteCenshareTracesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<CenshareTraceOrderingInput>>;
  where?: InputMaybe<CenshareTraceFilterInput>;
};


export type MutationDeletePrintHeadingArgs = {
  where: PrintHeadingUniqueFilterInput;
};


export type MutationDeletePrintHeadingIfExistsArgs = {
  where: PrintHeadingUniqueFilterInput;
};


export type MutationDeletePrintHeadingPrintTemplateArgs = {
  where: PrintHeadingPrintTemplateUniqueFilterInput;
};


export type MutationDeletePrintHeadingPrintTemplateIfExistsArgs = {
  where: PrintHeadingPrintTemplateUniqueFilterInput;
};


export type MutationDeletePrintHeadingPrintTemplatesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintHeadingPrintTemplateOrderingInput>>;
  where?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
};


export type MutationDeletePrintHeadingsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintHeadingOrderingInput>>;
  where?: InputMaybe<PrintHeadingFilterInput>;
};


export type MutationDeletePrintIssueArgs = {
  where: PrintIssueUniqueFilterInput;
};


export type MutationDeletePrintIssueIfExistsArgs = {
  where: PrintIssueUniqueFilterInput;
};


export type MutationDeletePrintIssueRailroadArgs = {
  where: PrintIssueRailroadUniqueFilterInput;
};


export type MutationDeletePrintIssueRailroadIfExistsArgs = {
  where: PrintIssueRailroadUniqueFilterInput;
};


export type MutationDeletePrintIssueRailroadsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintIssueRailroadOrderingInput>>;
  where?: InputMaybe<PrintIssueRailroadFilterInput>;
};


export type MutationDeletePrintIssuesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintIssueOrderingInput>>;
  where?: InputMaybe<PrintIssueFilterInput>;
};


export type MutationDeletePrintPublicationArgs = {
  where: PrintPublicationUniqueFilterInput;
};


export type MutationDeletePrintPublicationIfExistsArgs = {
  where: PrintPublicationUniqueFilterInput;
};


export type MutationDeletePrintPublicationsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintPublicationOrderingInput>>;
  where?: InputMaybe<PrintPublicationFilterInput>;
};


export type MutationDeletePrintTemplateArgs = {
  where: PrintTemplateUniqueFilterInput;
};


export type MutationDeletePrintTemplateIfExistsArgs = {
  where: PrintTemplateUniqueFilterInput;
};


export type MutationDeletePrintTemplatesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintTemplateOrderingInput>>;
  where?: InputMaybe<PrintTemplateFilterInput>;
};


export type MutationDeleteRailroadPageArgs = {
  where: RailroadPageUniqueFilterInput;
};


export type MutationDeleteRailroadPageIfExistsArgs = {
  where: RailroadPageUniqueFilterInput;
};


export type MutationDeleteRailroadPagesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RailroadPageOrderingInput>>;
  where?: InputMaybe<RailroadPageFilterInput>;
};


export type MutationDeleteRawArticleArgs = {
  where: RawArticleUniqueFilterInput;
};


export type MutationDeleteRawArticleIfExistsArgs = {
  where: RawArticleUniqueFilterInput;
};


export type MutationDeleteRawArticlesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RawArticleOrderingInput>>;
  where?: InputMaybe<RawArticleFilterInput>;
};


export type MutationDeleteRawArticleVersionArgs = {
  where: RawArticleVersionUniqueFilterInput;
};


export type MutationDeleteRawArticleVersionIfExistsArgs = {
  where: RawArticleVersionUniqueFilterInput;
};


export type MutationDeleteRawArticleVersionsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RawArticleVersionOrderingInput>>;
  where?: InputMaybe<RawArticleVersionFilterInput>;
};


export type MutationDeleteSubjectArgs = {
  where: SubjectUniqueFilterInput;
};


export type MutationDeleteSubjectCategoriesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectCategoryOrderingInput>>;
  where?: InputMaybe<SubjectCategoryFilterInput>;
};


export type MutationDeleteSubjectCategoryArgs = {
  where: SubjectCategoryUniqueFilterInput;
};


export type MutationDeleteSubjectCategoryIfExistsArgs = {
  where: SubjectCategoryUniqueFilterInput;
};


export type MutationDeleteSubjectIfExistsArgs = {
  where: SubjectUniqueFilterInput;
};


export type MutationDeleteSubjectsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectOrderingInput>>;
  where?: InputMaybe<SubjectFilterInput>;
};


export type MutationDeleteSubjectUserArgs = {
  where: SubjectUserUniqueFilterInput;
};


export type MutationDeleteSubjectUserIfExistsArgs = {
  where: SubjectUserUniqueFilterInput;
};


export type MutationDeleteSubjectUsersArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectUserOrderingInput>>;
  where?: InputMaybe<SubjectUserFilterInput>;
};


export type MutationDeleteSubSubjectArgs = {
  where: SubSubjectUniqueFilterInput;
};


export type MutationDeleteSubSubjectIfExistsArgs = {
  where: SubSubjectUniqueFilterInput;
};


export type MutationDeleteSubSubjectsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubSubjectOrderingInput>>;
  where?: InputMaybe<SubSubjectFilterInput>;
};


export type MutationDeleteTopicFotowebPigeArgs = {
  where: TopicFotowebPigeUniqueFilterInput;
};


export type MutationDeleteTopicFotowebPigeIfExistsArgs = {
  where: TopicFotowebPigeUniqueFilterInput;
};


export type MutationDeleteTopicFotowebPigesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<TopicFotowebPigeOrderingInput>>;
  where?: InputMaybe<TopicFotowebPigeFilterInput>;
};


export type MutationDeleteUnitArgs = {
  where: UnitUniqueFilterInput;
};


export type MutationDeleteUnitIfExistsArgs = {
  where: UnitUniqueFilterInput;
};


export type MutationDeleteUnitsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<UnitOrderingInput>>;
  where?: InputMaybe<UnitFilterInput>;
};


export type MutationDeleteWebsiteArgs = {
  where: WebsiteUniqueFilterInput;
};


export type MutationDeleteWebsiteIfExistsArgs = {
  where: WebsiteUniqueFilterInput;
};


export type MutationDeleteWebsitesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<WebsiteOrderingInput>>;
  where?: InputMaybe<WebsiteFilterInput>;
};


export type MutationEmailRawArticleArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  rawArticle?: InputMaybe<RawArticleUniqueFilterInput>;
  recipients: Array<Scalars['String']['input']>;
};


export type MutationEmailSubjectArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  recipients: Array<Scalars['String']['input']>;
  subject: SubjectUniqueFilterInput;
};


export type MutationExportPrintIssueToCenshareArgs = {
  printIssueId: Scalars['String']['input'];
};


export type MutationExportToCentshareArgs = {
  assignment: Scalars['String']['input'];
  brand: Scalars['String']['input'];
  issue: Scalars['String']['input'];
  photos?: InputMaybe<Array<Scalars['String']['input']>>;
  subject: Scalars['String']['input'];
};


export type MutationShareSubjectArgs = {
  subject: SubjectUniqueFilterInput;
  userIds: Array<Scalars['String']['input']>;
};


export type MutationUpdateArticleTemplateArgs = {
  data?: InputMaybe<ArticleTemplateUpdateInput>;
  where: ArticleTemplateUniqueFilterInput;
};


export type MutationUpdateArticleTemplateIfExistsArgs = {
  data?: InputMaybe<ArticleTemplateUpdateInput>;
  where: ArticleTemplateUniqueFilterInput;
};


export type MutationUpdateArticleTemplatesArgs = {
  data?: InputMaybe<ArticleTemplateUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<ArticleTemplateOrderingInput>>;
  where?: InputMaybe<ArticleTemplateFilterInput>;
};


export type MutationUpdateAssignmentArgs = {
  data?: InputMaybe<AssignmentUpdateInput>;
  where: AssignmentUniqueFilterInput;
};


export type MutationUpdateAssignmentIfExistsArgs = {
  data?: InputMaybe<AssignmentUpdateInput>;
  where: AssignmentUniqueFilterInput;
};


export type MutationUpdateAssignmentsArgs = {
  data?: InputMaybe<AssignmentUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AssignmentOrderingInput>>;
  where?: InputMaybe<AssignmentFilterInput>;
};


export type MutationUpdateAttachmentArgs = {
  data?: InputMaybe<AttachmentUpdateInput>;
  where: AttachmentUniqueFilterInput;
};


export type MutationUpdateAttachmentIfExistsArgs = {
  data?: InputMaybe<AttachmentUpdateInput>;
  where: AttachmentUniqueFilterInput;
};


export type MutationUpdateAttachmentsArgs = {
  data?: InputMaybe<AttachmentUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AttachmentOrderingInput>>;
  where?: InputMaybe<AttachmentFilterInput>;
};


export type MutationUpdateBrandArgs = {
  data?: InputMaybe<BrandUpdateInput>;
  where: BrandUniqueFilterInput;
};


export type MutationUpdateBrandIfExistsArgs = {
  data?: InputMaybe<BrandUpdateInput>;
  where: BrandUniqueFilterInput;
};


export type MutationUpdateBrandsArgs = {
  data?: InputMaybe<BrandUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<BrandOrderingInput>>;
  where?: InputMaybe<BrandFilterInput>;
};


export type MutationUpdateCenshareTraceArgs = {
  data?: InputMaybe<CenshareTraceUpdateInput>;
  where: CenshareTraceUniqueFilterInput;
};


export type MutationUpdateCenshareTraceIfExistsArgs = {
  data?: InputMaybe<CenshareTraceUpdateInput>;
  where: CenshareTraceUniqueFilterInput;
};


export type MutationUpdateCenshareTracesArgs = {
  data?: InputMaybe<CenshareTraceUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<CenshareTraceOrderingInput>>;
  where?: InputMaybe<CenshareTraceFilterInput>;
};


export type MutationUpdatePrintHeadingArgs = {
  data?: InputMaybe<PrintHeadingUpdateInput>;
  where: PrintHeadingUniqueFilterInput;
};


export type MutationUpdatePrintHeadingIfExistsArgs = {
  data?: InputMaybe<PrintHeadingUpdateInput>;
  where: PrintHeadingUniqueFilterInput;
};


export type MutationUpdatePrintHeadingPrintTemplateArgs = {
  data?: InputMaybe<PrintHeadingPrintTemplateUpdateInput>;
  where: PrintHeadingPrintTemplateUniqueFilterInput;
};


export type MutationUpdatePrintHeadingPrintTemplateIfExistsArgs = {
  data?: InputMaybe<PrintHeadingPrintTemplateUpdateInput>;
  where: PrintHeadingPrintTemplateUniqueFilterInput;
};


export type MutationUpdatePrintHeadingPrintTemplatesArgs = {
  data?: InputMaybe<PrintHeadingPrintTemplateUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintHeadingPrintTemplateOrderingInput>>;
  where?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
};


export type MutationUpdatePrintHeadingsArgs = {
  data?: InputMaybe<PrintHeadingUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintHeadingOrderingInput>>;
  where?: InputMaybe<PrintHeadingFilterInput>;
};


export type MutationUpdatePrintIssueArgs = {
  data?: InputMaybe<PrintIssueUpdateInput>;
  where: PrintIssueUniqueFilterInput;
};


export type MutationUpdatePrintIssueIfExistsArgs = {
  data?: InputMaybe<PrintIssueUpdateInput>;
  where: PrintIssueUniqueFilterInput;
};


export type MutationUpdatePrintIssueRailroadArgs = {
  data?: InputMaybe<PrintIssueRailroadUpdateInput>;
  where: PrintIssueRailroadUniqueFilterInput;
};


export type MutationUpdatePrintIssueRailroadIfExistsArgs = {
  data?: InputMaybe<PrintIssueRailroadUpdateInput>;
  where: PrintIssueRailroadUniqueFilterInput;
};


export type MutationUpdatePrintIssueRailroadsArgs = {
  data?: InputMaybe<PrintIssueRailroadUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintIssueRailroadOrderingInput>>;
  where?: InputMaybe<PrintIssueRailroadFilterInput>;
};


export type MutationUpdatePrintIssuesArgs = {
  data?: InputMaybe<PrintIssueUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintIssueOrderingInput>>;
  where?: InputMaybe<PrintIssueFilterInput>;
};


export type MutationUpdatePrintPublicationArgs = {
  data?: InputMaybe<PrintPublicationUpdateInput>;
  where: PrintPublicationUniqueFilterInput;
};


export type MutationUpdatePrintPublicationIfExistsArgs = {
  data?: InputMaybe<PrintPublicationUpdateInput>;
  where: PrintPublicationUniqueFilterInput;
};


export type MutationUpdatePrintPublicationsArgs = {
  data?: InputMaybe<PrintPublicationUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintPublicationOrderingInput>>;
  where?: InputMaybe<PrintPublicationFilterInput>;
};


export type MutationUpdatePrintTemplateArgs = {
  data?: InputMaybe<PrintTemplateUpdateInput>;
  where: PrintTemplateUniqueFilterInput;
};


export type MutationUpdatePrintTemplateIfExistsArgs = {
  data?: InputMaybe<PrintTemplateUpdateInput>;
  where: PrintTemplateUniqueFilterInput;
};


export type MutationUpdatePrintTemplatesArgs = {
  data?: InputMaybe<PrintTemplateUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintTemplateOrderingInput>>;
  where?: InputMaybe<PrintTemplateFilterInput>;
};


export type MutationUpdateRailroadPageArgs = {
  data?: InputMaybe<RailroadPageUpdateInput>;
  where: RailroadPageUniqueFilterInput;
};


export type MutationUpdateRailroadPageIfExistsArgs = {
  data?: InputMaybe<RailroadPageUpdateInput>;
  where: RailroadPageUniqueFilterInput;
};


export type MutationUpdateRailroadPagesArgs = {
  data?: InputMaybe<RailroadPageUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RailroadPageOrderingInput>>;
  where?: InputMaybe<RailroadPageFilterInput>;
};


export type MutationUpdateRawArticleArgs = {
  data?: InputMaybe<RawArticleUpdateInput>;
  where: RawArticleUniqueFilterInput;
};


export type MutationUpdateRawArticleIfExistsArgs = {
  data?: InputMaybe<RawArticleUpdateInput>;
  where: RawArticleUniqueFilterInput;
};


export type MutationUpdateRawArticlesArgs = {
  data?: InputMaybe<RawArticleUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RawArticleOrderingInput>>;
  where?: InputMaybe<RawArticleFilterInput>;
};


export type MutationUpdateRawArticleVersionArgs = {
  data?: InputMaybe<RawArticleVersionUpdateInput>;
  where: RawArticleVersionUniqueFilterInput;
};


export type MutationUpdateRawArticleVersionIfExistsArgs = {
  data?: InputMaybe<RawArticleVersionUpdateInput>;
  where: RawArticleVersionUniqueFilterInput;
};


export type MutationUpdateRawArticleVersionsArgs = {
  data?: InputMaybe<RawArticleVersionUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RawArticleVersionOrderingInput>>;
  where?: InputMaybe<RawArticleVersionFilterInput>;
};


export type MutationUpdateSubjectArgs = {
  data?: InputMaybe<SubjectUpdateInput>;
  where: SubjectUniqueFilterInput;
};


export type MutationUpdateSubjectCategoriesArgs = {
  data?: InputMaybe<SubjectCategoryUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectCategoryOrderingInput>>;
  where?: InputMaybe<SubjectCategoryFilterInput>;
};


export type MutationUpdateSubjectCategoryArgs = {
  data?: InputMaybe<SubjectCategoryUpdateInput>;
  where: SubjectCategoryUniqueFilterInput;
};


export type MutationUpdateSubjectCategoryIfExistsArgs = {
  data?: InputMaybe<SubjectCategoryUpdateInput>;
  where: SubjectCategoryUniqueFilterInput;
};


export type MutationUpdateSubjectIfExistsArgs = {
  data?: InputMaybe<SubjectUpdateInput>;
  where: SubjectUniqueFilterInput;
};


export type MutationUpdateSubjectsArgs = {
  data?: InputMaybe<SubjectUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectOrderingInput>>;
  where?: InputMaybe<SubjectFilterInput>;
};


export type MutationUpdateSubSubjectArgs = {
  data?: InputMaybe<SubSubjectUpdateInput>;
  where: SubSubjectUniqueFilterInput;
};


export type MutationUpdateSubSubjectIfExistsArgs = {
  data?: InputMaybe<SubSubjectUpdateInput>;
  where: SubSubjectUniqueFilterInput;
};


export type MutationUpdateSubSubjectsArgs = {
  data?: InputMaybe<SubSubjectUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubSubjectOrderingInput>>;
  where?: InputMaybe<SubSubjectFilterInput>;
};


export type MutationUpdateTopicFotowebPigeArgs = {
  data?: InputMaybe<TopicFotowebPigeUpdateInput>;
  where: TopicFotowebPigeUniqueFilterInput;
};


export type MutationUpdateTopicFotowebPigeIfExistsArgs = {
  data?: InputMaybe<TopicFotowebPigeUpdateInput>;
  where: TopicFotowebPigeUniqueFilterInput;
};


export type MutationUpdateTopicFotowebPigesArgs = {
  data?: InputMaybe<TopicFotowebPigeUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<TopicFotowebPigeOrderingInput>>;
  where?: InputMaybe<TopicFotowebPigeFilterInput>;
};


export type MutationUpdateUnitArgs = {
  data?: InputMaybe<UnitUpdateInput>;
  where: UnitUniqueFilterInput;
};


export type MutationUpdateUnitIfExistsArgs = {
  data?: InputMaybe<UnitUpdateInput>;
  where: UnitUniqueFilterInput;
};


export type MutationUpdateUnitsArgs = {
  data?: InputMaybe<UnitUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<UnitOrderingInput>>;
  where?: InputMaybe<UnitFilterInput>;
};


export type MutationUpdateWebsiteArgs = {
  data?: InputMaybe<WebsiteUpdateInput>;
  where: WebsiteUniqueFilterInput;
};


export type MutationUpdateWebsiteIfExistsArgs = {
  data?: InputMaybe<WebsiteUpdateInput>;
  where: WebsiteUniqueFilterInput;
};


export type MutationUpdateWebsitesArgs = {
  data?: InputMaybe<WebsiteUpdateInput>;
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<WebsiteOrderingInput>>;
  where?: InputMaybe<WebsiteFilterInput>;
};


export type MutationUpsertArticleTemplateArgs = {
  create: ArticleTemplateCreationInput;
  update?: InputMaybe<ArticleTemplateUpdateInput>;
  where: ArticleTemplateUniqueFilterInput;
};


export type MutationUpsertAssignmentArgs = {
  create: AssignmentCreationInput;
  update?: InputMaybe<AssignmentUpdateInput>;
  where: AssignmentUniqueFilterInput;
};


export type MutationUpsertAttachmentArgs = {
  create: AttachmentCreationInput;
  update?: InputMaybe<AttachmentUpdateInput>;
  where: AttachmentUniqueFilterInput;
};


export type MutationUpsertBrandArgs = {
  create: BrandCreationInput;
  update?: InputMaybe<BrandUpdateInput>;
  where: BrandUniqueFilterInput;
};


export type MutationUpsertCenshareTraceArgs = {
  create: CenshareTraceCreationInput;
  update?: InputMaybe<CenshareTraceUpdateInput>;
  where: CenshareTraceUniqueFilterInput;
};


export type MutationUpsertPrintHeadingArgs = {
  create: PrintHeadingCreationInput;
  update?: InputMaybe<PrintHeadingUpdateInput>;
  where: PrintHeadingUniqueFilterInput;
};


export type MutationUpsertPrintHeadingPrintTemplateArgs = {
  create: PrintHeadingPrintTemplateCreationInput;
  update?: InputMaybe<PrintHeadingPrintTemplateUpdateInput>;
  where: PrintHeadingPrintTemplateUniqueFilterInput;
};


export type MutationUpsertPrintIssueArgs = {
  create: PrintIssueCreationInput;
  update?: InputMaybe<PrintIssueUpdateInput>;
  where: PrintIssueUniqueFilterInput;
};


export type MutationUpsertPrintIssueRailroadArgs = {
  create: PrintIssueRailroadCreationInput;
  update?: InputMaybe<PrintIssueRailroadUpdateInput>;
  where: PrintIssueRailroadUniqueFilterInput;
};


export type MutationUpsertPrintPublicationArgs = {
  create: PrintPublicationCreationInput;
  update?: InputMaybe<PrintPublicationUpdateInput>;
  where: PrintPublicationUniqueFilterInput;
};


export type MutationUpsertPrintTemplateArgs = {
  create: PrintTemplateCreationInput;
  update?: InputMaybe<PrintTemplateUpdateInput>;
  where: PrintTemplateUniqueFilterInput;
};


export type MutationUpsertRailroadPageArgs = {
  create: RailroadPageCreationInput;
  update?: InputMaybe<RailroadPageUpdateInput>;
  where: RailroadPageUniqueFilterInput;
};


export type MutationUpsertRawArticleArgs = {
  create: RawArticleCreationInput;
  update?: InputMaybe<RawArticleUpdateInput>;
  where: RawArticleUniqueFilterInput;
};


export type MutationUpsertRawArticleVersionArgs = {
  create: RawArticleVersionCreationInput;
  update?: InputMaybe<RawArticleVersionUpdateInput>;
  where: RawArticleVersionUniqueFilterInput;
};


export type MutationUpsertSubjectArgs = {
  create: SubjectCreationInput;
  update?: InputMaybe<SubjectUpdateInput>;
  where: SubjectUniqueFilterInput;
};


export type MutationUpsertSubjectCategoryArgs = {
  create: SubjectCategoryCreationInput;
  update?: InputMaybe<SubjectCategoryUpdateInput>;
  where: SubjectCategoryUniqueFilterInput;
};


export type MutationUpsertSubSubjectArgs = {
  create: SubSubjectCreationInput;
  update?: InputMaybe<SubSubjectUpdateInput>;
  where: SubSubjectUniqueFilterInput;
};


export type MutationUpsertTopicFotowebPigeArgs = {
  create: TopicFotowebPigeCreationInput;
  update?: InputMaybe<TopicFotowebPigeUpdateInput>;
  where: TopicFotowebPigeUniqueFilterInput;
};


export type MutationUpsertUnitArgs = {
  create: UnitCreationInput;
  update?: InputMaybe<UnitUpdateInput>;
  where: UnitUniqueFilterInput;
};


export type MutationUpsertWebsiteArgs = {
  create: WebsiteCreationInput;
  update?: InputMaybe<WebsiteUpdateInput>;
  where: WebsiteUniqueFilterInput;
};

export type PdfPreviewResultType = {
  __typename?: 'PdfPreviewResultType';
  link: Scalars['String']['output'];
};

/** A header of a particular Print Issue */
export type PrintHeading = {
  __typename?: 'PrintHeading';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** Number of "assignments" */
  assignmentCount: Scalars['UnsignedInt']['output'];
  assignments: Array<Assignment>;
  autoPrototype?: Maybe<Scalars['Boolean']['output']>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** Number of "flatAssignments" */
  flatAssignmentCount: Scalars['UnsignedInt']['output'];
  flatAssignments: Array<FlatAssignment>;
  hasAutomaticSubject: Scalars['Boolean']['output'];
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  order: Scalars['Int']['output'];
  printIssue: PrintIssue;
  /** Number of "printTemplates" */
  printTemplateCount: Scalars['UnsignedInt']['output'];
  printTemplates: Array<PrintHeadingPrintTemplate>;
  title: Scalars['String']['output'];
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};


/** A header of a particular Print Issue */
export type PrintHeadingAssignmentCountArgs = {
  where?: InputMaybe<AssignmentFilterInput>;
};


/** A header of a particular Print Issue */
export type PrintHeadingAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<AssignmentFilterInput>;
};


/** A header of a particular Print Issue */
export type PrintHeadingFlatAssignmentCountArgs = {
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** A header of a particular Print Issue */
export type PrintHeadingFlatAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<FlatAssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** A header of a particular Print Issue */
export type PrintHeadingPrintTemplateCountArgs = {
  where?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
};


/** A header of a particular Print Issue */
export type PrintHeadingPrintTemplatesArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintHeadingPrintTemplateOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
};

export type PrintHeadingCreationAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutPrintHeadingInput>>;
};

/** The "PrintHeading" node's creation */
export type PrintHeadingCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<PrintHeadingCreationAssignmentsInput>;
  autoPrototype?: InputMaybe<Scalars['Boolean']['input']>;
  hasAutomaticSubject?: Scalars['Boolean']['input'];
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  printIssue: PrintHeadingCreationPrintIssueInput;
  printTemplates?: InputMaybe<PrintHeadingCreationPrintTemplatesInput>;
  title: Scalars['String']['input'];
};

export type PrintHeadingCreationPrintIssueCreateIfNotExistsInput = {
  data: PrintIssueCreationInput;
  where: PrintIssueUniqueFilterInput;
};

export type PrintHeadingCreationPrintIssueInput = {
  /** Connect a "PrintIssue" to a new "PrintHeading" through the "PrintHeading.printIssue" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintIssueUniqueFilterInput>;
  /** Create a "PrintIssue" and connect it to a new "PrintHeading" through the "PrintHeading.printIssue" edge. */
  create?: InputMaybe<PrintIssueCreationInput>;
  /** Create a "PrintIssue" if it does not exist, and connect it to a new "PrintHeading" through the "PrintHeading.printIssue" edge. */
  createIfNotExists?: InputMaybe<PrintHeadingCreationPrintIssueCreateIfNotExistsInput>;
};

export type PrintHeadingCreationPrintTemplatesInput = {
  create?: InputMaybe<Array<PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput>>;
};

/** The "PrintHeading" node's creation */
export type PrintHeadingCreationWithoutPrintIssueInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<PrintHeadingCreationAssignmentsInput>;
  autoPrototype?: InputMaybe<Scalars['Boolean']['input']>;
  hasAutomaticSubject?: Scalars['Boolean']['input'];
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Int']['input'];
  printTemplates?: InputMaybe<PrintHeadingCreationPrintTemplatesInput>;
  title: Scalars['String']['input'];
};

/** The "PrintHeading" nodes' filter */
export type PrintHeadingFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<PrintHeadingFilterInput>>>;
  assignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments_every?: InputMaybe<AssignmentFilterInput>;
  assignments_none?: InputMaybe<AssignmentFilterInput>;
  assignments_some?: InputMaybe<AssignmentFilterInput>;
  autoPrototype?: InputMaybe<Scalars['Boolean']['input']>;
  autoPrototype_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  autoPrototype_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  autoPrototype_not?: InputMaybe<Scalars['Boolean']['input']>;
  autoPrototype_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  flatAssignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignments_every?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_none?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_some?: InputMaybe<FlatAssignmentFilterInput>;
  hasAutomaticSubject?: InputMaybe<Scalars['Boolean']['input']>;
  hasAutomaticSubject_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  legacyId_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId_lt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_lte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  NOT?: InputMaybe<PrintHeadingFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<PrintHeadingFilterInput>>>;
  order?: InputMaybe<Scalars['Int']['input']>;
  order_gt?: InputMaybe<Scalars['Int']['input']>;
  order_gte?: InputMaybe<Scalars['Int']['input']>;
  order_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  order_lt?: InputMaybe<Scalars['Int']['input']>;
  order_lte?: InputMaybe<Scalars['Int']['input']>;
  order_not?: InputMaybe<Scalars['Int']['input']>;
  order_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  printIssue?: InputMaybe<PrintIssueFilterInput>;
  printIssue_not?: InputMaybe<PrintIssueFilterInput>;
  printTemplateCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printTemplateCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printTemplateCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printTemplateCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printTemplateCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printTemplateCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printTemplates_every?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
  printTemplates_none?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
  printTemplates_some?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "PrintHeading" nodes */
export enum PrintHeadingOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest number of "assignments" to the highest */
  assignmentCount_ASC = 'assignmentCount_ASC',
  /** from the highest number of "assignments" to the lowest */
  assignmentCount_DESC = 'assignmentCount_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest number of "flatAssignments" to the highest */
  flatAssignmentCount_ASC = 'flatAssignmentCount_ASC',
  /** from the highest number of "flatAssignments" to the lowest */
  flatAssignmentCount_DESC = 'flatAssignmentCount_DESC',
  /** from the lowest "legacyId" to the highest */
  legacyId_ASC = 'legacyId_ASC',
  /** from the highest "legacyId" to the lowest */
  legacyId_DESC = 'legacyId_DESC',
  /** from the lowest "order" to the highest */
  order_ASC = 'order_ASC',
  /** from the highest "order" to the lowest */
  order_DESC = 'order_DESC',
  /** from the lowest number of "printTemplates" to the highest */
  printTemplateCount_ASC = 'printTemplateCount_ASC',
  /** from the highest number of "printTemplates" to the lowest */
  printTemplateCount_DESC = 'printTemplateCount_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

export type PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  active: Scalars['Boolean']['output'];
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  printHeading: PrintHeading;
  printTemplate: PrintTemplate;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};

/** The "PrintHeadingPrintTemplate" node's creation */
export type PrintHeadingPrintTemplateCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  active?: Scalars['Boolean']['input'];
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  printHeading: PrintHeadingPrintTemplateCreationPrintHeadingInput;
  printTemplate: PrintHeadingPrintTemplateCreationPrintTemplateInput;
};

export type PrintHeadingPrintTemplateCreationPrintHeadingCreateIfNotExistsInput = {
  data: PrintHeadingCreationInput;
  where: PrintHeadingUniqueFilterInput;
};

export type PrintHeadingPrintTemplateCreationPrintHeadingInput = {
  /** Connect a "PrintHeading" to a new "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printHeading" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintHeadingUniqueFilterInput>;
  /** Create a "PrintHeading" and connect it to a new "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printHeading" edge. */
  create?: InputMaybe<PrintHeadingCreationInput>;
  /** Create a "PrintHeading" if it does not exist, and connect it to a new "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printHeading" edge. */
  createIfNotExists?: InputMaybe<PrintHeadingPrintTemplateCreationPrintHeadingCreateIfNotExistsInput>;
};

export type PrintHeadingPrintTemplateCreationPrintTemplateCreateIfNotExistsInput = {
  data: PrintTemplateCreationInput;
  where: PrintTemplateUniqueFilterInput;
};

export type PrintHeadingPrintTemplateCreationPrintTemplateInput = {
  /** Connect a "PrintTemplate" to a new "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printTemplate" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintTemplateUniqueFilterInput>;
  /** Create a "PrintTemplate" and connect it to a new "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printTemplate" edge. */
  create?: InputMaybe<PrintTemplateCreationInput>;
  /** Create a "PrintTemplate" if it does not exist, and connect it to a new "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printTemplate" edge. */
  createIfNotExists?: InputMaybe<PrintHeadingPrintTemplateCreationPrintTemplateCreateIfNotExistsInput>;
};

/** The "PrintHeadingPrintTemplate" node's creation */
export type PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  active?: Scalars['Boolean']['input'];
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  printTemplate: PrintHeadingPrintTemplateCreationPrintTemplateInput;
};

/** The "PrintHeadingPrintTemplate" node's creation */
export type PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  active?: Scalars['Boolean']['input'];
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  printHeading: PrintHeadingPrintTemplateCreationPrintHeadingInput;
};

/** The "PrintHeadingPrintTemplate" nodes' filter */
export type PrintHeadingPrintTemplateFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  AND?: InputMaybe<Array<InputMaybe<PrintHeadingPrintTemplateFilterInput>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  NOT?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<PrintHeadingPrintTemplateFilterInput>>>;
  printHeading?: InputMaybe<PrintHeadingFilterInput>;
  printHeading_not?: InputMaybe<PrintHeadingFilterInput>;
  printTemplate?: InputMaybe<PrintTemplateFilterInput>;
  printTemplate_not?: InputMaybe<PrintTemplateFilterInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "PrintHeadingPrintTemplate" nodes */
export enum PrintHeadingPrintTemplateOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "PrintHeadingPrintTemplate" with one of the following combination of components' value:
 * - _id
 * - id
 * - printHeading / printTemplate
 */
export type PrintHeadingPrintTemplateUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  printHeading?: InputMaybe<PrintHeadingUniqueFilterInput>;
  printTemplate?: InputMaybe<PrintTemplateUniqueFilterInput>;
};

/**
 * Given a "printHeading", identifies exactly one "PrintHeadingPrintTemplate" with one of the following combination of components' value:
 * - (printHeading) / printTemplate
 */
export type PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput = {
  printTemplate: PrintTemplateUniqueFilterInput;
};

/**
 * Given a "printTemplate", identifies exactly one "PrintHeadingPrintTemplate" with one of the following combination of components' value:
 * - printHeading / (printTemplate)
 */
export type PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput = {
  printHeading: PrintHeadingUniqueFilterInput;
};

/** The "PrintHeadingPrintTemplate" node's update */
export type PrintHeadingPrintTemplateUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  printHeading?: InputMaybe<PrintHeadingPrintTemplateUpdatePrintHeadingInput>;
  printTemplate?: InputMaybe<PrintHeadingPrintTemplateUpdatePrintTemplateInput>;
};

export type PrintHeadingPrintTemplateUpdatePrintHeadingCreateIfNotExistsInput = {
  data: PrintHeadingCreationInput;
  where: PrintHeadingUniqueFilterInput;
};

export type PrintHeadingPrintTemplateUpdatePrintHeadingInput = {
  /** Connect a "PrintHeading" to an existing "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printHeading" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintHeadingUniqueFilterInput>;
  /** Create a "PrintHeading" and connect it to an existing "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printHeading" edge. */
  create?: InputMaybe<PrintHeadingCreationInput>;
  /** Create a "PrintHeading" if it does not exist, and connect it to an existing "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printHeading" edge. */
  createIfNotExists?: InputMaybe<PrintHeadingPrintTemplateUpdatePrintHeadingCreateIfNotExistsInput>;
  /** Update the connected "PrintHeading", throw an error if the "PrintHeadingPrintTemplate.printHeading" edge does not exist. */
  update?: InputMaybe<PrintHeadingUpdateInput>;
};

export type PrintHeadingPrintTemplateUpdatePrintTemplateCreateIfNotExistsInput = {
  data: PrintTemplateCreationInput;
  where: PrintTemplateUniqueFilterInput;
};

export type PrintHeadingPrintTemplateUpdatePrintTemplateInput = {
  /** Connect a "PrintTemplate" to an existing "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printTemplate" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintTemplateUniqueFilterInput>;
  /** Create a "PrintTemplate" and connect it to an existing "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printTemplate" edge. */
  create?: InputMaybe<PrintTemplateCreationInput>;
  /** Create a "PrintTemplate" if it does not exist, and connect it to an existing "PrintHeadingPrintTemplate" through the "PrintHeadingPrintTemplate.printTemplate" edge. */
  createIfNotExists?: InputMaybe<PrintHeadingPrintTemplateUpdatePrintTemplateCreateIfNotExistsInput>;
  /** Update the connected "PrintTemplate", throw an error if the "PrintHeadingPrintTemplate.printTemplate" edge does not exist. */
  update?: InputMaybe<PrintTemplateUpdateInput>;
};

/** The "PrintHeadingPrintTemplate" node's update */
export type PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  printTemplate?: InputMaybe<PrintHeadingPrintTemplateUpdatePrintTemplateInput>;
};

/** The "PrintHeadingPrintTemplate" node's update */
export type PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  printHeading?: InputMaybe<PrintHeadingPrintTemplateUpdatePrintHeadingInput>;
};

/**
 * Identifies exactly one "PrintHeading" with one of the following combination of components' value:
 * - _id
 * - id
 * - legacyId / printIssue
 * - title / printIssue
 */
export type PrintHeadingUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  printIssue?: InputMaybe<PrintIssueUniqueFilterInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Given a "printIssue", identifies exactly one "PrintHeading" with one of the following combination of components' value:
 * - legacyId / (printIssue)
 * - title / (printIssue)
 */
export type PrintHeadingUniqueFilterWithoutPrintIssueInput = {
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PrintHeadingUpdateAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutPrintHeadingInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<AssignmentFilterInput>;
  updateAll?: InputMaybe<AssignmentUpdateWithoutPrintHeadingInput>;
  updateMany?: InputMaybe<PrintHeadingUpdateAssignmentsUpdateManyInput>;
};

export type PrintHeadingUpdateAssignmentsUpdateManyInput = {
  data?: InputMaybe<AssignmentUpdateWithoutPrintHeadingInput>;
  where?: InputMaybe<AssignmentFilterInput>;
};

/** The "PrintHeading" node's update */
export type PrintHeadingUpdateInput = {
  assignments?: InputMaybe<PrintHeadingUpdateAssignmentsInput>;
  autoPrototype?: InputMaybe<Scalars['Boolean']['input']>;
  hasAutomaticSubject?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  printIssue?: InputMaybe<PrintHeadingUpdatePrintIssueInput>;
  printTemplates?: InputMaybe<PrintHeadingUpdatePrintTemplatesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PrintHeadingUpdatePrintIssueCreateIfNotExistsInput = {
  data: PrintIssueCreationInput;
  where: PrintIssueUniqueFilterInput;
};

export type PrintHeadingUpdatePrintIssueInput = {
  /** Connect a "PrintIssue" to an existing "PrintHeading" through the "PrintHeading.printIssue" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintIssueUniqueFilterInput>;
  /** Create a "PrintIssue" and connect it to an existing "PrintHeading" through the "PrintHeading.printIssue" edge. */
  create?: InputMaybe<PrintIssueCreationInput>;
  /** Create a "PrintIssue" if it does not exist, and connect it to an existing "PrintHeading" through the "PrintHeading.printIssue" edge. */
  createIfNotExists?: InputMaybe<PrintHeadingUpdatePrintIssueCreateIfNotExistsInput>;
  /** Update the connected "PrintIssue", throw an error if the "PrintHeading.printIssue" edge does not exist. */
  update?: InputMaybe<PrintIssueUpdateInput>;
};

export type PrintHeadingUpdatePrintTemplatesCreateIfNotExistsInput = {
  data: PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput;
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
};

export type PrintHeadingUpdatePrintTemplatesInput = {
  create?: InputMaybe<Array<PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput>>;
  createIfNotExists?: InputMaybe<Array<PrintHeadingUpdatePrintTemplatesCreateIfNotExistsInput>>;
  delete?: InputMaybe<Array<PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Array<PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput>>;
  deleteMany?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
  update?: InputMaybe<Array<PrintHeadingUpdatePrintTemplatesUpdateInput>>;
  updateAll?: InputMaybe<PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput>;
  updateIfExists?: InputMaybe<Array<PrintHeadingUpdatePrintTemplatesUpdateIfExistsInput>>;
  updateMany?: InputMaybe<PrintHeadingUpdatePrintTemplatesUpdateManyInput>;
  upsert?: InputMaybe<Array<PrintHeadingUpdatePrintTemplatesUpsertInput>>;
};

export type PrintHeadingUpdatePrintTemplatesUpdateIfExistsInput = {
  data?: InputMaybe<PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput>;
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
};

export type PrintHeadingUpdatePrintTemplatesUpdateInput = {
  data?: InputMaybe<PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput>;
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
};

export type PrintHeadingUpdatePrintTemplatesUpdateManyInput = {
  data?: InputMaybe<PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput>;
  where?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
};

export type PrintHeadingUpdatePrintTemplatesUpsertInput = {
  create: PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput;
  update?: InputMaybe<PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput>;
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
};

/** The "PrintHeading" node's update */
export type PrintHeadingUpdateWithoutPrintIssueInput = {
  assignments?: InputMaybe<PrintHeadingUpdateAssignmentsInput>;
  autoPrototype?: InputMaybe<Scalars['Boolean']['input']>;
  hasAutomaticSubject?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  printTemplates?: InputMaybe<PrintHeadingUpdatePrintTemplatesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** An issue of a Print Publication (number 101, special summer,...) */
export type PrintIssue = {
  __typename?: 'PrintIssue';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** Number of "assignments" */
  assignmentCount: Scalars['UnsignedInt']['output'];
  assignments: Array<Assignment>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** Number of "flatAssignments" */
  flatAssignmentCount: Scalars['UnsignedInt']['output'];
  flatAssignments: Array<FlatAssignment>;
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  /** Number of "printHeadings" */
  printHeadingCount: Scalars['UnsignedInt']['output'];
  printHeadings: Array<PrintHeading>;
  printIssueRailroad?: Maybe<PrintIssueRailroad>;
  printPublication: PrintPublication;
  title: Scalars['String']['output'];
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};


/** An issue of a Print Publication (number 101, special summer,...) */
export type PrintIssueAssignmentCountArgs = {
  where?: InputMaybe<AssignmentFilterInput>;
};


/** An issue of a Print Publication (number 101, special summer,...) */
export type PrintIssueAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<AssignmentFilterInput>;
};


/** An issue of a Print Publication (number 101, special summer,...) */
export type PrintIssueFlatAssignmentCountArgs = {
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** An issue of a Print Publication (number 101, special summer,...) */
export type PrintIssueFlatAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<FlatAssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** An issue of a Print Publication (number 101, special summer,...) */
export type PrintIssuePrintHeadingCountArgs = {
  where?: InputMaybe<PrintHeadingFilterInput>;
};


/** An issue of a Print Publication (number 101, special summer,...) */
export type PrintIssuePrintHeadingsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintHeadingOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<PrintHeadingFilterInput>;
};

export type PrintIssueCreationAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutPrintIssueInput>>;
};

/** The "PrintIssue" node's creation */
export type PrintIssueCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<PrintIssueCreationAssignmentsInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  printHeadings?: InputMaybe<PrintIssueCreationPrintHeadingsInput>;
  printIssueRailroad?: InputMaybe<PrintIssueCreationPrintIssueRailroadInput>;
  printPublication: PrintIssueCreationPrintPublicationInput;
  title: Scalars['String']['input'];
};

export type PrintIssueCreationPrintHeadingsInput = {
  create?: InputMaybe<Array<PrintHeadingCreationWithoutPrintIssueInput>>;
};

export type PrintIssueCreationPrintIssueRailroadInput = {
  create?: InputMaybe<PrintIssueRailroadCreationWithoutPrintIssueInput>;
};

export type PrintIssueCreationPrintPublicationCreateIfNotExistsInput = {
  data: PrintPublicationCreationInput;
  where: PrintPublicationUniqueFilterInput;
};

export type PrintIssueCreationPrintPublicationInput = {
  /** Connect a "PrintPublication" to a new "PrintIssue" through the "PrintIssue.printPublication" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintPublicationUniqueFilterInput>;
  /** Create a "PrintPublication" and connect it to a new "PrintIssue" through the "PrintIssue.printPublication" edge. */
  create?: InputMaybe<PrintPublicationCreationInput>;
  /** Create a "PrintPublication" if it does not exist, and connect it to a new "PrintIssue" through the "PrintIssue.printPublication" edge. */
  createIfNotExists?: InputMaybe<PrintIssueCreationPrintPublicationCreateIfNotExistsInput>;
};

/** The "PrintIssue" node's creation */
export type PrintIssueCreationWithoutPrintPublicationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<PrintIssueCreationAssignmentsInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  printHeadings?: InputMaybe<PrintIssueCreationPrintHeadingsInput>;
  printIssueRailroad?: InputMaybe<PrintIssueCreationPrintIssueRailroadInput>;
  title: Scalars['String']['input'];
};

/** The "PrintIssue" nodes' filter */
export type PrintIssueFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<PrintIssueFilterInput>>>;
  assignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments_every?: InputMaybe<AssignmentFilterInput>;
  assignments_none?: InputMaybe<AssignmentFilterInput>;
  assignments_some?: InputMaybe<AssignmentFilterInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  flatAssignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignments_every?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_none?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_some?: InputMaybe<FlatAssignmentFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  legacyId_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId_lt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_lte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  NOT?: InputMaybe<PrintIssueFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<PrintIssueFilterInput>>>;
  printHeadingCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadingCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadingCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadingCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadingCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadingCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadings_every?: InputMaybe<PrintHeadingFilterInput>;
  printHeadings_none?: InputMaybe<PrintHeadingFilterInput>;
  printHeadings_some?: InputMaybe<PrintHeadingFilterInput>;
  printIssueRailroad?: InputMaybe<PrintIssueRailroadFilterInput>;
  printIssueRailroad_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  printIssueRailroad_not?: InputMaybe<PrintIssueRailroadFilterInput>;
  printPublication?: InputMaybe<PrintPublicationFilterInput>;
  printPublication_not?: InputMaybe<PrintPublicationFilterInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "PrintIssue" nodes */
export enum PrintIssueOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest number of "assignments" to the highest */
  assignmentCount_ASC = 'assignmentCount_ASC',
  /** from the highest number of "assignments" to the lowest */
  assignmentCount_DESC = 'assignmentCount_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest number of "flatAssignments" to the highest */
  flatAssignmentCount_ASC = 'flatAssignmentCount_ASC',
  /** from the highest number of "flatAssignments" to the lowest */
  flatAssignmentCount_DESC = 'flatAssignmentCount_DESC',
  /** from the lowest "legacyId" to the highest */
  legacyId_ASC = 'legacyId_ASC',
  /** from the highest "legacyId" to the lowest */
  legacyId_DESC = 'legacyId_DESC',
  /** from the lowest number of "printHeadings" to the highest */
  printHeadingCount_ASC = 'printHeadingCount_ASC',
  /** from the highest number of "printHeadings" to the lowest */
  printHeadingCount_DESC = 'printHeadingCount_DESC',
  /** from the lowest "title" to the highest */
  title_ASC = 'title_ASC',
  /** from the highest "title" to the lowest */
  title_DESC = 'title_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/** The railroad from Censhare */
export type PrintIssueRailroad = {
  __typename?: 'PrintIssueRailroad';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  censhareCreationDate?: Maybe<Scalars['DateTime']['output']>;
  censhareUpdateDate?: Maybe<Scalars['DateTime']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The public ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pageCount?: Maybe<Scalars['Int']['output']>;
  printIssue: PrintIssue;
  publicationDate: Scalars['DateTime']['output'];
  /** Number of "RailroadPages" */
  RailroadPageCount: Scalars['UnsignedInt']['output'];
  RailroadPages: Array<RailroadPage>;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};


/** The railroad from Censhare */
export type PrintIssueRailroadRailroadPageCountArgs = {
  where?: InputMaybe<RailroadPageFilterInput>;
};


/** The railroad from Censhare */
export type PrintIssueRailroadRailroadPagesArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RailroadPageOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<RailroadPageFilterInput>;
};

/** The "PrintIssueRailroad" node's creation */
export type PrintIssueRailroadCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  censhareCreationDate?: InputMaybe<Scalars['DateTime']['input']>;
  censhareUpdateDate?: InputMaybe<Scalars['DateTime']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  pageCount?: InputMaybe<Scalars['Int']['input']>;
  printIssue: PrintIssueRailroadCreationPrintIssueInput;
  publicationDate: Scalars['DateTime']['input'];
  RailroadPages?: InputMaybe<PrintIssueRailroadCreationRailroadPagesInput>;
};

export type PrintIssueRailroadCreationPrintIssueCreateIfNotExistsInput = {
  data: PrintIssueCreationInput;
  where: PrintIssueUniqueFilterInput;
};

export type PrintIssueRailroadCreationPrintIssueInput = {
  /** Connect a "PrintIssue" to a new "PrintIssueRailroad" through the "PrintIssueRailroad.printIssue" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintIssueUniqueFilterInput>;
  /** Create a "PrintIssue" and connect it to a new "PrintIssueRailroad" through the "PrintIssueRailroad.printIssue" edge. */
  create?: InputMaybe<PrintIssueCreationInput>;
  /** Create a "PrintIssue" if it does not exist, and connect it to a new "PrintIssueRailroad" through the "PrintIssueRailroad.printIssue" edge. */
  createIfNotExists?: InputMaybe<PrintIssueRailroadCreationPrintIssueCreateIfNotExistsInput>;
};

export type PrintIssueRailroadCreationRailroadPagesInput = {
  create?: InputMaybe<Array<RailroadPageCreationWithoutPrintIssueRailroadInput>>;
};

/** The "PrintIssueRailroad" node's creation */
export type PrintIssueRailroadCreationWithoutPrintIssueInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  censhareCreationDate?: InputMaybe<Scalars['DateTime']['input']>;
  censhareUpdateDate?: InputMaybe<Scalars['DateTime']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  pageCount?: InputMaybe<Scalars['Int']['input']>;
  publicationDate: Scalars['DateTime']['input'];
  RailroadPages?: InputMaybe<PrintIssueRailroadCreationRailroadPagesInput>;
};

/** The "PrintIssueRailroad" nodes' filter */
export type PrintIssueRailroadFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<PrintIssueRailroadFilterInput>>>;
  censhareCreationDate?: InputMaybe<Scalars['DateTime']['input']>;
  censhareCreationDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  censhareCreationDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  censhareCreationDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  censhareCreationDate_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  censhareCreationDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  censhareCreationDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  censhareCreationDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  censhareCreationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  censhareUpdateDate?: InputMaybe<Scalars['DateTime']['input']>;
  censhareUpdateDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  censhareUpdateDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  censhareUpdateDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  censhareUpdateDate_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  censhareUpdateDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  censhareUpdateDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  censhareUpdateDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  censhareUpdateDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  comment_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  comment_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  comment_not?: InputMaybe<Scalars['String']['input']>;
  comment_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  comment_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  name_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  name_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  NOT?: InputMaybe<PrintIssueRailroadFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<PrintIssueRailroadFilterInput>>>;
  pageCount?: InputMaybe<Scalars['Int']['input']>;
  pageCount_gt?: InputMaybe<Scalars['Int']['input']>;
  pageCount_gte?: InputMaybe<Scalars['Int']['input']>;
  pageCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pageCount_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  pageCount_lt?: InputMaybe<Scalars['Int']['input']>;
  pageCount_lte?: InputMaybe<Scalars['Int']['input']>;
  pageCount_not?: InputMaybe<Scalars['Int']['input']>;
  pageCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  printIssue?: InputMaybe<PrintIssueFilterInput>;
  printIssue_not?: InputMaybe<PrintIssueFilterInput>;
  publicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  publicationDate_gt?: InputMaybe<Scalars['DateTime']['input']>;
  publicationDate_gte?: InputMaybe<Scalars['DateTime']['input']>;
  publicationDate_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  publicationDate_lt?: InputMaybe<Scalars['DateTime']['input']>;
  publicationDate_lte?: InputMaybe<Scalars['DateTime']['input']>;
  publicationDate_not?: InputMaybe<Scalars['DateTime']['input']>;
  publicationDate_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  RailroadPageCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  RailroadPageCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  RailroadPageCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  RailroadPageCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  RailroadPageCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  RailroadPageCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  RailroadPages_every?: InputMaybe<RailroadPageFilterInput>;
  RailroadPages_none?: InputMaybe<RailroadPageFilterInput>;
  RailroadPages_some?: InputMaybe<RailroadPageFilterInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "PrintIssueRailroad" nodes */
export enum PrintIssueRailroadOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest "censhareCreationDate" to the highest */
  censhareCreationDate_ASC = 'censhareCreationDate_ASC',
  /** from the highest "censhareCreationDate" to the lowest */
  censhareCreationDate_DESC = 'censhareCreationDate_DESC',
  /** from the lowest "censhareUpdateDate" to the highest */
  censhareUpdateDate_ASC = 'censhareUpdateDate_ASC',
  /** from the highest "censhareUpdateDate" to the lowest */
  censhareUpdateDate_DESC = 'censhareUpdateDate_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "name" to the highest */
  name_ASC = 'name_ASC',
  /** from the highest "name" to the lowest */
  name_DESC = 'name_DESC',
  /** from the lowest "pageCount" to the highest */
  pageCount_ASC = 'pageCount_ASC',
  /** from the highest "pageCount" to the lowest */
  pageCount_DESC = 'pageCount_DESC',
  /** from the lowest "publicationDate" to the highest */
  publicationDate_ASC = 'publicationDate_ASC',
  /** from the highest "publicationDate" to the lowest */
  publicationDate_DESC = 'publicationDate_DESC',
  /** from the lowest number of "RailroadPages" to the highest */
  RailroadPageCount_ASC = 'RailroadPageCount_ASC',
  /** from the highest number of "RailroadPages" to the lowest */
  RailroadPageCount_DESC = 'RailroadPageCount_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "PrintIssueRailroad" with one of the following combination of components' value:
 * - _id
 * - id
 * - printIssue
 */
export type PrintIssueRailroadUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  printIssue?: InputMaybe<PrintIssueUniqueFilterInput>;
};

/** The "PrintIssueRailroad" node's update */
export type PrintIssueRailroadUpdateInput = {
  censhareCreationDate?: InputMaybe<Scalars['DateTime']['input']>;
  censhareUpdateDate?: InputMaybe<Scalars['DateTime']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageCount?: InputMaybe<Scalars['Int']['input']>;
  printIssue?: InputMaybe<PrintIssueRailroadUpdatePrintIssueInput>;
  publicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  RailroadPages?: InputMaybe<PrintIssueRailroadUpdateRailroadPagesInput>;
};

export type PrintIssueRailroadUpdatePrintIssueCreateIfNotExistsInput = {
  data: PrintIssueCreationInput;
  where: PrintIssueUniqueFilterInput;
};

export type PrintIssueRailroadUpdatePrintIssueInput = {
  /** Connect a "PrintIssue" to an existing "PrintIssueRailroad" through the "PrintIssueRailroad.printIssue" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintIssueUniqueFilterInput>;
  /** Create a "PrintIssue" and connect it to an existing "PrintIssueRailroad" through the "PrintIssueRailroad.printIssue" edge. */
  create?: InputMaybe<PrintIssueCreationInput>;
  /** Create a "PrintIssue" if it does not exist, and connect it to an existing "PrintIssueRailroad" through the "PrintIssueRailroad.printIssue" edge. */
  createIfNotExists?: InputMaybe<PrintIssueRailroadUpdatePrintIssueCreateIfNotExistsInput>;
  /** Update the connected "PrintIssue", throw an error if the "PrintIssueRailroad.printIssue" edge does not exist. */
  update?: InputMaybe<PrintIssueUpdateInput>;
};

export type PrintIssueRailroadUpdateRailroadPagesInput = {
  create?: InputMaybe<Array<RailroadPageCreationWithoutPrintIssueRailroadInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<RailroadPageFilterInput>;
  updateAll?: InputMaybe<RailroadPageUpdateWithoutPrintIssueRailroadInput>;
  updateMany?: InputMaybe<PrintIssueRailroadUpdateRailroadPagesUpdateManyInput>;
};

export type PrintIssueRailroadUpdateRailroadPagesUpdateManyInput = {
  data?: InputMaybe<RailroadPageUpdateWithoutPrintIssueRailroadInput>;
  where?: InputMaybe<RailroadPageFilterInput>;
};

/** The "PrintIssueRailroad" node's update */
export type PrintIssueRailroadUpdateWithoutPrintIssueInput = {
  censhareCreationDate?: InputMaybe<Scalars['DateTime']['input']>;
  censhareUpdateDate?: InputMaybe<Scalars['DateTime']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageCount?: InputMaybe<Scalars['Int']['input']>;
  publicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  RailroadPages?: InputMaybe<PrintIssueRailroadUpdateRailroadPagesInput>;
};

/**
 * Identifies exactly one "PrintIssue" with one of the following combination of components' value:
 * - _id
 * - id
 * - legacyId / printPublication
 * - title / printPublication
 */
export type PrintIssueUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  printPublication?: InputMaybe<PrintPublicationUniqueFilterInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Given a "printPublication", identifies exactly one "PrintIssue" with one of the following combination of components' value:
 * - legacyId / (printPublication)
 * - title / (printPublication)
 */
export type PrintIssueUniqueFilterWithoutPrintPublicationInput = {
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PrintIssueUpdateAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutPrintIssueInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<AssignmentFilterInput>;
  updateAll?: InputMaybe<AssignmentUpdateWithoutPrintIssueInput>;
  updateMany?: InputMaybe<PrintIssueUpdateAssignmentsUpdateManyInput>;
};

export type PrintIssueUpdateAssignmentsUpdateManyInput = {
  data?: InputMaybe<AssignmentUpdateWithoutPrintIssueInput>;
  where?: InputMaybe<AssignmentFilterInput>;
};

/** The "PrintIssue" node's update */
export type PrintIssueUpdateInput = {
  assignments?: InputMaybe<PrintIssueUpdateAssignmentsInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  printHeadings?: InputMaybe<PrintIssueUpdatePrintHeadingsInput>;
  printIssueRailroad?: InputMaybe<PrintIssueUpdatePrintIssueRailroadInput>;
  printPublication?: InputMaybe<PrintIssueUpdatePrintPublicationInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PrintIssueUpdatePrintHeadingsCreateIfNotExistsInput = {
  data: PrintHeadingCreationWithoutPrintIssueInput;
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
};

export type PrintIssueUpdatePrintHeadingsInput = {
  create?: InputMaybe<Array<PrintHeadingCreationWithoutPrintIssueInput>>;
  createIfNotExists?: InputMaybe<Array<PrintIssueUpdatePrintHeadingsCreateIfNotExistsInput>>;
  delete?: InputMaybe<Array<PrintHeadingUniqueFilterWithoutPrintIssueInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Array<PrintHeadingUniqueFilterWithoutPrintIssueInput>>;
  deleteMany?: InputMaybe<PrintHeadingFilterInput>;
  update?: InputMaybe<Array<PrintIssueUpdatePrintHeadingsUpdateInput>>;
  updateAll?: InputMaybe<PrintHeadingUpdateWithoutPrintIssueInput>;
  updateIfExists?: InputMaybe<Array<PrintIssueUpdatePrintHeadingsUpdateIfExistsInput>>;
  updateMany?: InputMaybe<PrintIssueUpdatePrintHeadingsUpdateManyInput>;
  upsert?: InputMaybe<Array<PrintIssueUpdatePrintHeadingsUpsertInput>>;
};

export type PrintIssueUpdatePrintHeadingsUpdateIfExistsInput = {
  data?: InputMaybe<PrintHeadingUpdateWithoutPrintIssueInput>;
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
};

export type PrintIssueUpdatePrintHeadingsUpdateInput = {
  data?: InputMaybe<PrintHeadingUpdateWithoutPrintIssueInput>;
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
};

export type PrintIssueUpdatePrintHeadingsUpdateManyInput = {
  data?: InputMaybe<PrintHeadingUpdateWithoutPrintIssueInput>;
  where?: InputMaybe<PrintHeadingFilterInput>;
};

export type PrintIssueUpdatePrintHeadingsUpsertInput = {
  create: PrintHeadingCreationWithoutPrintIssueInput;
  update?: InputMaybe<PrintHeadingUpdateWithoutPrintIssueInput>;
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
};

export type PrintIssueUpdatePrintIssueRailroadInput = {
  create?: InputMaybe<PrintIssueRailroadCreationWithoutPrintIssueInput>;
  createIfNotExists?: InputMaybe<PrintIssueRailroadCreationWithoutPrintIssueInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<PrintIssueRailroadUpdateWithoutPrintIssueInput>;
  updateIfExists?: InputMaybe<PrintIssueRailroadUpdateWithoutPrintIssueInput>;
  upsert?: InputMaybe<PrintIssueUpdatePrintIssueRailroadUpsertInput>;
};

export type PrintIssueUpdatePrintIssueRailroadUpsertInput = {
  create: PrintIssueRailroadCreationWithoutPrintIssueInput;
  update?: InputMaybe<PrintIssueRailroadUpdateWithoutPrintIssueInput>;
};

export type PrintIssueUpdatePrintPublicationCreateIfNotExistsInput = {
  data: PrintPublicationCreationInput;
  where: PrintPublicationUniqueFilterInput;
};

export type PrintIssueUpdatePrintPublicationInput = {
  /** Connect a "PrintPublication" to an existing "PrintIssue" through the "PrintIssue.printPublication" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintPublicationUniqueFilterInput>;
  /** Create a "PrintPublication" and connect it to an existing "PrintIssue" through the "PrintIssue.printPublication" edge. */
  create?: InputMaybe<PrintPublicationCreationInput>;
  /** Create a "PrintPublication" if it does not exist, and connect it to an existing "PrintIssue" through the "PrintIssue.printPublication" edge. */
  createIfNotExists?: InputMaybe<PrintIssueUpdatePrintPublicationCreateIfNotExistsInput>;
  /** Update the connected "PrintPublication", throw an error if the "PrintIssue.printPublication" edge does not exist. */
  update?: InputMaybe<PrintPublicationUpdateInput>;
};

/** The "PrintIssue" node's update */
export type PrintIssueUpdateWithoutPrintPublicationInput = {
  assignments?: InputMaybe<PrintIssueUpdateAssignmentsInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  printHeadings?: InputMaybe<PrintIssueUpdatePrintHeadingsInput>;
  printIssueRailroad?: InputMaybe<PrintIssueUpdatePrintIssueRailroadInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** A Print Publication: Femme Actuelle, Télé-loisir, Capital */
export type PrintPublication = {
  __typename?: 'PrintPublication';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** Number of "assignments" */
  assignmentCount: Scalars['UnsignedInt']['output'];
  assignments: Array<Assignment>;
  brand: Brand;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** Number of "flatAssignments" */
  flatAssignmentCount: Scalars['UnsignedInt']['output'];
  flatAssignments: Array<FlatAssignment>;
  /** The public ID */
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  /** Number of "printIssues" */
  printIssueCount: Scalars['UnsignedInt']['output'];
  printIssues: Array<PrintIssue>;
  title: Scalars['String']['output'];
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};


/** A Print Publication: Femme Actuelle, Télé-loisir, Capital */
export type PrintPublicationAssignmentCountArgs = {
  where?: InputMaybe<AssignmentFilterInput>;
};


/** A Print Publication: Femme Actuelle, Télé-loisir, Capital */
export type PrintPublicationAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<AssignmentFilterInput>;
};


/** A Print Publication: Femme Actuelle, Télé-loisir, Capital */
export type PrintPublicationFlatAssignmentCountArgs = {
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** A Print Publication: Femme Actuelle, Télé-loisir, Capital */
export type PrintPublicationFlatAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<FlatAssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** A Print Publication: Femme Actuelle, Télé-loisir, Capital */
export type PrintPublicationPrintIssueCountArgs = {
  where?: InputMaybe<PrintIssueFilterInput>;
};


/** A Print Publication: Femme Actuelle, Télé-loisir, Capital */
export type PrintPublicationPrintIssuesArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintIssueOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<PrintIssueFilterInput>;
};

export type PrintPublicationCreationAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutPrintPublicationInput>>;
};

export type PrintPublicationCreationBrandCreateIfNotExistsInput = {
  data: BrandCreationInput;
  where: BrandUniqueFilterInput;
};

export type PrintPublicationCreationBrandInput = {
  /** Connect a "Brand" to a new "PrintPublication" through the "PrintPublication.brand" edge, throw an error if it does not exist. */
  connect?: InputMaybe<BrandUniqueFilterInput>;
  /** Create a "Brand" and connect it to a new "PrintPublication" through the "PrintPublication.brand" edge. */
  create?: InputMaybe<BrandCreationInput>;
  /** Create a "Brand" if it does not exist, and connect it to a new "PrintPublication" through the "PrintPublication.brand" edge. */
  createIfNotExists?: InputMaybe<PrintPublicationCreationBrandCreateIfNotExistsInput>;
};

/** The "PrintPublication" node's creation */
export type PrintPublicationCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<PrintPublicationCreationAssignmentsInput>;
  brand: PrintPublicationCreationBrandInput;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  key: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  printIssues?: InputMaybe<PrintPublicationCreationPrintIssuesInput>;
  title: Scalars['String']['input'];
};

export type PrintPublicationCreationPrintIssuesInput = {
  create?: InputMaybe<Array<PrintIssueCreationWithoutPrintPublicationInput>>;
};

/** The "PrintPublication" node's creation */
export type PrintPublicationCreationWithoutBrandInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<PrintPublicationCreationAssignmentsInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  key: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  printIssues?: InputMaybe<PrintPublicationCreationPrintIssuesInput>;
  title: Scalars['String']['input'];
};

/** The "PrintPublication" nodes' filter */
export type PrintPublicationFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<PrintPublicationFilterInput>>>;
  assignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments_every?: InputMaybe<AssignmentFilterInput>;
  assignments_none?: InputMaybe<AssignmentFilterInput>;
  assignments_some?: InputMaybe<AssignmentFilterInput>;
  brand?: InputMaybe<BrandFilterInput>;
  brand_not?: InputMaybe<BrandFilterInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  flatAssignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignments_every?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_none?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_some?: InputMaybe<FlatAssignmentFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  key_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  key_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  legacyId_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId_lt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_lte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  NOT?: InputMaybe<PrintPublicationFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<PrintPublicationFilterInput>>>;
  printIssueCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printIssueCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printIssueCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printIssueCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printIssueCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printIssueCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printIssues_every?: InputMaybe<PrintIssueFilterInput>;
  printIssues_none?: InputMaybe<PrintIssueFilterInput>;
  printIssues_some?: InputMaybe<PrintIssueFilterInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "PrintPublication" nodes */
export enum PrintPublicationOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest number of "assignments" to the highest */
  assignmentCount_ASC = 'assignmentCount_ASC',
  /** from the highest number of "assignments" to the lowest */
  assignmentCount_DESC = 'assignmentCount_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest number of "flatAssignments" to the highest */
  flatAssignmentCount_ASC = 'flatAssignmentCount_ASC',
  /** from the highest number of "flatAssignments" to the lowest */
  flatAssignmentCount_DESC = 'flatAssignmentCount_DESC',
  /** from the lowest "legacyId" to the highest */
  legacyId_ASC = 'legacyId_ASC',
  /** from the highest "legacyId" to the lowest */
  legacyId_DESC = 'legacyId_DESC',
  /** from the lowest number of "printIssues" to the highest */
  printIssueCount_ASC = 'printIssueCount_ASC',
  /** from the highest number of "printIssues" to the lowest */
  printIssueCount_DESC = 'printIssueCount_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "PrintPublication" with one of the following combination of components' value:
 * - _id
 * - id
 * - key
 * - legacyId / brand
 * - title / brand
 */
export type PrintPublicationUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  brand?: InputMaybe<BrandUniqueFilterInput>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Given a "brand", identifies exactly one "PrintPublication" with one of the following combination of components' value:
 * - legacyId / (brand)
 * - title / (brand)
 */
export type PrintPublicationUniqueFilterWithoutBrandInput = {
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PrintPublicationUpdateAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutPrintPublicationInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<AssignmentFilterInput>;
  updateAll?: InputMaybe<AssignmentUpdateWithoutPrintPublicationInput>;
  updateMany?: InputMaybe<PrintPublicationUpdateAssignmentsUpdateManyInput>;
};

export type PrintPublicationUpdateAssignmentsUpdateManyInput = {
  data?: InputMaybe<AssignmentUpdateWithoutPrintPublicationInput>;
  where?: InputMaybe<AssignmentFilterInput>;
};

export type PrintPublicationUpdateBrandCreateIfNotExistsInput = {
  data: BrandCreationInput;
  where: BrandUniqueFilterInput;
};

export type PrintPublicationUpdateBrandInput = {
  /** Connect a "Brand" to an existing "PrintPublication" through the "PrintPublication.brand" edge, throw an error if it does not exist. */
  connect?: InputMaybe<BrandUniqueFilterInput>;
  /** Create a "Brand" and connect it to an existing "PrintPublication" through the "PrintPublication.brand" edge. */
  create?: InputMaybe<BrandCreationInput>;
  /** Create a "Brand" if it does not exist, and connect it to an existing "PrintPublication" through the "PrintPublication.brand" edge. */
  createIfNotExists?: InputMaybe<PrintPublicationUpdateBrandCreateIfNotExistsInput>;
  /** Update the connected "Brand", throw an error if the "PrintPublication.brand" edge does not exist. */
  update?: InputMaybe<BrandUpdateInput>;
};

/** The "PrintPublication" node's update */
export type PrintPublicationUpdateInput = {
  assignments?: InputMaybe<PrintPublicationUpdateAssignmentsInput>;
  brand?: InputMaybe<PrintPublicationUpdateBrandInput>;
  key?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  printIssues?: InputMaybe<PrintPublicationUpdatePrintIssuesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PrintPublicationUpdatePrintIssuesCreateIfNotExistsInput = {
  data: PrintIssueCreationWithoutPrintPublicationInput;
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
};

export type PrintPublicationUpdatePrintIssuesInput = {
  create?: InputMaybe<Array<PrintIssueCreationWithoutPrintPublicationInput>>;
  createIfNotExists?: InputMaybe<Array<PrintPublicationUpdatePrintIssuesCreateIfNotExistsInput>>;
  delete?: InputMaybe<Array<PrintIssueUniqueFilterWithoutPrintPublicationInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Array<PrintIssueUniqueFilterWithoutPrintPublicationInput>>;
  deleteMany?: InputMaybe<PrintIssueFilterInput>;
  update?: InputMaybe<Array<PrintPublicationUpdatePrintIssuesUpdateInput>>;
  updateAll?: InputMaybe<PrintIssueUpdateWithoutPrintPublicationInput>;
  updateIfExists?: InputMaybe<Array<PrintPublicationUpdatePrintIssuesUpdateIfExistsInput>>;
  updateMany?: InputMaybe<PrintPublicationUpdatePrintIssuesUpdateManyInput>;
  upsert?: InputMaybe<Array<PrintPublicationUpdatePrintIssuesUpsertInput>>;
};

export type PrintPublicationUpdatePrintIssuesUpdateIfExistsInput = {
  data?: InputMaybe<PrintIssueUpdateWithoutPrintPublicationInput>;
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
};

export type PrintPublicationUpdatePrintIssuesUpdateInput = {
  data?: InputMaybe<PrintIssueUpdateWithoutPrintPublicationInput>;
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
};

export type PrintPublicationUpdatePrintIssuesUpdateManyInput = {
  data?: InputMaybe<PrintIssueUpdateWithoutPrintPublicationInput>;
  where?: InputMaybe<PrintIssueFilterInput>;
};

export type PrintPublicationUpdatePrintIssuesUpsertInput = {
  create: PrintIssueCreationWithoutPrintPublicationInput;
  update?: InputMaybe<PrintIssueUpdateWithoutPrintPublicationInput>;
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
};

/** The "PrintPublication" node's update */
export type PrintPublicationUpdateWithoutBrandInput = {
  assignments?: InputMaybe<PrintPublicationUpdateAssignmentsInput>;
  key?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  printIssues?: InputMaybe<PrintPublicationUpdatePrintIssuesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** print templates definition */
export type PrintTemplate = {
  __typename?: 'PrintTemplate';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** Number of "articleTemplates" */
  articleTemplateCount: Scalars['UnsignedInt']['output'];
  articleTemplates: Array<ArticleTemplate>;
  /** Number of "assignments" */
  assignmentCount: Scalars['UnsignedInt']['output'];
  assignments: Array<Assignment>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** KAMI extension data */
  data: Scalars['String']['output'];
  /** KAMI extension version */
  extVersion?: Maybe<Scalars['String']['output']>;
  fields: Array<Maybe<PrintTemplateField>>;
  /** InDesign file name */
  filename: Scalars['String']['output'];
  /** InDesign file size */
  filesize?: Maybe<Scalars['Int']['output']>;
  /** Number of "flatAssignments" */
  flatAssignmentCount: Scalars['UnsignedInt']['output'];
  flatAssignments: Array<FlatAssignment>;
  /** The public ID */
  id: Scalars['ID']['output'];
  /** template label */
  label: Scalars['String']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  /** Template page count */
  pageCount?: Maybe<Scalars['Int']['output']>;
  /** Number of "printHeadings" */
  printHeadingCount: Scalars['UnsignedInt']['output'];
  printHeadings: Array<PrintHeadingPrintTemplate>;
  /** associated S3 object id */
  S3ObjectId: Scalars['String']['output'];
  /** template type */
  type?: Maybe<TemplateType>;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<User>;
};


/** print templates definition */
export type PrintTemplateArticleTemplateCountArgs = {
  where?: InputMaybe<ArticleTemplateFilterInput>;
};


/** print templates definition */
export type PrintTemplateArticleTemplatesArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<ArticleTemplateOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<ArticleTemplateFilterInput>;
};


/** print templates definition */
export type PrintTemplateAssignmentCountArgs = {
  where?: InputMaybe<AssignmentFilterInput>;
};


/** print templates definition */
export type PrintTemplateAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<AssignmentFilterInput>;
};


/** print templates definition */
export type PrintTemplateFlatAssignmentCountArgs = {
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** print templates definition */
export type PrintTemplateFlatAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<FlatAssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** print templates definition */
export type PrintTemplatePrintHeadingCountArgs = {
  where?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
};


/** print templates definition */
export type PrintTemplatePrintHeadingsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintHeadingPrintTemplateOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
};

export type PrintTemplateCreationArticleTemplatesInput = {
  create?: InputMaybe<Array<ArticleTemplateCreationWithoutPrintTemplateInput>>;
};

export type PrintTemplateCreationAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutPrintTemplateInput>>;
};

/** The "PrintTemplate" node's creation */
export type PrintTemplateCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplates?: InputMaybe<PrintTemplateCreationArticleTemplatesInput>;
  assignments?: InputMaybe<PrintTemplateCreationAssignmentsInput>;
  /** KAMI extension data */
  data?: InputMaybe<Scalars['String']['input']>;
  /** KAMI extension version */
  extVersion?: InputMaybe<Scalars['String']['input']>;
  /** InDesign file name */
  filename: Scalars['String']['input'];
  /** InDesign file size */
  filesize?: InputMaybe<Scalars['Int']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** template label */
  label: Scalars['String']['input'];
  /** Template page count */
  pageCount?: InputMaybe<Scalars['Int']['input']>;
  printHeadings?: InputMaybe<PrintTemplateCreationPrintHeadingsInput>;
  /** associated S3 object id */
  S3ObjectId: Scalars['String']['input'];
  /** template type */
  type?: InputMaybe<TemplateType>;
};

export type PrintTemplateCreationPrintHeadingsInput = {
  create?: InputMaybe<Array<PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput>>;
};

export type PrintTemplateDownloadResultType = {
  __typename?: 'PrintTemplateDownloadResultType';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PrintTemplateDraftArtifactType = {
  __typename?: 'PrintTemplateDraftArtifactType';
  page: Scalars['Int']['output'];
  S3ObjectId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PrintTemplateDraftFieldsDataType = {
  id: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export enum PrintTemplateDraftFormatType {
  INDD = 'INDD',
  PDF = 'PDF',
  PNG = 'PNG'
}

export type PrintTemplateDraftsResultType = {
  __typename?: 'PrintTemplateDraftsResultType';
  artifacts: Array<PrintTemplateDraftArtifactType>;
  draftId: Scalars['String']['output'];
};

export type PrintTemplateField = {
  __typename?: 'PrintTemplateField';
  characterStyles: Array<PrintTemplateFieldStyle>;
  coordinates: PrintTemplateObjectCoords;
  data: Scalars['String']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  locked?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  page: PrintTemplatePage;
  paragraphStyles: Array<PrintTemplateFieldStyle>;
  type: PrintTemplateFieldType;
  typeVariation: Scalars['String']['output'];
};

export type PrintTemplateFieldStyle = {
  __typename?: 'PrintTemplateFieldStyle';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum PrintTemplateFieldType {
  GRAPHIC_TYPE = 'GRAPHIC_TYPE',
  TEXT_TYPE = 'TEXT_TYPE',
  UNASSIGNED = 'UNASSIGNED'
}

/** The "PrintTemplate" nodes' filter */
export type PrintTemplateFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<PrintTemplateFilterInput>>>;
  articleTemplateCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplateCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplateCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplateCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplateCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplateCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  articleTemplates_every?: InputMaybe<ArticleTemplateFilterInput>;
  articleTemplates_none?: InputMaybe<ArticleTemplateFilterInput>;
  articleTemplates_some?: InputMaybe<ArticleTemplateFilterInput>;
  assignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments_every?: InputMaybe<AssignmentFilterInput>;
  assignments_none?: InputMaybe<AssignmentFilterInput>;
  assignments_some?: InputMaybe<AssignmentFilterInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  data?: InputMaybe<Scalars['String']['input']>;
  data_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  data_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  data_in?: InputMaybe<Array<Scalars['String']['input']>>;
  data_not?: InputMaybe<Scalars['String']['input']>;
  data_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  data_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  data_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  data_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  data_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  extVersion?: InputMaybe<Scalars['String']['input']>;
  extVersion_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  extVersion_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  extVersion_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  extVersion_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  extVersion_not?: InputMaybe<Scalars['String']['input']>;
  extVersion_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  extVersion_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  extVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  extVersion_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  extVersion_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filename_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename_in?: InputMaybe<Array<Scalars['String']['input']>>;
  filename_not?: InputMaybe<Scalars['String']['input']>;
  filename_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  filename_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filename_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  filesize_gt?: InputMaybe<Scalars['Int']['input']>;
  filesize_gte?: InputMaybe<Scalars['Int']['input']>;
  filesize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  filesize_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  filesize_lt?: InputMaybe<Scalars['Int']['input']>;
  filesize_lte?: InputMaybe<Scalars['Int']['input']>;
  filesize_not?: InputMaybe<Scalars['Int']['input']>;
  filesize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  flatAssignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignments_every?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_none?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_some?: InputMaybe<FlatAssignmentFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  label_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  label_in?: InputMaybe<Array<Scalars['String']['input']>>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  label_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  label_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  label_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  label_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  NOT?: InputMaybe<PrintTemplateFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<PrintTemplateFilterInput>>>;
  pageCount?: InputMaybe<Scalars['Int']['input']>;
  pageCount_gt?: InputMaybe<Scalars['Int']['input']>;
  pageCount_gte?: InputMaybe<Scalars['Int']['input']>;
  pageCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pageCount_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  pageCount_lt?: InputMaybe<Scalars['Int']['input']>;
  pageCount_lte?: InputMaybe<Scalars['Int']['input']>;
  pageCount_not?: InputMaybe<Scalars['Int']['input']>;
  pageCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  printHeadingCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadingCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadingCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadingCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadingCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadingCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  printHeadings_every?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
  printHeadings_none?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
  printHeadings_some?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
  S3ObjectId?: InputMaybe<Scalars['String']['input']>;
  S3ObjectId_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  S3ObjectId_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  S3ObjectId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  S3ObjectId_not?: InputMaybe<Scalars['String']['input']>;
  S3ObjectId_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  S3ObjectId_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  S3ObjectId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  S3ObjectId_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  S3ObjectId_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  type?: InputMaybe<TemplateType>;
  type_in?: InputMaybe<Array<InputMaybe<TemplateType>>>;
  type_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  type_not?: InputMaybe<TemplateType>;
  type_not_in?: InputMaybe<Array<InputMaybe<TemplateType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type PrintTemplateObjectCoords = {
  __typename?: 'PrintTemplateObjectCoords';
  height?: Maybe<Scalars['Float']['output']>;
  rotation?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

/** Order the "PrintTemplate" nodes */
export enum PrintTemplateOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest number of "articleTemplates" to the highest */
  articleTemplateCount_ASC = 'articleTemplateCount_ASC',
  /** from the highest number of "articleTemplates" to the lowest */
  articleTemplateCount_DESC = 'articleTemplateCount_DESC',
  /** from the lowest number of "assignments" to the highest */
  assignmentCount_ASC = 'assignmentCount_ASC',
  /** from the highest number of "assignments" to the lowest */
  assignmentCount_DESC = 'assignmentCount_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "filesize" to the highest */
  filesize_ASC = 'filesize_ASC',
  /** from the highest "filesize" to the lowest */
  filesize_DESC = 'filesize_DESC',
  /** from the lowest number of "flatAssignments" to the highest */
  flatAssignmentCount_ASC = 'flatAssignmentCount_ASC',
  /** from the highest number of "flatAssignments" to the lowest */
  flatAssignmentCount_DESC = 'flatAssignmentCount_DESC',
  /** from the lowest "pageCount" to the highest */
  pageCount_ASC = 'pageCount_ASC',
  /** from the highest "pageCount" to the lowest */
  pageCount_DESC = 'pageCount_DESC',
  /** from the lowest number of "printHeadings" to the highest */
  printHeadingCount_ASC = 'printHeadingCount_ASC',
  /** from the highest number of "printHeadings" to the lowest */
  printHeadingCount_DESC = 'printHeadingCount_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

export type PrintTemplatePage = {
  __typename?: 'PrintTemplatePage';
  coordinates: PrintTemplateObjectCoords;
  number: Scalars['String']['output'];
  offset: Scalars['Int']['output'];
};

/**
 * Identifies exactly one "PrintTemplate" with one of the following combination of components' value:
 * - _id
 * - id
 * - S3ObjectId
 */
export type PrintTemplateUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** associated S3 object id */
  S3ObjectId?: InputMaybe<Scalars['String']['input']>;
};

export type PrintTemplateUpdateArticleTemplatesInput = {
  create?: InputMaybe<Array<ArticleTemplateCreationWithoutPrintTemplateInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<ArticleTemplateFilterInput>;
  updateAll?: InputMaybe<ArticleTemplateUpdateWithoutPrintTemplateInput>;
  updateMany?: InputMaybe<PrintTemplateUpdateArticleTemplatesUpdateManyInput>;
};

export type PrintTemplateUpdateArticleTemplatesUpdateManyInput = {
  data?: InputMaybe<ArticleTemplateUpdateWithoutPrintTemplateInput>;
  where?: InputMaybe<ArticleTemplateFilterInput>;
};

export type PrintTemplateUpdateAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutPrintTemplateInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<AssignmentFilterInput>;
  updateAll?: InputMaybe<AssignmentUpdateWithoutPrintTemplateInput>;
  updateMany?: InputMaybe<PrintTemplateUpdateAssignmentsUpdateManyInput>;
};

export type PrintTemplateUpdateAssignmentsUpdateManyInput = {
  data?: InputMaybe<AssignmentUpdateWithoutPrintTemplateInput>;
  where?: InputMaybe<AssignmentFilterInput>;
};

/** The "PrintTemplate" node's update */
export type PrintTemplateUpdateInput = {
  articleTemplates?: InputMaybe<PrintTemplateUpdateArticleTemplatesInput>;
  assignments?: InputMaybe<PrintTemplateUpdateAssignmentsInput>;
  /** InDesign file name */
  filename?: InputMaybe<Scalars['String']['input']>;
  /** InDesign file size */
  filesize?: InputMaybe<Scalars['Int']['input']>;
  /** template label */
  label?: InputMaybe<Scalars['String']['input']>;
  printHeadings?: InputMaybe<PrintTemplateUpdatePrintHeadingsInput>;
  /** associated S3 object id */
  S3ObjectId?: InputMaybe<Scalars['String']['input']>;
  /** template type */
  type?: InputMaybe<TemplateType>;
};

export type PrintTemplateUpdatePrintHeadingsCreateIfNotExistsInput = {
  data: PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput;
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
};

export type PrintTemplateUpdatePrintHeadingsInput = {
  create?: InputMaybe<Array<PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput>>;
  createIfNotExists?: InputMaybe<Array<PrintTemplateUpdatePrintHeadingsCreateIfNotExistsInput>>;
  delete?: InputMaybe<Array<PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Array<PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput>>;
  deleteMany?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
  update?: InputMaybe<Array<PrintTemplateUpdatePrintHeadingsUpdateInput>>;
  updateAll?: InputMaybe<PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput>;
  updateIfExists?: InputMaybe<Array<PrintTemplateUpdatePrintHeadingsUpdateIfExistsInput>>;
  updateMany?: InputMaybe<PrintTemplateUpdatePrintHeadingsUpdateManyInput>;
  upsert?: InputMaybe<Array<PrintTemplateUpdatePrintHeadingsUpsertInput>>;
};

export type PrintTemplateUpdatePrintHeadingsUpdateIfExistsInput = {
  data?: InputMaybe<PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput>;
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
};

export type PrintTemplateUpdatePrintHeadingsUpdateInput = {
  data?: InputMaybe<PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput>;
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
};

export type PrintTemplateUpdatePrintHeadingsUpdateManyInput = {
  data?: InputMaybe<PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput>;
  where?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
};

export type PrintTemplateUpdatePrintHeadingsUpsertInput = {
  create: PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput;
  update?: InputMaybe<PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput>;
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
};

export type PrintTemplateUploadResultType = {
  __typename?: 'PrintTemplateUploadResultType';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'query';
  /** Retrieves one "ArticleTemplate", throws an error if it does not exist */
  articleTemplate: ArticleTemplate;
  /** Gets the number of "ArticleTemplates" */
  articleTemplateCount: Scalars['UnsignedInt']['output'];
  /** Either the "ArticleTemplate" exists or not? */
  articleTemplateExists: Scalars['Boolean']['output'];
  /** Retrieves one "ArticleTemplate" if it exists, returns null otherwise */
  articleTemplateIfExists?: Maybe<ArticleTemplate>;
  /** Retrieves a list of "ArticleTemplates" */
  articleTemplates: Array<ArticleTemplate>;
  /** Given a list of unique-filter's value, retrieves the corresponding "ArticleTemplates" in the same order, throws an error if one does not exist */
  articleTemplatesInOrder: Array<ArticleTemplate>;
  /** Given a list of unique-filter's value, retrieves the corresponding "ArticleTemplates", or null, in the same order */
  articleTemplatesInOrderIfExists: Array<Maybe<ArticleTemplate>>;
  /** Retrieves one "Assignment", throws an error if it does not exist */
  assignment: Assignment;
  /** Gets the number of "Assignments" */
  assignmentCount: Scalars['UnsignedInt']['output'];
  /** Either the "Assignment" exists or not? */
  assignmentExists: Scalars['Boolean']['output'];
  /** Retrieves one "Assignment" if it exists, returns null otherwise */
  assignmentIfExists?: Maybe<Assignment>;
  /** Retrieves a list of "Assignments" */
  assignments: Array<Assignment>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Assignments" in the same order, throws an error if one does not exist */
  assignmentsInOrder: Array<Assignment>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Assignments", or null, in the same order */
  assignmentsInOrderIfExists: Array<Maybe<Assignment>>;
  /** Retrieves one "Attachment", throws an error if it does not exist */
  attachment: Attachment;
  /** Gets the number of "Attachments" */
  attachmentCount: Scalars['UnsignedInt']['output'];
  /** Either the "Attachment" exists or not? */
  attachmentExists: Scalars['Boolean']['output'];
  /** Retrieves one "Attachment" if it exists, returns null otherwise */
  attachmentIfExists?: Maybe<Attachment>;
  /** Retrieves a list of "Attachments" */
  attachments: Array<Attachment>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Attachments" in the same order, throws an error if one does not exist */
  attachmentsInOrder: Array<Attachment>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Attachments", or null, in the same order */
  attachmentsInOrderIfExists: Array<Maybe<Attachment>>;
  /** Retrieves one "Brand", throws an error if it does not exist */
  brand: Brand;
  /** Gets the number of "Brands" */
  brandCount: Scalars['UnsignedInt']['output'];
  /** Either the "Brand" exists or not? */
  brandExists: Scalars['Boolean']['output'];
  /** Retrieves one "Brand" if it exists, returns null otherwise */
  brandIfExists?: Maybe<Brand>;
  /** Retrieves a list of "Brands" */
  brands: Array<Brand>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Brands" in the same order, throws an error if one does not exist */
  brandsInOrder: Array<Brand>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Brands", or null, in the same order */
  brandsInOrderIfExists: Array<Maybe<Brand>>;
  /** Retrieves one "CenshareTrace", throws an error if it does not exist */
  censhareTrace: CenshareTrace;
  /** Gets the number of "CenshareTraces" */
  censhareTraceCount: Scalars['UnsignedInt']['output'];
  /** Either the "CenshareTrace" exists or not? */
  censhareTraceExists: Scalars['Boolean']['output'];
  /** Retrieves one "CenshareTrace" if it exists, returns null otherwise */
  censhareTraceIfExists?: Maybe<CenshareTrace>;
  /** Retrieves a list of "CenshareTraces" */
  censhareTraces: Array<CenshareTrace>;
  /** Given a list of unique-filter's value, retrieves the corresponding "CenshareTraces" in the same order, throws an error if one does not exist */
  censhareTracesInOrder: Array<CenshareTrace>;
  /** Given a list of unique-filter's value, retrieves the corresponding "CenshareTraces", or null, in the same order */
  censhareTracesInOrderIfExists: Array<Maybe<CenshareTrace>>;
  /** Retrieves one "FlatAssignment", throws an error if it does not exist */
  flatAssignment: FlatAssignment;
  /** Gets the number of "FlatAssignments" */
  flatAssignmentCount: Scalars['UnsignedInt']['output'];
  /** Either the "FlatAssignment" exists or not? */
  flatAssignmentExists: Scalars['Boolean']['output'];
  /** Retrieves one "FlatAssignment" if it exists, returns null otherwise */
  flatAssignmentIfExists?: Maybe<FlatAssignment>;
  /** Retrieves a list of "FlatAssignments" */
  flatAssignments: Array<FlatAssignment>;
  /** Given a list of unique-filter's value, retrieves the corresponding "FlatAssignments" in the same order, throws an error if one does not exist */
  flatAssignmentsInOrder: Array<FlatAssignment>;
  /** Given a list of unique-filter's value, retrieves the corresponding "FlatAssignments", or null, in the same order */
  flatAssignmentsInOrderIfExists: Array<Maybe<FlatAssignment>>;
  /** get Censhare railroad content */
  getCenshareRailroad: CenshareRailroadResultType;
  /** get Censhare railroad content */
  getCenshareRailroadByName?: Maybe<CenshareRailroadByNameResultType>;
  /** generate print Template drafts */
  getElisaPdf: PdfPreviewResultType;
  /** Retrieves one "PrintHeading", throws an error if it does not exist */
  printHeading: PrintHeading;
  /** Gets the number of "PrintHeadings" */
  printHeadingCount: Scalars['UnsignedInt']['output'];
  /** Either the "PrintHeading" exists or not? */
  printHeadingExists: Scalars['Boolean']['output'];
  /** Retrieves one "PrintHeading" if it exists, returns null otherwise */
  printHeadingIfExists?: Maybe<PrintHeading>;
  /** Retrieves one "PrintHeadingPrintTemplate", throws an error if it does not exist */
  printHeadingPrintTemplate: PrintHeadingPrintTemplate;
  /** Gets the number of "PrintHeadingPrintTemplates" */
  printHeadingPrintTemplateCount: Scalars['UnsignedInt']['output'];
  /** Either the "PrintHeadingPrintTemplate" exists or not? */
  printHeadingPrintTemplateExists: Scalars['Boolean']['output'];
  /** Retrieves one "PrintHeadingPrintTemplate" if it exists, returns null otherwise */
  printHeadingPrintTemplateIfExists?: Maybe<PrintHeadingPrintTemplate>;
  /** Retrieves a list of "PrintHeadingPrintTemplates" */
  printHeadingPrintTemplates: Array<PrintHeadingPrintTemplate>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintHeadingPrintTemplates" in the same order, throws an error if one does not exist */
  printHeadingPrintTemplatesInOrder: Array<PrintHeadingPrintTemplate>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintHeadingPrintTemplates", or null, in the same order */
  printHeadingPrintTemplatesInOrderIfExists: Array<Maybe<PrintHeadingPrintTemplate>>;
  /** Retrieves a list of "PrintHeadings" */
  printHeadings: Array<PrintHeading>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintHeadings" in the same order, throws an error if one does not exist */
  printHeadingsInOrder: Array<PrintHeading>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintHeadings", or null, in the same order */
  printHeadingsInOrderIfExists: Array<Maybe<PrintHeading>>;
  /** Retrieves one "PrintIssue", throws an error if it does not exist */
  printIssue: PrintIssue;
  /** Gets the number of "PrintIssues" */
  printIssueCount: Scalars['UnsignedInt']['output'];
  /** Either the "PrintIssue" exists or not? */
  printIssueExists: Scalars['Boolean']['output'];
  /** Retrieves one "PrintIssue" if it exists, returns null otherwise */
  printIssueIfExists?: Maybe<PrintIssue>;
  /** Retrieves one "PrintIssueRailroad", throws an error if it does not exist */
  printIssueRailroad: PrintIssueRailroad;
  /** Gets the number of "PrintIssueRailroads" */
  printIssueRailroadCount: Scalars['UnsignedInt']['output'];
  /** Either the "PrintIssueRailroad" exists or not? */
  printIssueRailroadExists: Scalars['Boolean']['output'];
  /** Retrieves one "PrintIssueRailroad" if it exists, returns null otherwise */
  printIssueRailroadIfExists?: Maybe<PrintIssueRailroad>;
  /** Retrieves a list of "PrintIssueRailroads" */
  printIssueRailroads: Array<PrintIssueRailroad>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintIssueRailroads" in the same order, throws an error if one does not exist */
  printIssueRailroadsInOrder: Array<PrintIssueRailroad>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintIssueRailroads", or null, in the same order */
  printIssueRailroadsInOrderIfExists: Array<Maybe<PrintIssueRailroad>>;
  /** Retrieves a list of "PrintIssues" */
  printIssues: Array<PrintIssue>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintIssues" in the same order, throws an error if one does not exist */
  printIssuesInOrder: Array<PrintIssue>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintIssues", or null, in the same order */
  printIssuesInOrderIfExists: Array<Maybe<PrintIssue>>;
  /** Retrieves one "PrintPublication", throws an error if it does not exist */
  printPublication: PrintPublication;
  /** Gets the number of "PrintPublications" */
  printPublicationCount: Scalars['UnsignedInt']['output'];
  /** Either the "PrintPublication" exists or not? */
  printPublicationExists: Scalars['Boolean']['output'];
  /** Retrieves one "PrintPublication" if it exists, returns null otherwise */
  printPublicationIfExists?: Maybe<PrintPublication>;
  /** Retrieves a list of "PrintPublications" */
  printPublications: Array<PrintPublication>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintPublications" in the same order, throws an error if one does not exist */
  printPublicationsInOrder: Array<PrintPublication>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintPublications", or null, in the same order */
  printPublicationsInOrderIfExists: Array<Maybe<PrintPublication>>;
  /** Retrieves one "PrintTemplate", throws an error if it does not exist */
  printTemplate: PrintTemplate;
  /** Gets the number of "PrintTemplates" */
  printTemplateCount: Scalars['UnsignedInt']['output'];
  /** print Template download signed url */
  printTemplateDownload: PrintTemplateDownloadResultType;
  /** generate print Template drafts */
  printTemplateDrafts: PrintTemplateDraftsResultType;
  /** Either the "PrintTemplate" exists or not? */
  printTemplateExists: Scalars['Boolean']['output'];
  /** Retrieves one "PrintTemplate" if it exists, returns null otherwise */
  printTemplateIfExists?: Maybe<PrintTemplate>;
  /** print Template preview signed urls */
  printTemplatePreviewUrls: Array<Scalars['String']['output']>;
  /** Retrieves a list of "PrintTemplates" */
  printTemplates: Array<PrintTemplate>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintTemplates" in the same order, throws an error if one does not exist */
  printTemplatesInOrder: Array<PrintTemplate>;
  /** Given a list of unique-filter's value, retrieves the corresponding "PrintTemplates", or null, in the same order */
  printTemplatesInOrderIfExists: Array<Maybe<PrintTemplate>>;
  /** print Template upload signed url */
  printTemplateUpload: PrintTemplateUploadResultType;
  /** Retrieves one "RailroadPage", throws an error if it does not exist */
  railroadPage: RailroadPage;
  /** Gets the number of "RailroadPages" */
  railroadPageCount: Scalars['UnsignedInt']['output'];
  /** Either the "RailroadPage" exists or not? */
  railroadPageExists: Scalars['Boolean']['output'];
  /** Retrieves one "RailroadPage" if it exists, returns null otherwise */
  railroadPageIfExists?: Maybe<RailroadPage>;
  /** Retrieves a list of "RailroadPages" */
  railroadPages: Array<RailroadPage>;
  /** Given a list of unique-filter's value, retrieves the corresponding "RailroadPages" in the same order, throws an error if one does not exist */
  railroadPagesInOrder: Array<RailroadPage>;
  /** Given a list of unique-filter's value, retrieves the corresponding "RailroadPages", or null, in the same order */
  railroadPagesInOrderIfExists: Array<Maybe<RailroadPage>>;
  /** Retrieves one "RawArticle", throws an error if it does not exist */
  rawArticle: RawArticle;
  /** Gets the number of "RawArticles" */
  rawArticleCount: Scalars['UnsignedInt']['output'];
  /** Either the "RawArticle" exists or not? */
  rawArticleExists: Scalars['Boolean']['output'];
  /** Retrieves one "RawArticle" if it exists, returns null otherwise */
  rawArticleIfExists?: Maybe<RawArticle>;
  /** Retrieves a list of "RawArticles" */
  rawArticles: Array<RawArticle>;
  /** Given a list of unique-filter's value, retrieves the corresponding "RawArticles" in the same order, throws an error if one does not exist */
  rawArticlesInOrder: Array<RawArticle>;
  /** Given a list of unique-filter's value, retrieves the corresponding "RawArticles", or null, in the same order */
  rawArticlesInOrderIfExists: Array<Maybe<RawArticle>>;
  /** Retrieves one "RawArticleVersion", throws an error if it does not exist */
  rawArticleVersion: RawArticleVersion;
  /** Gets the number of "RawArticleVersions" */
  rawArticleVersionCount: Scalars['UnsignedInt']['output'];
  /** Either the "RawArticleVersion" exists or not? */
  rawArticleVersionExists: Scalars['Boolean']['output'];
  /** Retrieves one "RawArticleVersion" if it exists, returns null otherwise */
  rawArticleVersionIfExists?: Maybe<RawArticleVersion>;
  /** Retrieves a list of "RawArticleVersions" */
  rawArticleVersions: Array<RawArticleVersion>;
  /** Given a list of unique-filter's value, retrieves the corresponding "RawArticleVersions" in the same order, throws an error if one does not exist */
  rawArticleVersionsInOrder: Array<RawArticleVersion>;
  /** Given a list of unique-filter's value, retrieves the corresponding "RawArticleVersions", or null, in the same order */
  rawArticleVersionsInOrderIfExists: Array<Maybe<RawArticleVersion>>;
  /** Retrieves one "Subject", throws an error if it does not exist */
  subject: Subject;
  /** Retrieves a list of "SubjectCategories" */
  subjectCategories: Array<SubjectCategory>;
  /** Given a list of unique-filter's value, retrieves the corresponding "SubjectCategories" in the same order, throws an error if one does not exist */
  subjectCategoriesInOrder: Array<SubjectCategory>;
  /** Given a list of unique-filter's value, retrieves the corresponding "SubjectCategories", or null, in the same order */
  subjectCategoriesInOrderIfExists: Array<Maybe<SubjectCategory>>;
  /** Retrieves one "SubjectCategory", throws an error if it does not exist */
  subjectCategory: SubjectCategory;
  /** Gets the number of "SubjectCategories" */
  subjectCategoryCount: Scalars['UnsignedInt']['output'];
  /** Either the "SubjectCategory" exists or not? */
  subjectCategoryExists: Scalars['Boolean']['output'];
  /** Retrieves one "SubjectCategory" if it exists, returns null otherwise */
  subjectCategoryIfExists?: Maybe<SubjectCategory>;
  /** Gets the number of "Subjects" */
  subjectCount: Scalars['UnsignedInt']['output'];
  /** Either the "Subject" exists or not? */
  subjectExists: Scalars['Boolean']['output'];
  /** Retrieves one "Subject" if it exists, returns null otherwise */
  subjectIfExists?: Maybe<Subject>;
  /** Retrieves a list of "Subjects" */
  subjects: Array<Subject>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Subjects" in the same order, throws an error if one does not exist */
  subjectsInOrder: Array<Subject>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Subjects", or null, in the same order */
  subjectsInOrderIfExists: Array<Maybe<Subject>>;
  /** Retrieves one "SubjectUser", throws an error if it does not exist */
  subjectUser: SubjectUser;
  /** Gets the number of "SubjectUsers" */
  subjectUserCount: Scalars['UnsignedInt']['output'];
  /** Either the "SubjectUser" exists or not? */
  subjectUserExists: Scalars['Boolean']['output'];
  /** Retrieves one "SubjectUser" if it exists, returns null otherwise */
  subjectUserIfExists?: Maybe<SubjectUser>;
  /** Retrieves a list of "SubjectUsers" */
  subjectUsers: Array<SubjectUser>;
  /** Given a list of unique-filter's value, retrieves the corresponding "SubjectUsers" in the same order, throws an error if one does not exist */
  subjectUsersInOrder: Array<SubjectUser>;
  /** Given a list of unique-filter's value, retrieves the corresponding "SubjectUsers", or null, in the same order */
  subjectUsersInOrderIfExists: Array<Maybe<SubjectUser>>;
  /** Retrieves one "SubSubject", throws an error if it does not exist */
  subSubject: SubSubject;
  /** Gets the number of "SubSubjects" */
  subSubjectCount: Scalars['UnsignedInt']['output'];
  /** Either the "SubSubject" exists or not? */
  subSubjectExists: Scalars['Boolean']['output'];
  /** Retrieves one "SubSubject" if it exists, returns null otherwise */
  subSubjectIfExists?: Maybe<SubSubject>;
  /** Retrieves a list of "SubSubjects" */
  subSubjects: Array<SubSubject>;
  /** Given a list of unique-filter's value, retrieves the corresponding "SubSubjects" in the same order, throws an error if one does not exist */
  subSubjectsInOrder: Array<SubSubject>;
  /** Given a list of unique-filter's value, retrieves the corresponding "SubSubjects", or null, in the same order */
  subSubjectsInOrderIfExists: Array<Maybe<SubSubject>>;
  /** Retrieves one "TopicFotowebPige", throws an error if it does not exist */
  topicFotowebPige: TopicFotowebPige;
  /** Gets the number of "TopicFotowebPiges" */
  topicFotowebPigeCount: Scalars['UnsignedInt']['output'];
  /** Either the "TopicFotowebPige" exists or not? */
  topicFotowebPigeExists: Scalars['Boolean']['output'];
  /** Retrieves one "TopicFotowebPige" if it exists, returns null otherwise */
  topicFotowebPigeIfExists?: Maybe<TopicFotowebPige>;
  /** Retrieves a list of "TopicFotowebPiges" */
  topicFotowebPiges: Array<TopicFotowebPige>;
  /** Given a list of unique-filter's value, retrieves the corresponding "TopicFotowebPiges" in the same order, throws an error if one does not exist */
  topicFotowebPigesInOrder: Array<TopicFotowebPige>;
  /** Given a list of unique-filter's value, retrieves the corresponding "TopicFotowebPiges", or null, in the same order */
  topicFotowebPigesInOrderIfExists: Array<Maybe<TopicFotowebPige>>;
  /** Retrieves one "Unit", throws an error if it does not exist */
  unit: Unit;
  /** Gets the number of "Units" */
  unitCount: Scalars['UnsignedInt']['output'];
  /** Either the "Unit" exists or not? */
  unitExists: Scalars['Boolean']['output'];
  /** Retrieves one "Unit" if it exists, returns null otherwise */
  unitIfExists?: Maybe<Unit>;
  /** Retrieves a list of "Units" */
  units: Array<Unit>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Units" in the same order, throws an error if one does not exist */
  unitsInOrder: Array<Unit>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Units", or null, in the same order */
  unitsInOrderIfExists: Array<Maybe<Unit>>;
  /** Retrieves one "Website", throws an error if it does not exist */
  website: Website;
  /** Gets the number of "Websites" */
  websiteCount: Scalars['UnsignedInt']['output'];
  /** Either the "Website" exists or not? */
  websiteExists: Scalars['Boolean']['output'];
  /** Retrieves one "Website" if it exists, returns null otherwise */
  websiteIfExists?: Maybe<Website>;
  /** Retrieves a list of "Websites" */
  websites: Array<Website>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Websites" in the same order, throws an error if one does not exist */
  websitesInOrder: Array<Website>;
  /** Given a list of unique-filter's value, retrieves the corresponding "Websites", or null, in the same order */
  websitesInOrderIfExists: Array<Maybe<Website>>;
};


export type QueryArticleTemplateArgs = {
  where: ArticleTemplateUniqueFilterInput;
};


export type QueryArticleTemplateCountArgs = {
  where?: InputMaybe<ArticleTemplateFilterInput>;
};


export type QueryArticleTemplateExistsArgs = {
  where: ArticleTemplateUniqueFilterInput;
};


export type QueryArticleTemplateIfExistsArgs = {
  where: ArticleTemplateUniqueFilterInput;
};


export type QueryArticleTemplatesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<ArticleTemplateOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<ArticleTemplateFilterInput>;
};


export type QueryArticleTemplatesInOrderArgs = {
  subset?: InputMaybe<ArticleTemplateFilterInput>;
  where: Array<ArticleTemplateUniqueFilterInput>;
};


export type QueryArticleTemplatesInOrderIfExistsArgs = {
  subset?: InputMaybe<ArticleTemplateFilterInput>;
  where: Array<ArticleTemplateUniqueFilterInput>;
};


export type QueryAssignmentArgs = {
  where: AssignmentUniqueFilterInput;
};


export type QueryAssignmentCountArgs = {
  where?: InputMaybe<AssignmentFilterInput>;
};


export type QueryAssignmentExistsArgs = {
  where: AssignmentUniqueFilterInput;
};


export type QueryAssignmentIfExistsArgs = {
  where: AssignmentUniqueFilterInput;
};


export type QueryAssignmentsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<AssignmentFilterInput>;
};


export type QueryAssignmentsInOrderArgs = {
  subset?: InputMaybe<AssignmentFilterInput>;
  where: Array<AssignmentUniqueFilterInput>;
};


export type QueryAssignmentsInOrderIfExistsArgs = {
  subset?: InputMaybe<AssignmentFilterInput>;
  where: Array<AssignmentUniqueFilterInput>;
};


export type QueryAttachmentArgs = {
  where: AttachmentUniqueFilterInput;
};


export type QueryAttachmentCountArgs = {
  where?: InputMaybe<AttachmentFilterInput>;
};


export type QueryAttachmentExistsArgs = {
  where: AttachmentUniqueFilterInput;
};


export type QueryAttachmentIfExistsArgs = {
  where: AttachmentUniqueFilterInput;
};


export type QueryAttachmentsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AttachmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<AttachmentFilterInput>;
};


export type QueryAttachmentsInOrderArgs = {
  subset?: InputMaybe<AttachmentFilterInput>;
  where: Array<AttachmentUniqueFilterInput>;
};


export type QueryAttachmentsInOrderIfExistsArgs = {
  subset?: InputMaybe<AttachmentFilterInput>;
  where: Array<AttachmentUniqueFilterInput>;
};


export type QueryBrandArgs = {
  where: BrandUniqueFilterInput;
};


export type QueryBrandCountArgs = {
  where?: InputMaybe<BrandFilterInput>;
};


export type QueryBrandExistsArgs = {
  where: BrandUniqueFilterInput;
};


export type QueryBrandIfExistsArgs = {
  where: BrandUniqueFilterInput;
};


export type QueryBrandsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<BrandOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<BrandFilterInput>;
};


export type QueryBrandsInOrderArgs = {
  subset?: InputMaybe<BrandFilterInput>;
  where: Array<BrandUniqueFilterInput>;
};


export type QueryBrandsInOrderIfExistsArgs = {
  subset?: InputMaybe<BrandFilterInput>;
  where: Array<BrandUniqueFilterInput>;
};


export type QueryCenshareTraceArgs = {
  where: CenshareTraceUniqueFilterInput;
};


export type QueryCenshareTraceCountArgs = {
  where?: InputMaybe<CenshareTraceFilterInput>;
};


export type QueryCenshareTraceExistsArgs = {
  where: CenshareTraceUniqueFilterInput;
};


export type QueryCenshareTraceIfExistsArgs = {
  where: CenshareTraceUniqueFilterInput;
};


export type QueryCenshareTracesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<CenshareTraceOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<CenshareTraceFilterInput>;
};


export type QueryCenshareTracesInOrderArgs = {
  subset?: InputMaybe<CenshareTraceFilterInput>;
  where: Array<CenshareTraceUniqueFilterInput>;
};


export type QueryCenshareTracesInOrderIfExistsArgs = {
  subset?: InputMaybe<CenshareTraceFilterInput>;
  where: Array<CenshareTraceUniqueFilterInput>;
};


export type QueryFlatAssignmentArgs = {
  where: FlatAssignmentUniqueFilterInput;
};


export type QueryFlatAssignmentCountArgs = {
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


export type QueryFlatAssignmentExistsArgs = {
  where: FlatAssignmentUniqueFilterInput;
};


export type QueryFlatAssignmentIfExistsArgs = {
  where: FlatAssignmentUniqueFilterInput;
};


export type QueryFlatAssignmentsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<FlatAssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


export type QueryFlatAssignmentsInOrderArgs = {
  subset?: InputMaybe<FlatAssignmentFilterInput>;
  where: Array<FlatAssignmentUniqueFilterInput>;
};


export type QueryFlatAssignmentsInOrderIfExistsArgs = {
  subset?: InputMaybe<FlatAssignmentFilterInput>;
  where: Array<FlatAssignmentUniqueFilterInput>;
};


export type QueryGetCenshareRailroadArgs = {
  brandKey: Scalars['String']['input'];
  issue: Scalars['String']['input'];
};


export type QueryGetCenshareRailroadByNameArgs = {
  brandKey: Scalars['String']['input'];
  issue: Scalars['String']['input'];
};


export type QueryGetElisaPdfArgs = {
  brandKey: Scalars['String']['input'];
  issue: Scalars['Int']['input'];
};


export type QueryPrintHeadingArgs = {
  where: PrintHeadingUniqueFilterInput;
};


export type QueryPrintHeadingCountArgs = {
  where?: InputMaybe<PrintHeadingFilterInput>;
};


export type QueryPrintHeadingExistsArgs = {
  where: PrintHeadingUniqueFilterInput;
};


export type QueryPrintHeadingIfExistsArgs = {
  where: PrintHeadingUniqueFilterInput;
};


export type QueryPrintHeadingPrintTemplateArgs = {
  where: PrintHeadingPrintTemplateUniqueFilterInput;
};


export type QueryPrintHeadingPrintTemplateCountArgs = {
  where?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
};


export type QueryPrintHeadingPrintTemplateExistsArgs = {
  where: PrintHeadingPrintTemplateUniqueFilterInput;
};


export type QueryPrintHeadingPrintTemplateIfExistsArgs = {
  where: PrintHeadingPrintTemplateUniqueFilterInput;
};


export type QueryPrintHeadingPrintTemplatesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintHeadingPrintTemplateOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
};


export type QueryPrintHeadingPrintTemplatesInOrderArgs = {
  subset?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
  where: Array<PrintHeadingPrintTemplateUniqueFilterInput>;
};


export type QueryPrintHeadingPrintTemplatesInOrderIfExistsArgs = {
  subset?: InputMaybe<PrintHeadingPrintTemplateFilterInput>;
  where: Array<PrintHeadingPrintTemplateUniqueFilterInput>;
};


export type QueryPrintHeadingsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintHeadingOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<PrintHeadingFilterInput>;
};


export type QueryPrintHeadingsInOrderArgs = {
  subset?: InputMaybe<PrintHeadingFilterInput>;
  where: Array<PrintHeadingUniqueFilterInput>;
};


export type QueryPrintHeadingsInOrderIfExistsArgs = {
  subset?: InputMaybe<PrintHeadingFilterInput>;
  where: Array<PrintHeadingUniqueFilterInput>;
};


export type QueryPrintIssueArgs = {
  where: PrintIssueUniqueFilterInput;
};


export type QueryPrintIssueCountArgs = {
  where?: InputMaybe<PrintIssueFilterInput>;
};


export type QueryPrintIssueExistsArgs = {
  where: PrintIssueUniqueFilterInput;
};


export type QueryPrintIssueIfExistsArgs = {
  where: PrintIssueUniqueFilterInput;
};


export type QueryPrintIssueRailroadArgs = {
  where: PrintIssueRailroadUniqueFilterInput;
};


export type QueryPrintIssueRailroadCountArgs = {
  where?: InputMaybe<PrintIssueRailroadFilterInput>;
};


export type QueryPrintIssueRailroadExistsArgs = {
  where: PrintIssueRailroadUniqueFilterInput;
};


export type QueryPrintIssueRailroadIfExistsArgs = {
  where: PrintIssueRailroadUniqueFilterInput;
};


export type QueryPrintIssueRailroadsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintIssueRailroadOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<PrintIssueRailroadFilterInput>;
};


export type QueryPrintIssueRailroadsInOrderArgs = {
  subset?: InputMaybe<PrintIssueRailroadFilterInput>;
  where: Array<PrintIssueRailroadUniqueFilterInput>;
};


export type QueryPrintIssueRailroadsInOrderIfExistsArgs = {
  subset?: InputMaybe<PrintIssueRailroadFilterInput>;
  where: Array<PrintIssueRailroadUniqueFilterInput>;
};


export type QueryPrintIssuesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintIssueOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<PrintIssueFilterInput>;
};


export type QueryPrintIssuesInOrderArgs = {
  subset?: InputMaybe<PrintIssueFilterInput>;
  where: Array<PrintIssueUniqueFilterInput>;
};


export type QueryPrintIssuesInOrderIfExistsArgs = {
  subset?: InputMaybe<PrintIssueFilterInput>;
  where: Array<PrintIssueUniqueFilterInput>;
};


export type QueryPrintPublicationArgs = {
  where: PrintPublicationUniqueFilterInput;
};


export type QueryPrintPublicationCountArgs = {
  where?: InputMaybe<PrintPublicationFilterInput>;
};


export type QueryPrintPublicationExistsArgs = {
  where: PrintPublicationUniqueFilterInput;
};


export type QueryPrintPublicationIfExistsArgs = {
  where: PrintPublicationUniqueFilterInput;
};


export type QueryPrintPublicationsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintPublicationOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<PrintPublicationFilterInput>;
};


export type QueryPrintPublicationsInOrderArgs = {
  subset?: InputMaybe<PrintPublicationFilterInput>;
  where: Array<PrintPublicationUniqueFilterInput>;
};


export type QueryPrintPublicationsInOrderIfExistsArgs = {
  subset?: InputMaybe<PrintPublicationFilterInput>;
  where: Array<PrintPublicationUniqueFilterInput>;
};


export type QueryPrintTemplateArgs = {
  where: PrintTemplateUniqueFilterInput;
};


export type QueryPrintTemplateCountArgs = {
  where?: InputMaybe<PrintTemplateFilterInput>;
};


export type QueryPrintTemplateDownloadArgs = {
  printTemplateId: Scalars['String']['input'];
};


export type QueryPrintTemplateDraftsArgs = {
  embedRessources?: InputMaybe<Scalars['Boolean']['input']>;
  fieldsData?: InputMaybe<Array<InputMaybe<PrintTemplateDraftFieldsDataType>>>;
  format: PrintTemplateDraftFormatType;
  pages?: InputMaybe<Array<Scalars['Int']['input']>>;
  printHeadingId: Scalars['String']['input'];
  printTemplateId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPrintTemplateExistsArgs = {
  where: PrintTemplateUniqueFilterInput;
};


export type QueryPrintTemplateIfExistsArgs = {
  where: PrintTemplateUniqueFilterInput;
};


export type QueryPrintTemplatePreviewUrlsArgs = {
  printTemplateId: Scalars['String']['input'];
};


export type QueryPrintTemplatesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<PrintTemplateOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<PrintTemplateFilterInput>;
};


export type QueryPrintTemplatesInOrderArgs = {
  subset?: InputMaybe<PrintTemplateFilterInput>;
  where: Array<PrintTemplateUniqueFilterInput>;
};


export type QueryPrintTemplatesInOrderIfExistsArgs = {
  subset?: InputMaybe<PrintTemplateFilterInput>;
  where: Array<PrintTemplateUniqueFilterInput>;
};


export type QueryPrintTemplateUploadArgs = {
  filename: Scalars['String']['input'];
  S3ObjectId: Scalars['String']['input'];
};


export type QueryRailroadPageArgs = {
  where: RailroadPageUniqueFilterInput;
};


export type QueryRailroadPageCountArgs = {
  where?: InputMaybe<RailroadPageFilterInput>;
};


export type QueryRailroadPageExistsArgs = {
  where: RailroadPageUniqueFilterInput;
};


export type QueryRailroadPageIfExistsArgs = {
  where: RailroadPageUniqueFilterInput;
};


export type QueryRailroadPagesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RailroadPageOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<RailroadPageFilterInput>;
};


export type QueryRailroadPagesInOrderArgs = {
  subset?: InputMaybe<RailroadPageFilterInput>;
  where: Array<RailroadPageUniqueFilterInput>;
};


export type QueryRailroadPagesInOrderIfExistsArgs = {
  subset?: InputMaybe<RailroadPageFilterInput>;
  where: Array<RailroadPageUniqueFilterInput>;
};


export type QueryRawArticleArgs = {
  where: RawArticleUniqueFilterInput;
};


export type QueryRawArticleCountArgs = {
  where?: InputMaybe<RawArticleFilterInput>;
};


export type QueryRawArticleExistsArgs = {
  where: RawArticleUniqueFilterInput;
};


export type QueryRawArticleIfExistsArgs = {
  where: RawArticleUniqueFilterInput;
};


export type QueryRawArticlesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RawArticleOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<RawArticleFilterInput>;
};


export type QueryRawArticlesInOrderArgs = {
  subset?: InputMaybe<RawArticleFilterInput>;
  where: Array<RawArticleUniqueFilterInput>;
};


export type QueryRawArticlesInOrderIfExistsArgs = {
  subset?: InputMaybe<RawArticleFilterInput>;
  where: Array<RawArticleUniqueFilterInput>;
};


export type QueryRawArticleVersionArgs = {
  where: RawArticleVersionUniqueFilterInput;
};


export type QueryRawArticleVersionCountArgs = {
  where?: InputMaybe<RawArticleVersionFilterInput>;
};


export type QueryRawArticleVersionExistsArgs = {
  where: RawArticleVersionUniqueFilterInput;
};


export type QueryRawArticleVersionIfExistsArgs = {
  where: RawArticleVersionUniqueFilterInput;
};


export type QueryRawArticleVersionsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RawArticleVersionOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<RawArticleVersionFilterInput>;
};


export type QueryRawArticleVersionsInOrderArgs = {
  subset?: InputMaybe<RawArticleVersionFilterInput>;
  where: Array<RawArticleVersionUniqueFilterInput>;
};


export type QueryRawArticleVersionsInOrderIfExistsArgs = {
  subset?: InputMaybe<RawArticleVersionFilterInput>;
  where: Array<RawArticleVersionUniqueFilterInput>;
};


export type QuerySubjectArgs = {
  where: SubjectUniqueFilterInput;
};


export type QuerySubjectCategoriesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectCategoryOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<SubjectCategoryFilterInput>;
};


export type QuerySubjectCategoriesInOrderArgs = {
  subset?: InputMaybe<SubjectCategoryFilterInput>;
  where: Array<SubjectCategoryUniqueFilterInput>;
};


export type QuerySubjectCategoriesInOrderIfExistsArgs = {
  subset?: InputMaybe<SubjectCategoryFilterInput>;
  where: Array<SubjectCategoryUniqueFilterInput>;
};


export type QuerySubjectCategoryArgs = {
  where: SubjectCategoryUniqueFilterInput;
};


export type QuerySubjectCategoryCountArgs = {
  where?: InputMaybe<SubjectCategoryFilterInput>;
};


export type QuerySubjectCategoryExistsArgs = {
  where: SubjectCategoryUniqueFilterInput;
};


export type QuerySubjectCategoryIfExistsArgs = {
  where: SubjectCategoryUniqueFilterInput;
};


export type QuerySubjectCountArgs = {
  where?: InputMaybe<SubjectFilterInput>;
};


export type QuerySubjectExistsArgs = {
  where: SubjectUniqueFilterInput;
};


export type QuerySubjectIfExistsArgs = {
  where: SubjectUniqueFilterInput;
};


export type QuerySubjectsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<SubjectFilterInput>;
};


export type QuerySubjectsInOrderArgs = {
  subset?: InputMaybe<SubjectFilterInput>;
  where: Array<SubjectUniqueFilterInput>;
};


export type QuerySubjectsInOrderIfExistsArgs = {
  subset?: InputMaybe<SubjectFilterInput>;
  where: Array<SubjectUniqueFilterInput>;
};


export type QuerySubjectUserArgs = {
  where: SubjectUserUniqueFilterInput;
};


export type QuerySubjectUserCountArgs = {
  where?: InputMaybe<SubjectUserFilterInput>;
};


export type QuerySubjectUserExistsArgs = {
  where: SubjectUserUniqueFilterInput;
};


export type QuerySubjectUserIfExistsArgs = {
  where: SubjectUserUniqueFilterInput;
};


export type QuerySubjectUsersArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectUserOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<SubjectUserFilterInput>;
};


export type QuerySubjectUsersInOrderArgs = {
  subset?: InputMaybe<SubjectUserFilterInput>;
  where: Array<SubjectUserUniqueFilterInput>;
};


export type QuerySubjectUsersInOrderIfExistsArgs = {
  subset?: InputMaybe<SubjectUserFilterInput>;
  where: Array<SubjectUserUniqueFilterInput>;
};


export type QuerySubSubjectArgs = {
  where: SubSubjectUniqueFilterInput;
};


export type QuerySubSubjectCountArgs = {
  where?: InputMaybe<SubSubjectFilterInput>;
};


export type QuerySubSubjectExistsArgs = {
  where: SubSubjectUniqueFilterInput;
};


export type QuerySubSubjectIfExistsArgs = {
  where: SubSubjectUniqueFilterInput;
};


export type QuerySubSubjectsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubSubjectOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<SubSubjectFilterInput>;
};


export type QuerySubSubjectsInOrderArgs = {
  subset?: InputMaybe<SubSubjectFilterInput>;
  where: Array<SubSubjectUniqueFilterInput>;
};


export type QuerySubSubjectsInOrderIfExistsArgs = {
  subset?: InputMaybe<SubSubjectFilterInput>;
  where: Array<SubSubjectUniqueFilterInput>;
};


export type QueryTopicFotowebPigeArgs = {
  where: TopicFotowebPigeUniqueFilterInput;
};


export type QueryTopicFotowebPigeCountArgs = {
  where?: InputMaybe<TopicFotowebPigeFilterInput>;
};


export type QueryTopicFotowebPigeExistsArgs = {
  where: TopicFotowebPigeUniqueFilterInput;
};


export type QueryTopicFotowebPigeIfExistsArgs = {
  where: TopicFotowebPigeUniqueFilterInput;
};


export type QueryTopicFotowebPigesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<TopicFotowebPigeOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<TopicFotowebPigeFilterInput>;
};


export type QueryTopicFotowebPigesInOrderArgs = {
  subset?: InputMaybe<TopicFotowebPigeFilterInput>;
  where: Array<TopicFotowebPigeUniqueFilterInput>;
};


export type QueryTopicFotowebPigesInOrderIfExistsArgs = {
  subset?: InputMaybe<TopicFotowebPigeFilterInput>;
  where: Array<TopicFotowebPigeUniqueFilterInput>;
};


export type QueryUnitArgs = {
  where: UnitUniqueFilterInput;
};


export type QueryUnitCountArgs = {
  where?: InputMaybe<UnitFilterInput>;
};


export type QueryUnitExistsArgs = {
  where: UnitUniqueFilterInput;
};


export type QueryUnitIfExistsArgs = {
  where: UnitUniqueFilterInput;
};


export type QueryUnitsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<UnitOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<UnitFilterInput>;
};


export type QueryUnitsInOrderArgs = {
  subset?: InputMaybe<UnitFilterInput>;
  where: Array<UnitUniqueFilterInput>;
};


export type QueryUnitsInOrderIfExistsArgs = {
  subset?: InputMaybe<UnitFilterInput>;
  where: Array<UnitUniqueFilterInput>;
};


export type QueryWebsiteArgs = {
  where: WebsiteUniqueFilterInput;
};


export type QueryWebsiteCountArgs = {
  where?: InputMaybe<WebsiteFilterInput>;
};


export type QueryWebsiteExistsArgs = {
  where: WebsiteUniqueFilterInput;
};


export type QueryWebsiteIfExistsArgs = {
  where: WebsiteUniqueFilterInput;
};


export type QueryWebsitesArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<WebsiteOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<WebsiteFilterInput>;
};


export type QueryWebsitesInOrderArgs = {
  subset?: InputMaybe<WebsiteFilterInput>;
  where: Array<WebsiteUniqueFilterInput>;
};


export type QueryWebsitesInOrderIfExistsArgs = {
  subset?: InputMaybe<WebsiteFilterInput>;
  where: Array<WebsiteUniqueFilterInput>;
};

/** The pages from a railroad */
export type RailroadPage = {
  __typename?: 'RailroadPage';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The public ID */
  id: Scalars['ID']['output'];
  indesignFilename: Scalars['String']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  printIssueRailroad: PrintIssueRailroad;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  visual: Scalars['String']['output'];
  workflowName: Scalars['String']['output'];
  workflowStage: Scalars['String']['output'];
};

/** The "RailroadPage" node's creation */
export type RailroadPageCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  indesignFilename: Scalars['String']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  printIssueRailroad: RailroadPageCreationPrintIssueRailroadInput;
  visual: Scalars['String']['input'];
  workflowName: Scalars['String']['input'];
  workflowStage: Scalars['String']['input'];
};

export type RailroadPageCreationPrintIssueRailroadCreateIfNotExistsInput = {
  data: PrintIssueRailroadCreationInput;
  where: PrintIssueRailroadUniqueFilterInput;
};

export type RailroadPageCreationPrintIssueRailroadInput = {
  /** Connect a "PrintIssueRailroad" to a new "RailroadPage" through the "RailroadPage.printIssueRailroad" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintIssueRailroadUniqueFilterInput>;
  /** Create a "PrintIssueRailroad" and connect it to a new "RailroadPage" through the "RailroadPage.printIssueRailroad" edge. */
  create?: InputMaybe<PrintIssueRailroadCreationInput>;
  /** Create a "PrintIssueRailroad" if it does not exist, and connect it to a new "RailroadPage" through the "RailroadPage.printIssueRailroad" edge. */
  createIfNotExists?: InputMaybe<RailroadPageCreationPrintIssueRailroadCreateIfNotExistsInput>;
};

/** The "RailroadPage" node's creation */
export type RailroadPageCreationWithoutPrintIssueRailroadInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  indesignFilename: Scalars['String']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  visual: Scalars['String']['input'];
  workflowName: Scalars['String']['input'];
  workflowStage: Scalars['String']['input'];
};

/** The "RailroadPage" nodes' filter */
export type RailroadPageFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<RailroadPageFilterInput>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  indesignFilename?: InputMaybe<Scalars['String']['input']>;
  indesignFilename_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  indesignFilename_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  indesignFilename_gt?: InputMaybe<Scalars['String']['input']>;
  indesignFilename_gte?: InputMaybe<Scalars['String']['input']>;
  indesignFilename_in?: InputMaybe<Array<Scalars['String']['input']>>;
  indesignFilename_lt?: InputMaybe<Scalars['String']['input']>;
  indesignFilename_lte?: InputMaybe<Scalars['String']['input']>;
  indesignFilename_not?: InputMaybe<Scalars['String']['input']>;
  indesignFilename_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  indesignFilename_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  indesignFilename_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  indesignFilename_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  indesignFilename_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  NOT?: InputMaybe<RailroadPageFilterInput>;
  number?: InputMaybe<Scalars['Int']['input']>;
  number_gt?: InputMaybe<Scalars['Int']['input']>;
  number_gte?: InputMaybe<Scalars['Int']['input']>;
  number_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  number_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  number_lt?: InputMaybe<Scalars['Int']['input']>;
  number_lte?: InputMaybe<Scalars['Int']['input']>;
  number_not?: InputMaybe<Scalars['Int']['input']>;
  number_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  OR?: InputMaybe<Array<InputMaybe<RailroadPageFilterInput>>>;
  printIssueRailroad?: InputMaybe<PrintIssueRailroadFilterInput>;
  printIssueRailroad_not?: InputMaybe<PrintIssueRailroadFilterInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  visual?: InputMaybe<Scalars['String']['input']>;
  visual_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  visual_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  visual_in?: InputMaybe<Array<Scalars['String']['input']>>;
  visual_not?: InputMaybe<Scalars['String']['input']>;
  visual_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  visual_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  visual_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  visual_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  visual_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowName?: InputMaybe<Scalars['String']['input']>;
  workflowName_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowName_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowName_gt?: InputMaybe<Scalars['String']['input']>;
  workflowName_gte?: InputMaybe<Scalars['String']['input']>;
  workflowName_in?: InputMaybe<Array<Scalars['String']['input']>>;
  workflowName_lt?: InputMaybe<Scalars['String']['input']>;
  workflowName_lte?: InputMaybe<Scalars['String']['input']>;
  workflowName_not?: InputMaybe<Scalars['String']['input']>;
  workflowName_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowName_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowName_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  workflowName_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowName_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowStage?: InputMaybe<Scalars['String']['input']>;
  workflowStage_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowStage_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowStage_gt?: InputMaybe<Scalars['String']['input']>;
  workflowStage_gte?: InputMaybe<Scalars['String']['input']>;
  workflowStage_in?: InputMaybe<Array<Scalars['String']['input']>>;
  workflowStage_lt?: InputMaybe<Scalars['String']['input']>;
  workflowStage_lte?: InputMaybe<Scalars['String']['input']>;
  workflowStage_not?: InputMaybe<Scalars['String']['input']>;
  workflowStage_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowStage_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowStage_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  workflowStage_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  workflowStage_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
};

/** Order the "RailroadPage" nodes */
export enum RailroadPageOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "indesignFilename" to the highest */
  indesignFilename_ASC = 'indesignFilename_ASC',
  /** from the highest "indesignFilename" to the lowest */
  indesignFilename_DESC = 'indesignFilename_DESC',
  /** from the lowest "number" to the highest */
  number_ASC = 'number_ASC',
  /** from the highest "number" to the lowest */
  number_DESC = 'number_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC',
  /** from the lowest "workflowName" to the highest */
  workflowName_ASC = 'workflowName_ASC',
  /** from the highest "workflowName" to the lowest */
  workflowName_DESC = 'workflowName_DESC',
  /** from the lowest "workflowStage" to the highest */
  workflowStage_ASC = 'workflowStage_ASC',
  /** from the highest "workflowStage" to the lowest */
  workflowStage_DESC = 'workflowStage_DESC'
}

/**
 * Identifies exactly one "RailroadPage" with one of the following combination of components' value:
 * - _id
 * - id
 */
export type RailroadPageUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** The "RailroadPage" node's update */
export type RailroadPageUpdateInput = {
  indesignFilename?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  printIssueRailroad?: InputMaybe<RailroadPageUpdatePrintIssueRailroadInput>;
  visual?: InputMaybe<Scalars['String']['input']>;
  workflowName?: InputMaybe<Scalars['String']['input']>;
  workflowStage?: InputMaybe<Scalars['String']['input']>;
};

export type RailroadPageUpdatePrintIssueRailroadCreateIfNotExistsInput = {
  data: PrintIssueRailroadCreationInput;
  where: PrintIssueRailroadUniqueFilterInput;
};

export type RailroadPageUpdatePrintIssueRailroadInput = {
  /** Connect a "PrintIssueRailroad" to an existing "RailroadPage" through the "RailroadPage.printIssueRailroad" edge, throw an error if it does not exist. */
  connect?: InputMaybe<PrintIssueRailroadUniqueFilterInput>;
  /** Create a "PrintIssueRailroad" and connect it to an existing "RailroadPage" through the "RailroadPage.printIssueRailroad" edge. */
  create?: InputMaybe<PrintIssueRailroadCreationInput>;
  /** Create a "PrintIssueRailroad" if it does not exist, and connect it to an existing "RailroadPage" through the "RailroadPage.printIssueRailroad" edge. */
  createIfNotExists?: InputMaybe<RailroadPageUpdatePrintIssueRailroadCreateIfNotExistsInput>;
  /** Update the connected "PrintIssueRailroad", throw an error if the "RailroadPage.printIssueRailroad" edge does not exist. */
  update?: InputMaybe<PrintIssueRailroadUpdateInput>;
};

/** The "RailroadPage" node's update */
export type RailroadPageUpdateWithoutPrintIssueRailroadInput = {
  indesignFilename?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  visual?: InputMaybe<Scalars['String']['input']>;
  workflowName?: InputMaybe<Scalars['String']['input']>;
  workflowStage?: InputMaybe<Scalars['String']['input']>;
};

/** A raw article */
export type RawArticle = {
  __typename?: 'RawArticle';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  assignment?: Maybe<Assignment>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** Number of "flatAssignments" */
  flatAssignmentCount: Scalars['UnsignedInt']['output'];
  flatAssignments: Array<FlatAssignment>;
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  lastVersion?: Maybe<RawArticleVersion>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  majorVersions: Array<RawArticleVersion>;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  /** Number of "versions" */
  versionCount: Scalars['UnsignedInt']['output'];
  versions: Array<RawArticleVersion>;
};


/** A raw article */
export type RawArticleFlatAssignmentCountArgs = {
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** A raw article */
export type RawArticleFlatAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<FlatAssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** A raw article */
export type RawArticleMajorVersionsArgs = {
  first: Scalars['Int']['input'];
};


/** A raw article */
export type RawArticleVersionCountArgs = {
  where?: InputMaybe<RawArticleVersionFilterInput>;
};


/** A raw article */
export type RawArticleVersionsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RawArticleVersionOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<RawArticleVersionFilterInput>;
};

export type RawArticleCreationAssignmentInput = {
  create?: InputMaybe<AssignmentCreationWithoutRawArticleInput>;
};

/** The "RawArticle" node's creation */
export type RawArticleCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignment?: InputMaybe<RawArticleCreationAssignmentInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  versions?: InputMaybe<RawArticleCreationVersionsInput>;
};

export type RawArticleCreationVersionsInput = {
  create?: InputMaybe<Array<RawArticleVersionCreationWithoutRawArticleInput>>;
};

/** The "RawArticle" nodes' filter */
export type RawArticleFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<RawArticleFilterInput>>>;
  assignment?: InputMaybe<AssignmentFilterInput>;
  assignment_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  assignment_not?: InputMaybe<AssignmentFilterInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  flatAssignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignments_every?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_none?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_some?: InputMaybe<FlatAssignmentFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  legacyId_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId_lt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_lte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  NOT?: InputMaybe<RawArticleFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<RawArticleFilterInput>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  versionCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  versionCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  versionCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  versionCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  versionCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  versionCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  versions_every?: InputMaybe<RawArticleVersionFilterInput>;
  versions_none?: InputMaybe<RawArticleVersionFilterInput>;
  versions_some?: InputMaybe<RawArticleVersionFilterInput>;
};

/** Order the "RawArticle" nodes */
export enum RawArticleOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest number of "flatAssignments" to the highest */
  flatAssignmentCount_ASC = 'flatAssignmentCount_ASC',
  /** from the highest number of "flatAssignments" to the lowest */
  flatAssignmentCount_DESC = 'flatAssignmentCount_DESC',
  /** from the lowest "legacyId" to the highest */
  legacyId_ASC = 'legacyId_ASC',
  /** from the highest "legacyId" to the lowest */
  legacyId_DESC = 'legacyId_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC',
  /** from the lowest number of "versions" to the highest */
  versionCount_ASC = 'versionCount_ASC',
  /** from the highest number of "versions" to the lowest */
  versionCount_DESC = 'versionCount_DESC'
}

export enum RawArticleStatus {
  Correcting = 'Correcting',
  Correction = 'Correction',
  Editing = 'Editing',
  Mockup = 'Mockup',
  Validating = 'Validating',
  Validation = 'Validation'
}

/**
 * Identifies exactly one "RawArticle" with one of the following combination of components' value:
 * - _id
 * - id
 */
export type RawArticleUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RawArticleUpdateAssignmentInput = {
  create?: InputMaybe<AssignmentCreationWithoutRawArticleInput>;
  createIfNotExists?: InputMaybe<AssignmentCreationWithoutRawArticleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AssignmentUpdateWithoutRawArticleInput>;
  updateIfExists?: InputMaybe<AssignmentUpdateWithoutRawArticleInput>;
  upsert?: InputMaybe<RawArticleUpdateAssignmentUpsertInput>;
};

export type RawArticleUpdateAssignmentUpsertInput = {
  create: AssignmentCreationWithoutRawArticleInput;
  update?: InputMaybe<AssignmentUpdateWithoutRawArticleInput>;
};

/** The "RawArticle" node's update */
export type RawArticleUpdateInput = {
  assignment?: InputMaybe<RawArticleUpdateAssignmentInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  versions?: InputMaybe<RawArticleUpdateVersionsInput>;
};

export type RawArticleUpdateVersionsInput = {
  create?: InputMaybe<Array<RawArticleVersionCreationWithoutRawArticleInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<RawArticleVersionFilterInput>;
  updateAll?: InputMaybe<RawArticleVersionUpdateWithoutRawArticleInput>;
  updateMany?: InputMaybe<RawArticleUpdateVersionsUpdateManyInput>;
};

export type RawArticleUpdateVersionsUpdateManyInput = {
  data?: InputMaybe<RawArticleVersionUpdateWithoutRawArticleInput>;
  where?: InputMaybe<RawArticleVersionFilterInput>;
};

/** A raw article's version */
export type RawArticleVersion = {
  __typename?: 'RawArticleVersion';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  allowedStatuses: Array<RawArticleStatus>;
  /** Returns a list of users for whom the transfer is possible */
  allowedTransfers: Array<User>;
  body?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  editor?: Maybe<User>;
  /** The user assigned to the version */
  editorId: Scalars['String']['output'];
  /** Number of "forkChilrens" */
  forkChilrenCount: Scalars['UnsignedInt']['output'];
  forkChilrens: Array<RawArticleVersion>;
  forkOf?: Maybe<RawArticleVersion>;
  /** The public ID */
  id: Scalars['ID']['output'];
  lead?: Maybe<Scalars['String']['output']>;
  /** An array of left over Draft content */
  leftOver?: Maybe<Scalars['String']['output']>;
  nextVersion?: Maybe<RawArticleVersion>;
  previousMinorVersions: Array<RawArticleVersion>;
  previousVersion?: Maybe<RawArticleVersion>;
  rawArticle: RawArticle;
  signature?: Maybe<Scalars['String']['output']>;
  status: RawArticleStatus;
  textIsReadOnly: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
};


/** A raw article's version */
export type RawArticleVersionAllowedTransfersArgs = {
  name: Scalars['String']['input'];
};


/** A raw article's version */
export type RawArticleVersionForkChilrenCountArgs = {
  where?: InputMaybe<RawArticleVersionFilterInput>;
};


/** A raw article's version */
export type RawArticleVersionForkChilrensArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<RawArticleVersionOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<RawArticleVersionFilterInput>;
};


/** A raw article's version */
export type RawArticleVersionPreviousMinorVersionsArgs = {
  first: Scalars['Int']['input'];
};

export type RawArticleVersionCreationForkChilrensInput = {
  create?: InputMaybe<Array<RawArticleVersionCreationWithoutForkOfInput>>;
};

export type RawArticleVersionCreationForkOfCreateIfNotExistsInput = {
  data: RawArticleVersionCreationInput;
  where: RawArticleVersionUniqueFilterInput;
};

export type RawArticleVersionCreationForkOfInput = {
  /** Connect a "RawArticleVersion" to a new "RawArticleVersion" through the "RawArticleVersion.forkOf" edge, throw an error if it does not exist. */
  connect?: InputMaybe<RawArticleVersionUniqueFilterInput>;
  /** Connect a "RawArticleVersion" to a new "RawArticleVersion" through the "RawArticleVersion.forkOf" edge, if it exists. */
  connectIfExists?: InputMaybe<RawArticleVersionUniqueFilterInput>;
  /** Create a "RawArticleVersion" and connect it to a new "RawArticleVersion" through the "RawArticleVersion.forkOf" edge. */
  create?: InputMaybe<RawArticleVersionCreationInput>;
  /** Create a "RawArticleVersion" if it does not exist, and connect it to a new "RawArticleVersion" through the "RawArticleVersion.forkOf" edge. */
  createIfNotExists?: InputMaybe<RawArticleVersionCreationForkOfCreateIfNotExistsInput>;
};

/** The "RawArticleVersion" node's creation */
export type RawArticleVersionCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** The user assigned to the version */
  editorId?: InputMaybe<Scalars['String']['input']>;
  forkChilrens?: InputMaybe<RawArticleVersionCreationForkChilrensInput>;
  forkOf?: InputMaybe<RawArticleVersionCreationForkOfInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  /** An array of left over Draft content */
  leftOver?: InputMaybe<Scalars['String']['input']>;
  nextVersion?: InputMaybe<RawArticleVersionCreationNextVersionInput>;
  previousVersion?: InputMaybe<RawArticleVersionCreationPreviousVersionInput>;
  rawArticle: RawArticleVersionCreationRawArticleInput;
  signature?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RawArticleStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RawArticleVersionCreationNextVersionInput = {
  create?: InputMaybe<RawArticleVersionCreationWithoutPreviousVersionInput>;
};

export type RawArticleVersionCreationPreviousVersionCreateIfNotExistsInput = {
  data: RawArticleVersionCreationInput;
  where: RawArticleVersionUniqueFilterInput;
};

export type RawArticleVersionCreationPreviousVersionInput = {
  /** Connect a "RawArticleVersion" to a new "RawArticleVersion" through the "RawArticleVersion.previousVersion" edge, throw an error if it does not exist. */
  connect?: InputMaybe<RawArticleVersionUniqueFilterInput>;
  /** Connect a "RawArticleVersion" to a new "RawArticleVersion" through the "RawArticleVersion.previousVersion" edge, if it exists. */
  connectIfExists?: InputMaybe<RawArticleVersionUniqueFilterInput>;
  /** Create a "RawArticleVersion" and connect it to a new "RawArticleVersion" through the "RawArticleVersion.previousVersion" edge. */
  create?: InputMaybe<RawArticleVersionCreationInput>;
  /** Create a "RawArticleVersion" if it does not exist, and connect it to a new "RawArticleVersion" through the "RawArticleVersion.previousVersion" edge. */
  createIfNotExists?: InputMaybe<RawArticleVersionCreationPreviousVersionCreateIfNotExistsInput>;
};

export type RawArticleVersionCreationRawArticleCreateIfNotExistsInput = {
  data: RawArticleCreationInput;
  where: RawArticleUniqueFilterInput;
};

export type RawArticleVersionCreationRawArticleInput = {
  /** Connect a "RawArticle" to a new "RawArticleVersion" through the "RawArticleVersion.rawArticle" edge, throw an error if it does not exist. */
  connect?: InputMaybe<RawArticleUniqueFilterInput>;
  /** Create a "RawArticle" and connect it to a new "RawArticleVersion" through the "RawArticleVersion.rawArticle" edge. */
  create?: InputMaybe<RawArticleCreationInput>;
  /** Create a "RawArticle" if it does not exist, and connect it to a new "RawArticleVersion" through the "RawArticleVersion.rawArticle" edge. */
  createIfNotExists?: InputMaybe<RawArticleVersionCreationRawArticleCreateIfNotExistsInput>;
};

/** The "RawArticleVersion" node's creation */
export type RawArticleVersionCreationWithoutForkOfInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** The user assigned to the version */
  editorId?: InputMaybe<Scalars['String']['input']>;
  forkChilrens?: InputMaybe<RawArticleVersionCreationForkChilrensInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  /** An array of left over Draft content */
  leftOver?: InputMaybe<Scalars['String']['input']>;
  nextVersion?: InputMaybe<RawArticleVersionCreationNextVersionInput>;
  previousVersion?: InputMaybe<RawArticleVersionCreationPreviousVersionInput>;
  rawArticle: RawArticleVersionCreationRawArticleInput;
  signature?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RawArticleStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The "RawArticleVersion" node's creation */
export type RawArticleVersionCreationWithoutPreviousVersionInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** The user assigned to the version */
  editorId?: InputMaybe<Scalars['String']['input']>;
  forkChilrens?: InputMaybe<RawArticleVersionCreationForkChilrensInput>;
  forkOf?: InputMaybe<RawArticleVersionCreationForkOfInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  /** An array of left over Draft content */
  leftOver?: InputMaybe<Scalars['String']['input']>;
  nextVersion?: InputMaybe<RawArticleVersionCreationNextVersionInput>;
  rawArticle: RawArticleVersionCreationRawArticleInput;
  signature?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RawArticleStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The "RawArticleVersion" node's creation */
export type RawArticleVersionCreationWithoutRawArticleInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** The user assigned to the version */
  editorId?: InputMaybe<Scalars['String']['input']>;
  forkChilrens?: InputMaybe<RawArticleVersionCreationForkChilrensInput>;
  forkOf?: InputMaybe<RawArticleVersionCreationForkOfInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  /** An array of left over Draft content */
  leftOver?: InputMaybe<Scalars['String']['input']>;
  nextVersion?: InputMaybe<RawArticleVersionCreationNextVersionInput>;
  previousVersion?: InputMaybe<RawArticleVersionCreationPreviousVersionInput>;
  signature?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RawArticleStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The "RawArticleVersion" nodes' filter */
export type RawArticleVersionFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<RawArticleVersionFilterInput>>>;
  body?: InputMaybe<Scalars['String']['input']>;
  body_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  body_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  body_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  body_not?: InputMaybe<Scalars['String']['input']>;
  body_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  body_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  body_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  body_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  comment_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  comment_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  comment_not?: InputMaybe<Scalars['String']['input']>;
  comment_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  comment_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  editorId?: InputMaybe<Scalars['String']['input']>;
  editorId_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  editorId_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  editorId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  editorId_not?: InputMaybe<Scalars['String']['input']>;
  editorId_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  editorId_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  editorId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  editorId_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  editorId_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  forkChilrenCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  forkChilrenCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  forkChilrenCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  forkChilrenCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  forkChilrenCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  forkChilrenCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  forkChilrens_every?: InputMaybe<RawArticleVersionFilterInput>;
  forkChilrens_none?: InputMaybe<RawArticleVersionFilterInput>;
  forkChilrens_some?: InputMaybe<RawArticleVersionFilterInput>;
  forkOf?: InputMaybe<RawArticleVersionFilterInput>;
  forkOf_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  forkOf_not?: InputMaybe<RawArticleVersionFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lead?: InputMaybe<Scalars['String']['input']>;
  lead_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  lead_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  lead_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lead_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  lead_not?: InputMaybe<Scalars['String']['input']>;
  lead_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  lead_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  lead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lead_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  lead_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  leftOver?: InputMaybe<Scalars['String']['input']>;
  leftOver_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  leftOver_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  leftOver_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leftOver_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  leftOver_not?: InputMaybe<Scalars['String']['input']>;
  leftOver_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  leftOver_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  leftOver_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leftOver_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  leftOver_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  nextVersion?: InputMaybe<RawArticleVersionFilterInput>;
  nextVersion_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  nextVersion_not?: InputMaybe<RawArticleVersionFilterInput>;
  NOT?: InputMaybe<RawArticleVersionFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<RawArticleVersionFilterInput>>>;
  previousVersion?: InputMaybe<RawArticleVersionFilterInput>;
  previousVersion_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  previousVersion_not?: InputMaybe<RawArticleVersionFilterInput>;
  rawArticle?: InputMaybe<RawArticleFilterInput>;
  rawArticle_not?: InputMaybe<RawArticleFilterInput>;
  signature?: InputMaybe<Scalars['String']['input']>;
  signature_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  signature_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  signature_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  signature_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  signature_not?: InputMaybe<Scalars['String']['input']>;
  signature_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  signature_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  signature_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  signature_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  signature_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  status?: InputMaybe<RawArticleStatus>;
  status_in?: InputMaybe<Array<RawArticleStatus>>;
  status_not?: InputMaybe<RawArticleStatus>;
  status_not_in?: InputMaybe<Array<RawArticleStatus>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
};

/** Order the "RawArticleVersion" nodes */
export enum RawArticleVersionOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest number of "forkChilrens" to the highest */
  forkChilrenCount_ASC = 'forkChilrenCount_ASC',
  /** from the highest number of "forkChilrens" to the lowest */
  forkChilrenCount_DESC = 'forkChilrenCount_DESC'
}

/**
 * Identifies exactly one "RawArticleVersion" with one of the following combination of components' value:
 * - _id
 * - id
 * - previousVersion
 */
export type RawArticleVersionUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  previousVersion?: InputMaybe<RawArticleVersionUniqueFilterInput>;
};

export type RawArticleVersionUpdateForkChilrensInput = {
  create?: InputMaybe<Array<RawArticleVersionCreationWithoutForkOfInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<RawArticleVersionFilterInput>;
  updateAll?: InputMaybe<RawArticleVersionUpdateWithoutForkOfInput>;
  updateMany?: InputMaybe<RawArticleVersionUpdateForkChilrensUpdateManyInput>;
};

export type RawArticleVersionUpdateForkChilrensUpdateManyInput = {
  data?: InputMaybe<RawArticleVersionUpdateWithoutForkOfInput>;
  where?: InputMaybe<RawArticleVersionFilterInput>;
};

export type RawArticleVersionUpdateForkOfCreateIfNotExistsInput = {
  data: RawArticleVersionCreationInput;
  where: RawArticleVersionUniqueFilterInput;
};

export type RawArticleVersionUpdateForkOfInput = {
  /** Connect a "RawArticleVersion" to an existing "RawArticleVersion" through the "RawArticleVersion.forkOf" edge, throw an error if it does not exist. */
  connect?: InputMaybe<RawArticleVersionUniqueFilterInput>;
  /** Connect a "RawArticleVersion" to an existing "RawArticleVersion" through the "RawArticleVersion.forkOf" edge, if it exists. */
  connectIfExists?: InputMaybe<RawArticleVersionUniqueFilterInput>;
  /** Create a "RawArticleVersion" and connect it to an existing "RawArticleVersion" through the "RawArticleVersion.forkOf" edge. */
  create?: InputMaybe<RawArticleVersionCreationInput>;
  /** Create a "RawArticleVersion" if it does not exist, and connect it to an existing "RawArticleVersion" through the "RawArticleVersion.forkOf" edge. */
  createIfNotExists?: InputMaybe<RawArticleVersionUpdateForkOfCreateIfNotExistsInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  disconnectIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the connected "RawArticleVersion", throw an error if the "RawArticleVersion.forkOf" edge does not exist. */
  update?: InputMaybe<RawArticleVersionUpdateInput>;
  /** Update the connected "RawArticleVersion", if the "RawArticleVersion.forkOf" edge exists. */
  updateIfExists?: InputMaybe<RawArticleVersionUpdateInput>;
};

/** The "RawArticleVersion" node's update */
export type RawArticleVersionUpdateInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** The user assigned to the version */
  editorId?: InputMaybe<Scalars['String']['input']>;
  forkChilrens?: InputMaybe<RawArticleVersionUpdateForkChilrensInput>;
  forkOf?: InputMaybe<RawArticleVersionUpdateForkOfInput>;
  lead?: InputMaybe<Scalars['String']['input']>;
  /** An array of left over Draft content */
  leftOver?: InputMaybe<Scalars['String']['input']>;
  nextVersion?: InputMaybe<RawArticleVersionUpdateNextVersionInput>;
  previousVersion?: InputMaybe<RawArticleVersionUpdatePreviousVersionInput>;
  rawArticle?: InputMaybe<RawArticleVersionUpdateRawArticleInput>;
  signature?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RawArticleStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RawArticleVersionUpdateNextVersionInput = {
  create?: InputMaybe<RawArticleVersionCreationWithoutPreviousVersionInput>;
  createIfNotExists?: InputMaybe<RawArticleVersionCreationWithoutPreviousVersionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<RawArticleVersionUpdateWithoutPreviousVersionInput>;
  updateIfExists?: InputMaybe<RawArticleVersionUpdateWithoutPreviousVersionInput>;
  upsert?: InputMaybe<RawArticleVersionUpdateNextVersionUpsertInput>;
};

export type RawArticleVersionUpdateNextVersionUpsertInput = {
  create: RawArticleVersionCreationWithoutPreviousVersionInput;
  update?: InputMaybe<RawArticleVersionUpdateWithoutPreviousVersionInput>;
};

export type RawArticleVersionUpdatePreviousVersionCreateIfNotExistsInput = {
  data: RawArticleVersionCreationInput;
  where: RawArticleVersionUniqueFilterInput;
};

export type RawArticleVersionUpdatePreviousVersionInput = {
  /** Connect a "RawArticleVersion" to an existing "RawArticleVersion" through the "RawArticleVersion.previousVersion" edge, throw an error if it does not exist. */
  connect?: InputMaybe<RawArticleVersionUniqueFilterInput>;
  /** Connect a "RawArticleVersion" to an existing "RawArticleVersion" through the "RawArticleVersion.previousVersion" edge, if it exists. */
  connectIfExists?: InputMaybe<RawArticleVersionUniqueFilterInput>;
  /** Create a "RawArticleVersion" and connect it to an existing "RawArticleVersion" through the "RawArticleVersion.previousVersion" edge. */
  create?: InputMaybe<RawArticleVersionCreationInput>;
  /** Create a "RawArticleVersion" if it does not exist, and connect it to an existing "RawArticleVersion" through the "RawArticleVersion.previousVersion" edge. */
  createIfNotExists?: InputMaybe<RawArticleVersionUpdatePreviousVersionCreateIfNotExistsInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  disconnectIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the connected "RawArticleVersion", throw an error if the "RawArticleVersion.previousVersion" edge does not exist. */
  update?: InputMaybe<RawArticleVersionUpdateInput>;
  /** Update the connected "RawArticleVersion", if the "RawArticleVersion.previousVersion" edge exists. */
  updateIfExists?: InputMaybe<RawArticleVersionUpdateInput>;
};

export type RawArticleVersionUpdateRawArticleCreateIfNotExistsInput = {
  data: RawArticleCreationInput;
  where: RawArticleUniqueFilterInput;
};

export type RawArticleVersionUpdateRawArticleInput = {
  /** Connect a "RawArticle" to an existing "RawArticleVersion" through the "RawArticleVersion.rawArticle" edge, throw an error if it does not exist. */
  connect?: InputMaybe<RawArticleUniqueFilterInput>;
  /** Create a "RawArticle" and connect it to an existing "RawArticleVersion" through the "RawArticleVersion.rawArticle" edge. */
  create?: InputMaybe<RawArticleCreationInput>;
  /** Create a "RawArticle" if it does not exist, and connect it to an existing "RawArticleVersion" through the "RawArticleVersion.rawArticle" edge. */
  createIfNotExists?: InputMaybe<RawArticleVersionUpdateRawArticleCreateIfNotExistsInput>;
  /** Update the connected "RawArticle", throw an error if the "RawArticleVersion.rawArticle" edge does not exist. */
  update?: InputMaybe<RawArticleUpdateInput>;
};

/** The "RawArticleVersion" node's update */
export type RawArticleVersionUpdateWithoutForkOfInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** The user assigned to the version */
  editorId?: InputMaybe<Scalars['String']['input']>;
  forkChilrens?: InputMaybe<RawArticleVersionUpdateForkChilrensInput>;
  lead?: InputMaybe<Scalars['String']['input']>;
  /** An array of left over Draft content */
  leftOver?: InputMaybe<Scalars['String']['input']>;
  nextVersion?: InputMaybe<RawArticleVersionUpdateNextVersionInput>;
  previousVersion?: InputMaybe<RawArticleVersionUpdatePreviousVersionInput>;
  rawArticle?: InputMaybe<RawArticleVersionUpdateRawArticleInput>;
  signature?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RawArticleStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The "RawArticleVersion" node's update */
export type RawArticleVersionUpdateWithoutPreviousVersionInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** The user assigned to the version */
  editorId?: InputMaybe<Scalars['String']['input']>;
  forkChilrens?: InputMaybe<RawArticleVersionUpdateForkChilrensInput>;
  forkOf?: InputMaybe<RawArticleVersionUpdateForkOfInput>;
  lead?: InputMaybe<Scalars['String']['input']>;
  /** An array of left over Draft content */
  leftOver?: InputMaybe<Scalars['String']['input']>;
  nextVersion?: InputMaybe<RawArticleVersionUpdateNextVersionInput>;
  rawArticle?: InputMaybe<RawArticleVersionUpdateRawArticleInput>;
  signature?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RawArticleStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The "RawArticleVersion" node's update */
export type RawArticleVersionUpdateWithoutRawArticleInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** The user assigned to the version */
  editorId?: InputMaybe<Scalars['String']['input']>;
  forkChilrens?: InputMaybe<RawArticleVersionUpdateForkChilrensInput>;
  forkOf?: InputMaybe<RawArticleVersionUpdateForkOfInput>;
  lead?: InputMaybe<Scalars['String']['input']>;
  /** An array of left over Draft content */
  leftOver?: InputMaybe<Scalars['String']['input']>;
  nextVersion?: InputMaybe<RawArticleVersionUpdateNextVersionInput>;
  previousVersion?: InputMaybe<RawArticleVersionUpdatePreviousVersionInput>;
  signature?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RawArticleStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** A subject */
export type Subject = {
  __typename?: 'Subject';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** Number of "assignments" */
  assignmentCount: Scalars['UnsignedInt']['output'];
  assignments: Array<Assignment>;
  /** Number of "attachments" */
  attachmentCount: Scalars['UnsignedInt']['output'];
  attachments: Array<Attachment>;
  comment?: Maybe<Scalars['String']['output']>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** Number of "flatAssignments" */
  flatAssignmentCount: Scalars['UnsignedInt']['output'];
  flatAssignments: Array<FlatAssignment>;
  /** The public ID */
  id: Scalars['ID']['output'];
  isAuto: Scalars['Boolean']['output'];
  isClassified: Scalars['Boolean']['output'];
  /** Indicate if title is writable if none of article version has "Mockup" status */
  isTitleWritable: Scalars['Boolean']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  mediaRequest?: Maybe<Scalars['String']['output']>;
  subjectCategory?: Maybe<SubjectCategory>;
  /** Number of "subjectUsers" */
  subjectUserCount: Scalars['UnsignedInt']['output'];
  subjectUsers: Array<SubjectUser>;
  /** Number of "subSubjects" */
  subSubjectCount: Scalars['UnsignedInt']['output'];
  subSubjects: Array<SubSubject>;
  synopsis?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  unit: Unit;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<User>;
};


/** A subject */
export type SubjectAssignmentCountArgs = {
  where?: InputMaybe<AssignmentFilterInput>;
};


/** A subject */
export type SubjectAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<AssignmentFilterInput>;
};


/** A subject */
export type SubjectAttachmentCountArgs = {
  where?: InputMaybe<AttachmentFilterInput>;
};


/** A subject */
export type SubjectAttachmentsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AttachmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<AttachmentFilterInput>;
};


/** A subject */
export type SubjectFlatAssignmentCountArgs = {
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** A subject */
export type SubjectFlatAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<FlatAssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** A subject */
export type SubjectSubjectUserCountArgs = {
  where?: InputMaybe<SubjectUserFilterInput>;
};


/** A subject */
export type SubjectSubjectUsersArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectUserOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<SubjectUserFilterInput>;
};


/** A subject */
export type SubjectSubSubjectCountArgs = {
  where?: InputMaybe<SubSubjectFilterInput>;
};


/** A subject */
export type SubjectSubSubjectsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubSubjectOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<SubSubjectFilterInput>;
};

/** A subject category */
export type SubjectCategory = {
  __typename?: 'SubjectCategory';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** Number of "children" */
  childCount: Scalars['UnsignedInt']['output'];
  /** This category's children */
  children: Array<SubjectCategory>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<SubjectCategory>;
  /** Number of "subjects" */
  subjectCount: Scalars['UnsignedInt']['output'];
  subjects: Array<Subject>;
  title: Scalars['String']['output'];
  unit: Unit;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};


/** A subject category */
export type SubjectCategoryChildCountArgs = {
  where?: InputMaybe<SubjectCategoryFilterInput>;
};


/** A subject category */
export type SubjectCategoryChildrenArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectCategoryOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<SubjectCategoryFilterInput>;
};


/** A subject category */
export type SubjectCategorySubjectCountArgs = {
  where?: InputMaybe<SubjectFilterInput>;
};


/** A subject category */
export type SubjectCategorySubjectsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<SubjectFilterInput>;
};

export type SubjectCategoryCreationChildrenInput = {
  create?: InputMaybe<Array<SubjectCategoryCreationWithoutParentInput>>;
};

/** The "SubjectCategory" node's creation */
export type SubjectCategoryCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** This category's children */
  children?: InputMaybe<SubjectCategoryCreationChildrenInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  parent?: InputMaybe<SubjectCategoryCreationParentInput>;
  subjects?: InputMaybe<SubjectCategoryCreationSubjectsInput>;
  title: Scalars['String']['input'];
  unit: SubjectCategoryCreationUnitInput;
};

export type SubjectCategoryCreationParentCreateIfNotExistsInput = {
  data: SubjectCategoryCreationInput;
  where: SubjectCategoryUniqueFilterInput;
};

export type SubjectCategoryCreationParentInput = {
  /** Connect a "SubjectCategory" to a new "SubjectCategory" through the "SubjectCategory.parent" edge, throw an error if it does not exist. */
  connect?: InputMaybe<SubjectCategoryUniqueFilterInput>;
  /** Connect a "SubjectCategory" to a new "SubjectCategory" through the "SubjectCategory.parent" edge, if it exists. */
  connectIfExists?: InputMaybe<SubjectCategoryUniqueFilterInput>;
  /** Create a "SubjectCategory" and connect it to a new "SubjectCategory" through the "SubjectCategory.parent" edge. */
  create?: InputMaybe<SubjectCategoryCreationInput>;
  /** Create a "SubjectCategory" if it does not exist, and connect it to a new "SubjectCategory" through the "SubjectCategory.parent" edge. */
  createIfNotExists?: InputMaybe<SubjectCategoryCreationParentCreateIfNotExistsInput>;
};

export type SubjectCategoryCreationSubjectsInput = {
  create?: InputMaybe<Array<SubjectCreationWithoutSubjectCategoryInput>>;
};

export type SubjectCategoryCreationUnitCreateIfNotExistsInput = {
  data: UnitCreationInput;
  where: UnitUniqueFilterInput;
};

export type SubjectCategoryCreationUnitInput = {
  /** Connect a "Unit" to a new "SubjectCategory" through the "SubjectCategory.unit" edge, throw an error if it does not exist. */
  connect?: InputMaybe<UnitUniqueFilterInput>;
  /** Create a "Unit" and connect it to a new "SubjectCategory" through the "SubjectCategory.unit" edge. */
  create?: InputMaybe<UnitCreationInput>;
  /** Create a "Unit" if it does not exist, and connect it to a new "SubjectCategory" through the "SubjectCategory.unit" edge. */
  createIfNotExists?: InputMaybe<SubjectCategoryCreationUnitCreateIfNotExistsInput>;
};

/** The "SubjectCategory" node's creation */
export type SubjectCategoryCreationWithoutParentInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** This category's children */
  children?: InputMaybe<SubjectCategoryCreationChildrenInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  subjects?: InputMaybe<SubjectCategoryCreationSubjectsInput>;
  title: Scalars['String']['input'];
  unit: SubjectCategoryCreationUnitInput;
};

/** The "SubjectCategory" node's creation */
export type SubjectCategoryCreationWithoutUnitInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** This category's children */
  children?: InputMaybe<SubjectCategoryCreationChildrenInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  parent?: InputMaybe<SubjectCategoryCreationParentInput>;
  subjects?: InputMaybe<SubjectCategoryCreationSubjectsInput>;
  title: Scalars['String']['input'];
};

/** The "SubjectCategory" nodes' filter */
export type SubjectCategoryFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<SubjectCategoryFilterInput>>>;
  childCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  childCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  childCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  childCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  childCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  childCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  children_every?: InputMaybe<SubjectCategoryFilterInput>;
  children_none?: InputMaybe<SubjectCategoryFilterInput>;
  children_some?: InputMaybe<SubjectCategoryFilterInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  legacyId_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId_lt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_lte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  NOT?: InputMaybe<SubjectCategoryFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<SubjectCategoryFilterInput>>>;
  parent?: InputMaybe<SubjectCategoryFilterInput>;
  parent_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  parent_not?: InputMaybe<SubjectCategoryFilterInput>;
  subjectCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjects_every?: InputMaybe<SubjectFilterInput>;
  subjects_none?: InputMaybe<SubjectFilterInput>;
  subjects_some?: InputMaybe<SubjectFilterInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  unit?: InputMaybe<UnitFilterInput>;
  unit_not?: InputMaybe<UnitFilterInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "SubjectCategory" nodes */
export enum SubjectCategoryOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest number of "children" to the highest */
  childCount_ASC = 'childCount_ASC',
  /** from the highest number of "children" to the lowest */
  childCount_DESC = 'childCount_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "legacyId" to the highest */
  legacyId_ASC = 'legacyId_ASC',
  /** from the highest "legacyId" to the lowest */
  legacyId_DESC = 'legacyId_DESC',
  /** from the lowest number of "subjects" to the highest */
  subjectCount_ASC = 'subjectCount_ASC',
  /** from the highest number of "subjects" to the lowest */
  subjectCount_DESC = 'subjectCount_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "SubjectCategory" with one of the following combination of components' value:
 * - _id
 * - id
 */
export type SubjectCategoryUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SubjectCategoryUpdateChildrenInput = {
  create?: InputMaybe<Array<SubjectCategoryCreationWithoutParentInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<SubjectCategoryFilterInput>;
  updateAll?: InputMaybe<SubjectCategoryUpdateWithoutParentInput>;
  updateMany?: InputMaybe<SubjectCategoryUpdateChildrenUpdateManyInput>;
};

export type SubjectCategoryUpdateChildrenUpdateManyInput = {
  data?: InputMaybe<SubjectCategoryUpdateWithoutParentInput>;
  where?: InputMaybe<SubjectCategoryFilterInput>;
};

/** The "SubjectCategory" node's update */
export type SubjectCategoryUpdateInput = {
  /** This category's children */
  children?: InputMaybe<SubjectCategoryUpdateChildrenInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  parent?: InputMaybe<SubjectCategoryUpdateParentInput>;
  subjects?: InputMaybe<SubjectCategoryUpdateSubjectsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<SubjectCategoryUpdateUnitInput>;
};

export type SubjectCategoryUpdateParentCreateIfNotExistsInput = {
  data: SubjectCategoryCreationInput;
  where: SubjectCategoryUniqueFilterInput;
};

export type SubjectCategoryUpdateParentInput = {
  /** Connect a "SubjectCategory" to an existing "SubjectCategory" through the "SubjectCategory.parent" edge, throw an error if it does not exist. */
  connect?: InputMaybe<SubjectCategoryUniqueFilterInput>;
  /** Connect a "SubjectCategory" to an existing "SubjectCategory" through the "SubjectCategory.parent" edge, if it exists. */
  connectIfExists?: InputMaybe<SubjectCategoryUniqueFilterInput>;
  /** Create a "SubjectCategory" and connect it to an existing "SubjectCategory" through the "SubjectCategory.parent" edge. */
  create?: InputMaybe<SubjectCategoryCreationInput>;
  /** Create a "SubjectCategory" if it does not exist, and connect it to an existing "SubjectCategory" through the "SubjectCategory.parent" edge. */
  createIfNotExists?: InputMaybe<SubjectCategoryUpdateParentCreateIfNotExistsInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  disconnectIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the connected "SubjectCategory", throw an error if the "SubjectCategory.parent" edge does not exist. */
  update?: InputMaybe<SubjectCategoryUpdateInput>;
  /** Update the connected "SubjectCategory", if the "SubjectCategory.parent" edge exists. */
  updateIfExists?: InputMaybe<SubjectCategoryUpdateInput>;
};

export type SubjectCategoryUpdateSubjectsInput = {
  create?: InputMaybe<Array<SubjectCreationWithoutSubjectCategoryInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<SubjectFilterInput>;
  updateAll?: InputMaybe<SubjectUpdateWithoutSubjectCategoryInput>;
  updateMany?: InputMaybe<SubjectCategoryUpdateSubjectsUpdateManyInput>;
};

export type SubjectCategoryUpdateSubjectsUpdateManyInput = {
  data?: InputMaybe<SubjectUpdateWithoutSubjectCategoryInput>;
  where?: InputMaybe<SubjectFilterInput>;
};

export type SubjectCategoryUpdateUnitCreateIfNotExistsInput = {
  data: UnitCreationInput;
  where: UnitUniqueFilterInput;
};

export type SubjectCategoryUpdateUnitInput = {
  /** Connect a "Unit" to an existing "SubjectCategory" through the "SubjectCategory.unit" edge, throw an error if it does not exist. */
  connect?: InputMaybe<UnitUniqueFilterInput>;
  /** Create a "Unit" and connect it to an existing "SubjectCategory" through the "SubjectCategory.unit" edge. */
  create?: InputMaybe<UnitCreationInput>;
  /** Create a "Unit" if it does not exist, and connect it to an existing "SubjectCategory" through the "SubjectCategory.unit" edge. */
  createIfNotExists?: InputMaybe<SubjectCategoryUpdateUnitCreateIfNotExistsInput>;
  /** Update the connected "Unit", throw an error if the "SubjectCategory.unit" edge does not exist. */
  update?: InputMaybe<UnitUpdateInput>;
};

/** The "SubjectCategory" node's update */
export type SubjectCategoryUpdateWithoutParentInput = {
  /** This category's children */
  children?: InputMaybe<SubjectCategoryUpdateChildrenInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  subjects?: InputMaybe<SubjectCategoryUpdateSubjectsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<SubjectCategoryUpdateUnitInput>;
};

/** The "SubjectCategory" node's update */
export type SubjectCategoryUpdateWithoutUnitInput = {
  /** This category's children */
  children?: InputMaybe<SubjectCategoryUpdateChildrenInput>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  parent?: InputMaybe<SubjectCategoryUpdateParentInput>;
  subjects?: InputMaybe<SubjectCategoryUpdateSubjectsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type SubjectCreationAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutSubjectInput>>;
};

export type SubjectCreationAttachmentsInput = {
  create?: InputMaybe<Array<AttachmentCreationWithoutSubjectInput>>;
};

/** The "Subject" node's creation */
export type SubjectCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<SubjectCreationAssignmentsInput>;
  attachments?: InputMaybe<SubjectCreationAttachmentsInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  isAuto?: InputMaybe<Scalars['Boolean']['input']>;
  isClassified?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaRequest?: InputMaybe<Scalars['String']['input']>;
  subjectCategory?: InputMaybe<SubjectCreationSubjectCategoryInput>;
  subjectUsers?: InputMaybe<SubjectCreationSubjectUsersInput>;
  subSubjects?: InputMaybe<SubjectCreationSubSubjectsInput>;
  synopsis?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  unit: SubjectCreationUnitInput;
};

export type SubjectCreationSubjectCategoryCreateIfNotExistsInput = {
  data: SubjectCategoryCreationInput;
  where: SubjectCategoryUniqueFilterInput;
};

export type SubjectCreationSubjectCategoryInput = {
  /** Connect a "SubjectCategory" to a new "Subject" through the "Subject.subjectCategory" edge, throw an error if it does not exist. */
  connect?: InputMaybe<SubjectCategoryUniqueFilterInput>;
  /** Connect a "SubjectCategory" to a new "Subject" through the "Subject.subjectCategory" edge, if it exists. */
  connectIfExists?: InputMaybe<SubjectCategoryUniqueFilterInput>;
  /** Create a "SubjectCategory" and connect it to a new "Subject" through the "Subject.subjectCategory" edge. */
  create?: InputMaybe<SubjectCategoryCreationInput>;
  /** Create a "SubjectCategory" if it does not exist, and connect it to a new "Subject" through the "Subject.subjectCategory" edge. */
  createIfNotExists?: InputMaybe<SubjectCreationSubjectCategoryCreateIfNotExistsInput>;
};

export type SubjectCreationSubjectUsersInput = {
  create?: InputMaybe<Array<SubjectUserCreationWithoutSubjectInput>>;
};

export type SubjectCreationSubSubjectsInput = {
  create?: InputMaybe<Array<SubSubjectCreationWithoutSubjectInput>>;
};

export type SubjectCreationUnitCreateIfNotExistsInput = {
  data: UnitCreationInput;
  where: UnitUniqueFilterInput;
};

export type SubjectCreationUnitInput = {
  /** Connect a "Unit" to a new "Subject" through the "Subject.unit" edge, throw an error if it does not exist. */
  connect?: InputMaybe<UnitUniqueFilterInput>;
  /** Create a "Unit" and connect it to a new "Subject" through the "Subject.unit" edge. */
  create?: InputMaybe<UnitCreationInput>;
  /** Create a "Unit" if it does not exist, and connect it to a new "Subject" through the "Subject.unit" edge. */
  createIfNotExists?: InputMaybe<SubjectCreationUnitCreateIfNotExistsInput>;
};

/** The "Subject" node's creation */
export type SubjectCreationWithoutSubjectCategoryInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<SubjectCreationAssignmentsInput>;
  attachments?: InputMaybe<SubjectCreationAttachmentsInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  isAuto?: InputMaybe<Scalars['Boolean']['input']>;
  isClassified?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaRequest?: InputMaybe<Scalars['String']['input']>;
  subjectUsers?: InputMaybe<SubjectCreationSubjectUsersInput>;
  subSubjects?: InputMaybe<SubjectCreationSubSubjectsInput>;
  synopsis?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  unit: SubjectCreationUnitInput;
};

/** The "Subject" node's creation */
export type SubjectCreationWithoutUnitInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<SubjectCreationAssignmentsInput>;
  attachments?: InputMaybe<SubjectCreationAttachmentsInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  isAuto?: InputMaybe<Scalars['Boolean']['input']>;
  isClassified?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaRequest?: InputMaybe<Scalars['String']['input']>;
  subjectCategory?: InputMaybe<SubjectCreationSubjectCategoryInput>;
  subjectUsers?: InputMaybe<SubjectCreationSubjectUsersInput>;
  subSubjects?: InputMaybe<SubjectCreationSubSubjectsInput>;
  synopsis?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

/** The "Subject" nodes' filter */
export type SubjectFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<SubjectFilterInput>>>;
  assignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments_every?: InputMaybe<AssignmentFilterInput>;
  assignments_none?: InputMaybe<AssignmentFilterInput>;
  assignments_some?: InputMaybe<AssignmentFilterInput>;
  attachmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  attachmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  attachmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  attachmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  attachmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  attachmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  attachments_every?: InputMaybe<AttachmentFilterInput>;
  attachments_none?: InputMaybe<AttachmentFilterInput>;
  attachments_some?: InputMaybe<AttachmentFilterInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  comment_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  comment_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  comment_not?: InputMaybe<Scalars['String']['input']>;
  comment_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  comment_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  comment_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  flatAssignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignments_every?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_none?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_some?: InputMaybe<FlatAssignmentFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  isAuto?: InputMaybe<Scalars['Boolean']['input']>;
  isAuto_not?: InputMaybe<Scalars['Boolean']['input']>;
  isClassified?: InputMaybe<Scalars['Boolean']['input']>;
  isClassified_not?: InputMaybe<Scalars['Boolean']['input']>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  legacyId_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId_lt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_lte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  mediaRequest?: InputMaybe<Scalars['String']['input']>;
  mediaRequest_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  mediaRequest_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  mediaRequest_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaRequest_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  mediaRequest_not?: InputMaybe<Scalars['String']['input']>;
  mediaRequest_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  mediaRequest_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  mediaRequest_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mediaRequest_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  mediaRequest_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  NOT?: InputMaybe<SubjectFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<SubjectFilterInput>>>;
  subjectCategory?: InputMaybe<SubjectCategoryFilterInput>;
  subjectCategory_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  subjectCategory_not?: InputMaybe<SubjectCategoryFilterInput>;
  subjectUserCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectUserCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectUserCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectUserCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectUserCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectUserCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectUsers_every?: InputMaybe<SubjectUserFilterInput>;
  subjectUsers_none?: InputMaybe<SubjectUserFilterInput>;
  subjectUsers_some?: InputMaybe<SubjectUserFilterInput>;
  subSubjectCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subSubjectCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subSubjectCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subSubjectCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subSubjectCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subSubjectCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subSubjects_every?: InputMaybe<SubSubjectFilterInput>;
  subSubjects_none?: InputMaybe<SubSubjectFilterInput>;
  subSubjects_some?: InputMaybe<SubSubjectFilterInput>;
  synopsis?: InputMaybe<Scalars['String']['input']>;
  synopsis_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  synopsis_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  synopsis_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  synopsis_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  synopsis_not?: InputMaybe<Scalars['String']['input']>;
  synopsis_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  synopsis_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  synopsis_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  synopsis_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  synopsis_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  unit?: InputMaybe<UnitFilterInput>;
  unit_not?: InputMaybe<UnitFilterInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "Subject" nodes */
export enum SubjectOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest number of "assignments" to the highest */
  assignmentCount_ASC = 'assignmentCount_ASC',
  /** from the highest number of "assignments" to the lowest */
  assignmentCount_DESC = 'assignmentCount_DESC',
  /** from the lowest number of "attachments" to the highest */
  attachmentCount_ASC = 'attachmentCount_ASC',
  /** from the highest number of "attachments" to the lowest */
  attachmentCount_DESC = 'attachmentCount_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest number of "flatAssignments" to the highest */
  flatAssignmentCount_ASC = 'flatAssignmentCount_ASC',
  /** from the highest number of "flatAssignments" to the lowest */
  flatAssignmentCount_DESC = 'flatAssignmentCount_DESC',
  /** from the lowest "legacyId" to the highest */
  legacyId_ASC = 'legacyId_ASC',
  /** from the highest "legacyId" to the lowest */
  legacyId_DESC = 'legacyId_DESC',
  /** from the lowest number of "subjectUsers" to the highest */
  subjectUserCount_ASC = 'subjectUserCount_ASC',
  /** from the highest number of "subjectUsers" to the lowest */
  subjectUserCount_DESC = 'subjectUserCount_DESC',
  /** from the lowest number of "subSubjects" to the highest */
  subSubjectCount_ASC = 'subSubjectCount_ASC',
  /** from the highest number of "subSubjects" to the lowest */
  subSubjectCount_DESC = 'subSubjectCount_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "Subject" with one of the following combination of components' value:
 * - _id
 * - id
 * - title / unit
 * - legacyId / unit
 */
export type SubjectUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<UnitUniqueFilterInput>;
};

/**
 * Given a "unit", identifies exactly one "Subject" with one of the following combination of components' value:
 * - title / (unit)
 * - legacyId / (unit)
 */
export type SubjectUniqueFilterWithoutUnitInput = {
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type SubjectUpdateAssignmentsCreateIfNotExistsInput = {
  data: AssignmentCreationWithoutSubjectInput;
  where: AssignmentUniqueFilterWithoutSubjectInput;
};

export type SubjectUpdateAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutSubjectInput>>;
  createIfNotExists?: InputMaybe<Array<SubjectUpdateAssignmentsCreateIfNotExistsInput>>;
  delete?: InputMaybe<Array<AssignmentUniqueFilterWithoutSubjectInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Array<AssignmentUniqueFilterWithoutSubjectInput>>;
  deleteMany?: InputMaybe<AssignmentFilterInput>;
  update?: InputMaybe<Array<SubjectUpdateAssignmentsUpdateInput>>;
  updateAll?: InputMaybe<AssignmentUpdateWithoutSubjectInput>;
  updateIfExists?: InputMaybe<Array<SubjectUpdateAssignmentsUpdateIfExistsInput>>;
  updateMany?: InputMaybe<SubjectUpdateAssignmentsUpdateManyInput>;
  upsert?: InputMaybe<Array<SubjectUpdateAssignmentsUpsertInput>>;
};

export type SubjectUpdateAssignmentsUpdateIfExistsInput = {
  data?: InputMaybe<AssignmentUpdateWithoutSubjectInput>;
  where: AssignmentUniqueFilterWithoutSubjectInput;
};

export type SubjectUpdateAssignmentsUpdateInput = {
  data?: InputMaybe<AssignmentUpdateWithoutSubjectInput>;
  where: AssignmentUniqueFilterWithoutSubjectInput;
};

export type SubjectUpdateAssignmentsUpdateManyInput = {
  data?: InputMaybe<AssignmentUpdateWithoutSubjectInput>;
  where?: InputMaybe<AssignmentFilterInput>;
};

export type SubjectUpdateAssignmentsUpsertInput = {
  create: AssignmentCreationWithoutSubjectInput;
  update?: InputMaybe<AssignmentUpdateWithoutSubjectInput>;
  where: AssignmentUniqueFilterWithoutSubjectInput;
};

export type SubjectUpdateAttachmentsInput = {
  create?: InputMaybe<Array<AttachmentCreationWithoutSubjectInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<AttachmentFilterInput>;
  updateAll?: InputMaybe<AttachmentUpdateWithoutSubjectInput>;
  updateMany?: InputMaybe<SubjectUpdateAttachmentsUpdateManyInput>;
};

export type SubjectUpdateAttachmentsUpdateManyInput = {
  data?: InputMaybe<AttachmentUpdateWithoutSubjectInput>;
  where?: InputMaybe<AttachmentFilterInput>;
};

/** The "Subject" node's update */
export type SubjectUpdateInput = {
  assignments?: InputMaybe<SubjectUpdateAssignmentsInput>;
  attachments?: InputMaybe<SubjectUpdateAttachmentsInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  isAuto?: InputMaybe<Scalars['Boolean']['input']>;
  isClassified?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaRequest?: InputMaybe<Scalars['String']['input']>;
  subjectCategory?: InputMaybe<SubjectUpdateSubjectCategoryInput>;
  subjectUsers?: InputMaybe<SubjectUpdateSubjectUsersInput>;
  subSubjects?: InputMaybe<SubjectUpdateSubSubjectsInput>;
  synopsis?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<SubjectUpdateUnitInput>;
};

export type SubjectUpdateSubjectCategoryCreateIfNotExistsInput = {
  data: SubjectCategoryCreationInput;
  where: SubjectCategoryUniqueFilterInput;
};

export type SubjectUpdateSubjectCategoryInput = {
  /** Connect a "SubjectCategory" to an existing "Subject" through the "Subject.subjectCategory" edge, throw an error if it does not exist. */
  connect?: InputMaybe<SubjectCategoryUniqueFilterInput>;
  /** Connect a "SubjectCategory" to an existing "Subject" through the "Subject.subjectCategory" edge, if it exists. */
  connectIfExists?: InputMaybe<SubjectCategoryUniqueFilterInput>;
  /** Create a "SubjectCategory" and connect it to an existing "Subject" through the "Subject.subjectCategory" edge. */
  create?: InputMaybe<SubjectCategoryCreationInput>;
  /** Create a "SubjectCategory" if it does not exist, and connect it to an existing "Subject" through the "Subject.subjectCategory" edge. */
  createIfNotExists?: InputMaybe<SubjectUpdateSubjectCategoryCreateIfNotExistsInput>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  disconnectIfExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update the connected "SubjectCategory", throw an error if the "Subject.subjectCategory" edge does not exist. */
  update?: InputMaybe<SubjectCategoryUpdateInput>;
  /** Update the connected "SubjectCategory", if the "Subject.subjectCategory" edge exists. */
  updateIfExists?: InputMaybe<SubjectCategoryUpdateInput>;
};

export type SubjectUpdateSubjectUsersCreateIfNotExistsInput = {
  data: SubjectUserCreationWithoutSubjectInput;
  where: SubjectUserUniqueFilterWithoutSubjectInput;
};

export type SubjectUpdateSubjectUsersInput = {
  create?: InputMaybe<Array<SubjectUserCreationWithoutSubjectInput>>;
  createIfNotExists?: InputMaybe<Array<SubjectUpdateSubjectUsersCreateIfNotExistsInput>>;
  delete?: InputMaybe<Array<SubjectUserUniqueFilterWithoutSubjectInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Array<SubjectUserUniqueFilterWithoutSubjectInput>>;
  deleteMany?: InputMaybe<SubjectUserFilterInput>;
};

export type SubjectUpdateSubSubjectsCreateIfNotExistsInput = {
  data: SubSubjectCreationWithoutSubjectInput;
  where: SubSubjectUniqueFilterWithoutSubjectInput;
};

export type SubjectUpdateSubSubjectsInput = {
  create?: InputMaybe<Array<SubSubjectCreationWithoutSubjectInput>>;
  createIfNotExists?: InputMaybe<Array<SubjectUpdateSubSubjectsCreateIfNotExistsInput>>;
  delete?: InputMaybe<Array<SubSubjectUniqueFilterWithoutSubjectInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Array<SubSubjectUniqueFilterWithoutSubjectInput>>;
  deleteMany?: InputMaybe<SubSubjectFilterInput>;
  update?: InputMaybe<Array<SubjectUpdateSubSubjectsUpdateInput>>;
  updateAll?: InputMaybe<SubSubjectUpdateWithoutSubjectInput>;
  updateIfExists?: InputMaybe<Array<SubjectUpdateSubSubjectsUpdateIfExistsInput>>;
  updateMany?: InputMaybe<SubjectUpdateSubSubjectsUpdateManyInput>;
  upsert?: InputMaybe<Array<SubjectUpdateSubSubjectsUpsertInput>>;
};

export type SubjectUpdateSubSubjectsUpdateIfExistsInput = {
  data?: InputMaybe<SubSubjectUpdateWithoutSubjectInput>;
  where: SubSubjectUniqueFilterWithoutSubjectInput;
};

export type SubjectUpdateSubSubjectsUpdateInput = {
  data?: InputMaybe<SubSubjectUpdateWithoutSubjectInput>;
  where: SubSubjectUniqueFilterWithoutSubjectInput;
};

export type SubjectUpdateSubSubjectsUpdateManyInput = {
  data?: InputMaybe<SubSubjectUpdateWithoutSubjectInput>;
  where?: InputMaybe<SubSubjectFilterInput>;
};

export type SubjectUpdateSubSubjectsUpsertInput = {
  create: SubSubjectCreationWithoutSubjectInput;
  update?: InputMaybe<SubSubjectUpdateWithoutSubjectInput>;
  where: SubSubjectUniqueFilterWithoutSubjectInput;
};

export type SubjectUpdateUnitCreateIfNotExistsInput = {
  data: UnitCreationInput;
  where: UnitUniqueFilterInput;
};

export type SubjectUpdateUnitInput = {
  /** Connect a "Unit" to an existing "Subject" through the "Subject.unit" edge, throw an error if it does not exist. */
  connect?: InputMaybe<UnitUniqueFilterInput>;
  /** Create a "Unit" and connect it to an existing "Subject" through the "Subject.unit" edge. */
  create?: InputMaybe<UnitCreationInput>;
  /** Create a "Unit" if it does not exist, and connect it to an existing "Subject" through the "Subject.unit" edge. */
  createIfNotExists?: InputMaybe<SubjectUpdateUnitCreateIfNotExistsInput>;
  /** Update the connected "Unit", throw an error if the "Subject.unit" edge does not exist. */
  update?: InputMaybe<UnitUpdateInput>;
};

/** The "Subject" node's update */
export type SubjectUpdateWithoutSubjectCategoryInput = {
  assignments?: InputMaybe<SubjectUpdateAssignmentsInput>;
  attachments?: InputMaybe<SubjectUpdateAttachmentsInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  isAuto?: InputMaybe<Scalars['Boolean']['input']>;
  isClassified?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaRequest?: InputMaybe<Scalars['String']['input']>;
  subjectUsers?: InputMaybe<SubjectUpdateSubjectUsersInput>;
  subSubjects?: InputMaybe<SubjectUpdateSubSubjectsInput>;
  synopsis?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<SubjectUpdateUnitInput>;
};

/** The "Subject" node's update */
export type SubjectUpdateWithoutUnitInput = {
  assignments?: InputMaybe<SubjectUpdateAssignmentsInput>;
  attachments?: InputMaybe<SubjectUpdateAttachmentsInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  isAuto?: InputMaybe<Scalars['Boolean']['input']>;
  isClassified?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  mediaRequest?: InputMaybe<Scalars['String']['input']>;
  subjectCategory?: InputMaybe<SubjectUpdateSubjectCategoryInput>;
  subjectUsers?: InputMaybe<SubjectUpdateSubjectUsersInput>;
  subSubjects?: InputMaybe<SubjectUpdateSubSubjectsInput>;
  synopsis?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** A subject shared with a user */
export type SubjectUser = {
  __typename?: 'SubjectUser';
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  subject: Subject;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

/** The "SubjectUser" node's creation */
export type SubjectUserCreationInput = {
  subject: SubjectUserCreationSubjectInput;
  userId: Scalars['String']['input'];
};

export type SubjectUserCreationSubjectCreateIfNotExistsInput = {
  data: SubjectCreationInput;
  where: SubjectUniqueFilterInput;
};

export type SubjectUserCreationSubjectInput = {
  /** Connect a "Subject" to a new "SubjectUser" through the "SubjectUser.subject" edge, throw an error if it does not exist. */
  connect?: InputMaybe<SubjectUniqueFilterInput>;
  /** Create a "Subject" and connect it to a new "SubjectUser" through the "SubjectUser.subject" edge. */
  create?: InputMaybe<SubjectCreationInput>;
  /** Create a "Subject" if it does not exist, and connect it to a new "SubjectUser" through the "SubjectUser.subject" edge. */
  createIfNotExists?: InputMaybe<SubjectUserCreationSubjectCreateIfNotExistsInput>;
};

/** The "SubjectUser" node's creation */
export type SubjectUserCreationWithoutSubjectInput = {
  userId: Scalars['String']['input'];
};

/** The "SubjectUser" nodes' filter */
export type SubjectUserFilterInput = {
  AND?: InputMaybe<Array<InputMaybe<SubjectUserFilterInput>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  NOT?: InputMaybe<SubjectUserFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<SubjectUserFilterInput>>>;
  subject?: InputMaybe<SubjectFilterInput>;
  subject_not?: InputMaybe<SubjectFilterInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userId_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  userId_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  userId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  userId_not?: InputMaybe<Scalars['String']['input']>;
  userId_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  userId_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  userId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  userId_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  userId_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
};

/** Order the "SubjectUser" nodes */
export enum SubjectUserOrderingInput {
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "SubjectUser" with one of the following combination of components' value:
 * - userId / subject
 */
export type SubjectUserUniqueFilterInput = {
  subject: SubjectUniqueFilterInput;
  userId: Scalars['String']['input'];
};

/**
 * Given a "subject", identifies exactly one "SubjectUser" with one of the following combination of components' value:
 * - userId / (subject)
 */
export type SubjectUserUniqueFilterWithoutSubjectInput = {
  userId: Scalars['String']['input'];
};

/** A sub subject */
export type SubSubject = {
  __typename?: 'SubSubject';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  subject: Subject;
  title: Scalars['String']['output'];
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};

/** The "SubSubject" node's creation */
export type SubSubjectCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  subject: SubSubjectCreationSubjectInput;
  title: Scalars['String']['input'];
};

export type SubSubjectCreationSubjectCreateIfNotExistsInput = {
  data: SubjectCreationInput;
  where: SubjectUniqueFilterInput;
};

export type SubSubjectCreationSubjectInput = {
  /** Connect a "Subject" to a new "SubSubject" through the "SubSubject.subject" edge, throw an error if it does not exist. */
  connect?: InputMaybe<SubjectUniqueFilterInput>;
  /** Create a "Subject" and connect it to a new "SubSubject" through the "SubSubject.subject" edge. */
  create?: InputMaybe<SubjectCreationInput>;
  /** Create a "Subject" if it does not exist, and connect it to a new "SubSubject" through the "SubSubject.subject" edge. */
  createIfNotExists?: InputMaybe<SubSubjectCreationSubjectCreateIfNotExistsInput>;
};

/** The "SubSubject" node's creation */
export type SubSubjectCreationWithoutSubjectInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

/** The "SubSubject" nodes' filter */
export type SubSubjectFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<SubSubjectFilterInput>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  NOT?: InputMaybe<SubSubjectFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<SubSubjectFilterInput>>>;
  subject?: InputMaybe<SubjectFilterInput>;
  subject_not?: InputMaybe<SubjectFilterInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "SubSubject" nodes */
export enum SubSubjectOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "SubSubject" with one of the following combination of components' value:
 * - _id
 * - id
 * - title / subject
 */
export type SubSubjectUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  subject?: InputMaybe<SubjectUniqueFilterInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Given a "subject", identifies exactly one "SubSubject" with one of the following combination of components' value:
 * - title / (subject)
 */
export type SubSubjectUniqueFilterWithoutSubjectInput = {
  title: Scalars['String']['input'];
};

/** The "SubSubject" node's update */
export type SubSubjectUpdateInput = {
  subject?: InputMaybe<SubSubjectUpdateSubjectInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type SubSubjectUpdateSubjectCreateIfNotExistsInput = {
  data: SubjectCreationInput;
  where: SubjectUniqueFilterInput;
};

export type SubSubjectUpdateSubjectInput = {
  /** Connect a "Subject" to an existing "SubSubject" through the "SubSubject.subject" edge, throw an error if it does not exist. */
  connect?: InputMaybe<SubjectUniqueFilterInput>;
  /** Create a "Subject" and connect it to an existing "SubSubject" through the "SubSubject.subject" edge. */
  create?: InputMaybe<SubjectCreationInput>;
  /** Create a "Subject" if it does not exist, and connect it to an existing "SubSubject" through the "SubSubject.subject" edge. */
  createIfNotExists?: InputMaybe<SubSubjectUpdateSubjectCreateIfNotExistsInput>;
  /** Update the connected "Subject", throw an error if the "SubSubject.subject" edge does not exist. */
  update?: InputMaybe<SubjectUpdateInput>;
};

/** The "SubSubject" node's update */
export type SubSubjectUpdateWithoutSubjectInput = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum TemplateType {
  ARTICLE_TEMPLATE = 'ARTICLE_TEMPLATE',
  GAME_TEMPLATE = 'GAME_TEMPLATE',
  MULTIPLE_ARTICLE_TEMPLATE = 'MULTIPLE_ARTICLE_TEMPLATE'
}

/** Stock topic piges written by colorfactory using odbc */
export type TopicFotowebPige = {
  __typename?: 'TopicFotowebPige';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  caption?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<Scalars['String']['output']>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  credit?: Maybe<Scalars['String']['output']>;
  dimensions?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fotowebId: Scalars['String']['output'];
  headline?: Maybe<Scalars['String']['output']>;
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  originalName?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Scalars['String']['output']>;
  priceLevel?: Maybe<Scalars['String']['output']>;
  printIssueTitle: Scalars['String']['output'];
  printPublicationKey: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};

/** The "TopicFotowebPige" node's creation */
export type TopicFotowebPigeCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  copyright?: InputMaybe<Scalars['String']['input']>;
  credit?: InputMaybe<Scalars['String']['input']>;
  dimensions?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fotowebId: Scalars['String']['input'];
  headline?: InputMaybe<Scalars['String']['input']>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  priceLevel?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle: Scalars['String']['input'];
  printPublicationKey: Scalars['String']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};

/** The "TopicFotowebPige" nodes' filter */
export type TopicFotowebPigeFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<TopicFotowebPigeFilterInput>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  caption_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  caption_not?: InputMaybe<Scalars['String']['input']>;
  caption_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  caption_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  caption_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  copyright?: InputMaybe<Scalars['String']['input']>;
  copyright_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  copyright_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  copyright_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  copyright_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  copyright_not?: InputMaybe<Scalars['String']['input']>;
  copyright_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  copyright_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  copyright_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  copyright_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  copyright_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  credit?: InputMaybe<Scalars['String']['input']>;
  credit_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  credit_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  credit_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  credit_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  credit_not?: InputMaybe<Scalars['String']['input']>;
  credit_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  credit_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  credit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  credit_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  credit_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  dimensions?: InputMaybe<Scalars['String']['input']>;
  dimensions_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  dimensions_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  dimensions_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dimensions_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  dimensions_not?: InputMaybe<Scalars['String']['input']>;
  dimensions_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  dimensions_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  dimensions_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dimensions_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  dimensions_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileName_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fileName_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fileName_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  fileName_not?: InputMaybe<Scalars['String']['input']>;
  fileName_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fileName_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fileName_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fileName_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fotowebId?: InputMaybe<Scalars['String']['input']>;
  fotowebId_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fotowebId_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fotowebId_in?: InputMaybe<Array<Scalars['String']['input']>>;
  fotowebId_not?: InputMaybe<Scalars['String']['input']>;
  fotowebId_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fotowebId_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fotowebId_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  fotowebId_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  fotowebId_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  headline_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  headline_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  headline_not?: InputMaybe<Scalars['String']['input']>;
  headline_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  headline_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  headline_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  NOT?: InputMaybe<TopicFotowebPigeFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<TopicFotowebPigeFilterInput>>>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  originalName_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  originalName_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  originalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  originalName_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  originalName_not?: InputMaybe<Scalars['String']['input']>;
  originalName_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  originalName_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  originalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  originalName_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  originalName_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  page_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  page_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  page_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  page_not?: InputMaybe<Scalars['String']['input']>;
  page_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  page_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  page_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  page_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  priceLevel?: InputMaybe<Scalars['String']['input']>;
  priceLevel_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  priceLevel_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  priceLevel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  priceLevel_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  priceLevel_not?: InputMaybe<Scalars['String']['input']>;
  priceLevel_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  priceLevel_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  priceLevel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  priceLevel_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  priceLevel_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printIssueTitle?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printIssueTitle_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printIssueTitle_in?: InputMaybe<Array<Scalars['String']['input']>>;
  printIssueTitle_not?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printIssueTitle_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printIssueTitle_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  printIssueTitle_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printIssueTitle_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printPublicationKey?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printPublicationKey_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printPublicationKey_in?: InputMaybe<Array<Scalars['String']['input']>>;
  printPublicationKey_not?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printPublicationKey_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printPublicationKey_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  printPublicationKey_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  printPublicationKey_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  source_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  source_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  source_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  source_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  source_not?: InputMaybe<Scalars['String']['input']>;
  source_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  source_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  source_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  source_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  source_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "TopicFotowebPige" nodes */
export enum TopicFotowebPigeOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "TopicFotowebPige" with one of the following combination of components' value:
 * - _id
 * - id
 * - fotowebId / printIssueTitle
 */
export type TopicFotowebPigeUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  fotowebId?: InputMaybe<Scalars['String']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  printIssueTitle?: InputMaybe<Scalars['String']['input']>;
};

/** The "TopicFotowebPige" node's update */
export type TopicFotowebPigeUpdateInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  copyright?: InputMaybe<Scalars['String']['input']>;
  credit?: InputMaybe<Scalars['String']['input']>;
  dimensions?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fotowebId?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  priceLevel?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

/** A Business Unit (cinema/reality-tv/etc...) */
export type Unit = {
  __typename?: 'Unit';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** Number of "brands" */
  brandCount: Scalars['UnsignedInt']['output'];
  brands: Array<Brand>;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** The public ID */
  id: Scalars['ID']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  photoArchiveId?: Maybe<Scalars['Int']['output']>;
  subjectCategories: Array<SubjectCategory>;
  /** Number of "subjectCategories" */
  subjectCategoryCount: Scalars['UnsignedInt']['output'];
  /** Number of "subjects" */
  subjectCount: Scalars['UnsignedInt']['output'];
  subjects: Array<Subject>;
  title: Scalars['String']['output'];
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<User>;
};


/** A Business Unit (cinema/reality-tv/etc...) */
export type UnitBrandCountArgs = {
  where?: InputMaybe<BrandFilterInput>;
};


/** A Business Unit (cinema/reality-tv/etc...) */
export type UnitBrandsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<BrandOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<BrandFilterInput>;
};


/** A Business Unit (cinema/reality-tv/etc...) */
export type UnitSubjectCategoriesArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectCategoryOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<SubjectCategoryFilterInput>;
};


/** A Business Unit (cinema/reality-tv/etc...) */
export type UnitSubjectCategoryCountArgs = {
  where?: InputMaybe<SubjectCategoryFilterInput>;
};


/** A Business Unit (cinema/reality-tv/etc...) */
export type UnitSubjectCountArgs = {
  where?: InputMaybe<SubjectFilterInput>;
};


/** A Business Unit (cinema/reality-tv/etc...) */
export type UnitSubjectsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<SubjectOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<SubjectFilterInput>;
};

export type UnitCreationBrandsInput = {
  create?: InputMaybe<Array<BrandCreationWithoutUnitInput>>;
};

/** The "Unit" node's creation */
export type UnitCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  brands?: InputMaybe<UnitCreationBrandsInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  subjectCategories?: InputMaybe<UnitCreationSubjectCategoriesInput>;
  subjects?: InputMaybe<UnitCreationSubjectsInput>;
  title: Scalars['String']['input'];
};

export type UnitCreationSubjectCategoriesInput = {
  create?: InputMaybe<Array<SubjectCategoryCreationWithoutUnitInput>>;
};

export type UnitCreationSubjectsInput = {
  create?: InputMaybe<Array<SubjectCreationWithoutUnitInput>>;
};

/** The "Unit" nodes' filter */
export type UnitFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<UnitFilterInput>>>;
  brandCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  brandCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  brandCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  brandCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  brandCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  brandCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  brands_every?: InputMaybe<BrandFilterInput>;
  brands_none?: InputMaybe<BrandFilterInput>;
  brands_some?: InputMaybe<BrandFilterInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  NOT?: InputMaybe<UnitFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<UnitFilterInput>>>;
  subjectCategories_every?: InputMaybe<SubjectCategoryFilterInput>;
  subjectCategories_none?: InputMaybe<SubjectCategoryFilterInput>;
  subjectCategories_some?: InputMaybe<SubjectCategoryFilterInput>;
  subjectCategoryCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCategoryCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCategoryCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCategoryCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCategoryCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCategoryCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjectCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  subjects_every?: InputMaybe<SubjectFilterInput>;
  subjects_none?: InputMaybe<SubjectFilterInput>;
  subjects_some?: InputMaybe<SubjectFilterInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_gt?: InputMaybe<Scalars['String']['input']>;
  title_gte?: InputMaybe<Scalars['String']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_lt?: InputMaybe<Scalars['String']['input']>;
  title_lte?: InputMaybe<Scalars['String']['input']>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "Unit" nodes */
export enum UnitOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest number of "brands" to the highest */
  brandCount_ASC = 'brandCount_ASC',
  /** from the highest number of "brands" to the lowest */
  brandCount_DESC = 'brandCount_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest number of "subjectCategories" to the highest */
  subjectCategoryCount_ASC = 'subjectCategoryCount_ASC',
  /** from the highest number of "subjectCategories" to the lowest */
  subjectCategoryCount_DESC = 'subjectCategoryCount_DESC',
  /** from the lowest number of "subjects" to the highest */
  subjectCount_ASC = 'subjectCount_ASC',
  /** from the highest number of "subjects" to the lowest */
  subjectCount_DESC = 'subjectCount_DESC',
  /** from the lowest "title" to the highest */
  title_ASC = 'title_ASC',
  /** from the highest "title" to the lowest */
  title_DESC = 'title_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "Unit" with one of the following combination of components' value:
 * - _id
 * - id
 */
export type UnitUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UnitUpdateBrandsInput = {
  create?: InputMaybe<Array<BrandCreationWithoutUnitInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<BrandFilterInput>;
  updateAll?: InputMaybe<BrandUpdateWithoutUnitInput>;
  updateMany?: InputMaybe<UnitUpdateBrandsUpdateManyInput>;
};

export type UnitUpdateBrandsUpdateManyInput = {
  data?: InputMaybe<BrandUpdateWithoutUnitInput>;
  where?: InputMaybe<BrandFilterInput>;
};

/** The "Unit" node's update */
export type UnitUpdateInput = {
  brands?: InputMaybe<UnitUpdateBrandsInput>;
  subjectCategories?: InputMaybe<UnitUpdateSubjectCategoriesInput>;
  subjects?: InputMaybe<UnitUpdateSubjectsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UnitUpdateSubjectCategoriesInput = {
  create?: InputMaybe<Array<SubjectCategoryCreationWithoutUnitInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<SubjectCategoryFilterInput>;
  updateAll?: InputMaybe<SubjectCategoryUpdateWithoutUnitInput>;
  updateMany?: InputMaybe<UnitUpdateSubjectCategoriesUpdateManyInput>;
};

export type UnitUpdateSubjectCategoriesUpdateManyInput = {
  data?: InputMaybe<SubjectCategoryUpdateWithoutUnitInput>;
  where?: InputMaybe<SubjectCategoryFilterInput>;
};

export type UnitUpdateSubjectsCreateIfNotExistsInput = {
  data: SubjectCreationWithoutUnitInput;
  where: SubjectUniqueFilterWithoutUnitInput;
};

export type UnitUpdateSubjectsInput = {
  create?: InputMaybe<Array<SubjectCreationWithoutUnitInput>>;
  createIfNotExists?: InputMaybe<Array<UnitUpdateSubjectsCreateIfNotExistsInput>>;
  delete?: InputMaybe<Array<SubjectUniqueFilterWithoutUnitInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteIfExists?: InputMaybe<Array<SubjectUniqueFilterWithoutUnitInput>>;
  deleteMany?: InputMaybe<SubjectFilterInput>;
  update?: InputMaybe<Array<UnitUpdateSubjectsUpdateInput>>;
  updateAll?: InputMaybe<SubjectUpdateWithoutUnitInput>;
  updateIfExists?: InputMaybe<Array<UnitUpdateSubjectsUpdateIfExistsInput>>;
  updateMany?: InputMaybe<UnitUpdateSubjectsUpdateManyInput>;
  upsert?: InputMaybe<Array<UnitUpdateSubjectsUpsertInput>>;
};

export type UnitUpdateSubjectsUpdateIfExistsInput = {
  data?: InputMaybe<SubjectUpdateWithoutUnitInput>;
  where: SubjectUniqueFilterWithoutUnitInput;
};

export type UnitUpdateSubjectsUpdateInput = {
  data?: InputMaybe<SubjectUpdateWithoutUnitInput>;
  where: SubjectUniqueFilterWithoutUnitInput;
};

export type UnitUpdateSubjectsUpdateManyInput = {
  data?: InputMaybe<SubjectUpdateWithoutUnitInput>;
  where?: InputMaybe<SubjectFilterInput>;
};

export type UnitUpdateSubjectsUpsertInput = {
  create: SubjectCreationWithoutUnitInput;
  update?: InputMaybe<SubjectUpdateWithoutUnitInput>;
  where: SubjectUniqueFilterWithoutUnitInput;
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
};

/** A Website: Femme Actuelle, Télé-loisir, Capital */
export type Website = {
  __typename?: 'Website';
  /** The private ID */
  _id: Scalars['UnsignedInt']['output'];
  /** Number of "assignments" */
  assignmentCount: Scalars['UnsignedInt']['output'];
  assignments: Array<Assignment>;
  brand: Brand;
  /** The date and time, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The user who created the Element */
  creatorId: Scalars['ID']['output'];
  /** Number of "flatAssignments" */
  flatAssignmentCount: Scalars['UnsignedInt']['output'];
  flatAssignments: Array<FlatAssignment>;
  /** The public ID */
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  /** The last user who updated the Element */
  lastOperatorId: Scalars['ID']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  /** The date and time of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};


/** A Website: Femme Actuelle, Télé-loisir, Capital */
export type WebsiteAssignmentCountArgs = {
  where?: InputMaybe<AssignmentFilterInput>;
};


/** A Website: Femme Actuelle, Télé-loisir, Capital */
export type WebsiteAssignmentsArgs = {
  first: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<AssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<AssignmentFilterInput>;
};


/** A Website: Femme Actuelle, Télé-loisir, Capital */
export type WebsiteFlatAssignmentCountArgs = {
  where?: InputMaybe<FlatAssignmentFilterInput>;
};


/** A Website: Femme Actuelle, Télé-loisir, Capital */
export type WebsiteFlatAssignmentsArgs = {
  first?: Scalars['UnsignedInt']['input'];
  orderBy?: InputMaybe<Array<FlatAssignmentOrderingInput>>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  where?: InputMaybe<FlatAssignmentFilterInput>;
};

export type WebsiteCreationAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutWebsiteInput>>;
};

export type WebsiteCreationBrandCreateIfNotExistsInput = {
  data: BrandCreationInput;
  where: BrandUniqueFilterInput;
};

export type WebsiteCreationBrandInput = {
  /** Connect a "Brand" to a new "Website" through the "Website.brand" edge, throw an error if it does not exist. */
  connect?: InputMaybe<BrandUniqueFilterInput>;
  /** Create a "Brand" and connect it to a new "Website" through the "Website.brand" edge. */
  create?: InputMaybe<BrandCreationInput>;
  /** Create a "Brand" if it does not exist, and connect it to a new "Website" through the "Website.brand" edge. */
  createIfNotExists?: InputMaybe<WebsiteCreationBrandCreateIfNotExistsInput>;
};

/** The "Website" node's creation */
export type WebsiteCreationInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<WebsiteCreationAssignmentsInput>;
  brand: WebsiteCreationBrandInput;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  key: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

/** The "Website" node's creation */
export type WebsiteCreationWithoutBrandInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments?: InputMaybe<WebsiteCreationAssignmentsInput>;
  /** You can either provide an UUID or let one be generated for you */
  id?: InputMaybe<Scalars['ID']['input']>;
  key: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

/** The "Website" nodes' filter */
export type WebsiteFilterInput = {
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  _id_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['UnsignedInt']['input']>>;
  AND?: InputMaybe<Array<InputMaybe<WebsiteFilterInput>>>;
  assignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  assignments_every?: InputMaybe<AssignmentFilterInput>;
  assignments_none?: InputMaybe<AssignmentFilterInput>;
  assignments_some?: InputMaybe<AssignmentFilterInput>;
  brand?: InputMaybe<BrandFilterInput>;
  brand_not?: InputMaybe<BrandFilterInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  creatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  creatorId_not?: InputMaybe<Scalars['ID']['input']>;
  creatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  flatAssignmentCount?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_gte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lt?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_lte?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignmentCount_not?: InputMaybe<Scalars['UnsignedInt']['input']>;
  flatAssignments_every?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_none?: InputMaybe<FlatAssignmentFilterInput>;
  flatAssignments_some?: InputMaybe<FlatAssignmentFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  key_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  key_gt?: InputMaybe<Scalars['String']['input']>;
  key_gte?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_lt?: InputMaybe<Scalars['String']['input']>;
  key_lte?: InputMaybe<Scalars['String']['input']>;
  key_not?: InputMaybe<Scalars['String']['input']>;
  key_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  key_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  key_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  lastOperatorId?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastOperatorId_not?: InputMaybe<Scalars['ID']['input']>;
  lastOperatorId_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_gte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  legacyId_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId_lt?: InputMaybe<Scalars['Int']['input']>;
  legacyId_lte?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not?: InputMaybe<Scalars['Int']['input']>;
  legacyId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  NOT?: InputMaybe<WebsiteFilterInput>;
  OR?: InputMaybe<Array<InputMaybe<WebsiteFilterInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_ends_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  title_not_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title_starts_with?: InputMaybe<Scalars['NonEmptyString']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Order the "Website" nodes */
export enum WebsiteOrderingInput {
  /** from the lowest "_id" to the highest */
  _id_ASC = '_id_ASC',
  /** from the highest "_id" to the lowest */
  _id_DESC = '_id_DESC',
  /** from the lowest number of "assignments" to the highest */
  assignmentCount_ASC = 'assignmentCount_ASC',
  /** from the highest number of "assignments" to the lowest */
  assignmentCount_DESC = 'assignmentCount_DESC',
  /** from the lowest "createdAt" to the highest */
  createdAt_ASC = 'createdAt_ASC',
  /** from the highest "createdAt" to the lowest */
  createdAt_DESC = 'createdAt_DESC',
  /** from the lowest number of "flatAssignments" to the highest */
  flatAssignmentCount_ASC = 'flatAssignmentCount_ASC',
  /** from the highest number of "flatAssignments" to the lowest */
  flatAssignmentCount_DESC = 'flatAssignmentCount_DESC',
  /** from the lowest "key" to the highest */
  key_ASC = 'key_ASC',
  /** from the highest "key" to the lowest */
  key_DESC = 'key_DESC',
  /** from the lowest "legacyId" to the highest */
  legacyId_ASC = 'legacyId_ASC',
  /** from the highest "legacyId" to the lowest */
  legacyId_DESC = 'legacyId_DESC',
  /** from the lowest "updatedAt" to the highest */
  updatedAt_ASC = 'updatedAt_ASC',
  /** from the highest "updatedAt" to the lowest */
  updatedAt_DESC = 'updatedAt_DESC'
}

/**
 * Identifies exactly one "Website" with one of the following combination of components' value:
 * - _id
 * - id
 * - key
 * - legacyId / brand
 * - title / brand
 */
export type WebsiteUniqueFilterInput = {
  /** The private ID */
  _id?: InputMaybe<Scalars['UnsignedInt']['input']>;
  brand?: InputMaybe<BrandUniqueFilterInput>;
  /** The public ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Given a "brand", identifies exactly one "Website" with one of the following combination of components' value:
 * - legacyId / (brand)
 * - title / (brand)
 */
export type WebsiteUniqueFilterWithoutBrandInput = {
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type WebsiteUpdateAssignmentsInput = {
  create?: InputMaybe<Array<AssignmentCreationWithoutWebsiteInput>>;
  deleteAll?: InputMaybe<Scalars['Boolean']['input']>;
  deleteMany?: InputMaybe<AssignmentFilterInput>;
  updateAll?: InputMaybe<AssignmentUpdateWithoutWebsiteInput>;
  updateMany?: InputMaybe<WebsiteUpdateAssignmentsUpdateManyInput>;
};

export type WebsiteUpdateAssignmentsUpdateManyInput = {
  data?: InputMaybe<AssignmentUpdateWithoutWebsiteInput>;
  where?: InputMaybe<AssignmentFilterInput>;
};

export type WebsiteUpdateBrandCreateIfNotExistsInput = {
  data: BrandCreationInput;
  where: BrandUniqueFilterInput;
};

export type WebsiteUpdateBrandInput = {
  /** Connect a "Brand" to an existing "Website" through the "Website.brand" edge, throw an error if it does not exist. */
  connect?: InputMaybe<BrandUniqueFilterInput>;
  /** Create a "Brand" and connect it to an existing "Website" through the "Website.brand" edge. */
  create?: InputMaybe<BrandCreationInput>;
  /** Create a "Brand" if it does not exist, and connect it to an existing "Website" through the "Website.brand" edge. */
  createIfNotExists?: InputMaybe<WebsiteUpdateBrandCreateIfNotExistsInput>;
  /** Update the connected "Brand", throw an error if the "Website.brand" edge does not exist. */
  update?: InputMaybe<BrandUpdateInput>;
};

/** The "Website" node's update */
export type WebsiteUpdateInput = {
  assignments?: InputMaybe<WebsiteUpdateAssignmentsInput>;
  brand?: InputMaybe<WebsiteUpdateBrandInput>;
  key?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The "Website" node's update */
export type WebsiteUpdateWithoutBrandInput = {
  assignments?: InputMaybe<WebsiteUpdateAssignmentsInput>;
  key?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type EmailArticleMutation_mutation_emailRawArticle_EmailRawArticleResultType = {
  __typename?: 'EmailRawArticleResultType',
  success: boolean
};

export type EmailArticleMutation_mutation = {
  __typename?: 'mutation',
  emailRawArticle: EmailArticleMutation_mutation_emailRawArticle_EmailRawArticleResultType
};


export type EmailArticleMutationVariables = Exact<{
  rawArticle: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']> | Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
}>;


export type EmailArticleMutation = EmailArticleMutation_mutation;

export type EmailSubjectMutation_mutation_emailSubject_EmailSubjectResultType = {
  __typename?: 'EmailSubjectResultType',
  success: boolean
};

export type EmailSubjectMutation_mutation = {
  __typename?: 'mutation',
  emailSubject: EmailSubjectMutation_mutation_emailSubject_EmailSubjectResultType
};


export type EmailSubjectMutationVariables = Exact<{
  subject: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']> | Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
}>;


export type EmailSubjectMutation = EmailSubjectMutation_mutation;

export type ExportToCentshareMutation_mutation_exportToCentshare_ExportToCenshareResultType = {
  __typename?: 'ExportToCenshareResultType',
  message: string
};

export type ExportToCentshareMutation_mutation = {
  __typename?: 'mutation',
  exportToCentshare: ExportToCentshareMutation_mutation_exportToCentshare_ExportToCenshareResultType
};


export type ExportToCentshareMutationVariables = Exact<{
  assignment: Scalars['String']['input'];
  issue: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  brand: Scalars['String']['input'];
  photos?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type ExportToCentshareMutation = ExportToCentshareMutation_mutation;

export type ExportPrintIssueToCenshareMutation_mutation_exportPrintIssueToCenshare_ExportPrintIssueToCenshareResultType = {
  __typename?: 'ExportPrintIssueToCenshareResultType',
  message: string
};

export type ExportPrintIssueToCenshareMutation_mutation = {
  __typename?: 'mutation',
  exportPrintIssueToCenshare: ExportPrintIssueToCenshareMutation_mutation_exportPrintIssueToCenshare_ExportPrintIssueToCenshareResultType
};


export type ExportPrintIssueToCenshareMutationVariables = Exact<{
  printIssueId: Scalars['String']['input'];
}>;


export type ExportPrintIssueToCenshareMutation = ExportPrintIssueToCenshareMutation_mutation;

export type UpdateAssignmentPageNumberMutation_mutation_updateAssignment_Assignment = {
  __typename?: 'Assignment',
  id: string
};

export type UpdateAssignmentPageNumberMutation_mutation = {
  __typename?: 'mutation',
  updateAssignment: UpdateAssignmentPageNumberMutation_mutation_updateAssignment_Assignment
};


export type UpdateAssignmentPageNumberMutationVariables = Exact<{
  assignmentId: Scalars['ID']['input'];
  pageNumber: Scalars['Int']['input'];
}>;


export type UpdateAssignmentPageNumberMutation = UpdateAssignmentPageNumberMutation_mutation;

export type UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versions_RawArticleVersion_editor_User = {
  __typename?: 'User',
  id: string,
  name: string,
  avatarUrl?: string | null
};

export type UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versions_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  allowedStatuses: Array<RawArticleStatus>,
  id: string,
  title?: string | null,
  lead?: string | null,
  body?: string | null,
  signature?: string | null,
  leftOver?: string | null,
  status: RawArticleStatus,
  createdAt: string,
  textIsReadOnly: boolean,
  editor?: UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versions_RawArticleVersion_editor_User | null
};

export type UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versionList_RawArticleVersion_editor_User = {
  __typename?: 'User',
  id: string,
  name: string,
  avatarUrl?: string | null
};

export type UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versionList_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  status: RawArticleStatus,
  editorId: string,
  editor?: UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versionList_RawArticleVersion_editor_User | null
};

export type UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string,
  versions: Array<UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versions_RawArticleVersion>,
  versionList: Array<UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versionList_RawArticleVersion>
};

export type UpdateAssignmentMutation_mutation_updateAssignment_Assignment = {
  __typename?: 'Assignment',
  id: string,
  rawArticle?: UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle | null
};

export type UpdateAssignmentMutation_mutation = {
  __typename?: 'mutation',
  updateAssignment: UpdateAssignmentMutation_mutation_updateAssignment_Assignment
};


export type UpdateAssignmentMutationVariables = Exact<{
  where: AssignmentUniqueFilterInput;
  data: AssignmentUpdateInput;
}>;


export type UpdateAssignmentMutation = UpdateAssignmentMutation_mutation;

export type GetAssignmentQuery_query_assignment_Assignment_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string
};

export type GetAssignmentQuery_query_assignment_Assignment_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type GetAssignmentQuery_query_assignment_Assignment_subject_Subject_unit_Unit = {
  __typename?: 'Unit',
  id: string,
  title: string
};

export type GetAssignmentQuery_query_assignment_Assignment_subject_Subject = {
  __typename?: 'Subject',
  id: string,
  title: string,
  unit: GetAssignmentQuery_query_assignment_Assignment_subject_Subject_unit_Unit
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_paragraphStyles_PrintTemplateFieldStyle = {
  __typename?: 'PrintTemplateFieldStyle',
  id: string,
  name: string
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_characterStyles_PrintTemplateFieldStyle = {
  __typename?: 'PrintTemplateFieldStyle',
  id: string,
  name: string
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_coordinates_PrintTemplateObjectCoords = {
  __typename?: 'PrintTemplateObjectCoords',
  x?: number | null,
  y?: number | null,
  width?: number | null,
  height?: number | null,
  rotation?: number | null
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords = {
  __typename?: 'PrintTemplateObjectCoords',
  x?: number | null,
  y?: number | null,
  width?: number | null,
  height?: number | null,
  rotation?: number | null
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage = {
  __typename?: 'PrintTemplatePage',
  offset: number,
  number: string,
  coordinates: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField = {
  __typename?: 'PrintTemplateField',
  id: number,
  name: string,
  type: PrintTemplateFieldType,
  enabled?: boolean | null,
  data: string,
  typeVariation: string,
  paragraphStyles: Array<GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_paragraphStyles_PrintTemplateFieldStyle>,
  characterStyles: Array<GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_characterStyles_PrintTemplateFieldStyle>,
  coordinates: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_coordinates_PrintTemplateObjectCoords,
  page: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_createdBy_User = {
  __typename?: 'User',
  name: string
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_updatedBy_User = {
  __typename?: 'User',
  name: string
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string,
  S3ObjectId: string,
  label: string,
  filename: string,
  type?: TemplateType | null,
  filesize?: number | null,
  pageCount?: number | null,
  extVersion?: string | null,
  data: string,
  createdAt: string,
  updatedAt: string,
  fields: Array<GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField | null>,
  createdBy?: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_createdBy_User | null,
  updatedBy?: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_updatedBy_User | null
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  id: string,
  active: boolean,
  printTemplate: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication
};

export type GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  autoPrototype?: boolean | null,
  printTemplates: Array<GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate>,
  printIssue: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue
};

export type GetAssignmentQuery_query_assignment_Assignment = {
  __typename?: 'Assignment',
  id: string,
  printTemplate?: GetAssignmentQuery_query_assignment_Assignment_printTemplate_PrintTemplate | null,
  printPublication?: GetAssignmentQuery_query_assignment_Assignment_printPublication_PrintPublication | null,
  subject: GetAssignmentQuery_query_assignment_Assignment_subject_Subject,
  printHeading?: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading | null
};

export type GetAssignmentQuery_query = {
  __typename?: 'query',
  assignment: GetAssignmentQuery_query_assignment_Assignment
};


export type GetAssignmentQueryVariables = Exact<{
  assignmentId: Scalars['ID']['input'];
}>;


export type GetAssignmentQuery = GetAssignmentQuery_query;

export type GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string,
  type?: TemplateType | null
};

export type GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_printTemplates_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  printTemplate: GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate
};

export type GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  autoPrototype?: boolean | null,
  printTemplates: Array<GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_printTemplates_PrintHeadingPrintTemplate>
};

export type GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printHeadings: Array<GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading>
};

export type GetPrintHeadingByPrintIssueQuery_query = {
  __typename?: 'query',
  printIssue: GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue
};


export type GetPrintHeadingByPrintIssueQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPrintHeadingByPrintIssueQuery = GetPrintHeadingByPrintIssueQuery_query;

export type GetPrintIssuesByPrintPublicationQuery_query_printPublication_PrintPublication_printIssues_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string
};

export type GetPrintIssuesByPrintPublicationQuery_query_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string,
  printIssues: Array<GetPrintIssuesByPrintPublicationQuery_query_printPublication_PrintPublication_printIssues_PrintIssue>
};

export type GetPrintIssuesByPrintPublicationQuery_query = {
  __typename?: 'query',
  printPublication: GetPrintIssuesByPrintPublicationQuery_query_printPublication_PrintPublication
};


export type GetPrintIssuesByPrintPublicationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type GetPrintIssuesByPrintPublicationQuery = GetPrintIssuesByPrintPublicationQuery_query;

export type GetPrintPublicationByUnitQuery_query_units_Unit_brands_Brand_printPublications_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type GetPrintPublicationByUnitQuery_query_units_Unit_brands_Brand_websites_Website = {
  __typename?: 'Website',
  id: string,
  title: string,
  key: string
};

export type GetPrintPublicationByUnitQuery_query_units_Unit_brands_Brand = {
  __typename?: 'Brand',
  id: string,
  title: string,
  printPublications: Array<GetPrintPublicationByUnitQuery_query_units_Unit_brands_Brand_printPublications_PrintPublication>,
  websites: Array<GetPrintPublicationByUnitQuery_query_units_Unit_brands_Brand_websites_Website>
};

export type GetPrintPublicationByUnitQuery_query_units_Unit = {
  __typename?: 'Unit',
  id: string,
  title: string,
  brands: Array<GetPrintPublicationByUnitQuery_query_units_Unit_brands_Brand>
};

export type GetPrintPublicationByUnitQuery_query = {
  __typename?: 'query',
  units: Array<GetPrintPublicationByUnitQuery_query_units_Unit>
};


export type GetPrintPublicationByUnitQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
}>;


export type GetPrintPublicationByUnitQuery = GetPrintPublicationByUnitQuery_query;

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_assignments_Assignment_articleTemplate_ArticleTemplate = {
  __typename?: 'ArticleTemplate',
  id: string
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_assignments_Assignment_subject_Subject = {
  __typename?: 'Subject',
  id: string,
  title: string
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_assignments_Assignment_rawArticle_RawArticle_lastVersion_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  title?: string | null,
  lead?: string | null,
  body?: string | null,
  signature?: string | null,
  leftOver?: string | null,
  status: RawArticleStatus,
  textIsReadOnly: boolean
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_assignments_Assignment_rawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string,
  lastVersion?: GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_assignments_Assignment_rawArticle_RawArticle_lastVersion_RawArticleVersion | null
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_assignments_Assignment = {
  __typename?: 'Assignment',
  id: string,
  articleTemplate?: GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_assignments_Assignment_articleTemplate_ArticleTemplate | null,
  subject: GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_assignments_Assignment_subject_Subject,
  rawArticle?: GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_assignments_Assignment_rawArticle_RawArticle | null
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords = {
  __typename?: 'PrintTemplateObjectCoords',
  x?: number | null,
  y?: number | null,
  width?: number | null,
  height?: number | null,
  rotation?: number | null
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_fields_PrintTemplateField_page_PrintTemplatePage = {
  __typename?: 'PrintTemplatePage',
  offset: number,
  number: string,
  coordinates: GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_fields_PrintTemplateField = {
  __typename?: 'PrintTemplateField',
  id: number,
  name: string,
  type: PrintTemplateFieldType,
  data: string,
  enabled?: boolean | null,
  page: GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_fields_PrintTemplateField_page_PrintTemplatePage
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_assignments_Assignment_subject_Subject = {
  __typename?: 'Subject',
  id: string,
  title: string
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_assignments_Assignment_rawArticle_RawArticle_lastVersion_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  status: RawArticleStatus,
  body?: string | null
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_assignments_Assignment_rawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string,
  lastVersion?: GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_assignments_Assignment_rawArticle_RawArticle_lastVersion_RawArticleVersion | null
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_assignments_Assignment = {
  __typename?: 'Assignment',
  id: string,
  subject: GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_assignments_Assignment_subject_Subject,
  rawArticle?: GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_assignments_Assignment_rawArticle_RawArticle | null
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate = {
  __typename?: 'ArticleTemplate',
  id: string,
  label?: string | null,
  block?: string | null,
  fields: Array<GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_fields_PrintTemplateField | null>,
  assignments: Array<GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate_assignments_Assignment>
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string,
  label: string,
  type?: TemplateType | null,
  articleTemplates: Array<GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate_articleTemplates_ArticleTemplate>
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  id: string,
  active: boolean,
  printTemplate: GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate
};

export type GetPrintHeadingByIdQuery_query_printHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  title: string,
  createdAt: string,
  assignments: Array<GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_assignments_Assignment>,
  printTemplates: Array<GetPrintHeadingByIdQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate>
};

export type GetPrintHeadingByIdQuery_query = {
  __typename?: 'query',
  printHeading: GetPrintHeadingByIdQuery_query_printHeading_PrintHeading
};


export type GetPrintHeadingByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPrintHeadingByIdQuery = GetPrintHeadingByIdQuery_query;

export type PrintHeadingPrintTemplateFragment = {
  __typename?: 'PrintHeadingPrintTemplate',
  printTemplate: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate
};

export type PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField_paragraphStyles_PrintTemplateFieldStyle = {
  __typename?: 'PrintTemplateFieldStyle',
  id: string,
  name: string
};

export type PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField_characterStyles_PrintTemplateFieldStyle = {
  __typename?: 'PrintTemplateFieldStyle',
  id: string,
  name: string
};

export type PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField_coordinates_PrintTemplateObjectCoords = {
  __typename?: 'PrintTemplateObjectCoords',
  x?: number | null,
  y?: number | null,
  width?: number | null,
  height?: number | null,
  rotation?: number | null
};

export type PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords = {
  __typename?: 'PrintTemplateObjectCoords',
  x?: number | null,
  y?: number | null,
  width?: number | null,
  height?: number | null,
  rotation?: number | null
};

export type PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage = {
  __typename?: 'PrintTemplatePage',
  offset: number,
  number: string,
  coordinates: PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords
};

export type PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField = {
  __typename?: 'PrintTemplateField',
  id: number,
  name: string,
  type: PrintTemplateFieldType,
  enabled?: boolean | null,
  data: string,
  typeVariation: string,
  paragraphStyles: Array<PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField_paragraphStyles_PrintTemplateFieldStyle>,
  characterStyles: Array<PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField_characterStyles_PrintTemplateFieldStyle>,
  coordinates: PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField_coordinates_PrintTemplateObjectCoords,
  page: PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage
};

export type PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication_brand_Brand = {
  __typename?: 'Brand',
  id: string,
  title: string
};

export type PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string,
  brand: PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication_brand_Brand
};

export type PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate_printHeading_PrintHeading_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication
};

export type PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate_printHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  printIssue: PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate_printHeading_PrintHeading_printIssue_PrintIssue
};

export type PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  id: string,
  active: boolean,
  printHeading: PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate_printHeading_PrintHeading
};

export type PrintTemplateFragment_PrintTemplate_createdBy_User = {
  __typename?: 'User',
  name: string
};

export type PrintTemplateFragment_PrintTemplate_updatedBy_User = {
  __typename?: 'User',
  name: string
};

export type PrintTemplateFragment = {
  __typename?: 'PrintTemplate',
  id: string,
  S3ObjectId: string,
  label: string,
  filename: string,
  type?: TemplateType | null,
  filesize?: number | null,
  pageCount?: number | null,
  extVersion?: string | null,
  data: string,
  createdAt: string,
  updatedAt: string,
  fields: Array<PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField | null>,
  printHeadings: Array<PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate>,
  createdBy?: PrintTemplateFragment_PrintTemplate_createdBy_User | null,
  updatedBy?: PrintTemplateFragment_PrintTemplate_updatedBy_User | null
};

export type CreatePrintTemplateMutation_mutation_createPrintTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string,
  S3ObjectId: string,
  label: string,
  filename: string,
  type?: TemplateType | null,
  filesize?: number | null,
  pageCount?: number | null,
  extVersion?: string | null,
  data: string,
  createdAt: string,
  updatedAt: string,
  fields: Array<PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField | null>,
  printHeadings: Array<PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate>,
  createdBy?: PrintTemplateFragment_PrintTemplate_createdBy_User | null,
  updatedBy?: PrintTemplateFragment_PrintTemplate_updatedBy_User | null
};

export type CreatePrintTemplateMutation_mutation = {
  __typename?: 'mutation',
  createPrintTemplate: CreatePrintTemplateMutation_mutation_createPrintTemplate_PrintTemplate
};


export type CreatePrintTemplateMutationVariables = Exact<{
  data: PrintTemplateCreationInput;
}>;


export type CreatePrintTemplateMutation = CreatePrintTemplateMutation_mutation;

export type UpdatePrintTemplateMutation_mutation_updatePrintTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string,
  S3ObjectId: string,
  label: string,
  filename: string,
  type?: TemplateType | null,
  filesize?: number | null,
  pageCount?: number | null,
  extVersion?: string | null,
  data: string,
  createdAt: string,
  updatedAt: string,
  fields: Array<PrintTemplateFragment_PrintTemplate_fields_PrintTemplateField | null>,
  printHeadings: Array<PrintTemplateFragment_PrintTemplate_printHeadings_PrintHeadingPrintTemplate>,
  createdBy?: PrintTemplateFragment_PrintTemplate_createdBy_User | null,
  updatedBy?: PrintTemplateFragment_PrintTemplate_updatedBy_User | null
};

export type UpdatePrintTemplateMutation_mutation = {
  __typename?: 'mutation',
  updatePrintTemplate: UpdatePrintTemplateMutation_mutation_updatePrintTemplate_PrintTemplate
};


export type UpdatePrintTemplateMutationVariables = Exact<{
  data: PrintTemplateUpdateInput;
  where: PrintTemplateUniqueFilterInput;
}>;


export type UpdatePrintTemplateMutation = UpdatePrintTemplateMutation_mutation;

export type DetachPrintTemplateMutation_mutation_deletePrintHeadingPrintTemplate_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  id: string
};

export type DetachPrintTemplateMutation_mutation = {
  __typename?: 'mutation',
  deletePrintHeadingPrintTemplate: DetachPrintTemplateMutation_mutation_deletePrintHeadingPrintTemplate_PrintHeadingPrintTemplate
};


export type DetachPrintTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DetachPrintTemplateMutation = DetachPrintTemplateMutation_mutation;

export type UpdatePrintHeadingPrintTemplateMutation_mutation_updatePrintHeadingPrintTemplate_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  id: string,
  active: boolean
};

export type UpdatePrintHeadingPrintTemplateMutation_mutation = {
  __typename?: 'mutation',
  updatePrintHeadingPrintTemplate: UpdatePrintHeadingPrintTemplateMutation_mutation_updatePrintHeadingPrintTemplate_PrintHeadingPrintTemplate
};


export type UpdatePrintHeadingPrintTemplateMutationVariables = Exact<{
  data: PrintHeadingPrintTemplateUpdateInput;
  where: PrintHeadingPrintTemplateUniqueFilterInput;
}>;


export type UpdatePrintHeadingPrintTemplateMutation = UpdatePrintHeadingPrintTemplateMutation_mutation;

export type GetPrintHeadingQuery_query_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication_brand_Brand_unit_Unit = {
  __typename?: 'Unit',
  id: string,
  title: string
};

export type GetPrintHeadingQuery_query_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication_brand_Brand = {
  __typename?: 'Brand',
  id: string,
  title: string,
  unit: GetPrintHeadingQuery_query_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication_brand_Brand_unit_Unit
};

export type GetPrintHeadingQuery_query_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string,
  brand: GetPrintHeadingQuery_query_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication_brand_Brand
};

export type GetPrintHeadingQuery_query_printHeading_PrintHeading_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: GetPrintHeadingQuery_query_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication
};

export type GetPrintHeadingQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  id: string,
  active: boolean,
  printTemplate: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate
};

export type GetPrintHeadingQuery_query_printHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  order: number,
  legacyId?: number | null,
  hasAutomaticSubject: boolean,
  createdAt: string,
  updatedAt: string,
  printTemplateCount: number,
  printIssue: GetPrintHeadingQuery_query_printHeading_PrintHeading_printIssue_PrintIssue,
  printTemplates: Array<GetPrintHeadingQuery_query_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate>
};

export type GetPrintHeadingQuery_query = {
  __typename?: 'query',
  printHeading: GetPrintHeadingQuery_query_printHeading_PrintHeading
};


export type GetPrintHeadingQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetPrintHeadingQuery = GetPrintHeadingQuery_query;

export type GetPrintTemplateDownloadUrlQuery_query_printTemplateDownload_PrintTemplateDownloadResultType = {
  __typename?: 'PrintTemplateDownloadResultType',
  id: string,
  url: string
};

export type GetPrintTemplateDownloadUrlQuery_query = {
  __typename?: 'query',
  printTemplateDownload: GetPrintTemplateDownloadUrlQuery_query_printTemplateDownload_PrintTemplateDownloadResultType
};


export type GetPrintTemplateDownloadUrlQueryVariables = Exact<{
  printTemplateId: Scalars['String']['input'];
}>;


export type GetPrintTemplateDownloadUrlQuery = GetPrintTemplateDownloadUrlQuery_query;

export type GetPrintTemplateUploadUrlQuery_query_printTemplateUpload_PrintTemplateUploadResultType = {
  __typename?: 'PrintTemplateUploadResultType',
  id: string,
  url: string
};

export type GetPrintTemplateUploadUrlQuery_query = {
  __typename?: 'query',
  printTemplateUpload: GetPrintTemplateUploadUrlQuery_query_printTemplateUpload_PrintTemplateUploadResultType
};


export type GetPrintTemplateUploadUrlQueryVariables = Exact<{
  S3ObjectId: Scalars['String']['input'];
  filename: Scalars['String']['input'];
}>;


export type GetPrintTemplateUploadUrlQuery = GetPrintTemplateUploadUrlQuery_query;

export type GetPrintTemplatePreviewUrlsQuery_query = {
  __typename?: 'query',
  printTemplatePreviewUrls: Array<string>
};


export type GetPrintTemplatePreviewUrlsQueryVariables = Exact<{
  printTemplateId: Scalars['String']['input'];
}>;


export type GetPrintTemplatePreviewUrlsQuery = GetPrintTemplatePreviewUrlsQuery_query;

export type PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords = {
  __typename?: 'PrintTemplateObjectCoords',
  x?: number | null,
  y?: number | null,
  height?: number | null,
  width?: number | null,
  rotation?: number | null
};

export type PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage = {
  __typename?: 'PrintTemplatePage',
  offset: number,
  coordinates: PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords
};

export type PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField_paragraphStyles_PrintTemplateFieldStyle = {
  __typename?: 'PrintTemplateFieldStyle',
  id: string,
  name: string
};

export type PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField_characterStyles_PrintTemplateFieldStyle = {
  __typename?: 'PrintTemplateFieldStyle',
  id: string,
  name: string
};

export type PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField = {
  __typename?: 'PrintTemplateField',
  id: number,
  name: string,
  type: PrintTemplateFieldType,
  data: string,
  typeVariation: string,
  page: PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage,
  paragraphStyles: Array<PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField_paragraphStyles_PrintTemplateFieldStyle>,
  characterStyles: Array<PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField_characterStyles_PrintTemplateFieldStyle>
};

export type PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string,
  label: string,
  data: string,
  fields: Array<PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField | null>
};

export type PrintTemplateAssignmentFragment = {
  __typename?: 'Assignment',
  printTemplate?: PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate | null
};

export type ArticleForm_AssignmentFragment_Assignment_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type ArticleForm_AssignmentFragment_Assignment_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type ArticleForm_AssignmentFragment_Assignment_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: ArticleForm_AssignmentFragment_Assignment_printIssue_PrintIssue_printPublication_PrintPublication
};

export type ArticleForm_AssignmentFragment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  id: string,
  active: boolean,
  printTemplate: GetAssignmentQuery_query_assignment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate
};

export type ArticleForm_AssignmentFragment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type ArticleForm_AssignmentFragment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: ArticleForm_AssignmentFragment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication
};

export type ArticleForm_AssignmentFragment_Assignment_printHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  autoPrototype?: boolean | null,
  printTemplates: Array<ArticleForm_AssignmentFragment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate>,
  printIssue: ArticleForm_AssignmentFragment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue
};

export type ArticleForm_AssignmentFragment_Assignment_website_Website = {
  __typename?: 'Website',
  id: string,
  title: string,
  key: string
};

export type ArticleForm_AssignmentFragment = {
  __typename?: 'Assignment',
  id: string,
  printPublication?: ArticleForm_AssignmentFragment_Assignment_printPublication_PrintPublication | null,
  printIssue?: ArticleForm_AssignmentFragment_Assignment_printIssue_PrintIssue | null,
  printHeading?: ArticleForm_AssignmentFragment_Assignment_printHeading_PrintHeading | null,
  website?: ArticleForm_AssignmentFragment_Assignment_website_Website | null
};

export type ArticleForm_RawArticleFragment_RawArticle_versions_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  allowedStatuses: Array<RawArticleStatus>,
  id: string,
  title?: string | null,
  lead?: string | null,
  body?: string | null,
  signature?: string | null,
  leftOver?: string | null,
  status: RawArticleStatus,
  createdAt: string,
  textIsReadOnly: boolean,
  editor?: UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versions_RawArticleVersion_editor_User | null
};

export type ArticleForm_RawArticleFragment_RawArticle_versionList_RawArticleVersion_editor_User = {
  __typename?: 'User',
  id: string,
  name: string,
  avatarUrl?: string | null
};

export type ArticleForm_RawArticleFragment_RawArticle_versionList_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  status: RawArticleStatus,
  editorId: string,
  editor?: ArticleForm_RawArticleFragment_RawArticle_versionList_RawArticleVersion_editor_User | null
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_subject_Subject_unit_Unit = {
  __typename?: 'Unit',
  id: string,
  title: string
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_subject_Subject = {
  __typename?: 'Subject',
  id: string,
  title: string,
  synopsis?: string | null,
  unit: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_subject_Subject_unit_Unit
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField_paragraphStyles_PrintTemplateFieldStyle = {
  __typename?: 'PrintTemplateFieldStyle',
  id: string,
  name: string
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField_characterStyles_PrintTemplateFieldStyle = {
  __typename?: 'PrintTemplateFieldStyle',
  id: string,
  name: string
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField_coordinates_PrintTemplateObjectCoords = {
  __typename?: 'PrintTemplateObjectCoords',
  x?: number | null,
  y?: number | null,
  width?: number | null,
  height?: number | null,
  rotation?: number | null
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords = {
  __typename?: 'PrintTemplateObjectCoords',
  x?: number | null,
  y?: number | null,
  width?: number | null,
  height?: number | null,
  rotation?: number | null
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField_page_PrintTemplatePage = {
  __typename?: 'PrintTemplatePage',
  offset: number,
  number: string,
  coordinates: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField = {
  __typename?: 'PrintTemplateField',
  id: number,
  name: string,
  type: PrintTemplateFieldType,
  enabled?: boolean | null,
  data: string,
  typeVariation: string,
  paragraphStyles: Array<ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField_paragraphStyles_PrintTemplateFieldStyle>,
  characterStyles: Array<ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField_characterStyles_PrintTemplateFieldStyle>,
  coordinates: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField_coordinates_PrintTemplateObjectCoords,
  page: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField_page_PrintTemplatePage
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords = {
  __typename?: 'PrintTemplateObjectCoords',
  x?: number | null,
  y?: number | null,
  height?: number | null,
  width?: number | null,
  rotation?: number | null
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage = {
  __typename?: 'PrintTemplatePage',
  offset: number,
  coordinates: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage_coordinates_PrintTemplateObjectCoords
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_paragraphStyles_PrintTemplateFieldStyle = {
  __typename?: 'PrintTemplateFieldStyle',
  id: string,
  name: string
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_characterStyles_PrintTemplateFieldStyle = {
  __typename?: 'PrintTemplateFieldStyle',
  id: string,
  name: string
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField = {
  __typename?: 'PrintTemplateField',
  id: number,
  name: string,
  type: PrintTemplateFieldType,
  data: string,
  typeVariation: string,
  page: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_page_PrintTemplatePage,
  paragraphStyles: Array<ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_paragraphStyles_PrintTemplateFieldStyle>,
  characterStyles: Array<ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField_characterStyles_PrintTemplateFieldStyle>
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string,
  type?: TemplateType | null,
  fields: Array<ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate_fields_PrintTemplateField | null>
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate = {
  __typename?: 'ArticleTemplate',
  id: string,
  label?: string | null,
  block?: string | null,
  fields: Array<ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_fields_PrintTemplateField | null>,
  printTemplate: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate_printTemplate_PrintTemplate
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string,
  label: string,
  data: string,
  fields: Array<PrintTemplateAssignmentFragment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField | null>
};

export type ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment = {
  __typename?: 'Assignment',
  id: string,
  subject: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_subject_Subject,
  articleTemplate?: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_articleTemplate_ArticleTemplate | null,
  printTemplate?: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment_printTemplate_PrintTemplate | null,
  printPublication?: ArticleForm_AssignmentFragment_Assignment_printPublication_PrintPublication | null,
  printIssue?: ArticleForm_AssignmentFragment_Assignment_printIssue_PrintIssue | null,
  printHeading?: ArticleForm_AssignmentFragment_Assignment_printHeading_PrintHeading | null,
  website?: ArticleForm_AssignmentFragment_Assignment_website_Website | null
};

export type ArticleForm_RawArticleFragment = {
  __typename?: 'RawArticle',
  id: string,
  versions: Array<ArticleForm_RawArticleFragment_RawArticle_versions_RawArticleVersion>,
  versionList: Array<ArticleForm_RawArticleFragment_RawArticle_versionList_RawArticleVersion>,
  assignment?: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment | null
};

export type GetRawArticleQuery_query_rawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string,
  versions: Array<ArticleForm_RawArticleFragment_RawArticle_versions_RawArticleVersion>,
  versionList: Array<ArticleForm_RawArticleFragment_RawArticle_versionList_RawArticleVersion>,
  assignment?: ArticleForm_RawArticleFragment_RawArticle_assignment_Assignment | null
};

export type GetRawArticleQuery_query = {
  __typename?: 'query',
  rawArticle: GetRawArticleQuery_query_rawArticle_RawArticle
};


export type GetRawArticleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetRawArticleQuery = GetRawArticleQuery_query;

export type GetUnitsQuery_query_units_Unit = {
  __typename?: 'Unit',
  id: string,
  title: string
};

export type GetUnitsQuery_query = {
  __typename?: 'query',
  units: Array<GetUnitsQuery_query_units_Unit>
};


export type GetUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnitsQuery = GetUnitsQuery_query;

export type RawArticleItemFragment_RawArticle_versions_RawArticleVersion_editor_User = {
  __typename?: 'User',
  id: string,
  name: string
};

export type RawArticleItemFragment_RawArticle_versions_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  title?: string | null,
  status: RawArticleStatus,
  createdAt: string,
  editor?: RawArticleItemFragment_RawArticle_versions_RawArticleVersion_editor_User | null
};

export type RawArticleItemFragment = {
  __typename?: 'RawArticle',
  id: string,
  versions: Array<RawArticleItemFragment_RawArticle_versions_RawArticleVersion>
};

export type AssignmentItemFragment_Assignment_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string
};

export type AssignmentItemFragment_Assignment_articleTemplate_ArticleTemplate = {
  __typename?: 'ArticleTemplate',
  id: string
};

export type AssignmentItemFragment_Assignment_rawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string,
  versions: Array<RawArticleItemFragment_RawArticle_versions_RawArticleVersion>
};

export type AssignmentItemFragment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  type?: TemplateType | null
};

export type AssignmentItemFragment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  printTemplate: AssignmentItemFragment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate
};

export type AssignmentItemFragment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type AssignmentItemFragment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: AssignmentItemFragment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication
};

export type AssignmentItemFragment_Assignment_printHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  autoPrototype?: boolean | null,
  printTemplates: Array<AssignmentItemFragment_Assignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate>,
  printIssue: AssignmentItemFragment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue
};

export type AssignmentItemFragment_Assignment_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type AssignmentItemFragment_Assignment_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: AssignmentItemFragment_Assignment_printIssue_PrintIssue_printPublication_PrintPublication
};

export type AssignmentItemFragment_Assignment_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type AssignmentItemFragment_Assignment_website_Website = {
  __typename?: 'Website',
  id: string,
  title: string,
  key: string
};

export type AssignmentItemFragment_Assignment_subject_Subject = {
  __typename?: 'Subject',
  id: string
};

export type AssignmentItemFragment = {
  __typename?: 'Assignment',
  id: string,
  mediaSearchStatus: MediaSearchStatus,
  printTemplate?: AssignmentItemFragment_Assignment_printTemplate_PrintTemplate | null,
  articleTemplate?: AssignmentItemFragment_Assignment_articleTemplate_ArticleTemplate | null,
  rawArticle?: AssignmentItemFragment_Assignment_rawArticle_RawArticle | null,
  printHeading?: AssignmentItemFragment_Assignment_printHeading_PrintHeading | null,
  printIssue?: AssignmentItemFragment_Assignment_printIssue_PrintIssue | null,
  printPublication?: AssignmentItemFragment_Assignment_printPublication_PrintPublication | null,
  website?: AssignmentItemFragment_Assignment_website_Website | null,
  subject: AssignmentItemFragment_Assignment_subject_Subject
};

export type GetDownloadUrlAttachmentQuery_query_attachment_Attachment = {
  __typename?: 'Attachment',
  downloadUrl?: string | null
};

export type GetDownloadUrlAttachmentQuery_query = {
  __typename?: 'query',
  attachment: GetDownloadUrlAttachmentQuery_query_attachment_Attachment
};


export type GetDownloadUrlAttachmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDownloadUrlAttachmentQuery = GetDownloadUrlAttachmentQuery_query;

export type GetSubjectForMediaStatusDialogQuery_query_subject_Subject_assignments_Assignment = {
  __typename?: 'Assignment',
  id: string,
  mediaSearchStatus: MediaSearchStatus,
  printTemplate?: AssignmentItemFragment_Assignment_printTemplate_PrintTemplate | null,
  articleTemplate?: AssignmentItemFragment_Assignment_articleTemplate_ArticleTemplate | null,
  rawArticle?: AssignmentItemFragment_Assignment_rawArticle_RawArticle | null,
  printHeading?: AssignmentItemFragment_Assignment_printHeading_PrintHeading | null,
  printIssue?: AssignmentItemFragment_Assignment_printIssue_PrintIssue | null,
  printPublication?: AssignmentItemFragment_Assignment_printPublication_PrintPublication | null,
  website?: AssignmentItemFragment_Assignment_website_Website | null,
  subject: AssignmentItemFragment_Assignment_subject_Subject
};

export type GetSubjectForMediaStatusDialogQuery_query_subject_Subject = {
  __typename?: 'Subject',
  id: string,
  assignments: Array<GetSubjectForMediaStatusDialogQuery_query_subject_Subject_assignments_Assignment>
};

export type GetSubjectForMediaStatusDialogQuery_query = {
  __typename?: 'query',
  subject: GetSubjectForMediaStatusDialogQuery_query_subject_Subject
};


export type GetSubjectForMediaStatusDialogQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetSubjectForMediaStatusDialogQuery = GetSubjectForMediaStatusDialogQuery_query;

export type UpdateAssignmentMediaSearchStatusMutation_mutation_updateAssignment_Assignment = {
  __typename?: 'Assignment',
  id: string,
  mediaSearchStatus: MediaSearchStatus
};

export type UpdateAssignmentMediaSearchStatusMutation_mutation = {
  __typename?: 'mutation',
  updateAssignment: UpdateAssignmentMediaSearchStatusMutation_mutation_updateAssignment_Assignment
};


export type UpdateAssignmentMediaSearchStatusMutationVariables = Exact<{
  assignmentId: Scalars['ID']['input'];
  status: MediaSearchStatus;
}>;


export type UpdateAssignmentMediaSearchStatusMutation = UpdateAssignmentMediaSearchStatusMutation_mutation;

export type DeleteSubjectUserMutation_mutation_deleteSubjectUser_SubjectUser = {
  __typename?: 'SubjectUser',
  userId: string
};

export type DeleteSubjectUserMutation_mutation = {
  __typename?: 'mutation',
  deleteSubjectUser: DeleteSubjectUserMutation_mutation_deleteSubjectUser_SubjectUser
};


export type DeleteSubjectUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  subjectId: Scalars['ID']['input'];
}>;


export type DeleteSubjectUserMutation = DeleteSubjectUserMutation_mutation;

export type SubjectUserFragment_SubjectUser_user_User = {
  __typename?: 'User',
  email: string,
  name: string,
  avatarUrl?: string | null
};

export type SubjectUserFragment = {
  __typename?: 'SubjectUser',
  userId: string,
  user?: SubjectUserFragment_SubjectUser_user_User | null
};

export type GetSubjectUsersQuery_query_subject_Subject_subjectUsers_SubjectUser = {
  __typename?: 'SubjectUser',
  userId: string,
  user?: SubjectUserFragment_SubjectUser_user_User | null
};

export type GetSubjectUsersQuery_query_subject_Subject = {
  __typename?: 'Subject',
  id: string,
  subjectUsers: Array<GetSubjectUsersQuery_query_subject_Subject_subjectUsers_SubjectUser>
};

export type GetSubjectUsersQuery_query = {
  __typename?: 'query',
  subject: GetSubjectUsersQuery_query_subject_Subject
};


export type GetSubjectUsersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetSubjectUsersQuery = GetSubjectUsersQuery_query;

export type ShareSubjectMutation_mutation_shareSubject_Subject = {
  __typename?: 'Subject',
  id: string
};

export type ShareSubjectMutation_mutation = {
  __typename?: 'mutation',
  shareSubject?: ShareSubjectMutation_mutation_shareSubject_Subject | null
};


export type ShareSubjectMutationVariables = Exact<{
  subject: Scalars['ID']['input'];
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ShareSubjectMutation = ShareSubjectMutation_mutation;

export type UnitSpeedDial_FragmentFragment = {
  __typename?: 'Unit',
  id: string,
  title: string
};

export type UnitSpeedDial_GetUnitsQuery_query_units_Unit = {
  __typename?: 'Unit',
  id: string,
  title: string
};

export type UnitSpeedDial_GetUnitsQuery_query = {
  __typename?: 'query',
  units: Array<UnitSpeedDial_GetUnitsQuery_query_units_Unit>
};


export type UnitSpeedDial_GetUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitSpeedDial_GetUnitsQuery = UnitSpeedDial_GetUnitsQuery_query;

export type DeleteSubjectMutation_mutation_deleteSubject_Subject = {
  __typename?: 'Subject',
  id: string
};

export type DeleteSubjectMutation_mutation = {
  __typename?: 'mutation',
  deleteSubject: DeleteSubjectMutation_mutation_deleteSubject_Subject
};


export type DeleteSubjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteSubjectMutation = DeleteSubjectMutation_mutation;

export type GetPrintTemplateDraftQuery_query_printTemplateDrafts_PrintTemplateDraftsResultType_artifacts_PrintTemplateDraftArtifactType = {
  __typename?: 'PrintTemplateDraftArtifactType',
  page: number,
  url: string
};

export type GetPrintTemplateDraftQuery_query_printTemplateDrafts_PrintTemplateDraftsResultType = {
  __typename?: 'PrintTemplateDraftsResultType',
  draftId: string,
  artifacts: Array<GetPrintTemplateDraftQuery_query_printTemplateDrafts_PrintTemplateDraftsResultType_artifacts_PrintTemplateDraftArtifactType>
};

export type GetPrintTemplateDraftQuery_query = {
  __typename?: 'query',
  printTemplateDrafts: GetPrintTemplateDraftQuery_query_printTemplateDrafts_PrintTemplateDraftsResultType
};


export type GetPrintTemplateDraftQueryVariables = Exact<{
  printHeadingId: Scalars['String']['input'];
  printTemplateId?: InputMaybe<Scalars['String']['input']>;
  format: PrintTemplateDraftFormatType;
  pages?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  fieldsData?: InputMaybe<Array<InputMaybe<PrintTemplateDraftFieldsDataType>> | InputMaybe<PrintTemplateDraftFieldsDataType>>;
  embedRessources?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetPrintTemplateDraftQuery = GetPrintTemplateDraftQuery_query;

export type GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printIssue_PrintIssue_printPublication_PrintPublication
};

export type GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication
};

export type GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  printIssue: GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printHeading_PrintHeading_printIssue_PrintIssue
};

export type GetAssignmentByRawArticleIdQuery_query_assignment_Assignment = {
  __typename?: 'Assignment',
  id: string,
  printPublication?: GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printPublication_PrintPublication | null,
  printIssue?: GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printIssue_PrintIssue | null,
  printHeading?: GetAssignmentByRawArticleIdQuery_query_assignment_Assignment_printHeading_PrintHeading | null
};

export type GetAssignmentByRawArticleIdQuery_query = {
  __typename?: 'query',
  assignment: GetAssignmentByRawArticleIdQuery_query_assignment_Assignment
};


export type GetAssignmentByRawArticleIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAssignmentByRawArticleIdQuery = GetAssignmentByRawArticleIdQuery_query;

export type UpdateAssignmentByArticleMutation_mutation_updateAssignment_Assignment = {
  __typename?: 'Assignment',
  id: string
};

export type UpdateAssignmentByArticleMutation_mutation = {
  __typename?: 'mutation',
  updateAssignment: UpdateAssignmentByArticleMutation_mutation_updateAssignment_Assignment
};


export type UpdateAssignmentByArticleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: AssignmentUpdateInput;
}>;


export type UpdateAssignmentByArticleMutation = UpdateAssignmentByArticleMutation_mutation;

export type UpsertRawArticleMutation_mutation_upsertRawArticle_RawArticle_versions_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  allowedStatuses: Array<RawArticleStatus>,
  id: string,
  title?: string | null,
  lead?: string | null,
  body?: string | null,
  signature?: string | null,
  leftOver?: string | null,
  status: RawArticleStatus,
  createdAt: string,
  textIsReadOnly: boolean,
  editor?: UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versions_RawArticleVersion_editor_User | null
};

export type UpsertRawArticleMutation_mutation_upsertRawArticle_RawArticle_versionList_RawArticleVersion_editor_User = {
  __typename?: 'User',
  id: string,
  name: string,
  avatarUrl?: string | null
};

export type UpsertRawArticleMutation_mutation_upsertRawArticle_RawArticle_versionList_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  status: RawArticleStatus,
  editorId: string,
  editor?: UpsertRawArticleMutation_mutation_upsertRawArticle_RawArticle_versionList_RawArticleVersion_editor_User | null
};

export type UpsertRawArticleMutation_mutation_upsertRawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string,
  versions: Array<UpsertRawArticleMutation_mutation_upsertRawArticle_RawArticle_versions_RawArticleVersion>,
  versionList: Array<UpsertRawArticleMutation_mutation_upsertRawArticle_RawArticle_versionList_RawArticleVersion>
};

export type UpsertRawArticleMutation_mutation = {
  __typename?: 'mutation',
  upsertRawArticle: UpsertRawArticleMutation_mutation_upsertRawArticle_RawArticle
};


export type UpsertRawArticleMutationVariables = Exact<{
  create: RawArticleCreationInput;
  update: RawArticleUpdateInput;
  where: RawArticleUniqueFilterInput;
}>;


export type UpsertRawArticleMutation = UpsertRawArticleMutation_mutation;

export type CreateRawArticleVersionEditorTransferMutation_mutation_createRawArticleVersion_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  editorId: string,
  allowedStatuses: Array<RawArticleStatus>,
  id: string,
  title?: string | null,
  lead?: string | null,
  body?: string | null,
  signature?: string | null,
  leftOver?: string | null,
  status: RawArticleStatus,
  createdAt: string,
  textIsReadOnly: boolean,
  editor?: UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versions_RawArticleVersion_editor_User | null
};

export type CreateRawArticleVersionEditorTransferMutation_mutation = {
  __typename?: 'mutation',
  createRawArticleVersion: CreateRawArticleVersionEditorTransferMutation_mutation_createRawArticleVersion_RawArticleVersion
};


export type CreateRawArticleVersionEditorTransferMutationVariables = Exact<{
  data: RawArticleVersionCreationInput;
}>;


export type CreateRawArticleVersionEditorTransferMutation = CreateRawArticleVersionEditorTransferMutation_mutation;

export type GetUserBySearchQuery_query_rawArticleVersion_RawArticleVersion_allowedTransfers_User = {
  __typename?: 'User',
  id: string,
  name: string
};

export type GetUserBySearchQuery_query_rawArticleVersion_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  allowedTransfers: Array<GetUserBySearchQuery_query_rawArticleVersion_RawArticleVersion_allowedTransfers_User>
};

export type GetUserBySearchQuery_query = {
  __typename?: 'query',
  rawArticleVersion: GetUserBySearchQuery_query_rawArticleVersion_RawArticleVersion
};


export type GetUserBySearchQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  search: Scalars['String']['input'];
}>;


export type GetUserBySearchQuery = GetUserBySearchQuery_query;

export type ArticleForm_RawArticleVersionFragmentFragment = {
  __typename?: 'RawArticleVersion',
  allowedStatuses: Array<RawArticleStatus>,
  id: string,
  title?: string | null,
  lead?: string | null,
  body?: string | null,
  signature?: string | null,
  leftOver?: string | null,
  status: RawArticleStatus,
  createdAt: string,
  textIsReadOnly: boolean,
  editor?: UpdateAssignmentMutation_mutation_updateAssignment_Assignment_rawArticle_RawArticle_versions_RawArticleVersion_editor_User | null
};

export type GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_editor_User = {
  __typename?: 'User',
  id: string,
  name: string,
  avatarUrl?: string | null
};

export type GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_rawArticle_RawArticle_lastVersion_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string
};

export type GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_rawArticle_RawArticle_assignment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField = {
  __typename?: 'PrintTemplateField',
  id: number,
  name: string,
  type: PrintTemplateFieldType
};

export type GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_rawArticle_RawArticle_assignment_Assignment_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  fields: Array<GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_rawArticle_RawArticle_assignment_Assignment_printTemplate_PrintTemplate_fields_PrintTemplateField | null>
};

export type GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_rawArticle_RawArticle_assignment_Assignment = {
  __typename?: 'Assignment',
  printTemplate?: GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_rawArticle_RawArticle_assignment_Assignment_printTemplate_PrintTemplate | null
};

export type GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_rawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string,
  lastVersion?: GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_rawArticle_RawArticle_lastVersion_RawArticleVersion | null,
  assignment?: GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_rawArticle_RawArticle_assignment_Assignment | null
};

export type GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  title?: string | null,
  lead?: string | null,
  body?: string | null,
  signature?: string | null,
  createdAt: string,
  status: RawArticleStatus,
  editor?: GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_editor_User | null,
  rawArticle: GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion_rawArticle_RawArticle
};

export type GetRawArticleVersionArticleHistoryQuery_query = {
  __typename?: 'query',
  rawArticleVersion: GetRawArticleVersionArticleHistoryQuery_query_rawArticleVersion_RawArticleVersion
};


export type GetRawArticleVersionArticleHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetRawArticleVersionArticleHistoryQuery = GetRawArticleVersionArticleHistoryQuery_query;

export type ArticleHistory_MajorVersionFragment_RawArticleVersion_editor_User = {
  __typename?: 'User',
  id: string,
  name: string,
  avatarUrl?: string | null
};

export type ArticleHistory_MajorVersionFragment_RawArticleVersion_previousMinorVersions_RawArticleVersion_editor_User = {
  __typename?: 'User',
  id: string,
  name: string,
  avatarUrl?: string | null
};

export type ArticleHistory_MajorVersionFragment_RawArticleVersion_previousMinorVersions_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  createdAt: string,
  editor?: ArticleHistory_MajorVersionFragment_RawArticleVersion_previousMinorVersions_RawArticleVersion_editor_User | null
};

export type ArticleHistory_MajorVersionFragment = {
  __typename?: 'RawArticleVersion',
  id: string,
  status: RawArticleStatus,
  title?: string | null,
  lead?: string | null,
  body?: string | null,
  signature?: string | null,
  leftOver?: string | null,
  editorId: string,
  createdAt: string,
  editor?: ArticleHistory_MajorVersionFragment_RawArticleVersion_editor_User | null,
  previousMinorVersions: Array<ArticleHistory_MajorVersionFragment_RawArticleVersion_previousMinorVersions_RawArticleVersion>
};

export type GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle_majorVersions_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  status: RawArticleStatus,
  title?: string | null,
  lead?: string | null,
  body?: string | null,
  signature?: string | null,
  leftOver?: string | null,
  editorId: string,
  createdAt: string,
  editor?: ArticleHistory_MajorVersionFragment_RawArticleVersion_editor_User | null,
  previousMinorVersions: Array<ArticleHistory_MajorVersionFragment_RawArticleVersion_previousMinorVersions_RawArticleVersion>
};

export type GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string,
  majorVersions: Array<GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle_majorVersions_RawArticleVersion>
};

export type GetRawArticleMajorVersionsQuery_query = {
  __typename?: 'query',
  rawArticle: GetRawArticleMajorVersionsQuery_query_rawArticle_RawArticle
};


export type GetRawArticleMajorVersionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetRawArticleMajorVersionsQuery = GetRawArticleMajorVersionsQuery_query;

export type IssuePage_GetPrintPublicationsQuery_query_printPublications_PrintPublication_printIssues_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string
};

export type IssuePage_GetPrintPublicationsQuery_query_printPublications_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string,
  printIssues: Array<IssuePage_GetPrintPublicationsQuery_query_printPublications_PrintPublication_printIssues_PrintIssue>
};

export type IssuePage_GetPrintPublicationsQuery_query = {
  __typename?: 'query',
  printPublications: Array<IssuePage_GetPrintPublicationsQuery_query_printPublications_PrintPublication>
};


export type IssuePage_GetPrintPublicationsQueryVariables = Exact<{
  where?: InputMaybe<PrintPublicationFilterInput>;
  orderBy?: InputMaybe<Array<PrintPublicationOrderingInput> | PrintPublicationOrderingInput>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  first: Scalars['UnsignedInt']['input'];
}>;


export type IssuePage_GetPrintPublicationsQuery = IssuePage_GetPrintPublicationsQuery_query;

export type IssuePage_GetPrintIssuesQuery_query_printIssues_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string
};

export type IssuePage_GetPrintIssuesQuery_query = {
  __typename?: 'query',
  printIssues: Array<IssuePage_GetPrintIssuesQuery_query_printIssues_PrintIssue>
};


export type IssuePage_GetPrintIssuesQueryVariables = Exact<{
  where?: InputMaybe<PrintIssueFilterInput>;
  orderBy?: InputMaybe<Array<PrintIssueOrderingInput> | PrintIssueOrderingInput>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  first: Scalars['UnsignedInt']['input'];
}>;


export type IssuePage_GetPrintIssuesQuery = IssuePage_GetPrintIssuesQuery_query;

export type IssuePage_GetPrintIssueQuery_query_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  key: string
};

export type IssuePage_GetPrintIssueQuery_query_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: IssuePage_GetPrintIssueQuery_query_printIssue_PrintIssue_printPublication_PrintPublication
};

export type IssuePage_GetPrintIssueQuery_query = {
  __typename?: 'query',
  printIssue: IssuePage_GetPrintIssueQuery_query_printIssue_PrintIssue
};


export type IssuePage_GetPrintIssueQueryVariables = Exact<{
  where: PrintIssueUniqueFilterInput;
}>;


export type IssuePage_GetPrintIssueQuery = IssuePage_GetPrintIssueQuery_query;

export type IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading_assignments_Assignment_subject_Subject = {
  __typename?: 'Subject',
  id: string,
  title: string,
  comment?: string | null
};

export type IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading_assignments_Assignment_rawArticle_RawArticle_lastVersion_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  status: RawArticleStatus,
  title?: string | null
};

export type IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading_assignments_Assignment_rawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string,
  lastVersion?: IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading_assignments_Assignment_rawArticle_RawArticle_lastVersion_RawArticleVersion | null
};

export type IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading_assignments_Assignment = {
  __typename?: 'Assignment',
  id: string,
  mediaSearchStatus: MediaSearchStatus,
  pageNumber?: number | null,
  subject: IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading_assignments_Assignment_subject_Subject,
  rawArticle?: IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading_assignments_Assignment_rawArticle_RawArticle | null
};

export type IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  assignments: Array<IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading_assignments_Assignment>
};

export type IssuePage_GetPrintHeadingsQuery_query = {
  __typename?: 'query',
  printHeadings: Array<IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading>
};


export type IssuePage_GetPrintHeadingsQueryVariables = Exact<{
  where?: InputMaybe<PrintHeadingFilterInput>;
  orderBy?: InputMaybe<Array<PrintHeadingOrderingInput> | PrintHeadingOrderingInput>;
  skip?: InputMaybe<Scalars['UnsignedInt']['input']>;
  first: Scalars['UnsignedInt']['input'];
}>;


export type IssuePage_GetPrintHeadingsQuery = IssuePage_GetPrintHeadingsQuery_query;

export type IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_pages_CenshareRailroadByNamePagesResultType = {
  __typename?: 'CenshareRailroadByNamePagesResultType',
  filename?: string | null,
  indesignFilename?: string | null,
  pageNumber: string,
  workflow?: string | null
};

export type IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_censhareUpdateDate_CenshareRailroadByNameUpdateDateResultType = {
  __typename?: 'CenshareRailroadByNameUpdateDateResultType',
  date?: string | null,
  label_by?: string | null
};

export type IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_censhareCreationDate_CenshareRailroadByNameCreationDateResultType = {
  __typename?: 'CenshareRailroadByNameCreationDateResultType',
  date?: string | null,
  label_by?: string | null
};

export type IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType = {
  __typename?: 'CenshareRailroadByNameResultType',
  sheets?: string | null,
  comment: string,
  publicationDate: string,
  pages?: Array<IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_pages_CenshareRailroadByNamePagesResultType | null> | null,
  censhareUpdateDate?: IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_censhareUpdateDate_CenshareRailroadByNameUpdateDateResultType | null,
  censhareCreationDate?: IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_censhareCreationDate_CenshareRailroadByNameCreationDateResultType | null
};

export type IssuePage_GetRailroadPagesQuery_query = {
  __typename?: 'query',
  getCenshareRailroadByName?: IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType | null
};


export type IssuePage_GetRailroadPagesQueryVariables = Exact<{
  brandKey: Scalars['String']['input'];
  issue: Scalars['String']['input'];
}>;


export type IssuePage_GetRailroadPagesQuery = IssuePage_GetRailroadPagesQuery_query;

export type IssuePage_GetElisaPdfQuery_query_getElisaPdf_PdfPreviewResultType = {
  __typename?: 'PdfPreviewResultType',
  link: string
};

export type IssuePage_GetElisaPdfQuery_query = {
  __typename?: 'query',
  getElisaPdf: IssuePage_GetElisaPdfQuery_query_getElisaPdf_PdfPreviewResultType
};


export type IssuePage_GetElisaPdfQueryVariables = Exact<{
  brandKey: Scalars['String']['input'];
  issue: Scalars['Int']['input'];
}>;


export type IssuePage_GetElisaPdfQuery = IssuePage_GetElisaPdfQuery_query;

export type UpdatePrintHeadingMutation_mutation_updatePrintHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string
};

export type UpdatePrintHeadingMutation_mutation_updatePrintHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  id: string,
  active: boolean,
  createdAt: string,
  updatedAt: string,
  printTemplate: UpdatePrintHeadingMutation_mutation_updatePrintHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate
};

export type UpdatePrintHeadingMutation_mutation_updatePrintHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  order: number,
  hasAutomaticSubject: boolean,
  autoPrototype?: boolean | null,
  printTemplateCount: number,
  assignmentCount: number,
  printTemplates: Array<UpdatePrintHeadingMutation_mutation_updatePrintHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate>
};

export type UpdatePrintHeadingMutation_mutation = {
  __typename?: 'mutation',
  updatePrintHeading: UpdatePrintHeadingMutation_mutation_updatePrintHeading_PrintHeading
};


export type UpdatePrintHeadingMutationVariables = Exact<{
  data: PrintHeadingUpdateInput;
  where: PrintHeadingUniqueFilterInput;
}>;


export type UpdatePrintHeadingMutation = UpdatePrintHeadingMutation_mutation;

export type LocationPage_GetSubjectsFromPrintHeadingQuery_query_subjects_Subject_subjectCategory_SubjectCategory_parent_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string
};

export type LocationPage_GetSubjectsFromPrintHeadingQuery_query_subjects_Subject_subjectCategory_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string,
  parent?: LocationPage_GetSubjectsFromPrintHeadingQuery_query_subjects_Subject_subjectCategory_SubjectCategory_parent_SubjectCategory | null
};

export type LocationPage_GetSubjectsFromPrintHeadingQuery_query_subjects_Subject_flatAssignments_FlatAssignment_printHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string
};

export type LocationPage_GetSubjectsFromPrintHeadingQuery_query_subjects_Subject_flatAssignments_FlatAssignment = {
  __typename?: 'FlatAssignment',
  printHeading?: LocationPage_GetSubjectsFromPrintHeadingQuery_query_subjects_Subject_flatAssignments_FlatAssignment_printHeading_PrintHeading | null
};

export type LocationPage_GetSubjectsFromPrintHeadingQuery_query_subjects_Subject = {
  __typename?: 'Subject',
  id: string,
  title: string,
  isAuto: boolean,
  subjectCategory?: LocationPage_GetSubjectsFromPrintHeadingQuery_query_subjects_Subject_subjectCategory_SubjectCategory | null,
  flatAssignments: Array<LocationPage_GetSubjectsFromPrintHeadingQuery_query_subjects_Subject_flatAssignments_FlatAssignment>
};

export type LocationPage_GetSubjectsFromPrintHeadingQuery_query = {
  __typename?: 'query',
  subjects: Array<LocationPage_GetSubjectsFromPrintHeadingQuery_query_subjects_Subject>
};


export type LocationPage_GetSubjectsFromPrintHeadingQueryVariables = Exact<{
  where?: InputMaybe<SubjectFilterInput>;
}>;


export type LocationPage_GetSubjectsFromPrintHeadingQuery = LocationPage_GetSubjectsFromPrintHeadingQuery_query;

export type LocationPage_DeletePrintHeadingMutation_mutation_deletePrintHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string
};

export type LocationPage_DeletePrintHeadingMutation_mutation = {
  __typename?: 'mutation',
  deletePrintHeading: LocationPage_DeletePrintHeadingMutation_mutation_deletePrintHeading_PrintHeading
};


export type LocationPage_DeletePrintHeadingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type LocationPage_DeletePrintHeadingMutation = LocationPage_DeletePrintHeadingMutation_mutation;

export type LocationPage_GetPrintHeadingDeleteInformationQuery_query_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  title: string
};

export type LocationPage_GetPrintHeadingDeleteInformationQuery_query_printHeading_PrintHeading_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  title: string,
  printPublication: LocationPage_GetPrintHeadingDeleteInformationQuery_query_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication
};

export type LocationPage_GetPrintHeadingDeleteInformationQuery_query_printHeading_PrintHeading_assignments_Assignment_subject_Subject = {
  __typename?: 'Subject',
  title: string
};

export type LocationPage_GetPrintHeadingDeleteInformationQuery_query_printHeading_PrintHeading_assignments_Assignment = {
  __typename?: 'Assignment',
  subject: LocationPage_GetPrintHeadingDeleteInformationQuery_query_printHeading_PrintHeading_assignments_Assignment_subject_Subject
};

export type LocationPage_GetPrintHeadingDeleteInformationQuery_query_printHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  title: string,
  printIssue: LocationPage_GetPrintHeadingDeleteInformationQuery_query_printHeading_PrintHeading_printIssue_PrintIssue,
  assignments: Array<LocationPage_GetPrintHeadingDeleteInformationQuery_query_printHeading_PrintHeading_assignments_Assignment>
};

export type LocationPage_GetPrintHeadingDeleteInformationQuery_query = {
  __typename?: 'query',
  printHeading: LocationPage_GetPrintHeadingDeleteInformationQuery_query_printHeading_PrintHeading
};


export type LocationPage_GetPrintHeadingDeleteInformationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type LocationPage_GetPrintHeadingDeleteInformationQuery = LocationPage_GetPrintHeadingDeleteInformationQuery_query;

export type LocationPage_UpsertPrintHeadingMutation_mutation_upsertPrintHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  order: number,
  hasAutomaticSubject: boolean,
  autoPrototype?: boolean | null,
  printTemplateCount: number,
  assignmentCount: number,
  printTemplates: Array<UpdatePrintHeadingMutation_mutation_updatePrintHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate>
};

export type LocationPage_UpsertPrintHeadingMutation_mutation = {
  __typename?: 'mutation',
  upsertPrintHeading: LocationPage_UpsertPrintHeadingMutation_mutation_upsertPrintHeading_PrintHeading
};


export type LocationPage_UpsertPrintHeadingMutationVariables = Exact<{
  create: PrintHeadingCreationInput;
  update: PrintHeadingUpdateInput;
  where: PrintHeadingUniqueFilterInput;
}>;


export type LocationPage_UpsertPrintHeadingMutation = LocationPage_UpsertPrintHeadingMutation_mutation;

export type LocationPage_GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  key: string
};

export type LocationPage_GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: LocationPage_GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue_printPublication_PrintPublication
};

export type LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  order: number,
  hasAutomaticSubject: boolean,
  autoPrototype?: boolean | null,
  printTemplateCount: number,
  assignmentCount: number,
  printTemplates: Array<UpdatePrintHeadingMutation_mutation_updatePrintHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate>
};

export type LocationPage_GetPrintHeadingByPrintIssueQuery_query = {
  __typename?: 'query',
  printIssue: LocationPage_GetPrintHeadingByPrintIssueQuery_query_printIssue_PrintIssue,
  printHeadings: Array<LocationPage_GetPrintHeadingByPrintIssueQuery_query_printHeadings_PrintHeading>
};


export type LocationPage_GetPrintHeadingByPrintIssueQueryVariables = Exact<{
  printIssueId: Scalars['ID']['input'];
}>;


export type LocationPage_GetPrintHeadingByPrintIssueQuery = LocationPage_GetPrintHeadingByPrintIssueQuery_query;

export type PrintHeadingFragmentFragment = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  order: number,
  hasAutomaticSubject: boolean,
  autoPrototype?: boolean | null,
  printTemplateCount: number,
  assignmentCount: number,
  printTemplates: Array<UpdatePrintHeadingMutation_mutation_updatePrintHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate>
};

export type LocationPage_DeletePrintIssueMutation_mutation_deletePrintIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string
};

export type LocationPage_DeletePrintIssueMutation_mutation = {
  __typename?: 'mutation',
  deletePrintIssue: LocationPage_DeletePrintIssueMutation_mutation_deletePrintIssue_PrintIssue
};


export type LocationPage_DeletePrintIssueMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type LocationPage_DeletePrintIssueMutation = LocationPage_DeletePrintIssueMutation_mutation;

export type LocationPage_GetDeletePrintIssueInformationQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading = {
  __typename?: 'PrintHeading',
  title: string,
  assignmentCount: number
};

export type LocationPage_GetDeletePrintIssueInformationQuery_query_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type LocationPage_GetDeletePrintIssueInformationQuery_query_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  assignmentCount: number,
  title: string,
  printHeadings: Array<LocationPage_GetDeletePrintIssueInformationQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading>,
  printPublication: LocationPage_GetDeletePrintIssueInformationQuery_query_printIssue_PrintIssue_printPublication_PrintPublication
};

export type LocationPage_GetDeletePrintIssueInformationQuery_query = {
  __typename?: 'query',
  printIssue: LocationPage_GetDeletePrintIssueInformationQuery_query_printIssue_PrintIssue
};


export type LocationPage_GetDeletePrintIssueInformationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type LocationPage_GetDeletePrintIssueInformationQuery = LocationPage_GetDeletePrintIssueInformationQuery_query;

export type LocationPage_GetPrintPublicationKeyQuery_query_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  key: string
};

export type LocationPage_GetPrintPublicationKeyQuery_query = {
  __typename?: 'query',
  printPublication: LocationPage_GetPrintPublicationKeyQuery_query_printPublication_PrintPublication
};


export type LocationPage_GetPrintPublicationKeyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type LocationPage_GetPrintPublicationKeyQuery = LocationPage_GetPrintPublicationKeyQuery_query;

export type LocationPage_UpsertPrintIssueMutation_mutation_upsertPrintIssue_PrintIssue_printHeadings_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  order: number,
  hasAutomaticSubject: boolean
};

export type LocationPage_UpsertPrintIssueMutation_mutation_upsertPrintIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printHeadings: Array<LocationPage_UpsertPrintIssueMutation_mutation_upsertPrintIssue_PrintIssue_printHeadings_PrintHeading>
};

export type LocationPage_UpsertPrintIssueMutation_mutation = {
  __typename?: 'mutation',
  upsertPrintIssue: LocationPage_UpsertPrintIssueMutation_mutation_upsertPrintIssue_PrintIssue
};


export type LocationPage_UpsertPrintIssueMutationVariables = Exact<{
  create: PrintIssueCreationInput;
  update: PrintIssueUpdateInput;
  where: PrintIssueUniqueFilterInput;
}>;


export type LocationPage_UpsertPrintIssueMutation = LocationPage_UpsertPrintIssueMutation_mutation;

export type LocationPage_GetPrintIssuesByPrintPublicationQuery_query_printIssues_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string
};

export type LocationPage_GetPrintIssuesByPrintPublicationQuery_query_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type LocationPage_GetPrintIssuesByPrintPublicationQuery_query = {
  __typename?: 'query',
  printIssues: Array<LocationPage_GetPrintIssuesByPrintPublicationQuery_query_printIssues_PrintIssue>,
  printPublication: LocationPage_GetPrintIssuesByPrintPublicationQuery_query_printPublication_PrintPublication
};


export type LocationPage_GetPrintIssuesByPrintPublicationQueryVariables = Exact<{
  printPublicationId: Scalars['ID']['input'];
}>;


export type LocationPage_GetPrintIssuesByPrintPublicationQuery = LocationPage_GetPrintIssuesByPrintPublicationQuery_query;

export type PrintIssueFragmentFragment = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printHeadings: Array<LocationPage_UpsertPrintIssueMutation_mutation_upsertPrintIssue_PrintIssue_printHeadings_PrintHeading>
};

export type LocationPage_UpsertPrintPublicationMutation_mutation_upsertPrintPublication_PrintPublication_brand_Brand = {
  __typename?: 'Brand',
  id: string
};

export type LocationPage_UpsertPrintPublicationMutation_mutation_upsertPrintPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string,
  key: string,
  brand: LocationPage_UpsertPrintPublicationMutation_mutation_upsertPrintPublication_PrintPublication_brand_Brand
};

export type LocationPage_UpsertPrintPublicationMutation_mutation = {
  __typename?: 'mutation',
  upsertPrintPublication: LocationPage_UpsertPrintPublicationMutation_mutation_upsertPrintPublication_PrintPublication
};


export type LocationPage_UpsertPrintPublicationMutationVariables = Exact<{
  create: PrintPublicationCreationInput;
  update: PrintPublicationUpdateInput;
  where: PrintPublicationUniqueFilterInput;
}>;


export type LocationPage_UpsertPrintPublicationMutation = LocationPage_UpsertPrintPublicationMutation_mutation;

export type LocationPage_GetPrintPublicationsByUnitQuery_query_unit_Unit_brands_Brand_printPublications_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string,
  key: string,
  brand: LocationPage_UpsertPrintPublicationMutation_mutation_upsertPrintPublication_PrintPublication_brand_Brand
};

export type LocationPage_GetPrintPublicationsByUnitQuery_query_unit_Unit_brands_Brand = {
  __typename?: 'Brand',
  id: string,
  title: string,
  printPublications: Array<LocationPage_GetPrintPublicationsByUnitQuery_query_unit_Unit_brands_Brand_printPublications_PrintPublication>
};

export type LocationPage_GetPrintPublicationsByUnitQuery_query_unit_Unit = {
  __typename?: 'Unit',
  id: string,
  title: string,
  brands: Array<LocationPage_GetPrintPublicationsByUnitQuery_query_unit_Unit_brands_Brand>
};

export type LocationPage_GetPrintPublicationsByUnitQuery_query = {
  __typename?: 'query',
  unit: LocationPage_GetPrintPublicationsByUnitQuery_query_unit_Unit
};


export type LocationPage_GetPrintPublicationsByUnitQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
}>;


export type LocationPage_GetPrintPublicationsByUnitQuery = LocationPage_GetPrintPublicationsByUnitQuery_query;

export type PrintPublicationFragmentFragment = {
  __typename?: 'PrintPublication',
  id: string,
  title: string,
  key: string,
  brand: LocationPage_UpsertPrintPublicationMutation_mutation_upsertPrintPublication_PrintPublication_brand_Brand
};

export type LocationPage_UpdatePrintHeadingOrderMutation_mutation_updatePrintIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printHeadings: Array<LocationPage_UpsertPrintIssueMutation_mutation_upsertPrintIssue_PrintIssue_printHeadings_PrintHeading>
};

export type LocationPage_UpdatePrintHeadingOrderMutation_mutation = {
  __typename?: 'mutation',
  updatePrintIssue: LocationPage_UpdatePrintHeadingOrderMutation_mutation_updatePrintIssue_PrintIssue
};


export type LocationPage_UpdatePrintHeadingOrderMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  data: PrintIssueUpdateInput;
}>;


export type LocationPage_UpdatePrintHeadingOrderMutation = LocationPage_UpdatePrintHeadingOrderMutation_mutation;

export type SubjectForm_DeleteAssignmentMutation_mutation_deleteAssignment_Assignment = {
  __typename?: 'Assignment',
  id: string
};

export type SubjectForm_DeleteAssignmentMutation_mutation = {
  __typename?: 'mutation',
  deleteAssignment: SubjectForm_DeleteAssignmentMutation_mutation_deleteAssignment_Assignment
};


export type SubjectForm_DeleteAssignmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SubjectForm_DeleteAssignmentMutation = SubjectForm_DeleteAssignmentMutation_mutation;

export type DeleteAttachmentMutation_mutation_deleteAttachment_Attachment = {
  __typename?: 'Attachment',
  id: string
};

export type DeleteAttachmentMutation_mutation = {
  __typename?: 'mutation',
  deleteAttachment: DeleteAttachmentMutation_mutation_deleteAttachment_Attachment
};


export type DeleteAttachmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteAttachmentMutation = DeleteAttachmentMutation_mutation;

export type AttachmentFragmentFragment_Attachment_createdBy_User = {
  __typename?: 'User',
  name: string
};

export type AttachmentFragmentFragment = {
  __typename?: 'Attachment',
  id: string,
  filename: string,
  filesize?: number | null,
  contentType?: string | null,
  createdAt: string,
  createdBy?: AttachmentFragmentFragment_Attachment_createdBy_User | null
};

export type CreateAttachmentFragmentFragment = {
  __typename?: 'Attachment',
  id: string,
  uploadUrl?: string | null
};

export type DownloadAttachmentFragmentFragment = {
  __typename?: 'Attachment',
  downloadUrl?: string | null
};

export type CreateAttachmentMutation_mutation_createAttachment_Attachment = {
  __typename?: 'Attachment',
  id: string,
  uploadUrl?: string | null
};

export type CreateAttachmentMutation_mutation = {
  __typename?: 'mutation',
  createAttachment: CreateAttachmentMutation_mutation_createAttachment_Attachment
};


export type CreateAttachmentMutationVariables = Exact<{
  data: AttachmentCreationInput;
}>;


export type CreateAttachmentMutation = CreateAttachmentMutation_mutation;

export type UpdateAttachmentMutation_mutation_updateAttachment_Attachment = {
  __typename?: 'Attachment',
  id: string,
  filename: string,
  filesize?: number | null,
  contentType?: string | null,
  createdAt: string,
  createdBy?: AttachmentFragmentFragment_Attachment_createdBy_User | null
};

export type UpdateAttachmentMutation_mutation = {
  __typename?: 'mutation',
  updateAttachment: UpdateAttachmentMutation_mutation_updateAttachment_Attachment
};


export type UpdateAttachmentMutationVariables = Exact<{
  data: AttachmentUpdateInput;
  where: AttachmentUniqueFilterInput;
}>;


export type UpdateAttachmentMutation = UpdateAttachmentMutation_mutation;

export type CreateRawArticleMutation_mutation_createRawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string
};

export type CreateRawArticleMutation_mutation = {
  __typename?: 'mutation',
  createRawArticle: CreateRawArticleMutation_mutation_createRawArticle_RawArticle
};


export type CreateRawArticleMutationVariables = Exact<{
  data: RawArticleCreationInput;
}>;


export type CreateRawArticleMutation = CreateRawArticleMutation_mutation;

export type UpsertSubjectMutation_mutation_upsertSubject_Subject = {
  __typename?: 'Subject',
  id: string,
  title: string,
  comment?: string | null,
  synopsis?: string | null,
  mediaRequest?: string | null
};

export type UpsertSubjectMutation_mutation = {
  __typename?: 'mutation',
  upsertSubject: UpsertSubjectMutation_mutation_upsertSubject_Subject
};


export type UpsertSubjectMutationVariables = Exact<{
  create: SubjectCreationInput;
  update: SubjectUpdateInput;
  where: SubjectUniqueFilterInput;
}>;


export type UpsertSubjectMutation = UpsertSubjectMutation_mutation;

export type PrintSubject_FlatAssignmentFragment_FlatAssignment_assignment_Assignment = {
  __typename?: 'Assignment',
  id: string,
  mediaSearchStatus: MediaSearchStatus
};

export type PrintSubject_FlatAssignmentFragment_FlatAssignment_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type PrintSubject_FlatAssignmentFragment_FlatAssignment_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string
};

export type PrintSubject_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string
};

export type PrintSubject_FlatAssignmentFragment_FlatAssignment_website_Website = {
  __typename?: 'Website',
  id: string,
  key: string,
  title: string
};

export type PrintSubject_FlatAssignmentFragment = {
  __typename?: 'FlatAssignment',
  assignment: PrintSubject_FlatAssignmentFragment_FlatAssignment_assignment_Assignment,
  printPublication?: PrintSubject_FlatAssignmentFragment_FlatAssignment_printPublication_PrintPublication | null,
  printIssue?: PrintSubject_FlatAssignmentFragment_FlatAssignment_printIssue_PrintIssue | null,
  printHeading?: PrintSubject_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading | null,
  website?: PrintSubject_FlatAssignmentFragment_FlatAssignment_website_Website | null
};

export type GetPrintSubjectQuery_query_subject_Subject_subjectCategory_SubjectCategory_parent_SubjectCategory = {
  __typename?: 'SubjectCategory',
  title: string
};

export type GetPrintSubjectQuery_query_subject_Subject_subjectCategory_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string,
  parent?: GetPrintSubjectQuery_query_subject_Subject_subjectCategory_SubjectCategory_parent_SubjectCategory | null
};

export type GetPrintSubjectQuery_query_subject_Subject_assignments_FlatAssignment = {
  __typename?: 'FlatAssignment',
  assignment: PrintSubject_FlatAssignmentFragment_FlatAssignment_assignment_Assignment,
  printPublication?: PrintSubject_FlatAssignmentFragment_FlatAssignment_printPublication_PrintPublication | null,
  printIssue?: PrintSubject_FlatAssignmentFragment_FlatAssignment_printIssue_PrintIssue | null,
  printHeading?: PrintSubject_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading | null,
  website?: PrintSubject_FlatAssignmentFragment_FlatAssignment_website_Website | null
};

export type GetPrintSubjectQuery_query_subject_Subject = {
  __typename?: 'Subject',
  title: string,
  synopsis?: string | null,
  comment?: string | null,
  mediaRequest?: string | null,
  subjectCategory?: GetPrintSubjectQuery_query_subject_Subject_subjectCategory_SubjectCategory | null,
  assignments: Array<GetPrintSubjectQuery_query_subject_Subject_assignments_FlatAssignment>
};

export type GetPrintSubjectQuery_query = {
  __typename?: 'query',
  subject: GetPrintSubjectQuery_query_subject_Subject
};


export type GetPrintSubjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPrintSubjectQuery = GetPrintSubjectQuery_query;

export type GetSubjectCategoryQuery_query_subjectCategories_SubjectCategory_parent_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string
};

export type GetSubjectCategoryQuery_query_subjectCategories_SubjectCategory_children_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string
};

export type GetSubjectCategoryQuery_query_subjectCategories_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string,
  parent?: GetSubjectCategoryQuery_query_subjectCategories_SubjectCategory_parent_SubjectCategory | null,
  children: Array<GetSubjectCategoryQuery_query_subjectCategories_SubjectCategory_children_SubjectCategory>
};

export type GetSubjectCategoryQuery_query = {
  __typename?: 'query',
  subjectCategories: Array<GetSubjectCategoryQuery_query_subjectCategories_SubjectCategory>
};


export type GetSubjectCategoryQueryVariables = Exact<{
  unitId: Scalars['ID']['input'];
}>;


export type GetSubjectCategoryQuery = GetSubjectCategoryQuery_query;

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_assignment_Assignment_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_assignment_Assignment_articleTemplate_ArticleTemplate = {
  __typename?: 'ArticleTemplate',
  id: string
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_assignment_Assignment = {
  __typename?: 'Assignment',
  id: string,
  mediaSearchStatus: MediaSearchStatus,
  pageNumber?: number | null,
  printTemplate?: SubjectForm_FlatAssignmentFragment_FlatAssignment_assignment_Assignment_printTemplate_PrintTemplate | null,
  articleTemplate?: SubjectForm_FlatAssignmentFragment_FlatAssignment_assignment_Assignment_articleTemplate_ArticleTemplate | null
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  key: string,
  title: string
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: SubjectForm_FlatAssignmentFragment_FlatAssignment_printIssue_PrintIssue_printPublication_PrintPublication
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string,
  type?: TemplateType | null
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  printTemplate: SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printPublication: SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading_printIssue_PrintIssue_printPublication_PrintPublication
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  autoPrototype?: boolean | null,
  printTemplates: Array<SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading_printTemplates_PrintHeadingPrintTemplate>,
  printIssue: SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading_printIssue_PrintIssue
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_rawArticle_RawArticle_versions_RawArticleVersion_editor_User = {
  __typename?: 'User',
  id: string,
  name: string
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_rawArticle_RawArticle_versions_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  status: RawArticleStatus,
  title?: string | null,
  createdAt: string,
  editor?: SubjectForm_FlatAssignmentFragment_FlatAssignment_rawArticle_RawArticle_versions_RawArticleVersion_editor_User | null
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_rawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string,
  versions: Array<SubjectForm_FlatAssignmentFragment_FlatAssignment_rawArticle_RawArticle_versions_RawArticleVersion>
};

export type SubjectForm_FlatAssignmentFragment_FlatAssignment_website_Website = {
  __typename?: 'Website',
  id: string,
  key: string,
  title: string
};

export type SubjectForm_FlatAssignmentFragment = {
  __typename?: 'FlatAssignment',
  assignment: SubjectForm_FlatAssignmentFragment_FlatAssignment_assignment_Assignment,
  printPublication?: SubjectForm_FlatAssignmentFragment_FlatAssignment_printPublication_PrintPublication | null,
  printIssue?: SubjectForm_FlatAssignmentFragment_FlatAssignment_printIssue_PrintIssue | null,
  printHeading?: SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading | null,
  rawArticle?: SubjectForm_FlatAssignmentFragment_FlatAssignment_rawArticle_RawArticle | null,
  website?: SubjectForm_FlatAssignmentFragment_FlatAssignment_website_Website | null
};

export type GetSubjectQuery_query_subject_Subject_createdBy_User = {
  __typename?: 'User',
  name: string
};

export type GetSubjectQuery_query_subject_Subject_unit_Unit = {
  __typename?: 'Unit',
  id: string,
  title: string,
  photoArchiveId?: number | null
};

export type GetSubjectQuery_query_subject_Subject_subjectCategory_SubjectCategory_parent_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string
};

export type GetSubjectQuery_query_subject_Subject_subjectCategory_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  parent?: GetSubjectQuery_query_subject_Subject_subjectCategory_SubjectCategory_parent_SubjectCategory | null
};

export type GetSubjectQuery_query_subject_Subject_assignments_FlatAssignment = {
  __typename?: 'FlatAssignment',
  assignment: SubjectForm_FlatAssignmentFragment_FlatAssignment_assignment_Assignment,
  printPublication?: SubjectForm_FlatAssignmentFragment_FlatAssignment_printPublication_PrintPublication | null,
  printIssue?: SubjectForm_FlatAssignmentFragment_FlatAssignment_printIssue_PrintIssue | null,
  printHeading?: SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading | null,
  rawArticle?: SubjectForm_FlatAssignmentFragment_FlatAssignment_rawArticle_RawArticle | null,
  website?: SubjectForm_FlatAssignmentFragment_FlatAssignment_website_Website | null
};

export type GetSubjectQuery_query_subject_Subject_subjectUsers_SubjectUser = {
  __typename?: 'SubjectUser',
  userId: string,
  user?: SubjectUserFragment_SubjectUser_user_User | null
};

export type GetSubjectQuery_query_subject_Subject_attachments_Attachment = {
  __typename?: 'Attachment',
  id: string,
  filename: string,
  filesize?: number | null,
  contentType?: string | null,
  createdAt: string,
  createdBy?: AttachmentFragmentFragment_Attachment_createdBy_User | null
};

export type GetSubjectQuery_query_subject_Subject = {
  __typename?: 'Subject',
  id: string,
  title: string,
  synopsis?: string | null,
  comment?: string | null,
  mediaRequest?: string | null,
  isClassified: boolean,
  isTitleWritable: boolean,
  createdBy?: GetSubjectQuery_query_subject_Subject_createdBy_User | null,
  unit: GetSubjectQuery_query_subject_Subject_unit_Unit,
  subjectCategory?: GetSubjectQuery_query_subject_Subject_subjectCategory_SubjectCategory | null,
  assignments: Array<GetSubjectQuery_query_subject_Subject_assignments_FlatAssignment>,
  subjectUsers: Array<GetSubjectQuery_query_subject_Subject_subjectUsers_SubjectUser>,
  attachments: Array<GetSubjectQuery_query_subject_Subject_attachments_Attachment>
};

export type GetSubjectQuery_query = {
  __typename?: 'query',
  subject: GetSubjectQuery_query_subject_Subject
};


export type GetSubjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetSubjectQuery = GetSubjectQuery_query;

export type SubjectListPage_GetCategoryTreeQuery_query_subjectCategories_SubjectCategory_children_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string
};

export type SubjectListPage_GetCategoryTreeQuery_query_subjectCategories_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string,
  children: Array<SubjectListPage_GetCategoryTreeQuery_query_subjectCategories_SubjectCategory_children_SubjectCategory>
};

export type SubjectListPage_GetCategoryTreeQuery_query = {
  __typename?: 'query',
  subjectCategories: Array<SubjectListPage_GetCategoryTreeQuery_query_subjectCategories_SubjectCategory>
};


export type SubjectListPage_GetCategoryTreeQueryVariables = Exact<{ [key: string]: never; }>;


export type SubjectListPage_GetCategoryTreeQuery = SubjectListPage_GetCategoryTreeQuery_query;

export type SubjectListPage_GetFilteredCategoryTreeQuery_query_subjectCategories_SubjectCategory_children_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string
};

export type SubjectListPage_GetFilteredCategoryTreeQuery_query_subjectCategories_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string,
  children: Array<SubjectListPage_GetFilteredCategoryTreeQuery_query_subjectCategories_SubjectCategory_children_SubjectCategory>
};

export type SubjectListPage_GetFilteredCategoryTreeQuery_query = {
  __typename?: 'query',
  subjectCategories: Array<SubjectListPage_GetFilteredCategoryTreeQuery_query_subjectCategories_SubjectCategory>
};


export type SubjectListPage_GetFilteredCategoryTreeQueryVariables = Exact<{
  where: SubjectCategoryFilterInput;
}>;


export type SubjectListPage_GetFilteredCategoryTreeQuery = SubjectListPage_GetFilteredCategoryTreeQuery_query;

export type SubjectListPage_GetFilteredLocationTreeQuery_query_brands_Brand_printPublications_PrintPublication_printIssues_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string
};

export type SubjectListPage_GetFilteredLocationTreeQuery_query_brands_Brand_printPublications_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string,
  printIssues: Array<SubjectListPage_GetFilteredLocationTreeQuery_query_brands_Brand_printPublications_PrintPublication_printIssues_PrintIssue>
};

export type SubjectListPage_GetFilteredLocationTreeQuery_query_brands_Brand_websites_Website = {
  __typename?: 'Website',
  id: string,
  title: string,
  key: string
};

export type SubjectListPage_GetFilteredLocationTreeQuery_query_brands_Brand = {
  __typename?: 'Brand',
  id: string,
  title: string,
  printPublications: Array<SubjectListPage_GetFilteredLocationTreeQuery_query_brands_Brand_printPublications_PrintPublication>,
  websites: Array<SubjectListPage_GetFilteredLocationTreeQuery_query_brands_Brand_websites_Website>
};

export type SubjectListPage_GetFilteredLocationTreeQuery_query = {
  __typename?: 'query',
  brands: Array<SubjectListPage_GetFilteredLocationTreeQuery_query_brands_Brand>
};


export type SubjectListPage_GetFilteredLocationTreeQueryVariables = Exact<{
  searchString: Scalars['NonEmptyString']['input'];
  where: BrandFilterInput;
}>;


export type SubjectListPage_GetFilteredLocationTreeQuery = SubjectListPage_GetFilteredLocationTreeQuery_query;

export type SubjectListPage_GetLocationTreeQuery_query_brands_Brand_printPublications_PrintPublication_printIssues_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string
};

export type SubjectListPage_GetLocationTreeQuery_query_brands_Brand_printPublications_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string,
  printIssues: Array<SubjectListPage_GetLocationTreeQuery_query_brands_Brand_printPublications_PrintPublication_printIssues_PrintIssue>
};

export type SubjectListPage_GetLocationTreeQuery_query_brands_Brand_websites_Website = {
  __typename?: 'Website',
  id: string,
  title: string,
  key: string
};

export type SubjectListPage_GetLocationTreeQuery_query_brands_Brand = {
  __typename?: 'Brand',
  id: string,
  title: string,
  printPublications: Array<SubjectListPage_GetLocationTreeQuery_query_brands_Brand_printPublications_PrintPublication>,
  websites: Array<SubjectListPage_GetLocationTreeQuery_query_brands_Brand_websites_Website>
};

export type SubjectListPage_GetLocationTreeQuery_query = {
  __typename?: 'query',
  brands: Array<SubjectListPage_GetLocationTreeQuery_query_brands_Brand>
};


export type SubjectListPage_GetLocationTreeQueryVariables = Exact<{
  where: BrandFilterInput;
}>;


export type SubjectListPage_GetLocationTreeQuery = SubjectListPage_GetLocationTreeQuery_query;

export type GetSubjectsQuery_query_subjects_Subject_assignments_Assignment = {
  __typename?: 'Assignment',
  id: string,
  mediaSearchStatus: MediaSearchStatus,
  printTemplate?: AssignmentItemFragment_Assignment_printTemplate_PrintTemplate | null,
  articleTemplate?: AssignmentItemFragment_Assignment_articleTemplate_ArticleTemplate | null,
  rawArticle?: AssignmentItemFragment_Assignment_rawArticle_RawArticle | null,
  printHeading?: AssignmentItemFragment_Assignment_printHeading_PrintHeading | null,
  printIssue?: AssignmentItemFragment_Assignment_printIssue_PrintIssue | null,
  printPublication?: AssignmentItemFragment_Assignment_printPublication_PrintPublication | null,
  website?: AssignmentItemFragment_Assignment_website_Website | null,
  subject: AssignmentItemFragment_Assignment_subject_Subject
};

export type GetSubjectsQuery_query_subjects_Subject_subjectCategory_SubjectCategory_parent_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string
};

export type GetSubjectsQuery_query_subjects_Subject_subjectCategory_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string,
  parent?: GetSubjectsQuery_query_subjects_Subject_subjectCategory_SubjectCategory_parent_SubjectCategory | null
};

export type GetSubjectsQuery_query_subjects_Subject_unit_Unit_brands_Brand = {
  __typename?: 'Brand',
  id: string,
  title: string
};

export type GetSubjectsQuery_query_subjects_Subject_unit_Unit = {
  __typename?: 'Unit',
  id: string,
  title: string,
  photoArchiveId?: number | null,
  brands: Array<GetSubjectsQuery_query_subjects_Subject_unit_Unit_brands_Brand>
};

export type GetSubjectsQuery_query_subjects_Subject = {
  __typename?: 'Subject',
  id: string,
  title: string,
  comment?: string | null,
  assignmentCount: number,
  isClassified: boolean,
  assignments: Array<GetSubjectsQuery_query_subjects_Subject_assignments_Assignment>,
  subjectCategory?: GetSubjectsQuery_query_subjects_Subject_subjectCategory_SubjectCategory | null,
  unit: GetSubjectsQuery_query_subjects_Subject_unit_Unit
};

export type GetSubjectsQuery_query = {
  __typename?: 'query',
  subjects: Array<GetSubjectsQuery_query_subjects_Subject>
};


export type GetSubjectsQueryVariables = Exact<{
  where: SubjectFilterInput;
  skip: Scalars['UnsignedInt']['input'];
  first?: InputMaybe<Scalars['UnsignedInt']['input']>;
}>;


export type GetSubjectsQuery = GetSubjectsQuery_query;

export type SubjectListPage_GetPrintPublicationsByUnitQuery_query_units_Unit_brands_Brand_printPublications_PrintPublication = {
  __typename?: 'PrintPublication',
  id: string,
  title: string
};

export type SubjectListPage_GetPrintPublicationsByUnitQuery_query_units_Unit_brands_Brand = {
  __typename?: 'Brand',
  title: string,
  printPublications: Array<SubjectListPage_GetPrintPublicationsByUnitQuery_query_units_Unit_brands_Brand_printPublications_PrintPublication>
};

export type SubjectListPage_GetPrintPublicationsByUnitQuery_query_units_Unit = {
  __typename?: 'Unit',
  id: string,
  title: string,
  brands: Array<SubjectListPage_GetPrintPublicationsByUnitQuery_query_units_Unit_brands_Brand>
};

export type SubjectListPage_GetPrintPublicationsByUnitQuery_query = {
  __typename?: 'query',
  units: Array<SubjectListPage_GetPrintPublicationsByUnitQuery_query_units_Unit>
};


export type SubjectListPage_GetPrintPublicationsByUnitQueryVariables = Exact<{ [key: string]: never; }>;


export type SubjectListPage_GetPrintPublicationsByUnitQuery = SubjectListPage_GetPrintPublicationsByUnitQuery_query;

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  type?: TemplateType | null
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_printTemplates_PrintHeadingPrintTemplate = {
  __typename?: 'PrintHeadingPrintTemplate',
  printTemplate: GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_printTemplates_PrintHeadingPrintTemplate_printTemplate_PrintTemplate
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_printTemplate_PrintTemplate = {
  __typename?: 'PrintTemplate',
  id: string
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_subject_Subject_subjectCategory_SubjectCategory_parent_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_subject_Subject_subjectCategory_SubjectCategory = {
  __typename?: 'SubjectCategory',
  id: string,
  title: string,
  parent?: GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_subject_Subject_subjectCategory_SubjectCategory_parent_SubjectCategory | null
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_subject_Subject_assignments_FlatAssignment = {
  __typename?: 'FlatAssignment',
  assignment: SubjectForm_FlatAssignmentFragment_FlatAssignment_assignment_Assignment,
  printPublication?: SubjectForm_FlatAssignmentFragment_FlatAssignment_printPublication_PrintPublication | null,
  printIssue?: SubjectForm_FlatAssignmentFragment_FlatAssignment_printIssue_PrintIssue | null,
  printHeading?: SubjectForm_FlatAssignmentFragment_FlatAssignment_printHeading_PrintHeading | null,
  rawArticle?: SubjectForm_FlatAssignmentFragment_FlatAssignment_rawArticle_RawArticle | null,
  website?: SubjectForm_FlatAssignmentFragment_FlatAssignment_website_Website | null
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_subject_Subject = {
  __typename?: 'Subject',
  id: string,
  title: string,
  comment?: string | null,
  isClassified: boolean,
  subjectCategory?: GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_subject_Subject_subjectCategory_SubjectCategory | null,
  assignments: Array<GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_subject_Subject_assignments_FlatAssignment>
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_website_Website = {
  __typename?: 'Website',
  id: string,
  title: string,
  key: string
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_rawArticle_RawArticle_versions_RawArticleVersion_editor_User = {
  __typename?: 'User',
  id: string,
  name: string
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_rawArticle_RawArticle_versions_RawArticleVersion = {
  __typename?: 'RawArticleVersion',
  id: string,
  status: RawArticleStatus,
  title?: string | null,
  createdAt: string,
  editor?: GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_rawArticle_RawArticle_versions_RawArticleVersion_editor_User | null
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_rawArticle_RawArticle = {
  __typename?: 'RawArticle',
  id: string,
  versions: Array<GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_rawArticle_RawArticle_versions_RawArticleVersion>
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_articleTemplate_ArticleTemplate = {
  __typename?: 'ArticleTemplate',
  id: string
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment = {
  __typename?: 'Assignment',
  id: string,
  mediaSearchStatus: MediaSearchStatus,
  pageNumber?: number | null,
  printTemplate?: GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_printTemplate_PrintTemplate | null,
  subject: GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_subject_Subject,
  website?: GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_website_Website | null,
  rawArticle?: GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_rawArticle_RawArticle | null,
  articleTemplate?: GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment_articleTemplate_ArticleTemplate | null
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading = {
  __typename?: 'PrintHeading',
  id: string,
  title: string,
  autoPrototype?: boolean | null,
  printTemplates: Array<GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_printTemplates_PrintHeadingPrintTemplate>,
  assignments: Array<GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading_assignments_Assignment>
};

export type GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue = {
  __typename?: 'PrintIssue',
  id: string,
  title: string,
  printHeadings: Array<GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue_printHeadings_PrintHeading>
};

export type GetUnfilteredSubjectListByIssueQuery_query = {
  __typename?: 'query',
  printIssue: GetUnfilteredSubjectListByIssueQuery_query_printIssue_PrintIssue
};


export type GetUnfilteredSubjectListByIssueQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetUnfilteredSubjectListByIssueQuery = GetUnfilteredSubjectListByIssueQuery_query;

export const PrintTemplateFragmentDoc = gql`
    fragment PrintTemplate on PrintTemplate {
  id
  S3ObjectId
  label
  filename
  type
  filesize
  pageCount
  extVersion
  data
  fields {
    id
    name
    type
    enabled
    data
    typeVariation
    paragraphStyles {
      id
      name
    }
    characterStyles {
      id
      name
    }
    coordinates {
      x
      y
      width
      height
      rotation
    }
    page {
      offset
      number
      coordinates {
        x
        y
        width
        height
        rotation
      }
    }
  }
  createdAt
  updatedAt
  printHeadings(first: 1000) {
    id
    active
    printHeading {
      id
      title
      printIssue {
        id
        title
        printPublication {
          id
          title
          brand {
            id
            title
          }
        }
      }
    }
  }
  createdBy {
    name
  }
  updatedBy {
    name
  }
}
    `;
export const ArticleForm_RawArticleVersionFragmentFragmentDoc = gql`
    fragment ArticleForm_RawArticleVersionFragment on RawArticleVersion {
  allowedStatuses
  id
  title
  lead
  body
  signature
  leftOver
  status
  createdAt
  editor {
    id
    name
    avatarUrl
  }
  textIsReadOnly
}
    `;
export const PrintHeadingPrintTemplateFragmentDoc = gql`
    fragment PrintHeadingPrintTemplate on PrintHeadingPrintTemplate {
  printTemplate {
    id
    S3ObjectId
    label
    filename
    type
    filesize
    pageCount
    extVersion
    data
    fields {
      id
      name
      type
      enabled
      data
      typeVariation
      paragraphStyles {
        id
        name
      }
      characterStyles {
        id
        name
      }
      coordinates {
        x
        y
        width
        height
        rotation
      }
      page {
        offset
        number
        coordinates {
          x
          y
          width
          height
          rotation
        }
      }
    }
    createdAt
    updatedAt
    createdBy {
      name
    }
    updatedBy {
      name
    }
  }
}
    `;
export const ArticleForm_AssignmentFragmentDoc = gql`
    fragment ArticleForm_Assignment on Assignment {
  id
  printPublication {
    id
    title
  }
  printIssue {
    id
    title
    printPublication {
      id
      title
    }
  }
  printHeading {
    id
    title
    autoPrototype
    printTemplates(first: 50) {
      id
      active
      ...PrintHeadingPrintTemplate
    }
    printIssue {
      id
      title
      printPublication {
        id
        title
      }
    }
  }
  website {
    id
    title
    key
  }
}
    ${PrintHeadingPrintTemplateFragmentDoc}`;
export const PrintTemplateAssignmentFragmentDoc = gql`
    fragment PrintTemplateAssignment on Assignment {
  printTemplate {
    id
    label
    data
    fields {
      id
      name
      type
      page {
        offset
        coordinates {
          x
          y
          height
          width
          rotation
        }
      }
      data
      typeVariation
      paragraphStyles {
        id
        name
      }
      characterStyles {
        id
        name
      }
    }
  }
}
    `;
export const ArticleForm_RawArticleFragmentDoc = gql`
    fragment ArticleForm_RawArticle on RawArticle {
  id
  versions(first: 1, where: {nextVersion_is_null: true}) {
    ...ArticleForm_RawArticleVersionFragment
  }
  versionList: versions(first: 100, orderBy: _id_DESC) {
    id
    status
    editorId
    editor {
      id
      name
      avatarUrl
    }
  }
  assignment {
    ...ArticleForm_Assignment
    ...PrintTemplateAssignment
    subject {
      id
      title
      synopsis
      unit {
        id
        title
      }
    }
    articleTemplate {
      id
      label
      block
      fields {
        id
        name
        type
        enabled
        data
        typeVariation
        paragraphStyles {
          id
          name
        }
        characterStyles {
          id
          name
        }
        coordinates {
          x
          y
          width
          height
          rotation
        }
        page {
          offset
          number
          coordinates {
            x
            y
            width
            height
            rotation
          }
        }
      }
      printTemplate {
        id
        type
        fields {
          id
          name
          type
          page {
            offset
            coordinates {
              x
              y
              height
              width
              rotation
            }
          }
          data
          typeVariation
          paragraphStyles {
            id
            name
          }
          characterStyles {
            id
            name
          }
        }
      }
    }
    printTemplate {
      id
    }
  }
}
    ${ArticleForm_RawArticleVersionFragmentFragmentDoc}
${ArticleForm_AssignmentFragmentDoc}
${PrintTemplateAssignmentFragmentDoc}`;
export const RawArticleItemFragmentDoc = gql`
    fragment RawArticleItem on RawArticle {
  id
  versions(where: {nextVersion_is_null: true}, first: 1) {
    id
    title
    status
    createdAt
    editor {
      id
      name
    }
  }
}
    `;
export const AssignmentItemFragmentDoc = gql`
    fragment AssignmentItem on Assignment {
  id
  printTemplate {
    id
  }
  articleTemplate {
    id
  }
  mediaSearchStatus
  rawArticle {
    ...RawArticleItem
  }
  printHeading {
    id
    title
    autoPrototype
    printTemplates(first: 1, where: {active: true}) {
      printTemplate {
        type
      }
    }
    printIssue {
      id
      title
      printPublication {
        id
        title
      }
    }
  }
  printIssue {
    id
    title
    printPublication {
      id
      title
    }
  }
  printPublication {
    id
    title
  }
  website {
    id
    title
    key
  }
  subject {
    id
  }
}
    ${RawArticleItemFragmentDoc}`;
export const SubjectUserFragmentDoc = gql`
    fragment SubjectUser on SubjectUser {
  userId
  user {
    email
    name
    avatarUrl
  }
}
    `;
export const UnitSpeedDial_FragmentFragmentDoc = gql`
    fragment UnitSpeedDial_Fragment on Unit {
  id
  title
}
    `;
export const ArticleHistory_MajorVersionFragmentDoc = gql`
    fragment ArticleHistory_MajorVersion on RawArticleVersion {
  id
  status
  title
  lead
  body
  signature
  leftOver
  editorId
  createdAt
  editor {
    id
    name
    avatarUrl
  }
  previousMinorVersions(first: 150) {
    id
    editor {
      id
      name
      avatarUrl
    }
    createdAt
  }
}
    `;
export const PrintHeadingFragmentFragmentDoc = gql`
    fragment PrintHeadingFragment on PrintHeading {
  id
  title
  order
  hasAutomaticSubject
  autoPrototype
  printTemplateCount
  assignmentCount
  printTemplates(first: 50) {
    id
    active
    createdAt
    updatedAt
    printTemplate {
      id
    }
  }
}
    `;
export const PrintIssueFragmentFragmentDoc = gql`
    fragment PrintIssueFragment on PrintIssue {
  id
  title
  printHeadings(first: 1000, orderBy: order_ASC) {
    id
    title
    order
    hasAutomaticSubject
  }
}
    `;
export const PrintPublicationFragmentFragmentDoc = gql`
    fragment PrintPublicationFragment on PrintPublication {
  id
  title
  key
  brand {
    id
  }
}
    `;
export const AttachmentFragmentFragmentDoc = gql`
    fragment AttachmentFragment on Attachment {
  id
  filename
  filesize
  contentType
  createdAt
  createdBy {
    name
  }
}
    `;
export const CreateAttachmentFragmentFragmentDoc = gql`
    fragment CreateAttachmentFragment on Attachment {
  id
  uploadUrl
}
    `;
export const DownloadAttachmentFragmentFragmentDoc = gql`
    fragment DownloadAttachmentFragment on Attachment {
  downloadUrl
}
    `;
export const PrintSubject_FlatAssignmentFragmentDoc = gql`
    fragment PrintSubject_FlatAssignment on FlatAssignment {
  assignment {
    id
    mediaSearchStatus
  }
  printPublication {
    id
    title
  }
  printIssue {
    id
    title
  }
  printHeading {
    id
    title
  }
  website {
    id
    key
    title
  }
}
    `;
export const SubjectForm_FlatAssignmentFragmentDoc = gql`
    fragment SubjectForm_FlatAssignment on FlatAssignment {
  assignment {
    id
    printTemplate {
      id
    }
    articleTemplate {
      id
    }
    mediaSearchStatus
    pageNumber
  }
  printPublication {
    id
    key
    title
  }
  printIssue {
    id
    title
    printPublication {
      id
      title
    }
  }
  printHeading {
    id
    title
    autoPrototype
    printTemplates(first: 1, where: {active: true}) {
      printTemplate {
        id
        type
      }
    }
    printIssue {
      id
      title
      printPublication {
        id
        title
      }
    }
  }
  rawArticle {
    id
    versions(first: 1, where: {nextVersion_is_null: true}) {
      id
      status
      title
      editor {
        id
        name
      }
      createdAt
    }
  }
  website {
    id
    key
    title
  }
}
    `;
export const EmailArticleDocument = gql`
    mutation EmailArticle($rawArticle: ID!, $recipients: [String!]!, $comment: String) {
  emailRawArticle(rawArticle: {id: $rawArticle}, recipients: $recipients, comment: $comment) {
    success
  }
}
    `;
export type EmailArticleMutationFn = Apollo.MutationFunction<EmailArticleMutation, EmailArticleMutationVariables>;

/**
 * __useEmailArticleMutation__
 *
 * To run a mutation, you first call `useEmailArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailArticleMutation, { data, loading, error }] = useEmailArticleMutation({
 *   variables: {
 *      rawArticle: // value for 'rawArticle'
 *      recipients: // value for 'recipients'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useEmailArticleMutation(baseOptions?: Apollo.MutationHookOptions<EmailArticleMutation, EmailArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailArticleMutation, EmailArticleMutationVariables>(EmailArticleDocument, options);
      }
export type EmailArticleMutationHookResult = ReturnType<typeof useEmailArticleMutation>;
export type EmailArticleMutationResult = Apollo.MutationResult<EmailArticleMutation>;
export type EmailArticleMutationOptions = Apollo.BaseMutationOptions<EmailArticleMutation, EmailArticleMutationVariables>;
export const EmailSubjectDocument = gql`
    mutation EmailSubject($subject: ID!, $recipients: [String!]!, $comment: String) {
  emailSubject(subject: {id: $subject}, recipients: $recipients, comment: $comment) {
    success
  }
}
    `;
export type EmailSubjectMutationFn = Apollo.MutationFunction<EmailSubjectMutation, EmailSubjectMutationVariables>;

/**
 * __useEmailSubjectMutation__
 *
 * To run a mutation, you first call `useEmailSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailSubjectMutation, { data, loading, error }] = useEmailSubjectMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *      recipients: // value for 'recipients'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useEmailSubjectMutation(baseOptions?: Apollo.MutationHookOptions<EmailSubjectMutation, EmailSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailSubjectMutation, EmailSubjectMutationVariables>(EmailSubjectDocument, options);
      }
export type EmailSubjectMutationHookResult = ReturnType<typeof useEmailSubjectMutation>;
export type EmailSubjectMutationResult = Apollo.MutationResult<EmailSubjectMutation>;
export type EmailSubjectMutationOptions = Apollo.BaseMutationOptions<EmailSubjectMutation, EmailSubjectMutationVariables>;
export const ExportToCentshareDocument = gql`
    mutation ExportToCentshare($assignment: String!, $issue: String!, $subject: String!, $brand: String!, $photos: [String!]) {
  exportToCentshare(assignment: $assignment, issue: $issue, subject: $subject, brand: $brand, photos: $photos) {
    message
  }
}
    `;
export type ExportToCentshareMutationFn = Apollo.MutationFunction<ExportToCentshareMutation, ExportToCentshareMutationVariables>;

/**
 * __useExportToCentshareMutation__
 *
 * To run a mutation, you first call `useExportToCentshareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportToCentshareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportToCentshareMutation, { data, loading, error }] = useExportToCentshareMutation({
 *   variables: {
 *      assignment: // value for 'assignment'
 *      issue: // value for 'issue'
 *      subject: // value for 'subject'
 *      brand: // value for 'brand'
 *      photos: // value for 'photos'
 *   },
 * });
 */
export function useExportToCentshareMutation(baseOptions?: Apollo.MutationHookOptions<ExportToCentshareMutation, ExportToCentshareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportToCentshareMutation, ExportToCentshareMutationVariables>(ExportToCentshareDocument, options);
      }
export type ExportToCentshareMutationHookResult = ReturnType<typeof useExportToCentshareMutation>;
export type ExportToCentshareMutationResult = Apollo.MutationResult<ExportToCentshareMutation>;
export type ExportToCentshareMutationOptions = Apollo.BaseMutationOptions<ExportToCentshareMutation, ExportToCentshareMutationVariables>;
export const ExportPrintIssueToCenshareDocument = gql`
    mutation ExportPrintIssueToCenshare($printIssueId: String!) {
  exportPrintIssueToCenshare(printIssueId: $printIssueId) {
    message
  }
}
    `;
export type ExportPrintIssueToCenshareMutationFn = Apollo.MutationFunction<ExportPrintIssueToCenshareMutation, ExportPrintIssueToCenshareMutationVariables>;

/**
 * __useExportPrintIssueToCenshareMutation__
 *
 * To run a mutation, you first call `useExportPrintIssueToCenshareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportPrintIssueToCenshareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportPrintIssueToCenshareMutation, { data, loading, error }] = useExportPrintIssueToCenshareMutation({
 *   variables: {
 *      printIssueId: // value for 'printIssueId'
 *   },
 * });
 */
export function useExportPrintIssueToCenshareMutation(baseOptions?: Apollo.MutationHookOptions<ExportPrintIssueToCenshareMutation, ExportPrintIssueToCenshareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportPrintIssueToCenshareMutation, ExportPrintIssueToCenshareMutationVariables>(ExportPrintIssueToCenshareDocument, options);
      }
export type ExportPrintIssueToCenshareMutationHookResult = ReturnType<typeof useExportPrintIssueToCenshareMutation>;
export type ExportPrintIssueToCenshareMutationResult = Apollo.MutationResult<ExportPrintIssueToCenshareMutation>;
export type ExportPrintIssueToCenshareMutationOptions = Apollo.BaseMutationOptions<ExportPrintIssueToCenshareMutation, ExportPrintIssueToCenshareMutationVariables>;
export const UpdateAssignmentPageNumberDocument = gql`
    mutation updateAssignmentPageNumber($assignmentId: ID!, $pageNumber: Int!) {
  updateAssignment(data: {pageNumber: $pageNumber}, where: {id: $assignmentId}) {
    id
  }
}
    `;
export type UpdateAssignmentPageNumberMutationFn = Apollo.MutationFunction<UpdateAssignmentPageNumberMutation, UpdateAssignmentPageNumberMutationVariables>;

/**
 * __useUpdateAssignmentPageNumberMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentPageNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentPageNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentPageNumberMutation, { data, loading, error }] = useUpdateAssignmentPageNumberMutation({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      pageNumber: // value for 'pageNumber'
 *   },
 * });
 */
export function useUpdateAssignmentPageNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignmentPageNumberMutation, UpdateAssignmentPageNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssignmentPageNumberMutation, UpdateAssignmentPageNumberMutationVariables>(UpdateAssignmentPageNumberDocument, options);
      }
export type UpdateAssignmentPageNumberMutationHookResult = ReturnType<typeof useUpdateAssignmentPageNumberMutation>;
export type UpdateAssignmentPageNumberMutationResult = Apollo.MutationResult<UpdateAssignmentPageNumberMutation>;
export type UpdateAssignmentPageNumberMutationOptions = Apollo.BaseMutationOptions<UpdateAssignmentPageNumberMutation, UpdateAssignmentPageNumberMutationVariables>;
export const UpdateAssignmentDocument = gql`
    mutation UpdateAssignment($where: AssignmentUniqueFilterInput!, $data: AssignmentUpdateInput!) {
  updateAssignment(where: $where, data: $data) {
    id
    rawArticle {
      id
      versions(first: 1, where: {nextVersion_is_null: true}) {
        ...ArticleForm_RawArticleVersionFragment
      }
      versionList: versions(first: 100, orderBy: _id_DESC) {
        id
        status
        editorId
        editor {
          id
          name
          avatarUrl
        }
      }
    }
  }
}
    ${ArticleForm_RawArticleVersionFragmentFragmentDoc}`;
export type UpdateAssignmentMutationFn = Apollo.MutationFunction<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(UpdateAssignmentDocument, options);
      }
export type UpdateAssignmentMutationHookResult = ReturnType<typeof useUpdateAssignmentMutation>;
export type UpdateAssignmentMutationResult = Apollo.MutationResult<UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = Apollo.BaseMutationOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;
export const GetAssignmentDocument = gql`
    query GetAssignment($assignmentId: ID!) {
  assignment(where: {id: $assignmentId}) {
    id
    printTemplate {
      id
    }
    printPublication {
      id
      title
    }
    subject {
      id
      title
      unit {
        id
        title
      }
    }
    printHeading {
      id
      autoPrototype
      printTemplates(first: 50) {
        id
        active
        ...PrintHeadingPrintTemplate
      }
      printIssue {
        id
        title
        printPublication {
          id
          title
        }
      }
    }
  }
}
    ${PrintHeadingPrintTemplateFragmentDoc}`;

/**
 * __useGetAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGetAssignmentQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables> & ({ variables: GetAssignmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
      }
export function useGetAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
        }
export function useGetAssignmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
        }
export type GetAssignmentQueryHookResult = ReturnType<typeof useGetAssignmentQuery>;
export type GetAssignmentLazyQueryHookResult = ReturnType<typeof useGetAssignmentLazyQuery>;
export type GetAssignmentSuspenseQueryHookResult = ReturnType<typeof useGetAssignmentSuspenseQuery>;
export type GetAssignmentQueryResult = Apollo.QueryResult<GetAssignmentQuery, GetAssignmentQueryVariables>;
export const GetPrintHeadingByPrintIssueDocument = gql`
    query GetPrintHeadingByPrintIssue($id: ID!) {
  printIssue(where: {id: $id}) {
    id
    title
    printHeadings(first: 1000, orderBy: order_ASC) {
      id
      title
      autoPrototype
      printTemplates(first: 1, where: {active: true}) {
        printTemplate {
          id
          type
        }
      }
    }
  }
}
    `;

/**
 * __useGetPrintHeadingByPrintIssueQuery__
 *
 * To run a query within a React component, call `useGetPrintHeadingByPrintIssueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintHeadingByPrintIssueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintHeadingByPrintIssueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrintHeadingByPrintIssueQuery(baseOptions: Apollo.QueryHookOptions<GetPrintHeadingByPrintIssueQuery, GetPrintHeadingByPrintIssueQueryVariables> & ({ variables: GetPrintHeadingByPrintIssueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrintHeadingByPrintIssueQuery, GetPrintHeadingByPrintIssueQueryVariables>(GetPrintHeadingByPrintIssueDocument, options);
      }
export function useGetPrintHeadingByPrintIssueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrintHeadingByPrintIssueQuery, GetPrintHeadingByPrintIssueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrintHeadingByPrintIssueQuery, GetPrintHeadingByPrintIssueQueryVariables>(GetPrintHeadingByPrintIssueDocument, options);
        }
export function useGetPrintHeadingByPrintIssueSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrintHeadingByPrintIssueQuery, GetPrintHeadingByPrintIssueQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrintHeadingByPrintIssueQuery, GetPrintHeadingByPrintIssueQueryVariables>(GetPrintHeadingByPrintIssueDocument, options);
        }
export type GetPrintHeadingByPrintIssueQueryHookResult = ReturnType<typeof useGetPrintHeadingByPrintIssueQuery>;
export type GetPrintHeadingByPrintIssueLazyQueryHookResult = ReturnType<typeof useGetPrintHeadingByPrintIssueLazyQuery>;
export type GetPrintHeadingByPrintIssueSuspenseQueryHookResult = ReturnType<typeof useGetPrintHeadingByPrintIssueSuspenseQuery>;
export type GetPrintHeadingByPrintIssueQueryResult = Apollo.QueryResult<GetPrintHeadingByPrintIssueQuery, GetPrintHeadingByPrintIssueQueryVariables>;
export const GetPrintIssuesByPrintPublicationDocument = gql`
    query GetPrintIssuesByPrintPublication($id: ID!, $excludeIds: [ID!]) {
  printPublication(where: {id: $id}) {
    id
    title
    printIssues(first: 1000, orderBy: createdAt_DESC, where: {id_not_in: $excludeIds}) {
      id
      title
    }
  }
}
    `;

/**
 * __useGetPrintIssuesByPrintPublicationQuery__
 *
 * To run a query within a React component, call `useGetPrintIssuesByPrintPublicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintIssuesByPrintPublicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintIssuesByPrintPublicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useGetPrintIssuesByPrintPublicationQuery(baseOptions: Apollo.QueryHookOptions<GetPrintIssuesByPrintPublicationQuery, GetPrintIssuesByPrintPublicationQueryVariables> & ({ variables: GetPrintIssuesByPrintPublicationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrintIssuesByPrintPublicationQuery, GetPrintIssuesByPrintPublicationQueryVariables>(GetPrintIssuesByPrintPublicationDocument, options);
      }
export function useGetPrintIssuesByPrintPublicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrintIssuesByPrintPublicationQuery, GetPrintIssuesByPrintPublicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrintIssuesByPrintPublicationQuery, GetPrintIssuesByPrintPublicationQueryVariables>(GetPrintIssuesByPrintPublicationDocument, options);
        }
export function useGetPrintIssuesByPrintPublicationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrintIssuesByPrintPublicationQuery, GetPrintIssuesByPrintPublicationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrintIssuesByPrintPublicationQuery, GetPrintIssuesByPrintPublicationQueryVariables>(GetPrintIssuesByPrintPublicationDocument, options);
        }
export type GetPrintIssuesByPrintPublicationQueryHookResult = ReturnType<typeof useGetPrintIssuesByPrintPublicationQuery>;
export type GetPrintIssuesByPrintPublicationLazyQueryHookResult = ReturnType<typeof useGetPrintIssuesByPrintPublicationLazyQuery>;
export type GetPrintIssuesByPrintPublicationSuspenseQueryHookResult = ReturnType<typeof useGetPrintIssuesByPrintPublicationSuspenseQuery>;
export type GetPrintIssuesByPrintPublicationQueryResult = Apollo.QueryResult<GetPrintIssuesByPrintPublicationQuery, GetPrintIssuesByPrintPublicationQueryVariables>;
export const GetPrintPublicationByUnitDocument = gql`
    query GetPrintPublicationByUnit($unitId: ID!) {
  units(where: {id: $unitId}, first: 20) {
    id
    title
    brands(first: 20) {
      id
      title
      printPublications(first: 20) {
        id
        title
      }
      websites(first: 20) {
        id
        title
        key
      }
    }
  }
}
    `;

/**
 * __useGetPrintPublicationByUnitQuery__
 *
 * To run a query within a React component, call `useGetPrintPublicationByUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintPublicationByUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintPublicationByUnitQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useGetPrintPublicationByUnitQuery(baseOptions: Apollo.QueryHookOptions<GetPrintPublicationByUnitQuery, GetPrintPublicationByUnitQueryVariables> & ({ variables: GetPrintPublicationByUnitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrintPublicationByUnitQuery, GetPrintPublicationByUnitQueryVariables>(GetPrintPublicationByUnitDocument, options);
      }
export function useGetPrintPublicationByUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrintPublicationByUnitQuery, GetPrintPublicationByUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrintPublicationByUnitQuery, GetPrintPublicationByUnitQueryVariables>(GetPrintPublicationByUnitDocument, options);
        }
export function useGetPrintPublicationByUnitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrintPublicationByUnitQuery, GetPrintPublicationByUnitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrintPublicationByUnitQuery, GetPrintPublicationByUnitQueryVariables>(GetPrintPublicationByUnitDocument, options);
        }
export type GetPrintPublicationByUnitQueryHookResult = ReturnType<typeof useGetPrintPublicationByUnitQuery>;
export type GetPrintPublicationByUnitLazyQueryHookResult = ReturnType<typeof useGetPrintPublicationByUnitLazyQuery>;
export type GetPrintPublicationByUnitSuspenseQueryHookResult = ReturnType<typeof useGetPrintPublicationByUnitSuspenseQuery>;
export type GetPrintPublicationByUnitQueryResult = Apollo.QueryResult<GetPrintPublicationByUnitQuery, GetPrintPublicationByUnitQueryVariables>;
export const GetPrintHeadingByIdDocument = gql`
    query GetPrintHeadingById($id: ID!) {
  printHeading(where: {id: $id}) {
    title
    createdAt
    assignments(first: 20) {
      id
      articleTemplate {
        id
      }
      subject {
        id
        title
      }
      rawArticle {
        id
        lastVersion {
          id
          title
          lead
          body
          signature
          leftOver
          status
          textIsReadOnly
        }
      }
    }
    printTemplates(first: 50) {
      id
      active
      printTemplate {
        id
        label
        type
        articleTemplates(first: 10) {
          id
          label
          block
          fields {
            id
            name
            type
            data
            enabled
            page {
              offset
              number
              coordinates {
                x
                y
                width
                height
                rotation
              }
            }
          }
          assignments(first: 1, where: {printHeading: {id: $id}}) {
            id
            subject {
              id
              title
            }
            rawArticle {
              id
              lastVersion {
                id
                status
                body
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPrintHeadingByIdQuery__
 *
 * To run a query within a React component, call `useGetPrintHeadingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintHeadingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintHeadingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrintHeadingByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPrintHeadingByIdQuery, GetPrintHeadingByIdQueryVariables> & ({ variables: GetPrintHeadingByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrintHeadingByIdQuery, GetPrintHeadingByIdQueryVariables>(GetPrintHeadingByIdDocument, options);
      }
export function useGetPrintHeadingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrintHeadingByIdQuery, GetPrintHeadingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrintHeadingByIdQuery, GetPrintHeadingByIdQueryVariables>(GetPrintHeadingByIdDocument, options);
        }
export function useGetPrintHeadingByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrintHeadingByIdQuery, GetPrintHeadingByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrintHeadingByIdQuery, GetPrintHeadingByIdQueryVariables>(GetPrintHeadingByIdDocument, options);
        }
export type GetPrintHeadingByIdQueryHookResult = ReturnType<typeof useGetPrintHeadingByIdQuery>;
export type GetPrintHeadingByIdLazyQueryHookResult = ReturnType<typeof useGetPrintHeadingByIdLazyQuery>;
export type GetPrintHeadingByIdSuspenseQueryHookResult = ReturnType<typeof useGetPrintHeadingByIdSuspenseQuery>;
export type GetPrintHeadingByIdQueryResult = Apollo.QueryResult<GetPrintHeadingByIdQuery, GetPrintHeadingByIdQueryVariables>;
export const CreatePrintTemplateDocument = gql`
    mutation CreatePrintTemplate($data: PrintTemplateCreationInput!) {
  createPrintTemplate(data: $data) {
    ...PrintTemplate
  }
}
    ${PrintTemplateFragmentDoc}`;
export type CreatePrintTemplateMutationFn = Apollo.MutationFunction<CreatePrintTemplateMutation, CreatePrintTemplateMutationVariables>;

/**
 * __useCreatePrintTemplateMutation__
 *
 * To run a mutation, you first call `useCreatePrintTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrintTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrintTemplateMutation, { data, loading, error }] = useCreatePrintTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePrintTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrintTemplateMutation, CreatePrintTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrintTemplateMutation, CreatePrintTemplateMutationVariables>(CreatePrintTemplateDocument, options);
      }
export type CreatePrintTemplateMutationHookResult = ReturnType<typeof useCreatePrintTemplateMutation>;
export type CreatePrintTemplateMutationResult = Apollo.MutationResult<CreatePrintTemplateMutation>;
export type CreatePrintTemplateMutationOptions = Apollo.BaseMutationOptions<CreatePrintTemplateMutation, CreatePrintTemplateMutationVariables>;
export const UpdatePrintTemplateDocument = gql`
    mutation UpdatePrintTemplate($data: PrintTemplateUpdateInput!, $where: PrintTemplateUniqueFilterInput!) {
  updatePrintTemplate(data: $data, where: $where) {
    ...PrintTemplate
  }
}
    ${PrintTemplateFragmentDoc}`;
export type UpdatePrintTemplateMutationFn = Apollo.MutationFunction<UpdatePrintTemplateMutation, UpdatePrintTemplateMutationVariables>;

/**
 * __useUpdatePrintTemplateMutation__
 *
 * To run a mutation, you first call `useUpdatePrintTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrintTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrintTemplateMutation, { data, loading, error }] = useUpdatePrintTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePrintTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrintTemplateMutation, UpdatePrintTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrintTemplateMutation, UpdatePrintTemplateMutationVariables>(UpdatePrintTemplateDocument, options);
      }
export type UpdatePrintTemplateMutationHookResult = ReturnType<typeof useUpdatePrintTemplateMutation>;
export type UpdatePrintTemplateMutationResult = Apollo.MutationResult<UpdatePrintTemplateMutation>;
export type UpdatePrintTemplateMutationOptions = Apollo.BaseMutationOptions<UpdatePrintTemplateMutation, UpdatePrintTemplateMutationVariables>;
export const DetachPrintTemplateDocument = gql`
    mutation DetachPrintTemplate($id: ID!) {
  deletePrintHeadingPrintTemplate(where: {id: $id}) {
    id
  }
}
    `;
export type DetachPrintTemplateMutationFn = Apollo.MutationFunction<DetachPrintTemplateMutation, DetachPrintTemplateMutationVariables>;

/**
 * __useDetachPrintTemplateMutation__
 *
 * To run a mutation, you first call `useDetachPrintTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachPrintTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachPrintTemplateMutation, { data, loading, error }] = useDetachPrintTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetachPrintTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DetachPrintTemplateMutation, DetachPrintTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DetachPrintTemplateMutation, DetachPrintTemplateMutationVariables>(DetachPrintTemplateDocument, options);
      }
export type DetachPrintTemplateMutationHookResult = ReturnType<typeof useDetachPrintTemplateMutation>;
export type DetachPrintTemplateMutationResult = Apollo.MutationResult<DetachPrintTemplateMutation>;
export type DetachPrintTemplateMutationOptions = Apollo.BaseMutationOptions<DetachPrintTemplateMutation, DetachPrintTemplateMutationVariables>;
export const UpdatePrintHeadingPrintTemplateDocument = gql`
    mutation UpdatePrintHeadingPrintTemplate($data: PrintHeadingPrintTemplateUpdateInput!, $where: PrintHeadingPrintTemplateUniqueFilterInput!) {
  updatePrintHeadingPrintTemplate(data: $data, where: $where) {
    id
    active
  }
}
    `;
export type UpdatePrintHeadingPrintTemplateMutationFn = Apollo.MutationFunction<UpdatePrintHeadingPrintTemplateMutation, UpdatePrintHeadingPrintTemplateMutationVariables>;

/**
 * __useUpdatePrintHeadingPrintTemplateMutation__
 *
 * To run a mutation, you first call `useUpdatePrintHeadingPrintTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrintHeadingPrintTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrintHeadingPrintTemplateMutation, { data, loading, error }] = useUpdatePrintHeadingPrintTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePrintHeadingPrintTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrintHeadingPrintTemplateMutation, UpdatePrintHeadingPrintTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrintHeadingPrintTemplateMutation, UpdatePrintHeadingPrintTemplateMutationVariables>(UpdatePrintHeadingPrintTemplateDocument, options);
      }
export type UpdatePrintHeadingPrintTemplateMutationHookResult = ReturnType<typeof useUpdatePrintHeadingPrintTemplateMutation>;
export type UpdatePrintHeadingPrintTemplateMutationResult = Apollo.MutationResult<UpdatePrintHeadingPrintTemplateMutation>;
export type UpdatePrintHeadingPrintTemplateMutationOptions = Apollo.BaseMutationOptions<UpdatePrintHeadingPrintTemplateMutation, UpdatePrintHeadingPrintTemplateMutationVariables>;
export const GetPrintHeadingDocument = gql`
    query GetPrintHeading($id: ID) {
  printHeading(where: {id: $id}) {
    id
    title
    order
    legacyId
    hasAutomaticSubject
    createdAt
    updatedAt
    printIssue {
      id
      title
      printPublication {
        id
        title
        brand {
          id
          title
          unit {
            id
            title
          }
        }
      }
    }
    printTemplateCount
    printTemplates(first: 50) {
      id
      active
      ...PrintHeadingPrintTemplate
    }
  }
}
    ${PrintHeadingPrintTemplateFragmentDoc}`;

/**
 * __useGetPrintHeadingQuery__
 *
 * To run a query within a React component, call `useGetPrintHeadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintHeadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintHeadingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrintHeadingQuery(baseOptions?: Apollo.QueryHookOptions<GetPrintHeadingQuery, GetPrintHeadingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrintHeadingQuery, GetPrintHeadingQueryVariables>(GetPrintHeadingDocument, options);
      }
export function useGetPrintHeadingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrintHeadingQuery, GetPrintHeadingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrintHeadingQuery, GetPrintHeadingQueryVariables>(GetPrintHeadingDocument, options);
        }
export function useGetPrintHeadingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrintHeadingQuery, GetPrintHeadingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrintHeadingQuery, GetPrintHeadingQueryVariables>(GetPrintHeadingDocument, options);
        }
export type GetPrintHeadingQueryHookResult = ReturnType<typeof useGetPrintHeadingQuery>;
export type GetPrintHeadingLazyQueryHookResult = ReturnType<typeof useGetPrintHeadingLazyQuery>;
export type GetPrintHeadingSuspenseQueryHookResult = ReturnType<typeof useGetPrintHeadingSuspenseQuery>;
export type GetPrintHeadingQueryResult = Apollo.QueryResult<GetPrintHeadingQuery, GetPrintHeadingQueryVariables>;
export const GetPrintTemplateDownloadUrlDocument = gql`
    query GetPrintTemplateDownloadUrl($printTemplateId: String!) {
  printTemplateDownload(printTemplateId: $printTemplateId) {
    id
    url
  }
}
    `;

/**
 * __useGetPrintTemplateDownloadUrlQuery__
 *
 * To run a query within a React component, call `useGetPrintTemplateDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintTemplateDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintTemplateDownloadUrlQuery({
 *   variables: {
 *      printTemplateId: // value for 'printTemplateId'
 *   },
 * });
 */
export function useGetPrintTemplateDownloadUrlQuery(baseOptions: Apollo.QueryHookOptions<GetPrintTemplateDownloadUrlQuery, GetPrintTemplateDownloadUrlQueryVariables> & ({ variables: GetPrintTemplateDownloadUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrintTemplateDownloadUrlQuery, GetPrintTemplateDownloadUrlQueryVariables>(GetPrintTemplateDownloadUrlDocument, options);
      }
export function useGetPrintTemplateDownloadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrintTemplateDownloadUrlQuery, GetPrintTemplateDownloadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrintTemplateDownloadUrlQuery, GetPrintTemplateDownloadUrlQueryVariables>(GetPrintTemplateDownloadUrlDocument, options);
        }
export function useGetPrintTemplateDownloadUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrintTemplateDownloadUrlQuery, GetPrintTemplateDownloadUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrintTemplateDownloadUrlQuery, GetPrintTemplateDownloadUrlQueryVariables>(GetPrintTemplateDownloadUrlDocument, options);
        }
export type GetPrintTemplateDownloadUrlQueryHookResult = ReturnType<typeof useGetPrintTemplateDownloadUrlQuery>;
export type GetPrintTemplateDownloadUrlLazyQueryHookResult = ReturnType<typeof useGetPrintTemplateDownloadUrlLazyQuery>;
export type GetPrintTemplateDownloadUrlSuspenseQueryHookResult = ReturnType<typeof useGetPrintTemplateDownloadUrlSuspenseQuery>;
export type GetPrintTemplateDownloadUrlQueryResult = Apollo.QueryResult<GetPrintTemplateDownloadUrlQuery, GetPrintTemplateDownloadUrlQueryVariables>;
export const GetPrintTemplateUploadUrlDocument = gql`
    query GetPrintTemplateUploadUrl($S3ObjectId: String!, $filename: String!) {
  printTemplateUpload(S3ObjectId: $S3ObjectId, filename: $filename) {
    id
    url
  }
}
    `;

/**
 * __useGetPrintTemplateUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetPrintTemplateUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintTemplateUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintTemplateUploadUrlQuery({
 *   variables: {
 *      S3ObjectId: // value for 'S3ObjectId'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGetPrintTemplateUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<GetPrintTemplateUploadUrlQuery, GetPrintTemplateUploadUrlQueryVariables> & ({ variables: GetPrintTemplateUploadUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrintTemplateUploadUrlQuery, GetPrintTemplateUploadUrlQueryVariables>(GetPrintTemplateUploadUrlDocument, options);
      }
export function useGetPrintTemplateUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrintTemplateUploadUrlQuery, GetPrintTemplateUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrintTemplateUploadUrlQuery, GetPrintTemplateUploadUrlQueryVariables>(GetPrintTemplateUploadUrlDocument, options);
        }
export function useGetPrintTemplateUploadUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrintTemplateUploadUrlQuery, GetPrintTemplateUploadUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrintTemplateUploadUrlQuery, GetPrintTemplateUploadUrlQueryVariables>(GetPrintTemplateUploadUrlDocument, options);
        }
export type GetPrintTemplateUploadUrlQueryHookResult = ReturnType<typeof useGetPrintTemplateUploadUrlQuery>;
export type GetPrintTemplateUploadUrlLazyQueryHookResult = ReturnType<typeof useGetPrintTemplateUploadUrlLazyQuery>;
export type GetPrintTemplateUploadUrlSuspenseQueryHookResult = ReturnType<typeof useGetPrintTemplateUploadUrlSuspenseQuery>;
export type GetPrintTemplateUploadUrlQueryResult = Apollo.QueryResult<GetPrintTemplateUploadUrlQuery, GetPrintTemplateUploadUrlQueryVariables>;
export const GetPrintTemplatePreviewUrlsDocument = gql`
    query GetPrintTemplatePreviewUrls($printTemplateId: String!) {
  printTemplatePreviewUrls(printTemplateId: $printTemplateId)
}
    `;

/**
 * __useGetPrintTemplatePreviewUrlsQuery__
 *
 * To run a query within a React component, call `useGetPrintTemplatePreviewUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintTemplatePreviewUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintTemplatePreviewUrlsQuery({
 *   variables: {
 *      printTemplateId: // value for 'printTemplateId'
 *   },
 * });
 */
export function useGetPrintTemplatePreviewUrlsQuery(baseOptions: Apollo.QueryHookOptions<GetPrintTemplatePreviewUrlsQuery, GetPrintTemplatePreviewUrlsQueryVariables> & ({ variables: GetPrintTemplatePreviewUrlsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrintTemplatePreviewUrlsQuery, GetPrintTemplatePreviewUrlsQueryVariables>(GetPrintTemplatePreviewUrlsDocument, options);
      }
export function useGetPrintTemplatePreviewUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrintTemplatePreviewUrlsQuery, GetPrintTemplatePreviewUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrintTemplatePreviewUrlsQuery, GetPrintTemplatePreviewUrlsQueryVariables>(GetPrintTemplatePreviewUrlsDocument, options);
        }
export function useGetPrintTemplatePreviewUrlsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrintTemplatePreviewUrlsQuery, GetPrintTemplatePreviewUrlsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrintTemplatePreviewUrlsQuery, GetPrintTemplatePreviewUrlsQueryVariables>(GetPrintTemplatePreviewUrlsDocument, options);
        }
export type GetPrintTemplatePreviewUrlsQueryHookResult = ReturnType<typeof useGetPrintTemplatePreviewUrlsQuery>;
export type GetPrintTemplatePreviewUrlsLazyQueryHookResult = ReturnType<typeof useGetPrintTemplatePreviewUrlsLazyQuery>;
export type GetPrintTemplatePreviewUrlsSuspenseQueryHookResult = ReturnType<typeof useGetPrintTemplatePreviewUrlsSuspenseQuery>;
export type GetPrintTemplatePreviewUrlsQueryResult = Apollo.QueryResult<GetPrintTemplatePreviewUrlsQuery, GetPrintTemplatePreviewUrlsQueryVariables>;
export const GetRawArticleDocument = gql`
    query GetRawArticle($id: ID!) {
  rawArticle(where: {id: $id}) {
    ...ArticleForm_RawArticle
  }
}
    ${ArticleForm_RawArticleFragmentDoc}`;

/**
 * __useGetRawArticleQuery__
 *
 * To run a query within a React component, call `useGetRawArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRawArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRawArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRawArticleQuery(baseOptions: Apollo.QueryHookOptions<GetRawArticleQuery, GetRawArticleQueryVariables> & ({ variables: GetRawArticleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRawArticleQuery, GetRawArticleQueryVariables>(GetRawArticleDocument, options);
      }
export function useGetRawArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRawArticleQuery, GetRawArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRawArticleQuery, GetRawArticleQueryVariables>(GetRawArticleDocument, options);
        }
export function useGetRawArticleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRawArticleQuery, GetRawArticleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRawArticleQuery, GetRawArticleQueryVariables>(GetRawArticleDocument, options);
        }
export type GetRawArticleQueryHookResult = ReturnType<typeof useGetRawArticleQuery>;
export type GetRawArticleLazyQueryHookResult = ReturnType<typeof useGetRawArticleLazyQuery>;
export type GetRawArticleSuspenseQueryHookResult = ReturnType<typeof useGetRawArticleSuspenseQuery>;
export type GetRawArticleQueryResult = Apollo.QueryResult<GetRawArticleQuery, GetRawArticleQueryVariables>;
export const GetUnitsDocument = gql`
    query GetUnits {
  units(first: 100) {
    id
    title
  }
}
    `;

/**
 * __useGetUnitsQuery__
 *
 * To run a query within a React component, call `useGetUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
      }
export function useGetUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
        }
export function useGetUnitsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
        }
export type GetUnitsQueryHookResult = ReturnType<typeof useGetUnitsQuery>;
export type GetUnitsLazyQueryHookResult = ReturnType<typeof useGetUnitsLazyQuery>;
export type GetUnitsSuspenseQueryHookResult = ReturnType<typeof useGetUnitsSuspenseQuery>;
export type GetUnitsQueryResult = Apollo.QueryResult<GetUnitsQuery, GetUnitsQueryVariables>;
export const GetDownloadUrlAttachmentDocument = gql`
    query getDownloadUrlAttachment($id: ID!) {
  attachment(where: {id: $id}) {
    ...DownloadAttachmentFragment
  }
}
    ${DownloadAttachmentFragmentFragmentDoc}`;

/**
 * __useGetDownloadUrlAttachmentQuery__
 *
 * To run a query within a React component, call `useGetDownloadUrlAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDownloadUrlAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDownloadUrlAttachmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDownloadUrlAttachmentQuery(baseOptions: Apollo.QueryHookOptions<GetDownloadUrlAttachmentQuery, GetDownloadUrlAttachmentQueryVariables> & ({ variables: GetDownloadUrlAttachmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDownloadUrlAttachmentQuery, GetDownloadUrlAttachmentQueryVariables>(GetDownloadUrlAttachmentDocument, options);
      }
export function useGetDownloadUrlAttachmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDownloadUrlAttachmentQuery, GetDownloadUrlAttachmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDownloadUrlAttachmentQuery, GetDownloadUrlAttachmentQueryVariables>(GetDownloadUrlAttachmentDocument, options);
        }
export function useGetDownloadUrlAttachmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDownloadUrlAttachmentQuery, GetDownloadUrlAttachmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDownloadUrlAttachmentQuery, GetDownloadUrlAttachmentQueryVariables>(GetDownloadUrlAttachmentDocument, options);
        }
export type GetDownloadUrlAttachmentQueryHookResult = ReturnType<typeof useGetDownloadUrlAttachmentQuery>;
export type GetDownloadUrlAttachmentLazyQueryHookResult = ReturnType<typeof useGetDownloadUrlAttachmentLazyQuery>;
export type GetDownloadUrlAttachmentSuspenseQueryHookResult = ReturnType<typeof useGetDownloadUrlAttachmentSuspenseQuery>;
export type GetDownloadUrlAttachmentQueryResult = Apollo.QueryResult<GetDownloadUrlAttachmentQuery, GetDownloadUrlAttachmentQueryVariables>;
export const GetSubjectForMediaStatusDialogDocument = gql`
    query GetSubjectForMediaStatusDialog($id: ID!) {
  subject(where: {id: $id}) {
    id
    assignments(first: 20) {
      ...AssignmentItem
    }
  }
}
    ${AssignmentItemFragmentDoc}`;

/**
 * __useGetSubjectForMediaStatusDialogQuery__
 *
 * To run a query within a React component, call `useGetSubjectForMediaStatusDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectForMediaStatusDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectForMediaStatusDialogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubjectForMediaStatusDialogQuery(baseOptions: Apollo.QueryHookOptions<GetSubjectForMediaStatusDialogQuery, GetSubjectForMediaStatusDialogQueryVariables> & ({ variables: GetSubjectForMediaStatusDialogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectForMediaStatusDialogQuery, GetSubjectForMediaStatusDialogQueryVariables>(GetSubjectForMediaStatusDialogDocument, options);
      }
export function useGetSubjectForMediaStatusDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectForMediaStatusDialogQuery, GetSubjectForMediaStatusDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectForMediaStatusDialogQuery, GetSubjectForMediaStatusDialogQueryVariables>(GetSubjectForMediaStatusDialogDocument, options);
        }
export function useGetSubjectForMediaStatusDialogSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSubjectForMediaStatusDialogQuery, GetSubjectForMediaStatusDialogQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubjectForMediaStatusDialogQuery, GetSubjectForMediaStatusDialogQueryVariables>(GetSubjectForMediaStatusDialogDocument, options);
        }
export type GetSubjectForMediaStatusDialogQueryHookResult = ReturnType<typeof useGetSubjectForMediaStatusDialogQuery>;
export type GetSubjectForMediaStatusDialogLazyQueryHookResult = ReturnType<typeof useGetSubjectForMediaStatusDialogLazyQuery>;
export type GetSubjectForMediaStatusDialogSuspenseQueryHookResult = ReturnType<typeof useGetSubjectForMediaStatusDialogSuspenseQuery>;
export type GetSubjectForMediaStatusDialogQueryResult = Apollo.QueryResult<GetSubjectForMediaStatusDialogQuery, GetSubjectForMediaStatusDialogQueryVariables>;
export const UpdateAssignmentMediaSearchStatusDocument = gql`
    mutation UpdateAssignmentMediaSearchStatus($assignmentId: ID!, $status: MediaSearchStatus!) {
  updateAssignment(data: {mediaSearchStatus: $status}, where: {id: $assignmentId}) {
    id
    mediaSearchStatus
  }
}
    `;
export type UpdateAssignmentMediaSearchStatusMutationFn = Apollo.MutationFunction<UpdateAssignmentMediaSearchStatusMutation, UpdateAssignmentMediaSearchStatusMutationVariables>;

/**
 * __useUpdateAssignmentMediaSearchStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMediaSearchStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMediaSearchStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMediaSearchStatusMutation, { data, loading, error }] = useUpdateAssignmentMediaSearchStatusMutation({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAssignmentMediaSearchStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignmentMediaSearchStatusMutation, UpdateAssignmentMediaSearchStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssignmentMediaSearchStatusMutation, UpdateAssignmentMediaSearchStatusMutationVariables>(UpdateAssignmentMediaSearchStatusDocument, options);
      }
export type UpdateAssignmentMediaSearchStatusMutationHookResult = ReturnType<typeof useUpdateAssignmentMediaSearchStatusMutation>;
export type UpdateAssignmentMediaSearchStatusMutationResult = Apollo.MutationResult<UpdateAssignmentMediaSearchStatusMutation>;
export type UpdateAssignmentMediaSearchStatusMutationOptions = Apollo.BaseMutationOptions<UpdateAssignmentMediaSearchStatusMutation, UpdateAssignmentMediaSearchStatusMutationVariables>;
export const DeleteSubjectUserDocument = gql`
    mutation DeleteSubjectUser($userId: String!, $subjectId: ID!) {
  deleteSubjectUser(where: {userId: $userId, subject: {id: $subjectId}}) {
    userId
  }
}
    `;
export type DeleteSubjectUserMutationFn = Apollo.MutationFunction<DeleteSubjectUserMutation, DeleteSubjectUserMutationVariables>;

/**
 * __useDeleteSubjectUserMutation__
 *
 * To run a mutation, you first call `useDeleteSubjectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubjectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubjectUserMutation, { data, loading, error }] = useDeleteSubjectUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useDeleteSubjectUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubjectUserMutation, DeleteSubjectUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubjectUserMutation, DeleteSubjectUserMutationVariables>(DeleteSubjectUserDocument, options);
      }
export type DeleteSubjectUserMutationHookResult = ReturnType<typeof useDeleteSubjectUserMutation>;
export type DeleteSubjectUserMutationResult = Apollo.MutationResult<DeleteSubjectUserMutation>;
export type DeleteSubjectUserMutationOptions = Apollo.BaseMutationOptions<DeleteSubjectUserMutation, DeleteSubjectUserMutationVariables>;
export const GetSubjectUsersDocument = gql`
    query GetSubjectUsers($id: ID!) {
  subject(where: {id: $id}) {
    id
    subjectUsers(first: 100) {
      ...SubjectUser
    }
  }
}
    ${SubjectUserFragmentDoc}`;

/**
 * __useGetSubjectUsersQuery__
 *
 * To run a query within a React component, call `useGetSubjectUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubjectUsersQuery(baseOptions: Apollo.QueryHookOptions<GetSubjectUsersQuery, GetSubjectUsersQueryVariables> & ({ variables: GetSubjectUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectUsersQuery, GetSubjectUsersQueryVariables>(GetSubjectUsersDocument, options);
      }
export function useGetSubjectUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectUsersQuery, GetSubjectUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectUsersQuery, GetSubjectUsersQueryVariables>(GetSubjectUsersDocument, options);
        }
export function useGetSubjectUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSubjectUsersQuery, GetSubjectUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubjectUsersQuery, GetSubjectUsersQueryVariables>(GetSubjectUsersDocument, options);
        }
export type GetSubjectUsersQueryHookResult = ReturnType<typeof useGetSubjectUsersQuery>;
export type GetSubjectUsersLazyQueryHookResult = ReturnType<typeof useGetSubjectUsersLazyQuery>;
export type GetSubjectUsersSuspenseQueryHookResult = ReturnType<typeof useGetSubjectUsersSuspenseQuery>;
export type GetSubjectUsersQueryResult = Apollo.QueryResult<GetSubjectUsersQuery, GetSubjectUsersQueryVariables>;
export const ShareSubjectDocument = gql`
    mutation ShareSubject($subject: ID!, $userIds: [String!]!) {
  shareSubject(subject: {id: $subject}, userIds: $userIds) {
    id
  }
}
    `;
export type ShareSubjectMutationFn = Apollo.MutationFunction<ShareSubjectMutation, ShareSubjectMutationVariables>;

/**
 * __useShareSubjectMutation__
 *
 * To run a mutation, you first call `useShareSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareSubjectMutation, { data, loading, error }] = useShareSubjectMutation({
 *   variables: {
 *      subject: // value for 'subject'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useShareSubjectMutation(baseOptions?: Apollo.MutationHookOptions<ShareSubjectMutation, ShareSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareSubjectMutation, ShareSubjectMutationVariables>(ShareSubjectDocument, options);
      }
export type ShareSubjectMutationHookResult = ReturnType<typeof useShareSubjectMutation>;
export type ShareSubjectMutationResult = Apollo.MutationResult<ShareSubjectMutation>;
export type ShareSubjectMutationOptions = Apollo.BaseMutationOptions<ShareSubjectMutation, ShareSubjectMutationVariables>;
export const UnitSpeedDial_GetUnitsDocument = gql`
    query UnitSpeedDial_GetUnits {
  units(first: 10) {
    ...UnitSpeedDial_Fragment
  }
}
    ${UnitSpeedDial_FragmentFragmentDoc}`;

/**
 * __useUnitSpeedDial_GetUnitsQuery__
 *
 * To run a query within a React component, call `useUnitSpeedDial_GetUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitSpeedDial_GetUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitSpeedDial_GetUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitSpeedDial_GetUnitsQuery(baseOptions?: Apollo.QueryHookOptions<UnitSpeedDial_GetUnitsQuery, UnitSpeedDial_GetUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitSpeedDial_GetUnitsQuery, UnitSpeedDial_GetUnitsQueryVariables>(UnitSpeedDial_GetUnitsDocument, options);
      }
export function useUnitSpeedDial_GetUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitSpeedDial_GetUnitsQuery, UnitSpeedDial_GetUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitSpeedDial_GetUnitsQuery, UnitSpeedDial_GetUnitsQueryVariables>(UnitSpeedDial_GetUnitsDocument, options);
        }
export function useUnitSpeedDial_GetUnitsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UnitSpeedDial_GetUnitsQuery, UnitSpeedDial_GetUnitsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UnitSpeedDial_GetUnitsQuery, UnitSpeedDial_GetUnitsQueryVariables>(UnitSpeedDial_GetUnitsDocument, options);
        }
export type UnitSpeedDial_GetUnitsQueryHookResult = ReturnType<typeof useUnitSpeedDial_GetUnitsQuery>;
export type UnitSpeedDial_GetUnitsLazyQueryHookResult = ReturnType<typeof useUnitSpeedDial_GetUnitsLazyQuery>;
export type UnitSpeedDial_GetUnitsSuspenseQueryHookResult = ReturnType<typeof useUnitSpeedDial_GetUnitsSuspenseQuery>;
export type UnitSpeedDial_GetUnitsQueryResult = Apollo.QueryResult<UnitSpeedDial_GetUnitsQuery, UnitSpeedDial_GetUnitsQueryVariables>;
export const DeleteSubjectDocument = gql`
    mutation DeleteSubject($id: ID!) {
  deleteSubject(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteSubjectMutationFn = Apollo.MutationFunction<DeleteSubjectMutation, DeleteSubjectMutationVariables>;

/**
 * __useDeleteSubjectMutation__
 *
 * To run a mutation, you first call `useDeleteSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubjectMutation, { data, loading, error }] = useDeleteSubjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubjectMutation, DeleteSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubjectMutation, DeleteSubjectMutationVariables>(DeleteSubjectDocument, options);
      }
export type DeleteSubjectMutationHookResult = ReturnType<typeof useDeleteSubjectMutation>;
export type DeleteSubjectMutationResult = Apollo.MutationResult<DeleteSubjectMutation>;
export type DeleteSubjectMutationOptions = Apollo.BaseMutationOptions<DeleteSubjectMutation, DeleteSubjectMutationVariables>;
export const GetPrintTemplateDraftDocument = gql`
    query GetPrintTemplateDraft($printHeadingId: String!, $printTemplateId: String, $format: PrintTemplateDraftFormatType!, $pages: [Int!], $fieldsData: [PrintTemplateDraftFieldsDataType], $embedRessources: Boolean) {
  printTemplateDrafts(printHeadingId: $printHeadingId, printTemplateId: $printTemplateId, format: $format, pages: $pages, fieldsData: $fieldsData, embedRessources: $embedRessources) {
    draftId
    artifacts {
      page
      url
    }
  }
}
    `;

/**
 * __useGetPrintTemplateDraftQuery__
 *
 * To run a query within a React component, call `useGetPrintTemplateDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintTemplateDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintTemplateDraftQuery({
 *   variables: {
 *      printHeadingId: // value for 'printHeadingId'
 *      printTemplateId: // value for 'printTemplateId'
 *      format: // value for 'format'
 *      pages: // value for 'pages'
 *      fieldsData: // value for 'fieldsData'
 *      embedRessources: // value for 'embedRessources'
 *   },
 * });
 */
export function useGetPrintTemplateDraftQuery(baseOptions: Apollo.QueryHookOptions<GetPrintTemplateDraftQuery, GetPrintTemplateDraftQueryVariables> & ({ variables: GetPrintTemplateDraftQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrintTemplateDraftQuery, GetPrintTemplateDraftQueryVariables>(GetPrintTemplateDraftDocument, options);
      }
export function useGetPrintTemplateDraftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrintTemplateDraftQuery, GetPrintTemplateDraftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrintTemplateDraftQuery, GetPrintTemplateDraftQueryVariables>(GetPrintTemplateDraftDocument, options);
        }
export function useGetPrintTemplateDraftSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrintTemplateDraftQuery, GetPrintTemplateDraftQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrintTemplateDraftQuery, GetPrintTemplateDraftQueryVariables>(GetPrintTemplateDraftDocument, options);
        }
export type GetPrintTemplateDraftQueryHookResult = ReturnType<typeof useGetPrintTemplateDraftQuery>;
export type GetPrintTemplateDraftLazyQueryHookResult = ReturnType<typeof useGetPrintTemplateDraftLazyQuery>;
export type GetPrintTemplateDraftSuspenseQueryHookResult = ReturnType<typeof useGetPrintTemplateDraftSuspenseQuery>;
export type GetPrintTemplateDraftQueryResult = Apollo.QueryResult<GetPrintTemplateDraftQuery, GetPrintTemplateDraftQueryVariables>;
export const GetAssignmentByRawArticleIdDocument = gql`
    query GetAssignmentByRawArticleId($id: ID!) {
  assignment(where: {rawArticle: {id: $id}}) {
    id
    printPublication {
      id
      title
    }
    printIssue {
      id
      title
      printPublication {
        id
        title
      }
    }
    printHeading {
      id
      title
      printIssue {
        id
        title
        printPublication {
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __useGetAssignmentByRawArticleIdQuery__
 *
 * To run a query within a React component, call `useGetAssignmentByRawArticleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentByRawArticleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentByRawArticleIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentByRawArticleIdQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentByRawArticleIdQuery, GetAssignmentByRawArticleIdQueryVariables> & ({ variables: GetAssignmentByRawArticleIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentByRawArticleIdQuery, GetAssignmentByRawArticleIdQueryVariables>(GetAssignmentByRawArticleIdDocument, options);
      }
export function useGetAssignmentByRawArticleIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentByRawArticleIdQuery, GetAssignmentByRawArticleIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentByRawArticleIdQuery, GetAssignmentByRawArticleIdQueryVariables>(GetAssignmentByRawArticleIdDocument, options);
        }
export function useGetAssignmentByRawArticleIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssignmentByRawArticleIdQuery, GetAssignmentByRawArticleIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignmentByRawArticleIdQuery, GetAssignmentByRawArticleIdQueryVariables>(GetAssignmentByRawArticleIdDocument, options);
        }
export type GetAssignmentByRawArticleIdQueryHookResult = ReturnType<typeof useGetAssignmentByRawArticleIdQuery>;
export type GetAssignmentByRawArticleIdLazyQueryHookResult = ReturnType<typeof useGetAssignmentByRawArticleIdLazyQuery>;
export type GetAssignmentByRawArticleIdSuspenseQueryHookResult = ReturnType<typeof useGetAssignmentByRawArticleIdSuspenseQuery>;
export type GetAssignmentByRawArticleIdQueryResult = Apollo.QueryResult<GetAssignmentByRawArticleIdQuery, GetAssignmentByRawArticleIdQueryVariables>;
export const UpdateAssignmentByArticleDocument = gql`
    mutation UpdateAssignmentByArticle($id: ID!, $data: AssignmentUpdateInput!) {
  updateAssignment(where: {rawArticle: {id: $id}}, data: $data) {
    id
  }
}
    `;
export type UpdateAssignmentByArticleMutationFn = Apollo.MutationFunction<UpdateAssignmentByArticleMutation, UpdateAssignmentByArticleMutationVariables>;

/**
 * __useUpdateAssignmentByArticleMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentByArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentByArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentByArticleMutation, { data, loading, error }] = useUpdateAssignmentByArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAssignmentByArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignmentByArticleMutation, UpdateAssignmentByArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssignmentByArticleMutation, UpdateAssignmentByArticleMutationVariables>(UpdateAssignmentByArticleDocument, options);
      }
export type UpdateAssignmentByArticleMutationHookResult = ReturnType<typeof useUpdateAssignmentByArticleMutation>;
export type UpdateAssignmentByArticleMutationResult = Apollo.MutationResult<UpdateAssignmentByArticleMutation>;
export type UpdateAssignmentByArticleMutationOptions = Apollo.BaseMutationOptions<UpdateAssignmentByArticleMutation, UpdateAssignmentByArticleMutationVariables>;
export const UpsertRawArticleDocument = gql`
    mutation UpsertRawArticle($create: RawArticleCreationInput!, $update: RawArticleUpdateInput!, $where: RawArticleUniqueFilterInput!) {
  upsertRawArticle(create: $create, update: $update, where: $where) {
    id
    versions(first: 1, where: {nextVersion_is_null: true}) {
      ...ArticleForm_RawArticleVersionFragment
    }
    versionList: versions(first: 100, orderBy: _id_DESC) {
      id
      status
      editorId
      editor {
        id
        name
        avatarUrl
      }
    }
  }
}
    ${ArticleForm_RawArticleVersionFragmentFragmentDoc}`;
export type UpsertRawArticleMutationFn = Apollo.MutationFunction<UpsertRawArticleMutation, UpsertRawArticleMutationVariables>;

/**
 * __useUpsertRawArticleMutation__
 *
 * To run a mutation, you first call `useUpsertRawArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRawArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRawArticleMutation, { data, loading, error }] = useUpsertRawArticleMutation({
 *   variables: {
 *      create: // value for 'create'
 *      update: // value for 'update'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpsertRawArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRawArticleMutation, UpsertRawArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRawArticleMutation, UpsertRawArticleMutationVariables>(UpsertRawArticleDocument, options);
      }
export type UpsertRawArticleMutationHookResult = ReturnType<typeof useUpsertRawArticleMutation>;
export type UpsertRawArticleMutationResult = Apollo.MutationResult<UpsertRawArticleMutation>;
export type UpsertRawArticleMutationOptions = Apollo.BaseMutationOptions<UpsertRawArticleMutation, UpsertRawArticleMutationVariables>;
export const CreateRawArticleVersionEditorTransferDocument = gql`
    mutation CreateRawArticleVersionEditorTransfer($data: RawArticleVersionCreationInput!) {
  createRawArticleVersion(data: $data) {
    ...ArticleForm_RawArticleVersionFragment
    editorId
  }
}
    ${ArticleForm_RawArticleVersionFragmentFragmentDoc}`;
export type CreateRawArticleVersionEditorTransferMutationFn = Apollo.MutationFunction<CreateRawArticleVersionEditorTransferMutation, CreateRawArticleVersionEditorTransferMutationVariables>;

/**
 * __useCreateRawArticleVersionEditorTransferMutation__
 *
 * To run a mutation, you first call `useCreateRawArticleVersionEditorTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRawArticleVersionEditorTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRawArticleVersionEditorTransferMutation, { data, loading, error }] = useCreateRawArticleVersionEditorTransferMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRawArticleVersionEditorTransferMutation(baseOptions?: Apollo.MutationHookOptions<CreateRawArticleVersionEditorTransferMutation, CreateRawArticleVersionEditorTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRawArticleVersionEditorTransferMutation, CreateRawArticleVersionEditorTransferMutationVariables>(CreateRawArticleVersionEditorTransferDocument, options);
      }
export type CreateRawArticleVersionEditorTransferMutationHookResult = ReturnType<typeof useCreateRawArticleVersionEditorTransferMutation>;
export type CreateRawArticleVersionEditorTransferMutationResult = Apollo.MutationResult<CreateRawArticleVersionEditorTransferMutation>;
export type CreateRawArticleVersionEditorTransferMutationOptions = Apollo.BaseMutationOptions<CreateRawArticleVersionEditorTransferMutation, CreateRawArticleVersionEditorTransferMutationVariables>;
export const GetUserBySearchDocument = gql`
    query GetUserBySearch($id: ID!, $search: String!) {
  rawArticleVersion(where: {id: $id}) {
    id
    allowedTransfers(name: $search) {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserBySearchQuery__
 *
 * To run a query within a React component, call `useGetUserBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBySearchQuery({
 *   variables: {
 *      id: // value for 'id'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetUserBySearchQuery(baseOptions: Apollo.QueryHookOptions<GetUserBySearchQuery, GetUserBySearchQueryVariables> & ({ variables: GetUserBySearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserBySearchQuery, GetUserBySearchQueryVariables>(GetUserBySearchDocument, options);
      }
export function useGetUserBySearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBySearchQuery, GetUserBySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserBySearchQuery, GetUserBySearchQueryVariables>(GetUserBySearchDocument, options);
        }
export function useGetUserBySearchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserBySearchQuery, GetUserBySearchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserBySearchQuery, GetUserBySearchQueryVariables>(GetUserBySearchDocument, options);
        }
export type GetUserBySearchQueryHookResult = ReturnType<typeof useGetUserBySearchQuery>;
export type GetUserBySearchLazyQueryHookResult = ReturnType<typeof useGetUserBySearchLazyQuery>;
export type GetUserBySearchSuspenseQueryHookResult = ReturnType<typeof useGetUserBySearchSuspenseQuery>;
export type GetUserBySearchQueryResult = Apollo.QueryResult<GetUserBySearchQuery, GetUserBySearchQueryVariables>;
export const GetRawArticleVersionArticleHistoryDocument = gql`
    query GetRawArticleVersionArticleHistory($id: ID!) {
  rawArticleVersion(where: {id: $id}) {
    id
    title
    lead
    body
    signature
    createdAt
    status
    editor {
      id
      name
      avatarUrl
    }
    rawArticle {
      id
      lastVersion {
        id
      }
      assignment {
        printTemplate {
          fields {
            id
            name
            type
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRawArticleVersionArticleHistoryQuery__
 *
 * To run a query within a React component, call `useGetRawArticleVersionArticleHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRawArticleVersionArticleHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRawArticleVersionArticleHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRawArticleVersionArticleHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetRawArticleVersionArticleHistoryQuery, GetRawArticleVersionArticleHistoryQueryVariables> & ({ variables: GetRawArticleVersionArticleHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRawArticleVersionArticleHistoryQuery, GetRawArticleVersionArticleHistoryQueryVariables>(GetRawArticleVersionArticleHistoryDocument, options);
      }
export function useGetRawArticleVersionArticleHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRawArticleVersionArticleHistoryQuery, GetRawArticleVersionArticleHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRawArticleVersionArticleHistoryQuery, GetRawArticleVersionArticleHistoryQueryVariables>(GetRawArticleVersionArticleHistoryDocument, options);
        }
export function useGetRawArticleVersionArticleHistorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRawArticleVersionArticleHistoryQuery, GetRawArticleVersionArticleHistoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRawArticleVersionArticleHistoryQuery, GetRawArticleVersionArticleHistoryQueryVariables>(GetRawArticleVersionArticleHistoryDocument, options);
        }
export type GetRawArticleVersionArticleHistoryQueryHookResult = ReturnType<typeof useGetRawArticleVersionArticleHistoryQuery>;
export type GetRawArticleVersionArticleHistoryLazyQueryHookResult = ReturnType<typeof useGetRawArticleVersionArticleHistoryLazyQuery>;
export type GetRawArticleVersionArticleHistorySuspenseQueryHookResult = ReturnType<typeof useGetRawArticleVersionArticleHistorySuspenseQuery>;
export type GetRawArticleVersionArticleHistoryQueryResult = Apollo.QueryResult<GetRawArticleVersionArticleHistoryQuery, GetRawArticleVersionArticleHistoryQueryVariables>;
export const GetRawArticleMajorVersionsDocument = gql`
    query GetRawArticleMajorVersions($id: ID!) {
  rawArticle(where: {id: $id}) {
    id
    majorVersions(first: 100) {
      ...ArticleHistory_MajorVersion
    }
  }
}
    ${ArticleHistory_MajorVersionFragmentDoc}`;

/**
 * __useGetRawArticleMajorVersionsQuery__
 *
 * To run a query within a React component, call `useGetRawArticleMajorVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRawArticleMajorVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRawArticleMajorVersionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRawArticleMajorVersionsQuery(baseOptions: Apollo.QueryHookOptions<GetRawArticleMajorVersionsQuery, GetRawArticleMajorVersionsQueryVariables> & ({ variables: GetRawArticleMajorVersionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRawArticleMajorVersionsQuery, GetRawArticleMajorVersionsQueryVariables>(GetRawArticleMajorVersionsDocument, options);
      }
export function useGetRawArticleMajorVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRawArticleMajorVersionsQuery, GetRawArticleMajorVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRawArticleMajorVersionsQuery, GetRawArticleMajorVersionsQueryVariables>(GetRawArticleMajorVersionsDocument, options);
        }
export function useGetRawArticleMajorVersionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRawArticleMajorVersionsQuery, GetRawArticleMajorVersionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRawArticleMajorVersionsQuery, GetRawArticleMajorVersionsQueryVariables>(GetRawArticleMajorVersionsDocument, options);
        }
export type GetRawArticleMajorVersionsQueryHookResult = ReturnType<typeof useGetRawArticleMajorVersionsQuery>;
export type GetRawArticleMajorVersionsLazyQueryHookResult = ReturnType<typeof useGetRawArticleMajorVersionsLazyQuery>;
export type GetRawArticleMajorVersionsSuspenseQueryHookResult = ReturnType<typeof useGetRawArticleMajorVersionsSuspenseQuery>;
export type GetRawArticleMajorVersionsQueryResult = Apollo.QueryResult<GetRawArticleMajorVersionsQuery, GetRawArticleMajorVersionsQueryVariables>;
export const IssuePage_GetPrintPublicationsDocument = gql`
    query IssuePage_GetPrintPublications($where: PrintPublicationFilterInput, $orderBy: [PrintPublicationOrderingInput!], $skip: UnsignedInt, $first: UnsignedInt!) {
  printPublications(where: $where, first: $first, orderBy: $orderBy, skip: $skip) {
    id
    title
    printIssues(first: 1000) {
      id
    }
  }
}
    `;

/**
 * __useIssuePage_GetPrintPublicationsQuery__
 *
 * To run a query within a React component, call `useIssuePage_GetPrintPublicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuePage_GetPrintPublicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuePage_GetPrintPublicationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useIssuePage_GetPrintPublicationsQuery(baseOptions: Apollo.QueryHookOptions<IssuePage_GetPrintPublicationsQuery, IssuePage_GetPrintPublicationsQueryVariables> & ({ variables: IssuePage_GetPrintPublicationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssuePage_GetPrintPublicationsQuery, IssuePage_GetPrintPublicationsQueryVariables>(IssuePage_GetPrintPublicationsDocument, options);
      }
export function useIssuePage_GetPrintPublicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssuePage_GetPrintPublicationsQuery, IssuePage_GetPrintPublicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssuePage_GetPrintPublicationsQuery, IssuePage_GetPrintPublicationsQueryVariables>(IssuePage_GetPrintPublicationsDocument, options);
        }
export function useIssuePage_GetPrintPublicationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IssuePage_GetPrintPublicationsQuery, IssuePage_GetPrintPublicationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IssuePage_GetPrintPublicationsQuery, IssuePage_GetPrintPublicationsQueryVariables>(IssuePage_GetPrintPublicationsDocument, options);
        }
export type IssuePage_GetPrintPublicationsQueryHookResult = ReturnType<typeof useIssuePage_GetPrintPublicationsQuery>;
export type IssuePage_GetPrintPublicationsLazyQueryHookResult = ReturnType<typeof useIssuePage_GetPrintPublicationsLazyQuery>;
export type IssuePage_GetPrintPublicationsSuspenseQueryHookResult = ReturnType<typeof useIssuePage_GetPrintPublicationsSuspenseQuery>;
export type IssuePage_GetPrintPublicationsQueryResult = Apollo.QueryResult<IssuePage_GetPrintPublicationsQuery, IssuePage_GetPrintPublicationsQueryVariables>;
export const IssuePage_GetPrintIssuesDocument = gql`
    query IssuePage_GetPrintIssues($where: PrintIssueFilterInput, $orderBy: [PrintIssueOrderingInput!], $skip: UnsignedInt, $first: UnsignedInt!) {
  printIssues(where: $where, first: $first, orderBy: $orderBy, skip: $skip) {
    id
    title
  }
}
    `;

/**
 * __useIssuePage_GetPrintIssuesQuery__
 *
 * To run a query within a React component, call `useIssuePage_GetPrintIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuePage_GetPrintIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuePage_GetPrintIssuesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useIssuePage_GetPrintIssuesQuery(baseOptions: Apollo.QueryHookOptions<IssuePage_GetPrintIssuesQuery, IssuePage_GetPrintIssuesQueryVariables> & ({ variables: IssuePage_GetPrintIssuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssuePage_GetPrintIssuesQuery, IssuePage_GetPrintIssuesQueryVariables>(IssuePage_GetPrintIssuesDocument, options);
      }
export function useIssuePage_GetPrintIssuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssuePage_GetPrintIssuesQuery, IssuePage_GetPrintIssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssuePage_GetPrintIssuesQuery, IssuePage_GetPrintIssuesQueryVariables>(IssuePage_GetPrintIssuesDocument, options);
        }
export function useIssuePage_GetPrintIssuesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IssuePage_GetPrintIssuesQuery, IssuePage_GetPrintIssuesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IssuePage_GetPrintIssuesQuery, IssuePage_GetPrintIssuesQueryVariables>(IssuePage_GetPrintIssuesDocument, options);
        }
export type IssuePage_GetPrintIssuesQueryHookResult = ReturnType<typeof useIssuePage_GetPrintIssuesQuery>;
export type IssuePage_GetPrintIssuesLazyQueryHookResult = ReturnType<typeof useIssuePage_GetPrintIssuesLazyQuery>;
export type IssuePage_GetPrintIssuesSuspenseQueryHookResult = ReturnType<typeof useIssuePage_GetPrintIssuesSuspenseQuery>;
export type IssuePage_GetPrintIssuesQueryResult = Apollo.QueryResult<IssuePage_GetPrintIssuesQuery, IssuePage_GetPrintIssuesQueryVariables>;
export const IssuePage_GetPrintIssueDocument = gql`
    query IssuePage_GetPrintIssue($where: PrintIssueUniqueFilterInput!) {
  printIssue(where: $where) {
    id
    title
    printPublication {
      id
      key
    }
  }
}
    `;

/**
 * __useIssuePage_GetPrintIssueQuery__
 *
 * To run a query within a React component, call `useIssuePage_GetPrintIssueQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuePage_GetPrintIssueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuePage_GetPrintIssueQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useIssuePage_GetPrintIssueQuery(baseOptions: Apollo.QueryHookOptions<IssuePage_GetPrintIssueQuery, IssuePage_GetPrintIssueQueryVariables> & ({ variables: IssuePage_GetPrintIssueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssuePage_GetPrintIssueQuery, IssuePage_GetPrintIssueQueryVariables>(IssuePage_GetPrintIssueDocument, options);
      }
export function useIssuePage_GetPrintIssueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssuePage_GetPrintIssueQuery, IssuePage_GetPrintIssueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssuePage_GetPrintIssueQuery, IssuePage_GetPrintIssueQueryVariables>(IssuePage_GetPrintIssueDocument, options);
        }
export function useIssuePage_GetPrintIssueSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IssuePage_GetPrintIssueQuery, IssuePage_GetPrintIssueQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IssuePage_GetPrintIssueQuery, IssuePage_GetPrintIssueQueryVariables>(IssuePage_GetPrintIssueDocument, options);
        }
export type IssuePage_GetPrintIssueQueryHookResult = ReturnType<typeof useIssuePage_GetPrintIssueQuery>;
export type IssuePage_GetPrintIssueLazyQueryHookResult = ReturnType<typeof useIssuePage_GetPrintIssueLazyQuery>;
export type IssuePage_GetPrintIssueSuspenseQueryHookResult = ReturnType<typeof useIssuePage_GetPrintIssueSuspenseQuery>;
export type IssuePage_GetPrintIssueQueryResult = Apollo.QueryResult<IssuePage_GetPrintIssueQuery, IssuePage_GetPrintIssueQueryVariables>;
export const IssuePage_GetPrintHeadingsDocument = gql`
    query IssuePage_GetPrintHeadings($where: PrintHeadingFilterInput, $orderBy: [PrintHeadingOrderingInput!], $skip: UnsignedInt, $first: UnsignedInt!) {
  printHeadings(where: $where, first: $first, orderBy: $orderBy, skip: $skip) {
    id
    title
    assignments(first: 1000) {
      id
      mediaSearchStatus
      pageNumber
      subject {
        id
        title
        comment
      }
      rawArticle {
        id
        lastVersion {
          id
          status
          title
        }
      }
    }
  }
}
    `;

/**
 * __useIssuePage_GetPrintHeadingsQuery__
 *
 * To run a query within a React component, call `useIssuePage_GetPrintHeadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuePage_GetPrintHeadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuePage_GetPrintHeadingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useIssuePage_GetPrintHeadingsQuery(baseOptions: Apollo.QueryHookOptions<IssuePage_GetPrintHeadingsQuery, IssuePage_GetPrintHeadingsQueryVariables> & ({ variables: IssuePage_GetPrintHeadingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssuePage_GetPrintHeadingsQuery, IssuePage_GetPrintHeadingsQueryVariables>(IssuePage_GetPrintHeadingsDocument, options);
      }
export function useIssuePage_GetPrintHeadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssuePage_GetPrintHeadingsQuery, IssuePage_GetPrintHeadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssuePage_GetPrintHeadingsQuery, IssuePage_GetPrintHeadingsQueryVariables>(IssuePage_GetPrintHeadingsDocument, options);
        }
export function useIssuePage_GetPrintHeadingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IssuePage_GetPrintHeadingsQuery, IssuePage_GetPrintHeadingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IssuePage_GetPrintHeadingsQuery, IssuePage_GetPrintHeadingsQueryVariables>(IssuePage_GetPrintHeadingsDocument, options);
        }
export type IssuePage_GetPrintHeadingsQueryHookResult = ReturnType<typeof useIssuePage_GetPrintHeadingsQuery>;
export type IssuePage_GetPrintHeadingsLazyQueryHookResult = ReturnType<typeof useIssuePage_GetPrintHeadingsLazyQuery>;
export type IssuePage_GetPrintHeadingsSuspenseQueryHookResult = ReturnType<typeof useIssuePage_GetPrintHeadingsSuspenseQuery>;
export type IssuePage_GetPrintHeadingsQueryResult = Apollo.QueryResult<IssuePage_GetPrintHeadingsQuery, IssuePage_GetPrintHeadingsQueryVariables>;
export const IssuePage_GetRailroadPagesDocument = gql`
    query IssuePage_GetRailroadPages($brandKey: String!, $issue: String!) {
  getCenshareRailroadByName(brandKey: $brandKey, issue: $issue) {
    sheets
    pages {
      filename
      indesignFilename
      pageNumber
      workflow
    }
    comment
    publicationDate
    censhareUpdateDate {
      date
      label_by
    }
    censhareCreationDate {
      date
      label_by
    }
  }
}
    `;

/**
 * __useIssuePage_GetRailroadPagesQuery__
 *
 * To run a query within a React component, call `useIssuePage_GetRailroadPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuePage_GetRailroadPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuePage_GetRailroadPagesQuery({
 *   variables: {
 *      brandKey: // value for 'brandKey'
 *      issue: // value for 'issue'
 *   },
 * });
 */
export function useIssuePage_GetRailroadPagesQuery(baseOptions: Apollo.QueryHookOptions<IssuePage_GetRailroadPagesQuery, IssuePage_GetRailroadPagesQueryVariables> & ({ variables: IssuePage_GetRailroadPagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssuePage_GetRailroadPagesQuery, IssuePage_GetRailroadPagesQueryVariables>(IssuePage_GetRailroadPagesDocument, options);
      }
export function useIssuePage_GetRailroadPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssuePage_GetRailroadPagesQuery, IssuePage_GetRailroadPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssuePage_GetRailroadPagesQuery, IssuePage_GetRailroadPagesQueryVariables>(IssuePage_GetRailroadPagesDocument, options);
        }
export function useIssuePage_GetRailroadPagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IssuePage_GetRailroadPagesQuery, IssuePage_GetRailroadPagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IssuePage_GetRailroadPagesQuery, IssuePage_GetRailroadPagesQueryVariables>(IssuePage_GetRailroadPagesDocument, options);
        }
export type IssuePage_GetRailroadPagesQueryHookResult = ReturnType<typeof useIssuePage_GetRailroadPagesQuery>;
export type IssuePage_GetRailroadPagesLazyQueryHookResult = ReturnType<typeof useIssuePage_GetRailroadPagesLazyQuery>;
export type IssuePage_GetRailroadPagesSuspenseQueryHookResult = ReturnType<typeof useIssuePage_GetRailroadPagesSuspenseQuery>;
export type IssuePage_GetRailroadPagesQueryResult = Apollo.QueryResult<IssuePage_GetRailroadPagesQuery, IssuePage_GetRailroadPagesQueryVariables>;
export const IssuePage_GetElisaPdfDocument = gql`
    query IssuePage_GetElisaPdf($brandKey: String!, $issue: Int!) {
  getElisaPdf(brandKey: $brandKey, issue: $issue) {
    link
  }
}
    `;

/**
 * __useIssuePage_GetElisaPdfQuery__
 *
 * To run a query within a React component, call `useIssuePage_GetElisaPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuePage_GetElisaPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuePage_GetElisaPdfQuery({
 *   variables: {
 *      brandKey: // value for 'brandKey'
 *      issue: // value for 'issue'
 *   },
 * });
 */
export function useIssuePage_GetElisaPdfQuery(baseOptions: Apollo.QueryHookOptions<IssuePage_GetElisaPdfQuery, IssuePage_GetElisaPdfQueryVariables> & ({ variables: IssuePage_GetElisaPdfQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssuePage_GetElisaPdfQuery, IssuePage_GetElisaPdfQueryVariables>(IssuePage_GetElisaPdfDocument, options);
      }
export function useIssuePage_GetElisaPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssuePage_GetElisaPdfQuery, IssuePage_GetElisaPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssuePage_GetElisaPdfQuery, IssuePage_GetElisaPdfQueryVariables>(IssuePage_GetElisaPdfDocument, options);
        }
export function useIssuePage_GetElisaPdfSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IssuePage_GetElisaPdfQuery, IssuePage_GetElisaPdfQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IssuePage_GetElisaPdfQuery, IssuePage_GetElisaPdfQueryVariables>(IssuePage_GetElisaPdfDocument, options);
        }
export type IssuePage_GetElisaPdfQueryHookResult = ReturnType<typeof useIssuePage_GetElisaPdfQuery>;
export type IssuePage_GetElisaPdfLazyQueryHookResult = ReturnType<typeof useIssuePage_GetElisaPdfLazyQuery>;
export type IssuePage_GetElisaPdfSuspenseQueryHookResult = ReturnType<typeof useIssuePage_GetElisaPdfSuspenseQuery>;
export type IssuePage_GetElisaPdfQueryResult = Apollo.QueryResult<IssuePage_GetElisaPdfQuery, IssuePage_GetElisaPdfQueryVariables>;
export const UpdatePrintHeadingDocument = gql`
    mutation UpdatePrintHeading($data: PrintHeadingUpdateInput!, $where: PrintHeadingUniqueFilterInput!) {
  updatePrintHeading(data: $data, where: $where) {
    ...PrintHeadingFragment
  }
}
    ${PrintHeadingFragmentFragmentDoc}`;
export type UpdatePrintHeadingMutationFn = Apollo.MutationFunction<UpdatePrintHeadingMutation, UpdatePrintHeadingMutationVariables>;

/**
 * __useUpdatePrintHeadingMutation__
 *
 * To run a mutation, you first call `useUpdatePrintHeadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrintHeadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrintHeadingMutation, { data, loading, error }] = useUpdatePrintHeadingMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePrintHeadingMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrintHeadingMutation, UpdatePrintHeadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrintHeadingMutation, UpdatePrintHeadingMutationVariables>(UpdatePrintHeadingDocument, options);
      }
export type UpdatePrintHeadingMutationHookResult = ReturnType<typeof useUpdatePrintHeadingMutation>;
export type UpdatePrintHeadingMutationResult = Apollo.MutationResult<UpdatePrintHeadingMutation>;
export type UpdatePrintHeadingMutationOptions = Apollo.BaseMutationOptions<UpdatePrintHeadingMutation, UpdatePrintHeadingMutationVariables>;
export const LocationPage_GetSubjectsFromPrintHeadingDocument = gql`
    query LocationPage_GetSubjectsFromPrintHeading($where: SubjectFilterInput) {
  subjects(first: 1000, where: $where) {
    id
    title
    isAuto
    subjectCategory {
      id
      title
      parent {
        id
        title
      }
    }
    flatAssignments(first: 10) {
      printHeading {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useLocationPage_GetSubjectsFromPrintHeadingQuery__
 *
 * To run a query within a React component, call `useLocationPage_GetSubjectsFromPrintHeadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_GetSubjectsFromPrintHeadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationPage_GetSubjectsFromPrintHeadingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationPage_GetSubjectsFromPrintHeadingQuery(baseOptions?: Apollo.QueryHookOptions<LocationPage_GetSubjectsFromPrintHeadingQuery, LocationPage_GetSubjectsFromPrintHeadingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationPage_GetSubjectsFromPrintHeadingQuery, LocationPage_GetSubjectsFromPrintHeadingQueryVariables>(LocationPage_GetSubjectsFromPrintHeadingDocument, options);
      }
export function useLocationPage_GetSubjectsFromPrintHeadingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationPage_GetSubjectsFromPrintHeadingQuery, LocationPage_GetSubjectsFromPrintHeadingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationPage_GetSubjectsFromPrintHeadingQuery, LocationPage_GetSubjectsFromPrintHeadingQueryVariables>(LocationPage_GetSubjectsFromPrintHeadingDocument, options);
        }
export function useLocationPage_GetSubjectsFromPrintHeadingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LocationPage_GetSubjectsFromPrintHeadingQuery, LocationPage_GetSubjectsFromPrintHeadingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LocationPage_GetSubjectsFromPrintHeadingQuery, LocationPage_GetSubjectsFromPrintHeadingQueryVariables>(LocationPage_GetSubjectsFromPrintHeadingDocument, options);
        }
export type LocationPage_GetSubjectsFromPrintHeadingQueryHookResult = ReturnType<typeof useLocationPage_GetSubjectsFromPrintHeadingQuery>;
export type LocationPage_GetSubjectsFromPrintHeadingLazyQueryHookResult = ReturnType<typeof useLocationPage_GetSubjectsFromPrintHeadingLazyQuery>;
export type LocationPage_GetSubjectsFromPrintHeadingSuspenseQueryHookResult = ReturnType<typeof useLocationPage_GetSubjectsFromPrintHeadingSuspenseQuery>;
export type LocationPage_GetSubjectsFromPrintHeadingQueryResult = Apollo.QueryResult<LocationPage_GetSubjectsFromPrintHeadingQuery, LocationPage_GetSubjectsFromPrintHeadingQueryVariables>;
export const LocationPage_DeletePrintHeadingDocument = gql`
    mutation LocationPage_DeletePrintHeading($id: ID!) {
  deletePrintHeading(where: {id: $id}) {
    id
  }
}
    `;
export type LocationPage_DeletePrintHeadingMutationFn = Apollo.MutationFunction<LocationPage_DeletePrintHeadingMutation, LocationPage_DeletePrintHeadingMutationVariables>;

/**
 * __useLocationPage_DeletePrintHeadingMutation__
 *
 * To run a mutation, you first call `useLocationPage_DeletePrintHeadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_DeletePrintHeadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationPageDeletePrintHeadingMutation, { data, loading, error }] = useLocationPage_DeletePrintHeadingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationPage_DeletePrintHeadingMutation(baseOptions?: Apollo.MutationHookOptions<LocationPage_DeletePrintHeadingMutation, LocationPage_DeletePrintHeadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationPage_DeletePrintHeadingMutation, LocationPage_DeletePrintHeadingMutationVariables>(LocationPage_DeletePrintHeadingDocument, options);
      }
export type LocationPage_DeletePrintHeadingMutationHookResult = ReturnType<typeof useLocationPage_DeletePrintHeadingMutation>;
export type LocationPage_DeletePrintHeadingMutationResult = Apollo.MutationResult<LocationPage_DeletePrintHeadingMutation>;
export type LocationPage_DeletePrintHeadingMutationOptions = Apollo.BaseMutationOptions<LocationPage_DeletePrintHeadingMutation, LocationPage_DeletePrintHeadingMutationVariables>;
export const LocationPage_GetPrintHeadingDeleteInformationDocument = gql`
    query LocationPage_GetPrintHeadingDeleteInformation($id: ID!) {
  printHeading(where: {id: $id}) {
    title
    printIssue {
      title
      printPublication {
        title
      }
    }
    assignments(first: 100) {
      subject {
        title
      }
    }
  }
}
    `;

/**
 * __useLocationPage_GetPrintHeadingDeleteInformationQuery__
 *
 * To run a query within a React component, call `useLocationPage_GetPrintHeadingDeleteInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_GetPrintHeadingDeleteInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationPage_GetPrintHeadingDeleteInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationPage_GetPrintHeadingDeleteInformationQuery(baseOptions: Apollo.QueryHookOptions<LocationPage_GetPrintHeadingDeleteInformationQuery, LocationPage_GetPrintHeadingDeleteInformationQueryVariables> & ({ variables: LocationPage_GetPrintHeadingDeleteInformationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationPage_GetPrintHeadingDeleteInformationQuery, LocationPage_GetPrintHeadingDeleteInformationQueryVariables>(LocationPage_GetPrintHeadingDeleteInformationDocument, options);
      }
export function useLocationPage_GetPrintHeadingDeleteInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationPage_GetPrintHeadingDeleteInformationQuery, LocationPage_GetPrintHeadingDeleteInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationPage_GetPrintHeadingDeleteInformationQuery, LocationPage_GetPrintHeadingDeleteInformationQueryVariables>(LocationPage_GetPrintHeadingDeleteInformationDocument, options);
        }
export function useLocationPage_GetPrintHeadingDeleteInformationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LocationPage_GetPrintHeadingDeleteInformationQuery, LocationPage_GetPrintHeadingDeleteInformationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LocationPage_GetPrintHeadingDeleteInformationQuery, LocationPage_GetPrintHeadingDeleteInformationQueryVariables>(LocationPage_GetPrintHeadingDeleteInformationDocument, options);
        }
export type LocationPage_GetPrintHeadingDeleteInformationQueryHookResult = ReturnType<typeof useLocationPage_GetPrintHeadingDeleteInformationQuery>;
export type LocationPage_GetPrintHeadingDeleteInformationLazyQueryHookResult = ReturnType<typeof useLocationPage_GetPrintHeadingDeleteInformationLazyQuery>;
export type LocationPage_GetPrintHeadingDeleteInformationSuspenseQueryHookResult = ReturnType<typeof useLocationPage_GetPrintHeadingDeleteInformationSuspenseQuery>;
export type LocationPage_GetPrintHeadingDeleteInformationQueryResult = Apollo.QueryResult<LocationPage_GetPrintHeadingDeleteInformationQuery, LocationPage_GetPrintHeadingDeleteInformationQueryVariables>;
export const LocationPage_UpsertPrintHeadingDocument = gql`
    mutation LocationPage_UpsertPrintHeading($create: PrintHeadingCreationInput!, $update: PrintHeadingUpdateInput!, $where: PrintHeadingUniqueFilterInput!) {
  upsertPrintHeading(create: $create, update: $update, where: $where) {
    ...PrintHeadingFragment
  }
}
    ${PrintHeadingFragmentFragmentDoc}`;
export type LocationPage_UpsertPrintHeadingMutationFn = Apollo.MutationFunction<LocationPage_UpsertPrintHeadingMutation, LocationPage_UpsertPrintHeadingMutationVariables>;

/**
 * __useLocationPage_UpsertPrintHeadingMutation__
 *
 * To run a mutation, you first call `useLocationPage_UpsertPrintHeadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_UpsertPrintHeadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationPageUpsertPrintHeadingMutation, { data, loading, error }] = useLocationPage_UpsertPrintHeadingMutation({
 *   variables: {
 *      create: // value for 'create'
 *      update: // value for 'update'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationPage_UpsertPrintHeadingMutation(baseOptions?: Apollo.MutationHookOptions<LocationPage_UpsertPrintHeadingMutation, LocationPage_UpsertPrintHeadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationPage_UpsertPrintHeadingMutation, LocationPage_UpsertPrintHeadingMutationVariables>(LocationPage_UpsertPrintHeadingDocument, options);
      }
export type LocationPage_UpsertPrintHeadingMutationHookResult = ReturnType<typeof useLocationPage_UpsertPrintHeadingMutation>;
export type LocationPage_UpsertPrintHeadingMutationResult = Apollo.MutationResult<LocationPage_UpsertPrintHeadingMutation>;
export type LocationPage_UpsertPrintHeadingMutationOptions = Apollo.BaseMutationOptions<LocationPage_UpsertPrintHeadingMutation, LocationPage_UpsertPrintHeadingMutationVariables>;
export const LocationPage_GetPrintHeadingByPrintIssueDocument = gql`
    query LocationPage_GetPrintHeadingByPrintIssue($printIssueId: ID!) {
  printIssue(where: {id: $printIssueId}) {
    id
    title
    printPublication {
      id
      key
    }
  }
  printHeadings(first: 1000, orderBy: order_ASC, where: {printIssue: {id: $printIssueId}}) {
    ...PrintHeadingFragment
  }
}
    ${PrintHeadingFragmentFragmentDoc}`;

/**
 * __useLocationPage_GetPrintHeadingByPrintIssueQuery__
 *
 * To run a query within a React component, call `useLocationPage_GetPrintHeadingByPrintIssueQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_GetPrintHeadingByPrintIssueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationPage_GetPrintHeadingByPrintIssueQuery({
 *   variables: {
 *      printIssueId: // value for 'printIssueId'
 *   },
 * });
 */
export function useLocationPage_GetPrintHeadingByPrintIssueQuery(baseOptions: Apollo.QueryHookOptions<LocationPage_GetPrintHeadingByPrintIssueQuery, LocationPage_GetPrintHeadingByPrintIssueQueryVariables> & ({ variables: LocationPage_GetPrintHeadingByPrintIssueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationPage_GetPrintHeadingByPrintIssueQuery, LocationPage_GetPrintHeadingByPrintIssueQueryVariables>(LocationPage_GetPrintHeadingByPrintIssueDocument, options);
      }
export function useLocationPage_GetPrintHeadingByPrintIssueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationPage_GetPrintHeadingByPrintIssueQuery, LocationPage_GetPrintHeadingByPrintIssueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationPage_GetPrintHeadingByPrintIssueQuery, LocationPage_GetPrintHeadingByPrintIssueQueryVariables>(LocationPage_GetPrintHeadingByPrintIssueDocument, options);
        }
export function useLocationPage_GetPrintHeadingByPrintIssueSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LocationPage_GetPrintHeadingByPrintIssueQuery, LocationPage_GetPrintHeadingByPrintIssueQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LocationPage_GetPrintHeadingByPrintIssueQuery, LocationPage_GetPrintHeadingByPrintIssueQueryVariables>(LocationPage_GetPrintHeadingByPrintIssueDocument, options);
        }
export type LocationPage_GetPrintHeadingByPrintIssueQueryHookResult = ReturnType<typeof useLocationPage_GetPrintHeadingByPrintIssueQuery>;
export type LocationPage_GetPrintHeadingByPrintIssueLazyQueryHookResult = ReturnType<typeof useLocationPage_GetPrintHeadingByPrintIssueLazyQuery>;
export type LocationPage_GetPrintHeadingByPrintIssueSuspenseQueryHookResult = ReturnType<typeof useLocationPage_GetPrintHeadingByPrintIssueSuspenseQuery>;
export type LocationPage_GetPrintHeadingByPrintIssueQueryResult = Apollo.QueryResult<LocationPage_GetPrintHeadingByPrintIssueQuery, LocationPage_GetPrintHeadingByPrintIssueQueryVariables>;
export const LocationPage_DeletePrintIssueDocument = gql`
    mutation LocationPage_DeletePrintIssue($id: ID!) {
  deletePrintIssue(where: {id: $id}) {
    id
  }
}
    `;
export type LocationPage_DeletePrintIssueMutationFn = Apollo.MutationFunction<LocationPage_DeletePrintIssueMutation, LocationPage_DeletePrintIssueMutationVariables>;

/**
 * __useLocationPage_DeletePrintIssueMutation__
 *
 * To run a mutation, you first call `useLocationPage_DeletePrintIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_DeletePrintIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationPageDeletePrintIssueMutation, { data, loading, error }] = useLocationPage_DeletePrintIssueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationPage_DeletePrintIssueMutation(baseOptions?: Apollo.MutationHookOptions<LocationPage_DeletePrintIssueMutation, LocationPage_DeletePrintIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationPage_DeletePrintIssueMutation, LocationPage_DeletePrintIssueMutationVariables>(LocationPage_DeletePrintIssueDocument, options);
      }
export type LocationPage_DeletePrintIssueMutationHookResult = ReturnType<typeof useLocationPage_DeletePrintIssueMutation>;
export type LocationPage_DeletePrintIssueMutationResult = Apollo.MutationResult<LocationPage_DeletePrintIssueMutation>;
export type LocationPage_DeletePrintIssueMutationOptions = Apollo.BaseMutationOptions<LocationPage_DeletePrintIssueMutation, LocationPage_DeletePrintIssueMutationVariables>;
export const LocationPage_GetDeletePrintIssueInformationDocument = gql`
    query LocationPage_GetDeletePrintIssueInformation($id: ID!) {
  printIssue(where: {id: $id}) {
    id
    assignmentCount
    title
    printHeadings(first: 100, where: {assignments_some: {id_not: ""}}) {
      title
      assignmentCount
    }
    printPublication {
      id
      title
    }
  }
}
    `;

/**
 * __useLocationPage_GetDeletePrintIssueInformationQuery__
 *
 * To run a query within a React component, call `useLocationPage_GetDeletePrintIssueInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_GetDeletePrintIssueInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationPage_GetDeletePrintIssueInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationPage_GetDeletePrintIssueInformationQuery(baseOptions: Apollo.QueryHookOptions<LocationPage_GetDeletePrintIssueInformationQuery, LocationPage_GetDeletePrintIssueInformationQueryVariables> & ({ variables: LocationPage_GetDeletePrintIssueInformationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationPage_GetDeletePrintIssueInformationQuery, LocationPage_GetDeletePrintIssueInformationQueryVariables>(LocationPage_GetDeletePrintIssueInformationDocument, options);
      }
export function useLocationPage_GetDeletePrintIssueInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationPage_GetDeletePrintIssueInformationQuery, LocationPage_GetDeletePrintIssueInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationPage_GetDeletePrintIssueInformationQuery, LocationPage_GetDeletePrintIssueInformationQueryVariables>(LocationPage_GetDeletePrintIssueInformationDocument, options);
        }
export function useLocationPage_GetDeletePrintIssueInformationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LocationPage_GetDeletePrintIssueInformationQuery, LocationPage_GetDeletePrintIssueInformationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LocationPage_GetDeletePrintIssueInformationQuery, LocationPage_GetDeletePrintIssueInformationQueryVariables>(LocationPage_GetDeletePrintIssueInformationDocument, options);
        }
export type LocationPage_GetDeletePrintIssueInformationQueryHookResult = ReturnType<typeof useLocationPage_GetDeletePrintIssueInformationQuery>;
export type LocationPage_GetDeletePrintIssueInformationLazyQueryHookResult = ReturnType<typeof useLocationPage_GetDeletePrintIssueInformationLazyQuery>;
export type LocationPage_GetDeletePrintIssueInformationSuspenseQueryHookResult = ReturnType<typeof useLocationPage_GetDeletePrintIssueInformationSuspenseQuery>;
export type LocationPage_GetDeletePrintIssueInformationQueryResult = Apollo.QueryResult<LocationPage_GetDeletePrintIssueInformationQuery, LocationPage_GetDeletePrintIssueInformationQueryVariables>;
export const LocationPage_GetPrintPublicationKeyDocument = gql`
    query LocationPage_GetPrintPublicationKey($id: ID!) {
  printPublication(where: {id: $id}) {
    key
  }
}
    `;

/**
 * __useLocationPage_GetPrintPublicationKeyQuery__
 *
 * To run a query within a React component, call `useLocationPage_GetPrintPublicationKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_GetPrintPublicationKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationPage_GetPrintPublicationKeyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationPage_GetPrintPublicationKeyQuery(baseOptions: Apollo.QueryHookOptions<LocationPage_GetPrintPublicationKeyQuery, LocationPage_GetPrintPublicationKeyQueryVariables> & ({ variables: LocationPage_GetPrintPublicationKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationPage_GetPrintPublicationKeyQuery, LocationPage_GetPrintPublicationKeyQueryVariables>(LocationPage_GetPrintPublicationKeyDocument, options);
      }
export function useLocationPage_GetPrintPublicationKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationPage_GetPrintPublicationKeyQuery, LocationPage_GetPrintPublicationKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationPage_GetPrintPublicationKeyQuery, LocationPage_GetPrintPublicationKeyQueryVariables>(LocationPage_GetPrintPublicationKeyDocument, options);
        }
export function useLocationPage_GetPrintPublicationKeySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LocationPage_GetPrintPublicationKeyQuery, LocationPage_GetPrintPublicationKeyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LocationPage_GetPrintPublicationKeyQuery, LocationPage_GetPrintPublicationKeyQueryVariables>(LocationPage_GetPrintPublicationKeyDocument, options);
        }
export type LocationPage_GetPrintPublicationKeyQueryHookResult = ReturnType<typeof useLocationPage_GetPrintPublicationKeyQuery>;
export type LocationPage_GetPrintPublicationKeyLazyQueryHookResult = ReturnType<typeof useLocationPage_GetPrintPublicationKeyLazyQuery>;
export type LocationPage_GetPrintPublicationKeySuspenseQueryHookResult = ReturnType<typeof useLocationPage_GetPrintPublicationKeySuspenseQuery>;
export type LocationPage_GetPrintPublicationKeyQueryResult = Apollo.QueryResult<LocationPage_GetPrintPublicationKeyQuery, LocationPage_GetPrintPublicationKeyQueryVariables>;
export const LocationPage_UpsertPrintIssueDocument = gql`
    mutation LocationPage_UpsertPrintIssue($create: PrintIssueCreationInput!, $update: PrintIssueUpdateInput!, $where: PrintIssueUniqueFilterInput!) {
  upsertPrintIssue(create: $create, update: $update, where: $where) {
    ...PrintIssueFragment
  }
}
    ${PrintIssueFragmentFragmentDoc}`;
export type LocationPage_UpsertPrintIssueMutationFn = Apollo.MutationFunction<LocationPage_UpsertPrintIssueMutation, LocationPage_UpsertPrintIssueMutationVariables>;

/**
 * __useLocationPage_UpsertPrintIssueMutation__
 *
 * To run a mutation, you first call `useLocationPage_UpsertPrintIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_UpsertPrintIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationPageUpsertPrintIssueMutation, { data, loading, error }] = useLocationPage_UpsertPrintIssueMutation({
 *   variables: {
 *      create: // value for 'create'
 *      update: // value for 'update'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationPage_UpsertPrintIssueMutation(baseOptions?: Apollo.MutationHookOptions<LocationPage_UpsertPrintIssueMutation, LocationPage_UpsertPrintIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationPage_UpsertPrintIssueMutation, LocationPage_UpsertPrintIssueMutationVariables>(LocationPage_UpsertPrintIssueDocument, options);
      }
export type LocationPage_UpsertPrintIssueMutationHookResult = ReturnType<typeof useLocationPage_UpsertPrintIssueMutation>;
export type LocationPage_UpsertPrintIssueMutationResult = Apollo.MutationResult<LocationPage_UpsertPrintIssueMutation>;
export type LocationPage_UpsertPrintIssueMutationOptions = Apollo.BaseMutationOptions<LocationPage_UpsertPrintIssueMutation, LocationPage_UpsertPrintIssueMutationVariables>;
export const LocationPage_GetPrintIssuesByPrintPublicationDocument = gql`
    query LocationPage_GetPrintIssuesByPrintPublication($printPublicationId: ID!) {
  printIssues(first: 1000, where: {printPublication: {id: $printPublicationId}}, orderBy: createdAt_DESC) {
    id
    title
  }
  printPublication(where: {id: $printPublicationId}) {
    id
    title
  }
}
    `;

/**
 * __useLocationPage_GetPrintIssuesByPrintPublicationQuery__
 *
 * To run a query within a React component, call `useLocationPage_GetPrintIssuesByPrintPublicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_GetPrintIssuesByPrintPublicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationPage_GetPrintIssuesByPrintPublicationQuery({
 *   variables: {
 *      printPublicationId: // value for 'printPublicationId'
 *   },
 * });
 */
export function useLocationPage_GetPrintIssuesByPrintPublicationQuery(baseOptions: Apollo.QueryHookOptions<LocationPage_GetPrintIssuesByPrintPublicationQuery, LocationPage_GetPrintIssuesByPrintPublicationQueryVariables> & ({ variables: LocationPage_GetPrintIssuesByPrintPublicationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationPage_GetPrintIssuesByPrintPublicationQuery, LocationPage_GetPrintIssuesByPrintPublicationQueryVariables>(LocationPage_GetPrintIssuesByPrintPublicationDocument, options);
      }
export function useLocationPage_GetPrintIssuesByPrintPublicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationPage_GetPrintIssuesByPrintPublicationQuery, LocationPage_GetPrintIssuesByPrintPublicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationPage_GetPrintIssuesByPrintPublicationQuery, LocationPage_GetPrintIssuesByPrintPublicationQueryVariables>(LocationPage_GetPrintIssuesByPrintPublicationDocument, options);
        }
export function useLocationPage_GetPrintIssuesByPrintPublicationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LocationPage_GetPrintIssuesByPrintPublicationQuery, LocationPage_GetPrintIssuesByPrintPublicationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LocationPage_GetPrintIssuesByPrintPublicationQuery, LocationPage_GetPrintIssuesByPrintPublicationQueryVariables>(LocationPage_GetPrintIssuesByPrintPublicationDocument, options);
        }
export type LocationPage_GetPrintIssuesByPrintPublicationQueryHookResult = ReturnType<typeof useLocationPage_GetPrintIssuesByPrintPublicationQuery>;
export type LocationPage_GetPrintIssuesByPrintPublicationLazyQueryHookResult = ReturnType<typeof useLocationPage_GetPrintIssuesByPrintPublicationLazyQuery>;
export type LocationPage_GetPrintIssuesByPrintPublicationSuspenseQueryHookResult = ReturnType<typeof useLocationPage_GetPrintIssuesByPrintPublicationSuspenseQuery>;
export type LocationPage_GetPrintIssuesByPrintPublicationQueryResult = Apollo.QueryResult<LocationPage_GetPrintIssuesByPrintPublicationQuery, LocationPage_GetPrintIssuesByPrintPublicationQueryVariables>;
export const LocationPage_UpsertPrintPublicationDocument = gql`
    mutation LocationPage_UpsertPrintPublication($create: PrintPublicationCreationInput!, $update: PrintPublicationUpdateInput!, $where: PrintPublicationUniqueFilterInput!) {
  upsertPrintPublication(create: $create, update: $update, where: $where) {
    ...PrintPublicationFragment
  }
}
    ${PrintPublicationFragmentFragmentDoc}`;
export type LocationPage_UpsertPrintPublicationMutationFn = Apollo.MutationFunction<LocationPage_UpsertPrintPublicationMutation, LocationPage_UpsertPrintPublicationMutationVariables>;

/**
 * __useLocationPage_UpsertPrintPublicationMutation__
 *
 * To run a mutation, you first call `useLocationPage_UpsertPrintPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_UpsertPrintPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationPageUpsertPrintPublicationMutation, { data, loading, error }] = useLocationPage_UpsertPrintPublicationMutation({
 *   variables: {
 *      create: // value for 'create'
 *      update: // value for 'update'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLocationPage_UpsertPrintPublicationMutation(baseOptions?: Apollo.MutationHookOptions<LocationPage_UpsertPrintPublicationMutation, LocationPage_UpsertPrintPublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationPage_UpsertPrintPublicationMutation, LocationPage_UpsertPrintPublicationMutationVariables>(LocationPage_UpsertPrintPublicationDocument, options);
      }
export type LocationPage_UpsertPrintPublicationMutationHookResult = ReturnType<typeof useLocationPage_UpsertPrintPublicationMutation>;
export type LocationPage_UpsertPrintPublicationMutationResult = Apollo.MutationResult<LocationPage_UpsertPrintPublicationMutation>;
export type LocationPage_UpsertPrintPublicationMutationOptions = Apollo.BaseMutationOptions<LocationPage_UpsertPrintPublicationMutation, LocationPage_UpsertPrintPublicationMutationVariables>;
export const LocationPage_GetPrintPublicationsByUnitDocument = gql`
    query LocationPage_GetPrintPublicationsByUnit($unitId: ID!) {
  unit(where: {id: $unitId}) {
    id
    title
    brands(first: 20) {
      id
      title
      printPublications(first: 20) {
        ...PrintPublicationFragment
      }
    }
  }
}
    ${PrintPublicationFragmentFragmentDoc}`;

/**
 * __useLocationPage_GetPrintPublicationsByUnitQuery__
 *
 * To run a query within a React component, call `useLocationPage_GetPrintPublicationsByUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_GetPrintPublicationsByUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationPage_GetPrintPublicationsByUnitQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useLocationPage_GetPrintPublicationsByUnitQuery(baseOptions: Apollo.QueryHookOptions<LocationPage_GetPrintPublicationsByUnitQuery, LocationPage_GetPrintPublicationsByUnitQueryVariables> & ({ variables: LocationPage_GetPrintPublicationsByUnitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationPage_GetPrintPublicationsByUnitQuery, LocationPage_GetPrintPublicationsByUnitQueryVariables>(LocationPage_GetPrintPublicationsByUnitDocument, options);
      }
export function useLocationPage_GetPrintPublicationsByUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationPage_GetPrintPublicationsByUnitQuery, LocationPage_GetPrintPublicationsByUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationPage_GetPrintPublicationsByUnitQuery, LocationPage_GetPrintPublicationsByUnitQueryVariables>(LocationPage_GetPrintPublicationsByUnitDocument, options);
        }
export function useLocationPage_GetPrintPublicationsByUnitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LocationPage_GetPrintPublicationsByUnitQuery, LocationPage_GetPrintPublicationsByUnitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LocationPage_GetPrintPublicationsByUnitQuery, LocationPage_GetPrintPublicationsByUnitQueryVariables>(LocationPage_GetPrintPublicationsByUnitDocument, options);
        }
export type LocationPage_GetPrintPublicationsByUnitQueryHookResult = ReturnType<typeof useLocationPage_GetPrintPublicationsByUnitQuery>;
export type LocationPage_GetPrintPublicationsByUnitLazyQueryHookResult = ReturnType<typeof useLocationPage_GetPrintPublicationsByUnitLazyQuery>;
export type LocationPage_GetPrintPublicationsByUnitSuspenseQueryHookResult = ReturnType<typeof useLocationPage_GetPrintPublicationsByUnitSuspenseQuery>;
export type LocationPage_GetPrintPublicationsByUnitQueryResult = Apollo.QueryResult<LocationPage_GetPrintPublicationsByUnitQuery, LocationPage_GetPrintPublicationsByUnitQueryVariables>;
export const LocationPage_UpdatePrintHeadingOrderDocument = gql`
    mutation LocationPage_UpdatePrintHeadingOrder($id: ID!, $data: PrintIssueUpdateInput!) {
  updatePrintIssue(data: $data, where: {id: $id}) {
    ...PrintIssueFragment
  }
}
    ${PrintIssueFragmentFragmentDoc}`;
export type LocationPage_UpdatePrintHeadingOrderMutationFn = Apollo.MutationFunction<LocationPage_UpdatePrintHeadingOrderMutation, LocationPage_UpdatePrintHeadingOrderMutationVariables>;

/**
 * __useLocationPage_UpdatePrintHeadingOrderMutation__
 *
 * To run a mutation, you first call `useLocationPage_UpdatePrintHeadingOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationPage_UpdatePrintHeadingOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationPageUpdatePrintHeadingOrderMutation, { data, loading, error }] = useLocationPage_UpdatePrintHeadingOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLocationPage_UpdatePrintHeadingOrderMutation(baseOptions?: Apollo.MutationHookOptions<LocationPage_UpdatePrintHeadingOrderMutation, LocationPage_UpdatePrintHeadingOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationPage_UpdatePrintHeadingOrderMutation, LocationPage_UpdatePrintHeadingOrderMutationVariables>(LocationPage_UpdatePrintHeadingOrderDocument, options);
      }
export type LocationPage_UpdatePrintHeadingOrderMutationHookResult = ReturnType<typeof useLocationPage_UpdatePrintHeadingOrderMutation>;
export type LocationPage_UpdatePrintHeadingOrderMutationResult = Apollo.MutationResult<LocationPage_UpdatePrintHeadingOrderMutation>;
export type LocationPage_UpdatePrintHeadingOrderMutationOptions = Apollo.BaseMutationOptions<LocationPage_UpdatePrintHeadingOrderMutation, LocationPage_UpdatePrintHeadingOrderMutationVariables>;
export const SubjectForm_DeleteAssignmentDocument = gql`
    mutation SubjectForm_DeleteAssignment($id: ID!) {
  deleteAssignment(where: {id: $id}) {
    id
  }
}
    `;
export type SubjectForm_DeleteAssignmentMutationFn = Apollo.MutationFunction<SubjectForm_DeleteAssignmentMutation, SubjectForm_DeleteAssignmentMutationVariables>;

/**
 * __useSubjectForm_DeleteAssignmentMutation__
 *
 * To run a mutation, you first call `useSubjectForm_DeleteAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubjectForm_DeleteAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subjectFormDeleteAssignmentMutation, { data, loading, error }] = useSubjectForm_DeleteAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubjectForm_DeleteAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<SubjectForm_DeleteAssignmentMutation, SubjectForm_DeleteAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubjectForm_DeleteAssignmentMutation, SubjectForm_DeleteAssignmentMutationVariables>(SubjectForm_DeleteAssignmentDocument, options);
      }
export type SubjectForm_DeleteAssignmentMutationHookResult = ReturnType<typeof useSubjectForm_DeleteAssignmentMutation>;
export type SubjectForm_DeleteAssignmentMutationResult = Apollo.MutationResult<SubjectForm_DeleteAssignmentMutation>;
export type SubjectForm_DeleteAssignmentMutationOptions = Apollo.BaseMutationOptions<SubjectForm_DeleteAssignmentMutation, SubjectForm_DeleteAssignmentMutationVariables>;
export const DeleteAttachmentDocument = gql`
    mutation DeleteAttachment($id: ID!) {
  deleteAttachment(where: {id: $id}) {
    id
  }
}
    `;
export type DeleteAttachmentMutationFn = Apollo.MutationFunction<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>(DeleteAttachmentDocument, options);
      }
export type DeleteAttachmentMutationHookResult = ReturnType<typeof useDeleteAttachmentMutation>;
export type DeleteAttachmentMutationResult = Apollo.MutationResult<DeleteAttachmentMutation>;
export type DeleteAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;
export const CreateAttachmentDocument = gql`
    mutation CreateAttachment($data: AttachmentCreationInput!) {
  createAttachment(data: $data) {
    ...CreateAttachmentFragment
  }
}
    ${CreateAttachmentFragmentFragmentDoc}`;
export type CreateAttachmentMutationFn = Apollo.MutationFunction<CreateAttachmentMutation, CreateAttachmentMutationVariables>;

/**
 * __useCreateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentMutation, { data, loading, error }] = useCreateAttachmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAttachmentMutation, CreateAttachmentMutationVariables>(CreateAttachmentDocument, options);
      }
export type CreateAttachmentMutationHookResult = ReturnType<typeof useCreateAttachmentMutation>;
export type CreateAttachmentMutationResult = Apollo.MutationResult<CreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>;
export const UpdateAttachmentDocument = gql`
    mutation UpdateAttachment($data: AttachmentUpdateInput!, $where: AttachmentUniqueFilterInput!) {
  updateAttachment(data: $data, where: $where) {
    ...AttachmentFragment
  }
}
    ${AttachmentFragmentFragmentDoc}`;
export type UpdateAttachmentMutationFn = Apollo.MutationFunction<UpdateAttachmentMutation, UpdateAttachmentMutationVariables>;

/**
 * __useUpdateAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttachmentMutation, { data, loading, error }] = useUpdateAttachmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAttachmentMutation, UpdateAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAttachmentMutation, UpdateAttachmentMutationVariables>(UpdateAttachmentDocument, options);
      }
export type UpdateAttachmentMutationHookResult = ReturnType<typeof useUpdateAttachmentMutation>;
export type UpdateAttachmentMutationResult = Apollo.MutationResult<UpdateAttachmentMutation>;
export type UpdateAttachmentMutationOptions = Apollo.BaseMutationOptions<UpdateAttachmentMutation, UpdateAttachmentMutationVariables>;
export const CreateRawArticleDocument = gql`
    mutation CreateRawArticle($data: RawArticleCreationInput!) {
  createRawArticle(data: $data) {
    id
  }
}
    `;
export type CreateRawArticleMutationFn = Apollo.MutationFunction<CreateRawArticleMutation, CreateRawArticleMutationVariables>;

/**
 * __useCreateRawArticleMutation__
 *
 * To run a mutation, you first call `useCreateRawArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRawArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRawArticleMutation, { data, loading, error }] = useCreateRawArticleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRawArticleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRawArticleMutation, CreateRawArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRawArticleMutation, CreateRawArticleMutationVariables>(CreateRawArticleDocument, options);
      }
export type CreateRawArticleMutationHookResult = ReturnType<typeof useCreateRawArticleMutation>;
export type CreateRawArticleMutationResult = Apollo.MutationResult<CreateRawArticleMutation>;
export type CreateRawArticleMutationOptions = Apollo.BaseMutationOptions<CreateRawArticleMutation, CreateRawArticleMutationVariables>;
export const UpsertSubjectDocument = gql`
    mutation UpsertSubject($create: SubjectCreationInput!, $update: SubjectUpdateInput!, $where: SubjectUniqueFilterInput!) {
  upsertSubject(create: $create, update: $update, where: $where) {
    id
    title
    comment
    synopsis
    mediaRequest
  }
}
    `;
export type UpsertSubjectMutationFn = Apollo.MutationFunction<UpsertSubjectMutation, UpsertSubjectMutationVariables>;

/**
 * __useUpsertSubjectMutation__
 *
 * To run a mutation, you first call `useUpsertSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSubjectMutation, { data, loading, error }] = useUpsertSubjectMutation({
 *   variables: {
 *      create: // value for 'create'
 *      update: // value for 'update'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpsertSubjectMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSubjectMutation, UpsertSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSubjectMutation, UpsertSubjectMutationVariables>(UpsertSubjectDocument, options);
      }
export type UpsertSubjectMutationHookResult = ReturnType<typeof useUpsertSubjectMutation>;
export type UpsertSubjectMutationResult = Apollo.MutationResult<UpsertSubjectMutation>;
export type UpsertSubjectMutationOptions = Apollo.BaseMutationOptions<UpsertSubjectMutation, UpsertSubjectMutationVariables>;
export const GetPrintSubjectDocument = gql`
    query GetPrintSubject($id: ID!) {
  subject(where: {id: $id}) {
    title
    synopsis
    comment
    mediaRequest
    subjectCategory {
      id
      title
      parent {
        title
      }
    }
    assignments: flatAssignments(first: 20) {
      ...PrintSubject_FlatAssignment
    }
  }
}
    ${PrintSubject_FlatAssignmentFragmentDoc}`;

/**
 * __useGetPrintSubjectQuery__
 *
 * To run a query within a React component, call `useGetPrintSubjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintSubjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintSubjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrintSubjectQuery(baseOptions: Apollo.QueryHookOptions<GetPrintSubjectQuery, GetPrintSubjectQueryVariables> & ({ variables: GetPrintSubjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrintSubjectQuery, GetPrintSubjectQueryVariables>(GetPrintSubjectDocument, options);
      }
export function useGetPrintSubjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrintSubjectQuery, GetPrintSubjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrintSubjectQuery, GetPrintSubjectQueryVariables>(GetPrintSubjectDocument, options);
        }
export function useGetPrintSubjectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrintSubjectQuery, GetPrintSubjectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPrintSubjectQuery, GetPrintSubjectQueryVariables>(GetPrintSubjectDocument, options);
        }
export type GetPrintSubjectQueryHookResult = ReturnType<typeof useGetPrintSubjectQuery>;
export type GetPrintSubjectLazyQueryHookResult = ReturnType<typeof useGetPrintSubjectLazyQuery>;
export type GetPrintSubjectSuspenseQueryHookResult = ReturnType<typeof useGetPrintSubjectSuspenseQuery>;
export type GetPrintSubjectQueryResult = Apollo.QueryResult<GetPrintSubjectQuery, GetPrintSubjectQueryVariables>;
export const GetSubjectCategoryDocument = gql`
    query GetSubjectCategory($unitId: ID!) {
  subjectCategories(where: {parent_is_null: true, unit: {id: $unitId}}, first: 1000) {
    id
    title
    parent {
      id
    }
    children(first: 1000) {
      id
      title
    }
  }
}
    `;

/**
 * __useGetSubjectCategoryQuery__
 *
 * To run a query within a React component, call `useGetSubjectCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectCategoryQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useGetSubjectCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetSubjectCategoryQuery, GetSubjectCategoryQueryVariables> & ({ variables: GetSubjectCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectCategoryQuery, GetSubjectCategoryQueryVariables>(GetSubjectCategoryDocument, options);
      }
export function useGetSubjectCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectCategoryQuery, GetSubjectCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectCategoryQuery, GetSubjectCategoryQueryVariables>(GetSubjectCategoryDocument, options);
        }
export function useGetSubjectCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSubjectCategoryQuery, GetSubjectCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubjectCategoryQuery, GetSubjectCategoryQueryVariables>(GetSubjectCategoryDocument, options);
        }
export type GetSubjectCategoryQueryHookResult = ReturnType<typeof useGetSubjectCategoryQuery>;
export type GetSubjectCategoryLazyQueryHookResult = ReturnType<typeof useGetSubjectCategoryLazyQuery>;
export type GetSubjectCategorySuspenseQueryHookResult = ReturnType<typeof useGetSubjectCategorySuspenseQuery>;
export type GetSubjectCategoryQueryResult = Apollo.QueryResult<GetSubjectCategoryQuery, GetSubjectCategoryQueryVariables>;
export const GetSubjectDocument = gql`
    query GetSubject($id: ID!) {
  subject(where: {id: $id}) {
    id
    title
    synopsis
    comment
    mediaRequest
    createdBy {
      name
    }
    unit {
      id
      title
      photoArchiveId
    }
    subjectCategory {
      id
      parent {
        id
      }
    }
    assignments: flatAssignments(first: 20) {
      ...SubjectForm_FlatAssignment
    }
    isClassified
    subjectUsers(first: 100) {
      ...SubjectUser
    }
    attachments(first: 100, where: {isUploaded: true}) {
      ...AttachmentFragment
    }
    isTitleWritable
  }
}
    ${SubjectForm_FlatAssignmentFragmentDoc}
${SubjectUserFragmentDoc}
${AttachmentFragmentFragmentDoc}`;

/**
 * __useGetSubjectQuery__
 *
 * To run a query within a React component, call `useGetSubjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubjectQuery(baseOptions: Apollo.QueryHookOptions<GetSubjectQuery, GetSubjectQueryVariables> & ({ variables: GetSubjectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectQuery, GetSubjectQueryVariables>(GetSubjectDocument, options);
      }
export function useGetSubjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectQuery, GetSubjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectQuery, GetSubjectQueryVariables>(GetSubjectDocument, options);
        }
export function useGetSubjectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSubjectQuery, GetSubjectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubjectQuery, GetSubjectQueryVariables>(GetSubjectDocument, options);
        }
export type GetSubjectQueryHookResult = ReturnType<typeof useGetSubjectQuery>;
export type GetSubjectLazyQueryHookResult = ReturnType<typeof useGetSubjectLazyQuery>;
export type GetSubjectSuspenseQueryHookResult = ReturnType<typeof useGetSubjectSuspenseQuery>;
export type GetSubjectQueryResult = Apollo.QueryResult<GetSubjectQuery, GetSubjectQueryVariables>;
export const SubjectListPage_GetCategoryTreeDocument = gql`
    query SubjectListPage_GetCategoryTree {
  subjectCategories(first: 1000, where: {parent_is_null: true}) {
    id
    title
    children(first: 100) {
      id
      title
    }
  }
}
    `;

/**
 * __useSubjectListPage_GetCategoryTreeQuery__
 *
 * To run a query within a React component, call `useSubjectListPage_GetCategoryTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectListPage_GetCategoryTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectListPage_GetCategoryTreeQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubjectListPage_GetCategoryTreeQuery(baseOptions?: Apollo.QueryHookOptions<SubjectListPage_GetCategoryTreeQuery, SubjectListPage_GetCategoryTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubjectListPage_GetCategoryTreeQuery, SubjectListPage_GetCategoryTreeQueryVariables>(SubjectListPage_GetCategoryTreeDocument, options);
      }
export function useSubjectListPage_GetCategoryTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubjectListPage_GetCategoryTreeQuery, SubjectListPage_GetCategoryTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubjectListPage_GetCategoryTreeQuery, SubjectListPage_GetCategoryTreeQueryVariables>(SubjectListPage_GetCategoryTreeDocument, options);
        }
export function useSubjectListPage_GetCategoryTreeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubjectListPage_GetCategoryTreeQuery, SubjectListPage_GetCategoryTreeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubjectListPage_GetCategoryTreeQuery, SubjectListPage_GetCategoryTreeQueryVariables>(SubjectListPage_GetCategoryTreeDocument, options);
        }
export type SubjectListPage_GetCategoryTreeQueryHookResult = ReturnType<typeof useSubjectListPage_GetCategoryTreeQuery>;
export type SubjectListPage_GetCategoryTreeLazyQueryHookResult = ReturnType<typeof useSubjectListPage_GetCategoryTreeLazyQuery>;
export type SubjectListPage_GetCategoryTreeSuspenseQueryHookResult = ReturnType<typeof useSubjectListPage_GetCategoryTreeSuspenseQuery>;
export type SubjectListPage_GetCategoryTreeQueryResult = Apollo.QueryResult<SubjectListPage_GetCategoryTreeQuery, SubjectListPage_GetCategoryTreeQueryVariables>;
export const SubjectListPage_GetFilteredCategoryTreeDocument = gql`
    query SubjectListPage_GetFilteredCategoryTree($where: SubjectCategoryFilterInput!) {
  subjectCategories(first: 1000, where: {AND: [{parent_is_null: true}, $where]}) {
    id
    title
    children(first: 100) {
      id
      title
    }
  }
}
    `;

/**
 * __useSubjectListPage_GetFilteredCategoryTreeQuery__
 *
 * To run a query within a React component, call `useSubjectListPage_GetFilteredCategoryTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectListPage_GetFilteredCategoryTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectListPage_GetFilteredCategoryTreeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubjectListPage_GetFilteredCategoryTreeQuery(baseOptions: Apollo.QueryHookOptions<SubjectListPage_GetFilteredCategoryTreeQuery, SubjectListPage_GetFilteredCategoryTreeQueryVariables> & ({ variables: SubjectListPage_GetFilteredCategoryTreeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubjectListPage_GetFilteredCategoryTreeQuery, SubjectListPage_GetFilteredCategoryTreeQueryVariables>(SubjectListPage_GetFilteredCategoryTreeDocument, options);
      }
export function useSubjectListPage_GetFilteredCategoryTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubjectListPage_GetFilteredCategoryTreeQuery, SubjectListPage_GetFilteredCategoryTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubjectListPage_GetFilteredCategoryTreeQuery, SubjectListPage_GetFilteredCategoryTreeQueryVariables>(SubjectListPage_GetFilteredCategoryTreeDocument, options);
        }
export function useSubjectListPage_GetFilteredCategoryTreeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubjectListPage_GetFilteredCategoryTreeQuery, SubjectListPage_GetFilteredCategoryTreeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubjectListPage_GetFilteredCategoryTreeQuery, SubjectListPage_GetFilteredCategoryTreeQueryVariables>(SubjectListPage_GetFilteredCategoryTreeDocument, options);
        }
export type SubjectListPage_GetFilteredCategoryTreeQueryHookResult = ReturnType<typeof useSubjectListPage_GetFilteredCategoryTreeQuery>;
export type SubjectListPage_GetFilteredCategoryTreeLazyQueryHookResult = ReturnType<typeof useSubjectListPage_GetFilteredCategoryTreeLazyQuery>;
export type SubjectListPage_GetFilteredCategoryTreeSuspenseQueryHookResult = ReturnType<typeof useSubjectListPage_GetFilteredCategoryTreeSuspenseQuery>;
export type SubjectListPage_GetFilteredCategoryTreeQueryResult = Apollo.QueryResult<SubjectListPage_GetFilteredCategoryTreeQuery, SubjectListPage_GetFilteredCategoryTreeQueryVariables>;
export const SubjectListPage_GetFilteredLocationTreeDocument = gql`
    query SubjectListPage_GetFilteredLocationTree($searchString: NonEmptyString!, $where: BrandFilterInput!) {
  brands(first: 20, where: {AND: [$where, {OR: [{title_contains: $searchString}, {printPublications_some: {title_contains: $searchString}}, {printPublications_some: {printIssues_some: {title_contains: $searchString}}}]}]}) {
    id
    title
    printPublications(first: 20, where: {OR: [{title_contains: $searchString}, {printIssues_some: {title_contains: $searchString}}]}) {
      id
      title
      printIssues(where: {title_contains: $searchString}, first: 10) {
        id
        title
      }
    }
    websites(where: {title_contains: $searchString}, first: 10) {
      id
      title
      key
    }
  }
}
    `;

/**
 * __useSubjectListPage_GetFilteredLocationTreeQuery__
 *
 * To run a query within a React component, call `useSubjectListPage_GetFilteredLocationTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectListPage_GetFilteredLocationTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectListPage_GetFilteredLocationTreeQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubjectListPage_GetFilteredLocationTreeQuery(baseOptions: Apollo.QueryHookOptions<SubjectListPage_GetFilteredLocationTreeQuery, SubjectListPage_GetFilteredLocationTreeQueryVariables> & ({ variables: SubjectListPage_GetFilteredLocationTreeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubjectListPage_GetFilteredLocationTreeQuery, SubjectListPage_GetFilteredLocationTreeQueryVariables>(SubjectListPage_GetFilteredLocationTreeDocument, options);
      }
export function useSubjectListPage_GetFilteredLocationTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubjectListPage_GetFilteredLocationTreeQuery, SubjectListPage_GetFilteredLocationTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubjectListPage_GetFilteredLocationTreeQuery, SubjectListPage_GetFilteredLocationTreeQueryVariables>(SubjectListPage_GetFilteredLocationTreeDocument, options);
        }
export function useSubjectListPage_GetFilteredLocationTreeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubjectListPage_GetFilteredLocationTreeQuery, SubjectListPage_GetFilteredLocationTreeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubjectListPage_GetFilteredLocationTreeQuery, SubjectListPage_GetFilteredLocationTreeQueryVariables>(SubjectListPage_GetFilteredLocationTreeDocument, options);
        }
export type SubjectListPage_GetFilteredLocationTreeQueryHookResult = ReturnType<typeof useSubjectListPage_GetFilteredLocationTreeQuery>;
export type SubjectListPage_GetFilteredLocationTreeLazyQueryHookResult = ReturnType<typeof useSubjectListPage_GetFilteredLocationTreeLazyQuery>;
export type SubjectListPage_GetFilteredLocationTreeSuspenseQueryHookResult = ReturnType<typeof useSubjectListPage_GetFilteredLocationTreeSuspenseQuery>;
export type SubjectListPage_GetFilteredLocationTreeQueryResult = Apollo.QueryResult<SubjectListPage_GetFilteredLocationTreeQuery, SubjectListPage_GetFilteredLocationTreeQueryVariables>;
export const SubjectListPage_GetLocationTreeDocument = gql`
    query SubjectListPage_GetLocationTree($where: BrandFilterInput!) {
  brands(first: 100, where: $where) {
    id
    title
    printPublications(first: 100) {
      id
      title
      printIssues(first: 100, orderBy: createdAt_DESC) {
        id
        title
      }
    }
    websites(first: 10) {
      id
      title
      key
    }
  }
}
    `;

/**
 * __useSubjectListPage_GetLocationTreeQuery__
 *
 * To run a query within a React component, call `useSubjectListPage_GetLocationTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectListPage_GetLocationTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectListPage_GetLocationTreeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubjectListPage_GetLocationTreeQuery(baseOptions: Apollo.QueryHookOptions<SubjectListPage_GetLocationTreeQuery, SubjectListPage_GetLocationTreeQueryVariables> & ({ variables: SubjectListPage_GetLocationTreeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubjectListPage_GetLocationTreeQuery, SubjectListPage_GetLocationTreeQueryVariables>(SubjectListPage_GetLocationTreeDocument, options);
      }
export function useSubjectListPage_GetLocationTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubjectListPage_GetLocationTreeQuery, SubjectListPage_GetLocationTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubjectListPage_GetLocationTreeQuery, SubjectListPage_GetLocationTreeQueryVariables>(SubjectListPage_GetLocationTreeDocument, options);
        }
export function useSubjectListPage_GetLocationTreeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubjectListPage_GetLocationTreeQuery, SubjectListPage_GetLocationTreeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubjectListPage_GetLocationTreeQuery, SubjectListPage_GetLocationTreeQueryVariables>(SubjectListPage_GetLocationTreeDocument, options);
        }
export type SubjectListPage_GetLocationTreeQueryHookResult = ReturnType<typeof useSubjectListPage_GetLocationTreeQuery>;
export type SubjectListPage_GetLocationTreeLazyQueryHookResult = ReturnType<typeof useSubjectListPage_GetLocationTreeLazyQuery>;
export type SubjectListPage_GetLocationTreeSuspenseQueryHookResult = ReturnType<typeof useSubjectListPage_GetLocationTreeSuspenseQuery>;
export type SubjectListPage_GetLocationTreeQueryResult = Apollo.QueryResult<SubjectListPage_GetLocationTreeQuery, SubjectListPage_GetLocationTreeQueryVariables>;
export const GetSubjectsDocument = gql`
    query GetSubjects($where: SubjectFilterInput!, $skip: UnsignedInt!, $first: UnsignedInt = 20) {
  subjects(where: $where, skip: $skip, orderBy: [createdAt_DESC], first: $first) {
    id
    title
    comment
    assignments(first: 20) {
      ...AssignmentItem
    }
    assignmentCount
    subjectCategory {
      id
      title
      parent {
        id
        title
      }
    }
    unit {
      id
      title
      photoArchiveId
      brands(first: 20) {
        id
        title
      }
    }
    isClassified
  }
}
    ${AssignmentItemFragmentDoc}`;

/**
 * __useGetSubjectsQuery__
 *
 * To run a query within a React component, call `useGetSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetSubjectsQuery(baseOptions: Apollo.QueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables> & ({ variables: GetSubjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GetSubjectsDocument, options);
      }
export function useGetSubjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GetSubjectsDocument, options);
        }
export function useGetSubjectsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GetSubjectsDocument, options);
        }
export type GetSubjectsQueryHookResult = ReturnType<typeof useGetSubjectsQuery>;
export type GetSubjectsLazyQueryHookResult = ReturnType<typeof useGetSubjectsLazyQuery>;
export type GetSubjectsSuspenseQueryHookResult = ReturnType<typeof useGetSubjectsSuspenseQuery>;
export type GetSubjectsQueryResult = Apollo.QueryResult<GetSubjectsQuery, GetSubjectsQueryVariables>;
export const SubjectListPage_GetPrintPublicationsByUnitDocument = gql`
    query SubjectListPage_GetPrintPublicationsByUnit {
  units(first: 20) {
    id
    title
    brands(first: 20) {
      title
      printPublications(first: 20) {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useSubjectListPage_GetPrintPublicationsByUnitQuery__
 *
 * To run a query within a React component, call `useSubjectListPage_GetPrintPublicationsByUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectListPage_GetPrintPublicationsByUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectListPage_GetPrintPublicationsByUnitQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubjectListPage_GetPrintPublicationsByUnitQuery(baseOptions?: Apollo.QueryHookOptions<SubjectListPage_GetPrintPublicationsByUnitQuery, SubjectListPage_GetPrintPublicationsByUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubjectListPage_GetPrintPublicationsByUnitQuery, SubjectListPage_GetPrintPublicationsByUnitQueryVariables>(SubjectListPage_GetPrintPublicationsByUnitDocument, options);
      }
export function useSubjectListPage_GetPrintPublicationsByUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubjectListPage_GetPrintPublicationsByUnitQuery, SubjectListPage_GetPrintPublicationsByUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubjectListPage_GetPrintPublicationsByUnitQuery, SubjectListPage_GetPrintPublicationsByUnitQueryVariables>(SubjectListPage_GetPrintPublicationsByUnitDocument, options);
        }
export function useSubjectListPage_GetPrintPublicationsByUnitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubjectListPage_GetPrintPublicationsByUnitQuery, SubjectListPage_GetPrintPublicationsByUnitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubjectListPage_GetPrintPublicationsByUnitQuery, SubjectListPage_GetPrintPublicationsByUnitQueryVariables>(SubjectListPage_GetPrintPublicationsByUnitDocument, options);
        }
export type SubjectListPage_GetPrintPublicationsByUnitQueryHookResult = ReturnType<typeof useSubjectListPage_GetPrintPublicationsByUnitQuery>;
export type SubjectListPage_GetPrintPublicationsByUnitLazyQueryHookResult = ReturnType<typeof useSubjectListPage_GetPrintPublicationsByUnitLazyQuery>;
export type SubjectListPage_GetPrintPublicationsByUnitSuspenseQueryHookResult = ReturnType<typeof useSubjectListPage_GetPrintPublicationsByUnitSuspenseQuery>;
export type SubjectListPage_GetPrintPublicationsByUnitQueryResult = Apollo.QueryResult<SubjectListPage_GetPrintPublicationsByUnitQuery, SubjectListPage_GetPrintPublicationsByUnitQueryVariables>;
export const GetUnfilteredSubjectListByIssueDocument = gql`
    query GetUnfilteredSubjectListByIssue($id: ID!) {
  printIssue(where: {id: $id}) {
    id
    title
    printHeadings(first: 250, orderBy: order_ASC) {
      id
      title
      autoPrototype
      printTemplates(first: 1, where: {active: true}) {
        printTemplate {
          type
        }
      }
      assignments(first: 200) {
        id
        printTemplate {
          id
        }
        mediaSearchStatus
        pageNumber
        subject {
          id
          title
          comment
          subjectCategory {
            id
            title
            parent {
              id
              title
            }
          }
          isClassified
          assignments: flatAssignments(first: 20) {
            ...SubjectForm_FlatAssignment
          }
        }
        website {
          id
          title
          key
        }
        rawArticle {
          id
          versions(first: 20, where: {nextVersion_is_null: true}) {
            id
            status
            title
            createdAt
            editor {
              id
              name
            }
          }
        }
        articleTemplate {
          id
        }
      }
    }
  }
}
    ${SubjectForm_FlatAssignmentFragmentDoc}`;

/**
 * __useGetUnfilteredSubjectListByIssueQuery__
 *
 * To run a query within a React component, call `useGetUnfilteredSubjectListByIssueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnfilteredSubjectListByIssueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnfilteredSubjectListByIssueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnfilteredSubjectListByIssueQuery(baseOptions: Apollo.QueryHookOptions<GetUnfilteredSubjectListByIssueQuery, GetUnfilteredSubjectListByIssueQueryVariables> & ({ variables: GetUnfilteredSubjectListByIssueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnfilteredSubjectListByIssueQuery, GetUnfilteredSubjectListByIssueQueryVariables>(GetUnfilteredSubjectListByIssueDocument, options);
      }
export function useGetUnfilteredSubjectListByIssueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnfilteredSubjectListByIssueQuery, GetUnfilteredSubjectListByIssueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnfilteredSubjectListByIssueQuery, GetUnfilteredSubjectListByIssueQueryVariables>(GetUnfilteredSubjectListByIssueDocument, options);
        }
export function useGetUnfilteredSubjectListByIssueSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUnfilteredSubjectListByIssueQuery, GetUnfilteredSubjectListByIssueQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnfilteredSubjectListByIssueQuery, GetUnfilteredSubjectListByIssueQueryVariables>(GetUnfilteredSubjectListByIssueDocument, options);
        }
export type GetUnfilteredSubjectListByIssueQueryHookResult = ReturnType<typeof useGetUnfilteredSubjectListByIssueQuery>;
export type GetUnfilteredSubjectListByIssueLazyQueryHookResult = ReturnType<typeof useGetUnfilteredSubjectListByIssueLazyQuery>;
export type GetUnfilteredSubjectListByIssueSuspenseQueryHookResult = ReturnType<typeof useGetUnfilteredSubjectListByIssueSuspenseQuery>;
export type GetUnfilteredSubjectListByIssueQueryResult = Apollo.QueryResult<GetUnfilteredSubjectListByIssueQuery, GetUnfilteredSubjectListByIssueQueryVariables>;